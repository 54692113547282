import * as React from 'react';
import {AssetEntity, DocumentEntity, FormsEntity, MehubAccountEntity} from 'Models/Entities';
import SecuredPage from 'Views/Components/Security/SecuredPage';
import EntityCRUD from 'Views/Components/CRUD/EntityCRUD';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router';
import { getFrontendNavLinks } from 'Views/FrontendNavLinks';
import Navigation, { Orientation } from 'Views/Components/Navigation/Navigation';
import NewAsset from "../CustomComponents/NewAsset";
import {lowerCaseFirst} from "../../Util/StringUtils";
import {getFormListQuery} from "../../Forms/Admin/Version/FormVersionCollection";
import {getFetchSingleQuery} from "../../Util/EntityUtils";
import Collection from "../Components/Collection/Collection";
import {Button, Colors, Display, Sizes} from "../Components/Button/Button";
import {IAssetEntityAttributes} from "../../Models/Entities/AssetEntity";
import {action, computed, observable, runInAction} from "mobx";
import {store} from "../../Models/Store";
import axios from "axios";
import {SERVER_URL} from "../../Constants";
import alert from "../../Util/ToastifyUtils";
import If from "../Components/If/If";
import Tabs, {ITabConfig} from "../Components/Tabs/Tabs";
import {Model} from "../../Models/Model";
import {Comparison} from "../../Forms/Schema/Question";
import {HasCondition, IWhereCondition} from "../Components/ModelCollection/ModelQuery";


export interface ActiveDocumentsPageProps {
    id: string;
}

enum ActiveDocumentsTabNames {
    SuggestedDocuments = "Suggested documents",
    ActiveDocuments = "Active documents" 
}

@observer
class ActiveDocumentsPage extends React.Component<RouteComponentProps<ActiveDocumentsPageProps>> {

    @observable
    private tabIndex: number = 0;

    @observable
    private formEntity: FormsEntity;
    
    componentDidMount() {
        FormsEntity.fetch<FormsEntity>({
            ids: [this.props.match.params.id]
        }).then(form => {
            runInAction(() => {
                this.formEntity = form[0];
            });
            return form[0];
        });
    }

    @action
    private changeTab = (index: number) => {
        this.tabIndex = index;
    }

    @computed
    private get currentTabName(): ActiveDocumentsTabNames {
        return this.getTabs()[this.tabIndex].name as ActiveDocumentsTabNames;
    }

    private getTabs(): ITabConfig[] {
        let tabs = [];

        tabs.push({
            name: ActiveDocumentsTabNames.SuggestedDocuments,
            key: '',
            component: null
        });

        tabs.push({
            name: ActiveDocumentsTabNames.ActiveDocuments,
            key: 'ACTIVE',
            component: null
        });

        return tabs;
    }

    private renderTabs(isRenderTabs: boolean) {
        if (isRenderTabs) {
            return <Tabs className='project-tab-nav-documents'
                         tabs={this.getTabs()}
                         onTabClicked={this.changeTab}
                         defaultTab={0}
                         currentTab={this.tabIndex}>
            </Tabs>;
        }
        return null;
    }

    @observable
    private selectedItems: string[];

    @action
    private onItemSelectionChange = (models: string[]) => {
        this.selectedItems = models;
    };
    private cancelAttachDocuments = () => {
        store.routerHistory.push(`/safety-form/${this.props.match.params.id}`);
    }
    private attachDocumentsToForm = async () => {
        try {
            await axios.post(`${SERVER_URL}/api/entity/DocumentEntity/attachDocuments`, {
                formId: this.props.match.params.id,
                documents: this.selectedItems
            });

            alert('Successfully attached documents', 'success');

            // Finish attaching will assign the assets to the form and take the user back to form
            store.routerHistory.push(`/safety-form/${this.props.match.params.id}`);
        } catch (e) {
            alert('Failed to attach documents to the form', 'error');
            console.error(e);
        }
    }
    private downloadDocument = (model: DocumentEntity,
                                event: React.MouseEvent<Element, MouseEvent>) => {
        // models.forEach((model, index) => {
            let downloadDelay = 300; // this delay is needed to avoid browser limitations with concurrent downloads
            setTimeout(function() {
                const url = `${SERVER_URL}/api/files/${model.documentUploadId}?download=true`;
                const link = document.createElement('a');
                link.href = url;
                link.target = "_blank";
                link.rel = "noopener noreferrer";
                link.setAttribute('download', model.documentUploadId);
                link.click();
            }, downloadDelay);
        // });    
    }

    private get getHasConditions(): HasCondition<Model>[][] | undefined {
        if (this.currentTabName === ActiveDocumentsTabNames.SuggestedDocuments) {
            if(this.formEntity && this.formEntity.documentTypess) {
                const formDocumentTypesFilter: IWhereCondition<Model>[] = this.formEntity.documentTypess
                .map(d => ({
                    path: "documentTypes.name",
                    comparison: 'equal',
                    value: d.documentTypes.name
                }))
            
            return [[{
                path: "documentTypess" as keyof Model,
                conditions: [formDocumentTypesFilter]
            }]];
            }
        }
        
        return undefined;
    }

    render() {
        const isRenderTabs = !(new RegExp("edit|view|create", "i")).test(this.props.location.pathname);
        const contents = (
            <SecuredPage groups={['Super Administrators', 'MehubAccount', 'MehubAdmin']}>
                <Navigation
                    linkGroups={getFrontendNavLinks(this.props)}
                    orientation={Orientation.HORIZONTAL}
                    match={this.props.match}
                    location={this.props.location}
                    history={this.props.history}
                    staticContext={this.props.staticContext}
                    className={'hub-navigation'}
                />
                <div className="body-content">
                    <div className="active-assets-header web">
                        <h3>Attach documents</h3>
                        <Button  display={Display.Solid}
                                 colors={Colors.FormPrimary}
                                 sizes={Sizes.Medium}
                                 onClick={this.attachDocumentsToForm}>
                            Finish attaching
                        </Button>
                    </div>
                    {this.renderTabs(isRenderTabs)}
                    <EntityCRUD
                        {...this.props}
                        modelType={DocumentEntity}
                        collectionCreateAction={()=> undefined}
                        collectionUpdateAction={() => undefined}
                        filterCollection={[[{path: "status", comparison: 'equal', value: "ACTIVE" }]]}
                        entityCollectionProps={{
                            itemSelectionChanged: this.onItemSelectionChange,
                        }}
                        hasConditon={this.getHasConditions}
                        disableBulkExport
                        additionalTableActions={[{
                            buttonClass: 'download-document',
                            label: "",
                            showIcon: true,
                            icon: "download",
                            iconPos: 'icon-left',
                            action: this.downloadDocument,
                        }]}
                    />
                    <div className='btn-group btn attachment-buttons attach-docs-buttons mobile'>
                        <Button className={'attach-doc-button attach-cancel-button'}
                            display={Display.Outline}
                            colors={Colors.FormPrimary}
                            sizes={Sizes.Medium}
                            onClick={this.cancelAttachDocuments}>
                                Cancel
                        </Button>
                        <Button className={'attach-doc-button attach-done-button'}
                            display={Display.Solid}
                            colors={Colors.FormPrimary}
                            sizes={Sizes.Medium}
                            onClick={this.attachDocumentsToForm}>
                                Done
                        </Button>
                    </div>
                </div>
            </SecuredPage>
        );

        return contents;
    }
}

export default ActiveDocumentsPage;

