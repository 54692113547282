/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import moment from 'moment';
import { action, observable } from 'mobx';
import { Model, IModelAttributes, attribute, entity } from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from "Util/AttributeUtils";
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchOneToManyFunc,
	makeEnumFetchFunction,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import { VisitorsLicenceEntity } from 'Models/Security/Acl/VisitorsLicenceEntity';
import { MehubAccountLicenceEntity } from 'Models/Security/Acl/MehubAccountLicenceEntity';
import { MehubAdminLicenceEntity } from 'Models/Security/Acl/MehubAdminLicenceEntity';
import * as Enums from '../Enums';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import { FileListPreview } from 'Views/Components/CRUD/Attributes/AttributeFile';
import {SuperAdministratorScheme} from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] on begin
import {IWhereCondition} from "../../Views/Components/ModelCollection/ModelQuery";
import {store} from "../Store";
import {gql} from "@apollo/client";
import {ErrorResponse} from "@apollo/client/link/error";
import {getTheNetworkError} from "../../Util/GraphQLUtils";
// % protected region % [Add any further imports here] end

export interface ILicenceEntityAttributes extends IModelAttributes {
	licenceName: string;
	licenceIssuer: string;
	licenceType: Enums.licenceTypes;
	renewalDate: Date;
	licenceUploadId: string;
	licenceUpload: Blob;
	status: Enums.licenceStatus;
	authorizedDate: Date;
	description: string;
	licenceNumber: string;
	otherLicenceDetails: string;

	licenceAttachmentss: Array<Models.LicenceAttachmentsEntity | Models.ILicenceAttachmentsEntityAttributes>;
	mehubAccountId?: string;
	mehubAccount?: Models.MehubAccountEntity | Models.IMehubAccountEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('LicenceEntity', 'Licence ')
// % protected region % [Customise your entity metadata here] end
export default class LicenceEntity extends Model implements ILicenceEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsLicenceEntity(),
		new MehubAccountLicenceEntity(),
		new MehubAdminLicenceEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Licence Name'] off begin
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'Licence Name',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public licenceName: string;
	// % protected region % [Modify props to the crud options here for attribute 'Licence Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Licence Issuer'] off begin
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'Licence Issuer',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public licenceIssuer: string;
	// % protected region % [Modify props to the crud options here for attribute 'Licence Issuer'] end

	// % protected region % [Modify props to the crud options here for attribute 'Licence type'] off begin
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'Licence type',
		displayType: 'enum-combobox',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.licenceTypesOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.licenceTypesOptions),
		displayFunction: (attribute: Enums.licenceTypes) => Enums.licenceTypesOptions[attribute],
	})
	public licenceType: Enums.licenceTypes;
	// % protected region % [Modify props to the crud options here for attribute 'Licence type'] end

	// % protected region % [Modify props to the crud options here for attribute 'Renewal Date'] on begin
	@observable
	@attribute()
	@CRUD({
		name: 'Renewal Date',
		displayType: 'datepicker',
		order: 30,
		headerColumn: false,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseDate,
	})
	public renewalDate: Date;
	// % protected region % [Modify props to the crud options here for attribute 'Renewal Date'] end

	// % protected region % [Modify props to the crud options here for attribute 'Licence Upload'] on begin
	@observable
	@attribute({file: 'licenceUpload'})
	@CRUD({
		name: 'Licence Upload',
		displayType: 'hidden',
		order: 160,
		headerColumn: false,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseUuid,
		fileAttribute: 'licenceUpload',
		// fileAttribute: 'attachments',
		displayFunction: attr => attr
			? <FileListPreview url={attr} />
			: 'No File Attached',
	})
	public licenceUploadId: string;
	@observable
	public licenceUpload: Blob;


	// % protected region % [Modify props to the crud options here for attribute 'Licence Upload'] end

	// % protected region % [Modify props to the crud options here for attribute 'Status'] on begin
	@observable
	@attribute()
	@CRUD({
		name: 'Status',
		displayType: 'hidden',
		order: 50,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.licenceStatusOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.licenceStatusOptions),
		displayFunction: (attribute: Enums.licenceStatus, that: Model) => {
			let model = that as LicenceEntity;
			let status = model.status;

			if (status !== "PENDING") {
				let date = model.renewalDate;
				let now = new Date();
				
				if (date !== null && now > date) {
					status = 'EXPIRED';
				} else {
					status = 'ACTIVE';
				}
			}
			
			return Enums.licenceStatusOptions[status];
		},
	})
	public status: Enums.licenceStatus;
	// % protected region % [Modify props to the crud options here for attribute 'Status'] end

	// % protected region % [Modify props to the crud options here for attribute 'Authorized Date'] on begin
	@observable
	@attribute()
	@CRUD({
		name: 'Authorized Date',
		displayType: 'datepicker',
		order: 70,
		searchable: true,
		searchFunction: 'equal',
		isReadonly: true,
		searchTransform: AttrUtils.standardiseDate,
	})
	public authorizedDate: Date;
	// % protected region % [Modify props to the crud options here for attribute 'Authorized Date'] end

	// % protected region % [Modify props to the crud options here for attribute 'Description'] on begin
	@observable
	@attribute()
	@CRUD({
		name: 'Description',
		displayType: 'textarea',
		order: 80,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public description: string;
	// % protected region % [Modify props to the crud options here for attribute 'Description'] end

	// % protected region % [Modify props to the crud options here for attribute 'Licence Number'] on begin
	@observable
	@attribute()
	@CRUD({
		name: 'Licence Number',
		displayType: 'textfield',
		order: 40,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public licenceNumber: string;
	// % protected region % [Modify props to the crud options here for attribute 'Licence Number'] end

	// % protected region % [Modify props to the crud options here for attribute 'Other Licence Details'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'Other Licence Details',
		displayType: 'textfield',
		order: 100,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public otherLicenceDetails: string;
	// % protected region % [Modify props to the crud options here for attribute 'Other Licence Details'] end

	@observable
	@attribute({isReference: true, manyReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Licence Attachments'] on begin
		name: "Licence Attachments",
		displayType: 'fileList',
		fileAttribute: 'attachments',
		order: 160,
		// % protected region % [Modify props to the crud options here for reference 'Licence Attachments'] end
	})
	public licenceAttachmentss: Models.LicenceAttachmentsEntity[] = [];

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'MeHub Account'] on begin
		name: 'MeHub Account',
		displayType: 'hidden',
		order: 90,
		referenceTypeFunc: () => Models.MehubAccountEntity,
		// % protected region % [Modify props to the crud options here for reference 'MeHub Account'] end
	})
	public mehubAccountId?: string;
	@observable
	@attribute({isReference: true, manyReference: false})
	public mehubAccount: Models.MehubAccountEntity;

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<ILicenceEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] on begin
		this.delete = this.delete.bind(this);
		this.deleteWhere = this.deleteWhere.bind(this);
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<ILicenceEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.licenceName !== undefined) {
				this.licenceName = attributes.licenceName;
			}
			if (attributes.licenceIssuer !== undefined) {
				this.licenceIssuer = attributes.licenceIssuer;
			}
			if (attributes.licenceType !== undefined) {
				this.licenceType = attributes.licenceType;
			}
			if (attributes.renewalDate !== undefined) {
				if (attributes.renewalDate === null) {
					this.renewalDate = attributes.renewalDate;
				} else {
					this.renewalDate = moment(attributes.renewalDate).toDate();
				}
			}
			if (attributes.licenceUpload !== undefined) {
				this.licenceUpload = attributes.licenceUpload;
			}
			if (attributes.licenceUploadId !== undefined) {
				this.licenceUploadId = attributes.licenceUploadId;
			}
			if (attributes.status !== undefined) {
				this.status = attributes.status;
			}
			if (attributes.authorizedDate !== undefined) {
				if (attributes.authorizedDate === null) {
					this.authorizedDate = attributes.authorizedDate;
				} else {
					this.authorizedDate = moment(attributes.authorizedDate).toDate();
				}
			}
			if (attributes.description !== undefined) {
				this.description = attributes.description;
			}
			if (attributes.licenceNumber !== undefined) {
				this.licenceNumber = attributes.licenceNumber;
			}
			if (attributes.otherLicenceDetails !== undefined) {
				this.otherLicenceDetails = attributes.otherLicenceDetails;
			}
			if (attributes.licenceAttachmentss !== undefined && Array.isArray(attributes.licenceAttachmentss)) {
				for (const model of attributes.licenceAttachmentss) {
					if (model instanceof Models.LicenceAttachmentsEntity) {
						this.licenceAttachmentss.push(model);
					} else {
						this.licenceAttachmentss.push(new Models.LicenceAttachmentsEntity(model));
					}
				}
			}
			if (attributes.mehubAccountId !== undefined) {
				this.mehubAccountId = attributes.mehubAccountId;
			}
			if (attributes.mehubAccount !== undefined) {
				if (attributes.mehubAccount === null) {
					this.mehubAccount = attributes.mehubAccount;
				} else {
					if (attributes.mehubAccount instanceof Models.MehubAccountEntity) {
						this.mehubAccount = attributes.mehubAccount;
						this.mehubAccountId = attributes.mehubAccount.id;
					} else {
						this.mehubAccount = new Models.MehubAccountEntity(attributes.mehubAccount);
						this.mehubAccountId = this.mehubAccount.id;
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] on begin
	public defaultExpands = `
		licenceName
		renewalDate
		status
		licenceAttachmentss {
			${Models.LicenceAttachmentsEntity.getAttributes().join('\n')}
			${Models.LicenceAttachmentsEntity.getFiles().map(f => f.name).join('\n')}
		}
		mehubAccount {
			${Models.MehubAccountEntity.getAttributes().join('\n')}
		}
	`;
	public listExpands = `
		licenceName
		renewalDate
		licenceAttachmentss {
			${Models.LicenceAttachmentsEntity.getAttributes().join('\n')}
			${Models.LicenceAttachmentsEntity.getFiles().map(f => f.name).join('\n')}
		}
		status
		mehubAccount {
			${Models.MehubAccountEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath = {
			licenceAttachmentss: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'licenceAttachmentss',
						]
					},
				],
				contentType: 'multipart/form-data',
			}
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}


	// % protected region % [Add any further custom model features here] on begin
	/**
	 * [#310] Custom deletes the model from the server
	 */
	public async delete() {
		const functionName = `delete${this.getModelName()}`;

		return store.apolloClient
			.mutate({
				mutation: gql`
					mutation delete($licenceEntityIds:[ID]) {
						${functionName} (licenceEntityIds: $licenceEntityIds) {
							id
						}
					}`,
				variables: {licenceEntityIds: [this.id]},
				update: (cache, results) => {

				},
			})
			.then(action((response) => {
				const data = response.data[functionName][0];
				Object.assign(this, data);

				// Delete related reminders
				const whereCondition = {
					"path": "EntityID",
					"comparison": "equal",
					"value": this.id,
				};

				store.apolloClient
					.mutate({
						mutation: gql`
							mutation delete($whereConditional:WhereExpressionGraph) {
								deleteReminderLogEntitysConditional(conditions: [$whereConditional]) {
									value
								}
							}`,
						variables: {whereConditional: whereCondition}
					})
					.catch((response: ErrorResponse) => {
						throw getTheNetworkError(response);
				});
			}))
			.catch((response: ErrorResponse) => {
				throw getTheNetworkError(response);
		});
	}

	/**
	 * [#310] Custom deletes the models from the server by conditions
	 */
	public async deleteWhere(conditions?: Array<IWhereCondition<Model>> | Array<Array<IWhereCondition<Model>>>, ids?: string[]) {
		return new Promise<object>((resolve, reject) => {
			const functionName = `delete${this.getModelName()}sConditional`;
			
			return store.apolloClient
				.mutate({
					mutation: gql`
						mutation deleteModelsConditional($args: [[WhereExpressionGraph]], $ids:[ID]) {
							${functionName}(conditions: $args, ids: $ids){
								value
							}
						}`,
					variables: {
						"args": conditions,
						"ids": ids || null
					},
					update: (cache, results) => {

					},
				})
				.then((response) => {
					
				// Delete related reminders
				// Create a condition for all entity IDS
				const whereIds = {
					"path": "EntityID",
					"comparison": "in",
					"value": ids || "this-is-a-placeholder"
				};
				// Create a condition for the passed in condition
				// Assuming there is only one condition and it has the values `path=id`, `comparison=notIn`
				let whereConditions = {
					"path": "EntityID",
					"comparison": "in",
					"value": "this-is-a-placeholder"
				};

				if(conditions != null 
					&& (conditions?.length ?? 0) > 0)
				{
					whereConditions = {
						"path": "EntityID",
						"comparison": "notIn",
						"value": conditions[0][0].value || "this-is-a-placeholder"
					};
				}
				

				// Send request, reject the promise on error
				store.apolloClient
					.mutate({
						mutation: gql`
							mutation delete($whereIds:WhereExpressionGraph, $whereConditions:WhereExpressionGraph) {
								deleteReminderLogEntitysConditional(conditions: [[$whereIds, $whereConditions]]) {
									value
								}
							}`,
						variables: {
							whereIds: whereIds,
							whereConditions: whereConditions,
						}
					})
					.catch((response: ErrorResponse) => {
						const errorMessage = getTheNetworkError(response);
						reject(errorMessage);
				});

				// Resolve the response
				const result = response.data[functionName];
				resolve(result);
				})
				.catch((response: ErrorResponse) => {
					const errorMessage = getTheNetworkError(response);
					reject(errorMessage);
				});
		})
	}
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [ createdAttr, modifiedAttr ] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(LicenceEntity.prototype, 'created');
CRUD(modifiedAttr)(LicenceEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
