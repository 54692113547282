/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import moment from 'moment';
import { action, observable } from 'mobx';
import { Model, IModelAttributes, attribute, entity } from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from "Util/AttributeUtils";
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchOneToManyFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import { VisitorsFormReviewRequestEntity } from 'Models/Security/Acl/VisitorsFormReviewRequestEntity';
import { MehubAccountFormReviewRequestEntity } from 'Models/Security/Acl/MehubAccountFormReviewRequestEntity';
import { MehubAdminFormReviewRequestEntity } from 'Models/Security/Acl/MehubAdminFormReviewRequestEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import {SuperAdministratorScheme} from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IFormReviewRequestEntityAttributes extends IModelAttributes {
	timeReviewed: Date;
	formData: string;
	showNotification: boolean;

	mehubAccountId?: string;
	mehubAccount?: Models.MehubAccountEntity | Models.IMehubAccountEntityAttributes;
	formSubmissions: Models.FormSubmissionsEntity | Models.IFormSubmissionsEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('FormReviewRequestEntity', 'Form Review Request')
// % protected region % [Customise your entity metadata here] end
export default class FormReviewRequestEntity extends Model implements IFormReviewRequestEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsFormReviewRequestEntity(),
		new MehubAccountFormReviewRequestEntity(),
		new MehubAdminFormReviewRequestEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Time Reviewed'] off begin
	/**
	 * The time a Manager reviewed this form submission
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Time Reviewed',
		displayType: 'datetimepicker',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseDate,
	})
	public timeReviewed: Date;
	// % protected region % [Modify props to the crud options here for attribute 'Time Reviewed'] end

	// % protected region % [Modify props to the crud options here for attribute 'Form Data'] off begin
	/**
	 * A serialised string of the Review Slide form data
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Form Data',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public formData: string;
	// % protected region % [Modify props to the crud options here for attribute 'Form Data'] end

	// % protected region % [Modify props to the crud options here for attribute 'Show Notification'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'Show Notification',
		displayType: 'checkbox',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public showNotification: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Show Notification'] end

	/**
	 * Connects Managers with Form Review Requests they have reviewed
	 */
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'MeHub Account'] off begin
		name: 'MeHub Account',
		displayType: 'reference-combobox',
		order: 40,
		referenceTypeFunc: () => Models.MehubAccountEntity,
		// % protected region % [Modify props to the crud options here for reference 'MeHub Account'] end
	})
	public mehubAccountId?: string;
	@observable
	@attribute({isReference: true, manyReference: false})
	public mehubAccount: Models.MehubAccountEntity;

	@Validators.Required()
	@observable
	@attribute({isReference: true, manyReference: false})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Form Submissions'] off begin
		name: 'Form Submissions',
		displayType: 'reference-combobox',
		order: 50,
		referenceTypeFunc: () => Models.FormSubmissionsEntity,
		optionEqualFunc: (model, option) => model.id === option,
		inputProps: {
			fetchReferenceEntity: true,
		},
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'formSubmissionss',
			oppositeEntity: () => Models.FormSubmissionsEntity,
		})
		// % protected region % [Modify props to the crud options here for reference 'Form Submissions'] end
	})
	public formSubmissions: Models.FormSubmissionsEntity;

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IFormReviewRequestEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IFormReviewRequestEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.timeReviewed !== undefined) {
				if (attributes.timeReviewed === null) {
					this.timeReviewed = attributes.timeReviewed;
				} else {
					this.timeReviewed = moment(attributes.timeReviewed).toDate();
				}
			}
			if (attributes.formData !== undefined) {
				this.formData = attributes.formData;
			}
			if (attributes.showNotification !== undefined) {
				this.showNotification = attributes.showNotification;
			}
			if (attributes.mehubAccountId !== undefined) {
				this.mehubAccountId = attributes.mehubAccountId;
			}
			if (attributes.mehubAccount !== undefined) {
				if (attributes.mehubAccount === null) {
					this.mehubAccount = attributes.mehubAccount;
				} else {
					if (attributes.mehubAccount instanceof Models.MehubAccountEntity) {
						this.mehubAccount = attributes.mehubAccount;
						this.mehubAccountId = attributes.mehubAccount.id;
					} else {
						this.mehubAccount = new Models.MehubAccountEntity(attributes.mehubAccount);
						this.mehubAccountId = this.mehubAccount.id;
					}
				}
			}
			if (attributes.formSubmissions !== undefined) {
				if (attributes.formSubmissions === null) {
					this.formSubmissions = attributes.formSubmissions;
				} else {
					if (attributes.formSubmissions instanceof Models.FormSubmissionsEntity) {
						this.formSubmissions = attributes.formSubmissions;
					} else {
						this.formSubmissions = new Models.FormSubmissionsEntity(attributes.formSubmissions);
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		mehubAccount {
			${Models.MehubAccountEntity.getAttributes().join('\n')}
		}
		formSubmissions {
			${Models.FormSubmissionsEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath = {
			formSubmissions: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'formSubmissions',
						]
					},
				],
			}
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}


	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [ createdAttr, modifiedAttr ] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(FormReviewRequestEntity.prototype, 'created');
CRUD(modifiedAttr)(FormReviewRequestEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
