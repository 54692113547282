import * as React from 'react';
import { Model } from 'Models/Model';
import { observer } from 'mobx-react';
import AttributeReferenceMultiCombobox, {IAttributeReferenceComboboxProps} from "./AttributeReferenceMultiCombobox";
import { Button, Colors, Display } from "../../Button/Button";
import { action, observable } from "mobx";

export interface IAttributeReferenceMultiComboboxWithSelectAllProps<T extends Model> {
	attributeReferenceMultiComboboxProps: IAttributeReferenceComboboxProps<T>,
	onSelectAll?: Function,
	onUnselectAll?: Function,
}

/**
 * A wrapper of AttributeReferenceMultiCombobox that introduces a button to allow select all and unselect all
 */
@observer
export default class AttributeReferenceMultiComboboxWithSelectAll<T extends Model> 
	extends React.Component<IAttributeReferenceMultiComboboxWithSelectAllProps<T>> {
	
	@observable
	private selectedAll: boolean = false;
	
	@action
	toggleSelectedAll = () => {
		const { 
			attributeReferenceMultiComboboxProps: { model },
			onSelectAll,
			onUnselectAll
		} = this.props;

		this.selectedAll = !this.selectedAll;
		
		if (this.selectedAll && onSelectAll) {
			onSelectAll(model);
		} else if (onUnselectAll) {
			onUnselectAll(model);
		}
	}
	
	public render() {
		
		const { attributeReferenceMultiComboboxProps } = this.props;
		
		return (
			<div className="reference-multicombobox-button-wrapper">
				<AttributeReferenceMultiCombobox
					{...attributeReferenceMultiComboboxProps}
				/>
				<Button onClick={this.toggleSelectedAll} colors={Colors.FormPrimary} display={Display.Outline}>
					{ this.selectedAll ? "Unselect All" : "Select All" }
				</Button>
			</div>
		);
	}
}
