/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import React, { Component, ReactNode } from 'react';
import { action, observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import _ from 'lodash';
import classNames from 'classnames';
import { Button } from 'Views/Components/Button/Button';
import If from 'Views/Components/If/If';
import { Form, Question } from './Schema/Question';
import { SlideTile } from './SlideTile';
import { buildValidationErrorMessage } from './Validators/ValidationUtils';
import CheckDisplayConditions from './Conditions/ConditionUtils';
import {getNestedQuestions} from './Questions/QuestionUtils';
import { Switch, Route, Redirect} from 'react-router';
import { store } from 'Models/Store';
// % protected region % [Add any further imports here] on begin
import { Colors, Display, Sizes } from 'Views/Components/Button/Button';
import { StepIndicator } from 'Views/Components/StepIndicator/StepIndicator';
import { Alignment, ButtonGroup } from "../Views/Components/Button/ButtonGroup";
import Modal from "../Views/Components/Modal/Modal";
import alert from "../Util/ToastifyUtils";
import { mehubUserRoleOptions } from "../Models/Enums";
import {
	AdditionalAttachmentEntity,
	FormSubmissionsAssets,
	FormSubmissionsDocuments,
	FormSubmissionsEntity
} from "../Models/Entities";
import MultiFileUpload from "../Views/Components/FileUpload/MultiFileUpload";
import { SERVER_URL } from "../Constants";
import { exportPDF } from "../Util/PdfExportUtils";
// % protected region % [Add any further imports here] end

export interface IFormProps {
	isReadOnly?: boolean;
	className?: string;
	submitText?: string;
	disableShowConditions? : boolean;
	// % protected region % [Add extra form tile props here] on begin
	submissionEntity?: FormSubmissionsEntity;
	// % protected region % [Add extra form tile props here] end
}

export interface IFormTileProps<T> extends IFormProps {
	schema: Form;
	model: T;
	onSubmit?: (model: T) => void;
	preview?: boolean;
	routePath?: string;
	// % protected region % [Add to IFormTileProps here] on begin
	formTitle?: string;
	onCancel?: (() => void);
	onSaveDraft?: (model: T) => Promise<void>;
	formId?: string;
	// % protected region % [Add to IFormTileProps here] end
}

@observer
export class FormTile<T> extends Component<IFormTileProps<T>> {
	@observable
	private reValidate: boolean = false;
	static defaultProps: Partial<IFormProps> = {
		submitText: 'Submit',
		// % protected region % [Add to defaultProps here] off begin
		// % protected region % [Add to defaultProps here] end
	};

	// % protected region % [Add extra class fields here] off begin
	// % protected region % [Add extra class fields here] end

	private onSubmit = (): void => {
		// % protected region % [Override onSubmit here] on begin
		const { schema, onSubmit, model } = this.props;

		// Remove hidden question answers from the model 
		schema.slides.forEach((slide) => {
			slide.contents.forEach((question: Question) => {
				if (!this.isQuestionShown(question)) {
					Object.keys({model}).forEach((key) => {
						if (key.includes(question.id)) {
							delete model[key];
						}
					})
				}
			});
		});

		if (onSubmit) {
			if (this.validateForm()) {
				onSubmit(model);
			} else {
				runInAction((): void => {
					this.reValidate = true;
				});
			}
		}
		// % protected region % [Override onSubmit here] end
	};

	private isQuestionShown = (question: Question): boolean => {
		// % protected region % [Override isQuestionShown here] off begin
		const { showConditions } = question;
		const { disableShowConditions, model, schema } = this.props;
		if (showConditions !== undefined && !disableShowConditions) {
			return showConditions.every(condition => { return CheckDisplayConditions(condition, model, schema); });
		}
		return true;
		// % protected region % [Override isQuestionShown here] end
	};

	private validateQuestion = (question: Question): boolean => {
		// % protected region % [Override validateQuestion here] on begin
		const { model, schema } = this.props;
		const { validators } = question;
		let errorMessage: string[] = [];

		if (this.isQuestionShown(question)) {
			if (question.validators !== undefined && question.id !== 'ReviewSlideComments') {
				errorMessage = buildValidationErrorMessage(validators, model, schema, false);
				return _.filter(errorMessage, e => e).length === 0;
			}
		}
		return true;
		// % protected region % [Override validateQuestion here] end
	};

	private validateQuestions = (questions: Question[]): boolean => {
		// % protected region % [Override validateQuestions here] off begin
		let valid: boolean = true;
		questions.forEach(q => {
			valid = valid && this.validateQuestion(q);
		});
		return valid;
		// % protected region % [Override validateQuestions here] end
	};

	private validateForm = (): boolean => {
		// % protected region % [Override validateForm here] off begin
		const { schema } = this.props;
		const questions = _.flatMap(schema.slides, o => getNestedQuestions(o.contents));
		return this.validateQuestions(questions);
		// % protected region % [Override validateForm here] end
	};

	private validateSlide = (): boolean => {
		// % protected region % [Override validateSlide here] off begin
		const { schema } = this.props;
		const questions = _.flatMap(getNestedQuestions(schema.slides[this.currentSlideNumber-1].contents));
		return this.validateQuestions(questions);
		// % protected region % [Override validateSlide here] end
	};

	@observable
	private currentSlideNumber: number = 1;

	@action
	private nextSlide = () => {
		if (this.validateSlide()){
			this.currentSlideNumber++;
		}
		else {
			this.reValidate = true;
		}
	}

	@action
	private nextSlideUrl = (slide: number) => {
		const questions = _.flatMap(getNestedQuestions(this.props.schema.slides[slide].contents));
		if (this.validateQuestions(questions)){
			store.routerHistory?.push(`./${slide+2}`)}													
		else {
			this.reValidate = true;
		}
	}

	@action
	private previousSlide = () => {
		this.currentSlideNumber--;
	}

	// MEASD-209: clicking next on a form scrolls user to top of the page
	@action
	private onNext = () => {							
		if (this.validateCurrentSlide()) {
			this.reValidate = false;
			this.currentSlideNumber += 1;
			document.getElementById('form-slides')!.scrollIntoView(true)
		}
	}

	// MEASD-209: clicking back on a form scrolls user to top of the page
	@action
	private onBack = () => {
		this.currentSlideNumber -= 1;
		document.getElementById('form-slides')!.scrollIntoView(true)
	}

	private renderScrollForm(slideTiles: ReactNode[]): ReactNode { 
		const {
			submitText, onSubmit,
		} = this.props;
		return (
			<>
				{slideTiles}
				<If condition={onSubmit !== undefined}>
					<Button onClick={this.onSubmit}>{submitText}</Button>
				</If>
			</>
		)
	}

	private renderPageForm(slideTiles: ReactNode[]): ReactNode { 
		const {
			submitText, onSubmit,
		} = this.props;
		const pages = slideTiles.length;
		return (
			<>

				<If condition={this.props.preview}>
					<h5>This is a preview using page based pagination.</h5>
				</If>
				{slideTiles[this.currentSlideNumber-1]}
				<Button onClick={this.previousSlide} disabled={this.currentSlideNumber === 1}>Previous Slide</Button>
				<Button onClick={this.nextSlide} disabled={this.currentSlideNumber === pages}>Next Slide</Button>
				<If condition={onSubmit !== undefined && this.currentSlideNumber === pages}>
					<Button onClick={this.onSubmit}>{submitText}</Button>
				</If>
				<h3>{this.currentSlideNumber}/{pages}</h3>
			</>
		)
	}

	private renderUrlForm(slideTiles: ReactNode[]): ReactNode { 
		const {
			onSubmit, routePath
		} = this.props;
		const pages = slideTiles.length;
		let pageNumber = 1;
		const history = store.routerHistory;
		return (
			<>
				<section className="content">
					<Switch>
						{slideTiles.map((slide, i) => {

							const hasPrevPage = i > 0;
							const hasNextPage = i < (pages - 1);
							return (
								<Route key={pageNumber++} path={`${routePath}/slide/${i+1}/`}>
									{slide}

									<div className="slide-button__container">
										<If condition={hasPrevPage}>
											<Button
												disabled={!hasPrevPage}
												onClick={() => history?.push(`./${i}`)}
											>
												Previous Slide
											</Button>
										</If>
										<If condition={hasNextPage}>
											<Button
												disabled={!hasNextPage}
												onClick={() => {
													this.nextSlideUrl(i);
												}}
												>
												Next Slide
											</Button>
										</If>
										<If condition={!hasNextPage}>
											<If condition={onSubmit !== undefined}>
												<Button 
													onClick={this.onSubmit}
												>
													Submit
												</Button>
											</If>
										</If>
									</div>
								</Route> 
							);
						})}
						<Redirect to={`${routePath}/slide/1`}/>
					</Switch>
				</section>
			</>
		)
	}

	private renderForm(formType: String, slideTiles: ReactNode[]): ReactNode{
		if (this.props.preview && formType === "url"){
			formType = "page";
		}
		switch (formType) {
			case "page":
				return this.renderPageForm(slideTiles);
			case "url":
				return this.renderUrlForm(slideTiles);
			case "scroll":
				return this.renderScrollForm(slideTiles);
			default:
				return this.renderPageForm(slideTiles);
		}
	}

	public render(): ReactNode {
		// % protected region % [Customize render here] on begin
		const {
			className, schema, isReadOnly, disableShowConditions, submitText, model, formTitle, submissionEntity
		} = this.props;
		let filteredSlides = schema.slides;
		const reviewSlides = schema.slides.filter(slide => slide.contents.some((question: Question) =>
			question.id.includes('ReviewSlideOption') || question.id.includes('ReviewSlideComments')));
		if (reviewSlides.length > 0) {
			filteredSlides = schema.slides.slice(0, schema.slides.length-1);
		}
		const filteredSchema: Form = {
			slides: filteredSlides,
			pagination: {
				type: '',
			},
			customOptions: [],
		};
		const reviewSchema: Form = {
			slides: reviewSlides,
			pagination: {
				type: '',
			},
			customOptions: [],
		}
		const currentSlide = filteredSlides[this.currentSlideNumber - 1];
		const slideNames = filteredSlides.map((slide) => slide['name']);
		const WORKER_OR_MANAGER: string[] = [
			mehubUserRoleOptions.WORKER,
			mehubUserRoleOptions.MANAGER
		];

		return (
			<div className={classNames('form-tile', className)}>
				<div className="slide-progress-sidebar">
					<StepIndicator
						stepNames={slideNames}
						currentStep={this.currentSlideNumber}
						stepChange={this.setSlideNumber}
					/>
				</div>
				<div id="form-slides" className="form-slides">
					<If condition={currentSlide != null}>
						<SlideTile
							model={model}
							schema={filteredSchema}
							isReadOnly={isReadOnly}
							disableShowConditions={disableShowConditions}
							contents={currentSlide?.contents}
							name={formTitle ? formTitle : currentSlide?.name}
							reValidate={this.reValidate}
							beforeSlideContent={WORKER_OR_MANAGER.includes(store.roleName ?? '') ? this.renderAttachmentsLimited : this.renderAttachments}
						/>
					</If>
					<div className="form-slides-button">
						<ButtonGroup alignment={Alignment.HORIZONTAL}>
							<If condition={(filteredSlides.length > 0) && !isReadOnly}>
								<Button className={`form-cancel-button ${this.currentSlideNumber <= 1 ? "" : "hidden-cancel"}`}
									display={Display.Outline}
									colors={Colors.FormTertiary}
									sizes={Sizes.Medium}
									onClick={() => this.updateModalState(true)}>
									Cancel
								</Button>
							</If>
							{/* <ButtonGroup alignment={Alignment.HORIZONTAL}> */}
							<If condition={this.currentSlideNumber > 1}>
								<Button className="form-back-button"
									display={Display.Outline}
									colors={Colors.FormSecondary}
									sizes={Sizes.Medium}
									onClick={this.onBack}
								>
									Back
								</Button>
							</If>
							<If condition={(filteredSlides.length - this.currentSlideNumber) > 0}>
								<Button className="form-next-button"
									display={Display.Solid}
									colors={Colors.FormPrimary}
									sizes={Sizes.Medium}
									onClick={this.onNext}
								>
									Next
								</Button>
							</If>
							<If condition={(this.currentSlideNumber === filteredSlides.length) && !isReadOnly}>
								<Button className="form-submit-button"
									display={Display.Solid}
									colors={Colors.FormPrimary}
									sizes={Sizes.Medium}
									onClick={this.onSubmit}>
									{submitText}
								</Button>
							</If>
						</ButtonGroup>
					</div>
					<If condition={store.appLocation === 'admin' ? true
						: reviewSlides.length > 0 
						&& (this.isRoleWorker() ? submissionEntity?.formReviewRequestId !== null && submissionEntity?.formReviewRequestId !== undefined : submissionEntity?.isCompleted === true) 
						&& isReadOnly}>
						<SlideTile
							model={model}
							schema={reviewSchema}
							isReadOnly={this.isRoleWorker()}
							disableShowConditions={disableShowConditions}
							contents={reviewSlides[0]?.contents}
							name={'Review Slide'}
							reValidate={this.reValidate}
						/>
					</If>
				</div>

				{this.renderIncompleteFormModal()}
			</div>
		);
		// % protected region % [Customize render here] end
	}

	// % protected region % [Add any extra form tile methods here] on begin
	private isRoleWorker = () => {
		return store.roleName === mehubUserRoleOptions.SAFETY_WORKER 
			|| store.roleName === mehubUserRoleOptions.WORKER;
	}
	
	@observable
	private modalState: boolean = false;

	@action
	private setSlideNumber = (slideNumber: number): void => {

		if (slideNumber <= this.currentSlideNumber) {
			runInAction(() => {
				this.reValidate = false;
				this.currentSlideNumber = slideNumber;
			});
		}

		if ((slideNumber === this.currentSlideNumber + 1) && this.validateCurrentSlide()) {
			runInAction(() => {
				this.reValidate = false;
				this.currentSlideNumber = slideNumber;
			});
		}
	};

	@action
	private updateModalState = (display: boolean) => {
		this.modalState = display;
	};

	private renderIncompleteFormModal = () => {
		return (
			<Modal
				className='incomplete-form-modal'
				label=""
				isOpen={this.modalState}
				onRequestClose={() => this.updateModalState(false)}
			>
				<h4>You have not yet completed this form.</h4>
				<p>You can either save your current progress as a draft (this will overwrite any previous drafts), or
					you can discard your changes.</p>
				<div key="actions" className="modal__actions">
					<Button display={Display.Solid}
						colors={Colors.FormSecondary}
						sizes={Sizes.Medium}
						key="cancel"
						onClick={this.discardFormSubmission}>
						Discard and take me back
					</Button>
					<Button display={Display.Solid}
						colors={Colors.FormPrimary}
						sizes={Sizes.Medium}
						key="submit"
						onClick={() => this.saveAsDraft()}>
						Save progress as draft
					</Button>
				</div>
			</Modal>
		)
	}

	private discardFormSubmission = async () => {
		if (this.props.onCancel) {
			await this.props.onCancel();
		}
		store.routerHistory.push('/mesafety');
	}

	private saveAsDraft = async (redirectUrl?: string) => {
		const { schema, onSaveDraft, model } = this.props;

		// Remove hidden question answers from the model 
		schema.slides.forEach((slide) => {
			slide.contents.forEach((question: Question) => {
				if (!this.isQuestionShown(question)) {
					Object.keys({model}).forEach((key) => {
						if (key.includes(question.id)) {
							delete model[key];
						}
					})
				}
			});
		});

		if (onSaveDraft) {
			try {
				await onSaveDraft(model);

				let url = '/mesafety';
				if (redirectUrl) {
					url = redirectUrl;
				}
				store.routerHistory.push(url);
			} catch (e) {
				alert('There was an error saving the form', 'error');
				console.error(e);
			}
		}
	}

	private exportToPDF = () => {
		if (this.props.submissionEntity != null) {
			exportPDF("includedIds", [this.props.submissionEntity.id]);
		}
	}

	@action
	private validateCurrentSlide = () => {
		const { schema } = this.props;
		const filteredSchema = schema.slides.filter(slide => slide.contents.some((question: Question) => this.isQuestionShown(question)));
		const currentSlide = [filteredSchema[this.currentSlideNumber - 1]];
		const questions = _.flatMap(currentSlide, o => {
			return o.contents;
		});

		const validationResult = this.validateQuestions(questions);

		if (!validationResult) {
			runInAction((): void => {
				this.reValidate = true;
			});
		}

		return validationResult;
	}

	@observable
	private attachmentsState: boolean = false;

	@action
	private updateAttachmentState = (display: boolean) => {
		this.attachmentsState = display;
	}

	@action
	private detachAsset = async (formAsset: FormSubmissionsAssets) => {
		await formAsset.deleteWhere(undefined, [formAsset.id]);
		const index = this.props.submissionEntity?.assetss.findIndex(x => x.id === formAsset.id);
		if (index !== undefined) {
			runInAction(() => {
				this.props.submissionEntity?.assetss.splice(index, 1);
			});
		}
	}

	@action
	private detachDocument = async (formDocument: FormSubmissionsDocuments) => {
		await formDocument.deleteWhere(undefined, [formDocument.id]);
		const index = this.props.submissionEntity?.documentss.findIndex(x => x.id == formDocument.id);
		if (index !== undefined) {
			runInAction(() => {
				this.props.submissionEntity?.documentss.splice(index, 1);
			})
		}
	}

	@action
	private detachAttachment = async (formAttachment: AdditionalAttachmentEntity, index: number) => {
		let deleteIndex: number | undefined = index;
		if (formAttachment.id) {
			await formAttachment.deleteWhere(undefined, [formAttachment.id]);
			deleteIndex = this.props.submissionEntity?.additionalAttachmentss.findIndex(x => x.id == formAttachment.id);
		}
		if (deleteIndex !== undefined) {
			runInAction(() => {
				this.props.submissionEntity?.additionalAttachmentss.splice(index ?? 0, 1);
			})
		}
	}

	private getFormAttachments = () => {

		if (!this.attachmentsState) {
			return null;
		}

		return (
			<div className="attachment-list">

				<div>
					<h4>Your assets</h4>
					{this.props.submissionEntity?.assetss.map(asset => {
						return (
							<div key={asset.id} className='row'>
								<span>{asset.assets.description}</span>
								<div
									className='icon-cross icon-right'
									onClick={() => this.detachAsset(asset)}
								>
								</div>
							</div>
						);
					})}
					<h4>Your documents</h4>
					{this.props.submissionEntity?.documentss.map(document => {
						return (
							<div key={document.id} className='row'>
								<a href={`${SERVER_URL}/api/files/${document.documents.documentUploadId}?download=true`}>
									{document.documents.documentName}
								</a>
								<div
									className='icon-cross icon-right'
									onClick={() => this.detachDocument(document)}
								>
								</div>
							</div>
						);
					})}
					<h4>Other files</h4>
					{this.props.submissionEntity?.additionalAttachmentss.map((attachment, index) => {

						var attachmentIdExists = attachment.attachmentId;
						const content = attachmentIdExists ?
							(<a href={`${SERVER_URL}/api/files/${attachment.attachmentId}?download=true`}>
								{attachment.attachment['fileName'] ?? attachment.attachment['name']}
							</a>) :
							(<span>{attachment.attachment['fileName'] ?? attachment.attachment['name']}</span>);

						return (
							<div key={attachment.id} className='row'>
								{content}
								<div
									className='icon-cross icon-right'
									onClick={() => this.detachAttachment(attachment, index)}
								>
								</div>
							</div>
						);
					})}
				</div>
			</div>
		);
	};

	@observable
	private toggleDropdown: boolean = false;

	private renderAttachments = () => {
		let totalAttachments = (this.props.submissionEntity?.assetss.length ?? 0)
			+ (this.props.submissionEntity?.documentss.length ?? 0)
			+ (this.props.submissionEntity?.additionalAttachmentss.length ?? 0);

		let attachmentIcon = !this.toggleDropdown ? "link-2" : "cross";
		var checkUrl = window.location.href.includes('user-submissions') 
			|| window.location.href.includes('/safety-form/')
		var isEditPage = window.location.href.includes("edit");
		
		return (
			<div className="form-attachments btn-group btn-group--horizontal">
				<div className={`${checkUrl ? "" : "hide"} form-attachments attachments-number`}>{totalAttachments}</div>
				<div className='attachments-dropdown'>
					<Button className={`${checkUrl ? "" : "hide"} attachment-list-button attachments-button icon-only mobile`}
						icon={{ icon: attachmentIcon }}
						display={Display.Round}
						colors={Colors.FormPrimary}
						sizes={Sizes.Medium}
						key="submit"
						onClick={this.handleToggleDropdown}>
					</Button>
				</div>
				<div className='export-PDF-button'>
					<Button
						className={`exportPDF ${!this.toggleDropdown ? "hideMobile" : ""}`}
						display={(checkUrl ? Display.Solid : Display.NoShow)}
						colors={Colors.FormPrimary}
						sizes={Sizes.Medium}
						key="PDF"
						onClick={() => { this.exportToPDF() }}>
						Export PDF
					</Button>
				</div>
				<If condition={!isEditPage}>
					<Button className={`attach-document-button attachments-button ${!this.toggleDropdown ? "hide" : ""}`}
						icon={{ icon: 'link-2', iconPos: "icon-right" }}
						display={Display.Outline}
						colors={Colors.FormSecondary}
						sizes={Sizes.Medium}
						key="submit"
						onClick={() => this.saveAsDraft(`/safety-form/${this.props.formId}/active-documents`)}>
						Attach documents
					</Button>
					<Button className={`attach-asset-button attachments-button ${!this.toggleDropdown ? "hide" : ""}`}
						icon={{ icon: 'link-2', iconPos: "icon-right" }}
						display={Display.Outline}
						colors={Colors.FormPrimary}
						sizes={Sizes.Medium}
						key="submit"
						onClick={() => this.saveAsDraft(`/safety-form/${this.props.formId}/active-assets`)}>
						Attach assets
					</Button>
					<div className={`additional-attachment web ${!this.toggleDropdown ? "hide" : ""}`}>
						<MultiFileUpload model={this.props.submissionEntity}
							modelProperty='additionalAttachmentss'
							fileModel={AdditionalAttachmentEntity}
							fileModelProperty='attachment'
							preview={false}
							disableDropArea={true}
							maxFileUploadSize={20}
							display={Display.Outline}
							icon={{ icon: 'link-2', iconPos: 'icon-right' }}
							sizes={Sizes.Medium}
							colors={Colors.FormTertiary}
							buttonText="Additional attachments"
						/>
					</div>
					<div className={`additional-attachment mobile ${!this.toggleDropdown ? "hide" : ""}`}>
						<MultiFileUpload model={this.props.submissionEntity}
							modelProperty='additionalAttachmentss'
							fileModel={AdditionalAttachmentEntity}
							fileModelProperty='attachment'
							preview={false}
							disableDropArea={true}
							maxFileUploadSize={20}
							display={Display.Outline}
							icon={{ icon: 'link-2', iconPos: 'icon-right' }}
							sizes={Sizes.Medium}
							colors={Colors.FormTertiary}
							buttonText="Upload File"
						/>
					</div>
				</If>
				<div className={`attachments-dropdown ${!this.toggleDropdown ? "hide" : ""}`}>
					<Button
						className={classNames('attachment-list-button', 'attachments-button', !this.attachmentsState ? 'collapse' : 'expand')}
						icon={{ icon: !this.attachmentsState ? 'chevron-down' : 'chevron-up', iconPos: "icon-right" }}
						display={Display.Solid}
						colors={Colors.FormPrimary}
						sizes={Sizes.Medium}
						key="submit"
						onClick={() => {
							this.updateAttachmentState(!this.attachmentsState)
						}}>
						Attachments ({totalAttachments})
					</Button>
					{this.getFormAttachments()}
				</div>
			</div>
		);
	}

	@action
	private handleToggleDropdown = () => {
		this.toggleDropdown = !this.toggleDropdown;
	}
	private renderAttachmentsLimited = () => {
		let totalAttachments = (this.props.submissionEntity?.assetss.length ?? 0)
			+ (this.props.submissionEntity?.documentss.length ?? 0)
			+ (this.props.submissionEntity?.additionalAttachmentss.length ?? 0);

		var checkUrl = window.location.href.includes('user-submissions')
			|| window.location.href.includes('/safety-form/');
		let attachmentIcon = !this.toggleDropdown ? "link-2" : "cross"

		return (
			<div className="form-attachments btn-group btn-group--horizontal">
				{/* <div className="additional-attachment"> */}
					<div className={`${checkUrl ? "" : "hide"} form-attachments attachments-number`}>{totalAttachments}</div>
					<div className='attachments-dropdown'>
						<Button className={`${checkUrl ? "" : "hide"} attachment-list-button attachments-button icon-only mobile`}
							icon={{ icon: attachmentIcon }}
							display={Display.Round}
							colors={Colors.FormPrimary}
							sizes={Sizes.Medium}
							key="submit"
							onClick={this.handleToggleDropdown}>
						</Button>
					</div>
					<div className={`additional-attachment web ${!this.toggleDropdown ? "hide" : ""}`}>
						<MultiFileUpload model={this.props.submissionEntity}
							modelProperty='additionalAttachmentss'
							fileModel={AdditionalAttachmentEntity}
							fileModelProperty='attachment'
							preview={false}
							disableDropArea={true}
							maxFileUploadSize={20}
							display={Display.Outline}
							icon={{ icon: 'link-2', iconPos: 'icon-right' }}
							sizes={Sizes.Medium}
							colors={Colors.FormTertiary}
							buttonText="Additional attachments"
						/>
					</div>
				{/* </div> */}

				<div className={`additional-attachment mobile ${!this.toggleDropdown ? "hide" : ""}`}>
					<MultiFileUpload model={this.props.submissionEntity}
						modelProperty='additionalAttachmentss'
						fileModel={AdditionalAttachmentEntity}
						fileModelProperty='attachment'
						preview={false}
						disableDropArea={true}
						maxFileUploadSize={20}
						display={Display.Outline}
						icon={{ icon: 'link-2', iconPos: 'icon-right' }}
						sizes={Sizes.Medium}
						colors={Colors.FormTertiary}
						buttonText="Upload File"
					/>
				</div>
				<div className={`attachments-dropdown ${!this.toggleDropdown ? "hide" : ""}`}>
					<Button
						className={classNames('attachment-list-button', 'attachments-button', !this.attachmentsState ? 'collapse' : 'expand')}
						icon={{ icon: !this.attachmentsState ? 'chevron-down' : 'chevron-up', iconPos: "icon-right" }}
						display={Display.Solid}
						colors={Colors.FormPrimary}
						sizes={Sizes.Medium}
						key="submit"
						onClick={() => {
							this.updateAttachmentState(!this.attachmentsState)
						}}>
						Attachments ({totalAttachments})
					</Button>
					{this.getFormAttachments()}
				</div>
			</div>
		);
	}
	// % protected region % [Add any extra form tile methods here] end
}