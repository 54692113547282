import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { TextField } from 'Views/Components/TextBox/TextBox';
import { QuestionType, ValidatorType } from 'Forms/Schema/Question';
import { IQuestionTile, QuestionTileOptionsProps } from '../QuestionTile';
import { hasRequiredValidator } from '../../Validators/ValidationUtils';
import TileOptions from '../Options/TileOptions';
import CompareText from 'Forms/Conditions/CompareText';
import { ValidateText } from 'Forms/Validators/ValidateText';
import { QuestionComponent, QuestionComponentProps } from 'Forms/Questions/QuestionComponent';

@observer
export class JobNumberQuestionTileOptions extends Component<QuestionTileOptionsProps> {

	public render() {
		const { question, schema } = this.props;

		return (
			<TileOptions
				question={question}
				schema={schema}
				hasShowConditions
				hasValidators
				hasTooltip
			/>
		);
	}
}

export interface IJobNumberQuestionTileProps<T> extends QuestionComponentProps<T> {

}

@observer
export class JobNumberQuestionTile<T> extends QuestionComponent<T, IJobNumberQuestionTileProps<T>> implements IQuestionTile {

	static displayName = 'Job Number';

	static questionType: QuestionType = 'jobNumber';

	static optionsMenu = JobNumberQuestionTileOptions;

	static compareFunction = CompareText;

	static validateFunction = ValidateText;

	static conditionOptions = [
		{ display: 'Equal', value: 'equal' },
		{ display: 'Not equal', value: 'notEqual' },
		{ display: 'Contains', value: 'contains' },
	];

	static validatorOptions: { display: string, value: ValidatorType }[] = [
		{ display: 'Required', value: 'required' },
		{ display: 'Email', value: 'email' },
		{ display: 'Phone', value: 'phone' },
		{ display: 'Custom', value: 'custom' },
	];

	static stylingOptions = undefined;

	public render() {
		const { title, model, id, isReadOnly, validators, toolTip, className, checkValidation } = this.props;

		return (
			<TextField
				model={model}
				modelProperty={id}
				label={title}
				labelVisible
				isReadOnly={isReadOnly}
				isRequired={hasRequiredValidator(validators)}
				tooltip={toolTip}
				onAfterChange={checkValidation}
				className={className}
			/>
		);
	}
}