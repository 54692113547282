/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import { action, observable } from 'mobx';
import { Model, IModelAttributes, attribute, entity } from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from "Util/AttributeUtils";
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchManyToManyFunc,
	makeJoinEqualsFunc,
	makeEnumFetchFunction,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import { VisitorsDocumentEntity } from 'Models/Security/Acl/VisitorsDocumentEntity';
import { MehubAccountDocumentEntity } from 'Models/Security/Acl/MehubAccountDocumentEntity';
import { MehubAdminDocumentEntity } from 'Models/Security/Acl/MehubAdminDocumentEntity';
import * as Enums from '../Enums';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import { FileListPreview } from 'Views/Components/CRUD/Attributes/AttributeFile';
import {SuperAdministratorScheme} from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] on begin
import moment from 'moment';
import {store} from "../Store";
import {DocumentTypesDocuments} from "Models/Entities";
import {SERVER_URL} from "../../Constants";
import PreviewDocButton from "../../Views/CustomComponents/PreviewDocButton";
// % protected region % [Add any further imports here] end

export interface IDocumentEntityAttributes extends IModelAttributes {
	documentName: string;
	documentUploadId: string;
	documentUpload: Blob;
	commentBox: string;
	documentLink: string;
	annualReview: boolean;
	status: Enums.documentStatus;

	memberOrganisationId?: string;
	memberOrganisation?: Models.MemberOrganisationEntity | Models.IMemberOrganisationEntityAttributes;
	documentTypess: Array<Models.DocumentTypesDocuments | Models.IDocumentTypesDocumentsAttributes>;
	formSubmissionss: Array<Models.FormSubmissionsDocuments | Models.IFormSubmissionsDocumentsAttributes>;
	mehubAccountss: Array<Models.DocumentsMehubAccounts | Models.IDocumentsMehubAccountsAttributes>;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('DocumentEntity', 'Document')
// % protected region % [Customise your entity metadata here] end
export default class DocumentEntity extends Model implements IDocumentEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsDocumentEntity(),
		new MehubAccountDocumentEntity(),
		new MehubAdminDocumentEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Document Name'] off begin
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'Document Name',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public documentName: string;
	// % protected region % [Modify props to the crud options here for attribute 'Document Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Document Upload'] on begin
	@observable
	@attribute({file: 'documentUpload'})
	@CRUD({
		name: 'Document File',
		displayType: 'file',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseUuid,
		fileAttribute: 'documentUpload',
		displayFunction: (attr, that) => {
			const document = (that as DocumentEntity);
			if ((store.routerHistory.location.pathname.includes("/mea-documents")
				|| store.routerHistory.location.pathname.includes("/admin/meadocumententity"))) {
				return <PreviewDocButton 
							documentLink={document.documentLink}
							documentID={document.documentUploadId}
						/>
			}
			if (!!attr) {
				return <FileListPreview url={attr} />;
			} else if (!!document.documentLink) {
				return <a href={document.documentLink} target="_blank" className='document-upload-link'>{document.documentLink}</a>;
			}
			return 'No document or link was provided';
		},
	})
	public documentUploadId: string;
	@observable
	public documentUpload: Blob;
	// % protected region % [Modify props to the crud options here for attribute 'Document Upload'] end

	// % protected region % [Modify props to the crud options here for attribute 'Comment Box'] on begin
	@observable
	@attribute()
	@CRUD({
		name: 'Comments',
		displayType: 'textarea',
		order: 40,
		headerColumn: false,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public commentBox: string;
	// % protected region % [Modify props to the crud options here for attribute 'Comment Box'] end

	// % protected region % [Modify props to the crud options here for attribute 'Document Link'] on begin
	@Validators.Url()
	@observable
	@attribute()
	@CRUD({
		name: 'Document Link',
		displayType: 'textfield',
		order: 30,
		headerColumn: false,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public documentLink: string;
	// % protected region % [Modify props to the crud options here for attribute 'Document Link'] end

	// % protected region % [Modify props to the crud options here for attribute 'Annual Review'] on begin
	@observable
	@attribute()
	@CRUD({
		name: 'Annual Review',
		displayType: 'checkbox',
		order: 50,
		headerColumn: false,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public annualReview: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Annual Review'] end

	// % protected region % [Modify props to the crud options here for attribute 'Status'] on begin
	@observable
	@attribute()
	@CRUD({
		name: 'Status',
		displayType: 'hidden',
		order: 40,
		headerColumn: false,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.documentStatusOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.documentStatusOptions),
		displayFunction: (attribute: Enums.documentStatus) => Enums.documentStatusOptions[attribute],
	})
	public status: Enums.documentStatus;
	// % protected region % [Modify props to the crud options here for attribute 'Status'] end

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Member Organisation'] on begin
		name: 'Member Organisation',
		displayType: 'hidden',
		order: 50,
		referenceTypeFunc: () => Models.MemberOrganisationEntity,
		// % protected region % [Modify props to the crud options here for reference 'Member Organisation'] end
	})
	public memberOrganisationId?: string;
	@observable
	@attribute({isReference: true, manyReference: false})
	public memberOrganisation: Models.MemberOrganisationEntity;

	@Validators.Length(1)
	@Validators.Required()
	@observable
	@attribute({isReference: true, manyReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Document Types'] on begin
		name: 'Document Types',
		displayType: 'reference-multicombobox',
		order: 20,
		isJoinEntity: true,
		headerColumn: true,
		displayFunction: (attr: Models.DocumentTypesDocuments[]) => {
			let documentTypeNames: string[] = [];
			attr.forEach((element: Models.DocumentTypesDocuments) => {
				let documentType: Models.DocumentTypesEntity = element.documentTypes;
				if (documentType) documentTypeNames.push(documentType.name);
			});
			return documentTypeNames.length ? documentTypeNames.join(', ') : "None";
		},
		referenceTypeFunc: () => Models.DocumentTypesDocuments,
		optionEqualFunc: makeJoinEqualsFunc('documentTypesId'),
		referenceResolveFunction: (query: string | string[], queryOptions: {model: Model})  => {
			const manyToManyFunc = makeFetchManyToManyFunc({
				entityName: 'documentEntity',
				oppositeEntityName: 'documentTypesEntity',
				relationName: 'documents',
				relationOppositeName: 'documentTypes',
				entity: () => Models.DocumentEntity,
				joinEntity: () => Models.DocumentTypesDocuments,
				oppositeEntity: () => Models.DocumentTypesEntity,
			})
			return manyToManyFunc(query, queryOptions).then(options => {
				if (store.routerHistory.location.pathname.includes("/mea-documents/") 
					|| store.routerHistory.location.pathname.includes("/admin/meadocumententity") ) {
					return options.filter(option => (option.value as DocumentTypesDocuments).documentTypes.meaDocument);
				} else {
					return options.filter(option => !(option.value as DocumentTypesDocuments).documentTypes.meaDocument);
				}
			})
		},
		// % protected region % [Modify props to the crud options here for reference 'Document Types'] end
	})
	public documentTypess: Models.DocumentTypesDocuments[] = [];

	@observable
	@attribute({isReference: true, manyReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Form Submissions'] on begin
		name: 'Form Submissions',
		displayType: 'hidden',
		order: 60,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.FormSubmissionsDocuments,
		optionEqualFunc: makeJoinEqualsFunc('formSubmissionsId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'documentEntity',
			oppositeEntityName: 'formSubmissionsEntity',
			relationName: 'documents',
			relationOppositeName: 'formSubmissions',
			entity: () => Models.DocumentEntity,
			joinEntity: () => Models.FormSubmissionsDocuments,
			oppositeEntity: () => Models.FormSubmissionsEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Form Submissions'] end
	})
	public formSubmissionss: Models.FormSubmissionsDocuments[] = [];

	@observable
	@attribute({isReference: true, manyReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'MeHub Accounts'] on begin
		name: 'MeHub Accounts',
		displayType: 'hidden',
		order: 80,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.DocumentsMehubAccounts,
		optionEqualFunc: makeJoinEqualsFunc('mehubAccountsId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'documentEntity',
			oppositeEntityName: 'mehubAccountEntity',
			relationName: 'documents',
			relationOppositeName: 'mehubAccounts',
			entity: () => Models.DocumentEntity,
			joinEntity: () => Models.DocumentsMehubAccounts,
			oppositeEntity: () => Models.MehubAccountEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'MeHub Accounts'] end
	})
	public mehubAccountss: Models.DocumentsMehubAccounts[] = [];

	// % protected region % [Add any custom attributes to the model here] on begin
	@CRUD({
		name: "Pending Approval",
		displayType: 'hidden',
		order: 5,
		readFieldType: 'pending-approval',
	})
	public pendingApproval: undefined = undefined;
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IDocumentEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IDocumentEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.documentName !== undefined) {
				this.documentName = attributes.documentName;
			}
			if (attributes.documentUpload !== undefined) {
				this.documentUpload = attributes.documentUpload;
			}
			if (attributes.documentUploadId !== undefined) {
				this.documentUploadId = attributes.documentUploadId;
			}
			if (attributes.commentBox !== undefined) {
				this.commentBox = attributes.commentBox;
			}
			if (attributes.documentLink !== undefined) {
				this.documentLink = attributes.documentLink;
			}
			if (attributes.annualReview !== undefined) {
				this.annualReview = attributes.annualReview;
			}
			if (attributes.status !== undefined) {
				this.status = attributes.status;
			}
			if (attributes.memberOrganisationId !== undefined) {
				this.memberOrganisationId = attributes.memberOrganisationId;
			}
			if (attributes.memberOrganisation !== undefined) {
				if (attributes.memberOrganisation === null) {
					this.memberOrganisation = attributes.memberOrganisation;
				} else {
					if (attributes.memberOrganisation instanceof Models.MemberOrganisationEntity) {
						this.memberOrganisation = attributes.memberOrganisation;
						this.memberOrganisationId = attributes.memberOrganisation.id;
					} else {
						this.memberOrganisation = new Models.MemberOrganisationEntity(attributes.memberOrganisation);
						this.memberOrganisationId = this.memberOrganisation.id;
					}
				}
			}
			if (attributes.documentTypess !== undefined && Array.isArray(attributes.documentTypess)) {
				for (const model of attributes.documentTypess) {
					if (model instanceof Models.DocumentTypesDocuments) {
						this.documentTypess.push(model);
					} else {
						this.documentTypess.push(new Models.DocumentTypesDocuments(model));
					}
				}
			}
			if (attributes.formSubmissionss !== undefined && Array.isArray(attributes.formSubmissionss)) {
				for (const model of attributes.formSubmissionss) {
					if (model instanceof Models.FormSubmissionsDocuments) {
						this.formSubmissionss.push(model);
					} else {
						this.formSubmissionss.push(new Models.FormSubmissionsDocuments(model));
					}
				}
			}
			if (attributes.mehubAccountss !== undefined && Array.isArray(attributes.mehubAccountss)) {
				for (const model of attributes.mehubAccountss) {
					if (model instanceof Models.DocumentsMehubAccounts) {
						this.mehubAccountss.push(model);
					} else {
						this.mehubAccountss.push(new Models.DocumentsMehubAccounts(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] on begin
	public defaultExpands = `
		documentTypess {
			${Models.DocumentTypesDocuments.getAttributes().join('\n')}
			documentTypes {
				${Models.DocumentTypesEntity.getAttributes().join('\n')}
			}
		}
		formSubmissionss {
			${Models.FormSubmissionsDocuments.getAttributes().join('\n')}
			formSubmissions {
				${Models.FormSubmissionsEntity.getAttributes().join('\n')}
			}
		}
	`;

	public listExpands =  `
	documentTypess {
		${Models.DocumentTypesDocuments.getAttributes().join('\n')}
		documentTypes {
			${Models.DocumentTypesEntity.getAttributes().join('\n')}
		}
	}`;

	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath = {
			mehubAccountss: {},
			documentTypess: {},
			formSubmissionss: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'documentTypess',
							'formSubmissionss',
							'mehubAccountss',
						]
					},
				],
				contentType: 'multipart/form-data',
			}
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] on begin
		return this.documentName;
		// % protected region % [Customise the display name for this entity] end
	}


	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] on begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [ createdAttr, modifiedAttr ] = getCreatedModifiedCrudOptions();
let customCreatedAttr = {
	...createdAttr,
	name: 'Date created',
	headerColumn: true,
	order: 50,
	displayFunction: (attr: moment.MomentInput) => moment.utc(attr).local().format('DD/MM/YYYY hh:mm A'),
}

CRUD(customCreatedAttr)(DocumentEntity.prototype, 'created');
CRUD(modifiedAttr)(DocumentEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
