import * as React from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router';
import Query_Button from '../../images/Query_Button.svg';

import standards_icon from "../../images/Standards Icon.svg";
import industry_icon from "../../images/Industry Updates Icon.svg";
import business_icon from "../../images/Business Growth Icon.svg";
import member_icon from "../../images/Member Benefits Icon.svg";
import safety_icon from "../../images/Safety Icon.svg";
import technical_docs_icon from "../../images/Technical Legal Icon.svg";
import legal_icon from "../../images/Legal Icon.svg";
import employment_icon from "../../images/Workplace Relations Icon.svg";
import { store } from 'Models/Store';
import GlobalSearch from 'Views/CustomComponents/GlobalSearch';
import { gql } from '@apollo/client';
import { observable, runInAction } from 'mobx';
import { HomePageLinkEntity } from 'Models/Entities';
import classNames from 'classnames';

export function getHomepageLinkListQuery() {
	return gql`query fetch ($args: [[WhereExpressionGraph]]) {
	homepage: homePageLinkEntitysConditional (conditions: $args) {
			name
			summary
			tooltip
		}
	}`;
}

interface MeHubTileProps extends RouteComponentProps {
	fixed?: boolean; // should tile have fixed dimensions
}

@observer
export default class MeHubTile extends React.Component<MeHubTileProps> {
	@observable
	private homepageLinkEntities: HomePageLinkEntity[] = [];

	componentDidMount() {
		this.fetchHomepagLinks();
	}
	
	private fetchHomepagLinks = () => {
		store.apolloClient.query({ query: getHomepageLinkListQuery(), fetchPolicy: 'network-only' }).then(
			({ loading, errors, data }) => {
				if (loading) {
					return null;
				}
				if (errors) {
					console.error(errors);
					return 'Something went wrong while connecting to the server. The error is ' + JSON.stringify(errors);
				}
				if (data !== undefined) {
					runInAction(() => {
						this.homepageLinkEntities = data ? data['homepage'] : [];
					});
				}
				return null;
			}
		);
	}

	public render() {
		let contents = null;

		contents = (
			<>
				<section className="hub-controls">

					<div className="hub-controls__search">
						<h2>{store.givenName ? `Hi, ${store.givenName}` : "Welcome"}. Let's get started.</h2>
						<div className="input-group input-group-block" aria-label="Search-Field">
							<GlobalSearch precedentType={null} />
						</div>
					</div>

					<div className="hub-controls__tiles">

						{this.landingTile(
							safety_icon,
							'Safety',
							false,
							'/mesafety',
							this.homepageLinkEntities.find(h => h.name === 'Safety')!,
							this.props.fixed,
						)}

						{this.landingTile(
							standards_icon,
							'Standards',
							false, 
							'/standards',
							this.homepageLinkEntities.find(h => h.name === 'Standards'),
							this.props.fixed,
						)}

						{this.landingTile(
							employment_icon,
							'My Documents',
							false,
							'/my-documents',
							this.homepageLinkEntities.find(h => h.name === 'Employment-HR'),
							this.props.fixed,
						)}

						{this.landingTile(
							member_icon,
							'Member Benefits',
							true, 
							'https://www.masterelectricians.com.au/members/resources/member-benefits',
							this.homepageLinkEntities.find(h => h.name === 'Member Benefits'),
							this.props.fixed,
						)}

						{this.landingTile(
							technical_docs_icon,
							'MEA Documents',
							false, 
							'/mea-documents',
							this.homepageLinkEntities.find(h => h.name === 'Technical'),
							this.props.fixed,
						)}

						{this.landingTile(
							legal_icon,
							'Legal',
							true, 
							'https://www.masterelectricians.com.au/member-resources/legal',
							this.homepageLinkEntities.find(h => h.name === 'Legal'),
							this.props.fixed,
						)}


						{this.landingTile(
							industry_icon,
							'Industry Updates',
							true, 
							'https://www.masterelectricians.com.au/member-resources/industry-updates',
							this.homepageLinkEntities.find(h => h.name === 'Industry Updates'),
							this.props.fixed,
						)}

						{this.landingTile(
							business_icon,
							'Business Growth',
							true, 
							'https://www.masterelectricians.com.au/member-resources/business-growth',
							this.homepageLinkEntities.find(h => h.name === 'Business Growth'),
							this.props.fixed,
						)}
					</div>

				</section>
			</>
		);

		return contents;
	}

	private landingTile = (icon: string, header: string, external: boolean, link: string, homepage?: HomePageLinkEntity, fixed: boolean = false) => {
		return (
			<div className={classNames('tile', { fixed })} onClick={() => this.onLinkClick(external, header, link)}> 
				<div className="tile__tooltip" onClick={(e) => e.stopPropagation()}>
					<img src={Query_Button} />
					<span className="tooltip__content">{homepage?.tooltip}</span>
				</div>
				<div className="tile__icon"><img src={icon} /></div>
				<div className="tile__text">
					<h4>{header}</h4>
					<p>{homepage?.summary}</p>
				</div>
			</div>
		);
	}

	private onLinkClick = (external: boolean, header: string, link: string) => {
		if (external) {
			window.open(link);  
		} else {
			const eventName = 'Homepage_Click_' + header;

			store.routerHistory.push(link); 
		}
	}
}