import React, { ReactNode } from 'react';
import { Combobox } from 'Views/Components/Combobox/Combobox';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import axios from 'axios';
import { SERVER_URL } from 'Constants';
import If from 'Views/Components/If/If';
import { runInAction } from 'mobx';
import * as uuid from 'uuid';
import { Link } from "react-router-dom";
import { store } from 'Models/Store';

export type SearchedLinkType = 'SAFETY' | 'STANDARD' | 'GUIDANCE' | null;
export type PrecedentType = 'SAFETY' | 'LIBRARY' | 'STANDARD' | null;

export interface IGlobalSearchProps{
	// display this type of result first in the search result list
	precedentType: PrecedentType;
}

export interface SearchedLink {
	searchedLinkType: SearchedLinkType,
	sectionName: string,
	name: string,
	create: string,
	view: string,
	header: string,
	description: string
};

export default class GlobalSearch extends React.Component<IGlobalSearchProps>{ 
	private searchState: { value: string | null | undefined; }
		= { value: null };
	private divOutOfComboboxRef = React.createRef<HTMLDivElement>();
	
	public render() {
		return (
			<>
				<Combobox model={this.searchState}
					modelProperty='value'
					label=''
					labelVisible={false}
					placeholder='Search...'
					options={this.getOptions()}
					searchable={true}
					isClearable={true}
					className="global-search"
					minSearchLength={2}
					inputProps={{
						selectOnBlur: false,
						noResultsMessageOverwrite: (
							<>
								<p>Hmm no results, please try our <a className="advanced-search" href="https://www.masterelectricians.com.au/search" target="_blank" rel="noreferrer">advanced search</a> now.</p>
								<p>If you still cannot find what you are looking for call <a href="tel:1300 889 198">1300 889 198</a> or email via <Link to="contactus">Contact Us</Link>.</p>
							</>
						)
					}}
					onAfterChange={() => {
						runInAction(() => {
							this.searchState = { value: undefined };
						})
					}}
				/>
				<div ref={this.divOutOfComboboxRef} />
			</>
		);
	}

	private getOptions = () => {
		return AwesomeDebouncePromise(this.fetchOptions, 500);
	}

	private fetchOptions = async (query?: string | string[]) => {
		if (Array.isArray(query)) {
			return [];
		}

		return axios.get<Array<SearchedLink>>(`${SERVER_URL}/api/entity/safetylinkentity/search_link_list`,
			{
				params: { 
					searchTerm: query,
					precedentType: this.props.precedentType
				}
			})
			.then(({data}) => this.onSuccess(data))
			.catch(this.onError);
	}

	private getLinkItem = (link: SearchedLink): React.ReactNode => {
		switch (link.searchedLinkType) {
			case 'SAFETY':
				return (
					<div className='link-item'>
						<span className='link-name'>{link.sectionName} | {link.name}</span>
						<If condition={!!link.create}>
							<a target="_blank" className="create-button-search" href='#'
								onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => this.onLinkClicked(event, link, 'create')}
							>Create</a>
						</If>
						<If condition={!!link.view}>
							<a target="_blank" className="view-button-search" href='#'
								onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => this.onLinkClicked(event, link, 'view')}
							>View</a>
						</If>
					</div>
				);
			case 'STANDARD':
				return (
					<div className='link-item'>
						<div className='link-name'>
							<div className='name'>{link.sectionName} | {link.name}</div>
							<div className='header'>{link.header}</div>
						</div>
						<If condition={!!link.create}>
							<a target="_blank" className="create-button-search" href='#'
								onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => this.onLinkClicked(event, link, 'create')}
							>Create</a>
						</If>
						<If condition={!!link.view}>
							<a target="_blank" className="view-button-search" href='#'
								onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => this.onLinkClicked(event, link, 'view')}
							>View</a>
						</If>
					</div>
				);
			case 'GUIDANCE':
				return (
					<div className='link-item'>
						<div className='link-name'>
							<div className='name'>{link.sectionName} | {link.name}</div>
							<div className='header'>{link.header}</div>
						</div>
						<If condition={!!link.create}>
							<a target="_blank" className="create-button-search" href='#'
								onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => this.onLinkClicked(event, link, 'create')}
							>Create</a>
						</If>
						<If condition={!!link.view}>
							<a target="_blank" className="view-button-search" href='#'
								onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => this.onLinkClicked(event, link, 'view')}
							>View</a>
						</If>
					</div>
				);
			default:
				return null;
		}

	}

	private onLinkClicked = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, link: SearchedLink, clickedAction: 'create' | 'view') => {
		event.preventDefault();
		event.stopPropagation();
		const linkToOpen = clickedAction === 'create' ? link.create : link.view; 
		window.open(linkToOpen, '_blank');
	}

	private onSuccess = (data: SearchedLink[]): Array<{ display: React.ReactNode, value: string }> => {
		return data.map(l => ({
			display: this.getLinkItem(l),
			value: l.sectionName + l.name + uuid.v4()
		}));
	}

	private onError = (data: any) => {
		return [];
	}
}