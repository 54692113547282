import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {QuestionType} from 'Forms/Schema/Question';
import {IQuestionTile, QuestionTileOptionsProps} from '../QuestionTile';
import TileOptions from '../Options/TileOptions';
import CompareBoolean from 'Forms/Conditions/CompareBoolean';
import {QuestionComponent, QuestionComponentProps} from 'Forms/Questions/QuestionComponent';
import CanvasDraw from "react-canvas-draw";
import {Button, Colors, Display} from "../../../Views/Components/Button/Button";
import {Resizable, ResizeCallbackData} from 'react-resizable';
import {observable, runInAction} from "mobx";
import classNames from "classnames";

@observer
export class CanvasDrawQuestionTileOptions extends Component<QuestionTileOptionsProps> {

	public render() {
		const { question, schema } = this.props;
		return (
			<TileOptions question={question} schema={schema} hasShowConditions/>
		);
	}
}

export interface ICanvasDrawQuestionTileProps<T> extends QuestionComponentProps<T> {
	options: {
		values: {
			value: string
		}[]
	};
}

@observer
export class CanvasDrawQuestionTile<T> extends QuestionComponent<T, ICanvasDrawQuestionTileProps<T>> implements IQuestionTile {
	static displayName = 'CanvasDraw';

	static questionType: QuestionType = 'canvasDraw';
	static optionsMenu = CanvasDrawQuestionTileOptions;
	static conditionOptions = [
		{ display: 'Equal', value: 'equal' },
		{ display: 'Not equal', value: 'notEqual' },
	];
	static compareFunction = CompareBoolean;

	@observable
	private canvasHeight: number = 300;

	@observable
	private canvasWidth: number = 400;

	@observable
	private resizableHeight: number = 320;

	@observable
	private resizableWidth: number = 420;

	private saveableCanvas: CanvasDraw | null;

	private isCanvasDataLoaded: boolean = false;

	componentDidMount() {
		this.loadInitialCanvasData();
	}

    componentDidUpdate() {
		if(!this.isCanvasDataLoaded) {
			this.loadInitialCanvasData();
		}
    }

	private loadInitialCanvasData() {
		const { model, id } = this.props;
		const modelProperty = `${id}-canvas-draw`;
		if(this.saveableCanvas && model[`${modelProperty}-data-string`]) {
			this.saveableCanvas.loadSaveData(model[`${modelProperty}-data-string`]);
			this.isCanvasDataLoaded = true;	
		}
	}

	private onChange = (canvasDraw: CanvasDraw) => {
		const { model, id } = this.props;
		
		const modelProperty = `${id}-canvas-draw`;

		// String is required to 're-load' the canvas if needed
		model[`${modelProperty}-data-string`] = canvasDraw.getSaveData();
		// DataURL is the image 
		model[`${modelProperty}-dataURL`] = canvasDraw["canvas"]["drawing"].toDataURL();
	}
	
	onResize = (event :React.SyntheticEvent, { size } :ResizeCallbackData) => {
		runInAction(()=>{
			this.resizableHeight = size.height;
			this.resizableWidth = size.width;

			/* Canvas height is slightly smaller than the resizeable
			 * box due to resizable having a 10px padding */
			this.canvasHeight = this.resizableHeight - 20;
			this.canvasWidth = this.resizableWidth - 20;
		});
	};

	onUndo = () => {
		this.saveableCanvas?.undo();
	};

	onClear = () => {
		this.saveableCanvas?.clear();

		// Additional undo to trigger an on change to save the clear to the model 
		this.saveableCanvas?.undo();		
	};

	public render() {
		const { title, isReadOnly, className } = this.props;

		const classes = classNames('canvasDraw', className);

		return (
			<div className="canvasDraw-question-wrapper">
				<p>{title}</p>
				<Resizable
					height={this.resizableHeight}
					width={this.resizableWidth}
					onResize={this.onResize}
					minConstraints={[150, 100]}
					handleSize={[14, 14]}
					resizeHandles={['sw', 'se', 'ne', 'e', 's']}
				>
					<div style={{ height: this.resizableHeight + 'px', width: this.resizableWidth + 'px'}}>
						<CanvasDraw
							ref={canvasDraw => (this.saveableCanvas = canvasDraw)}
							className={classes}
							onChange={this.onChange}
							canvasHeight={this.canvasHeight}
							canvasWidth={this.canvasWidth}
							brushColor={'#666'}
							brushRadius={4}
							lazyRadius={8}
							hideInterface
							disabled={isReadOnly}
						/>
					</div>
				</Resizable>
				<Button
					onClick={this.onClear}
					display={Display.Outline}
					colors={Colors.Primary}>
					Clear
				</Button>
				<Button
					onClick={this.onUndo}
					display={Display.Outline}>
					Undo
				</Button>
			</div>
		);
	}
}
