/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
// % protected region % [Add any extra imports here] off begin
// % protected region % [Add any extra imports here] end

// % protected region % [Override contents here] on begin
import {formTypes} from "./Models/Enums";

export const SERVER_URL = '';

export const SAML_CONFIG = {
	production: false,
	samlSsoUrl: `${SERVER_URL}/Saml/InitiateSingleSignOn?returnurl=`,
	samlSloUrl: `${SERVER_URL}/Saml/InitiateSingleLogout?returnurl=`
};

/**
 * Here are the hotJar details
Prod:

<!-- Hotjar Tracking Code for my site -->
<script>
    (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:3326919,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
</script>

 */

export const SAFETY_TOOLTIPS: { [key in formTypes]: string } = {
	RISK_ASSESSMENT: 'For every new task activity to identify any hazards and asses the risks to implement control measures.',
	INCIDENT: 'Record injury/damage or a near miss to persons, property, environment etc.',
	INSPECTIONS: 'Use for inspections and checks of workplaces, vehicles, machinery, equipment and supervision.',
	PERMITS: 'When required to undertake Live Work, Hot Works, enter Confined Spaces, etc.',
	VERIFICATION: 'Document and record inspection and testing, such as live testing, and fault finding.',
	PROJECT: 'Activities related to project safety.',
	QUALITY: 'Undertake Audits, measure analyse and evaluate your suppliers, Business Operations and Management Responsibilities.',
	WORKPLACE_RELATIONS: 'Licence and Competency records for your business.',
	MEETING_AND_TRAINING: 'Plan, conduct and document Safety Meetings, Toolbox Talks etc.',
};

// % protected region % [Override contents here] end

// % protected region % [Override application name here] off begin
export const APPLICATION_NAME = 'mehub';
// % protected region % [Override application name here] end

// % protected region % [Add any extra constants here] off begin
// % protected region % [Add any extra constants here] end
