/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import moment from 'moment';
import { action, observable } from 'mobx';
import { Model, IModelAttributes, attribute, entity } from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from "Util/AttributeUtils";
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchManyToManyFunc,
	makeJoinEqualsFunc,
	makeFetchOneToManyFunc,
	makeEnumFetchFunction,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import { VisitorsMehubAccountEntity } from 'Models/Security/Acl/VisitorsMehubAccountEntity';
import { MehubAccountMehubAccountEntity } from 'Models/Security/Acl/MehubAccountMehubAccountEntity';
import { MehubAdminMehubAccountEntity } from 'Models/Security/Acl/MehubAdminMehubAccountEntity';
import * as Enums from '../Enums';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import { TimelineModel } from 'Timelines/TimelineModel';
import {SuperAdministratorScheme} from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IMehubAccountEntityAttributes extends IModelAttributes {
	email: string;
	userName: string;
	twoFactorEnabled: boolean;
	memnetID: number;
	firstname: string;
	surname: string;
	mobileNumber: string;
	streetAddress1: string;
	streetAddress2: string;
	streetAddress3: string;
	postcode: string;
	city: string;
	state: Enums.states;
	country: string;
	startDate: Date;
	endDate: Date;
	isDuplicate: boolean;
	lastsyncdate: Date;

	formSubmissionss: Array<Models.FormSubmissionsEntity | Models.IFormSubmissionsEntityAttributes>;
	licencess: Array<Models.LicenceEntity | Models.ILicenceEntityAttributes>;
	memberOrganisationId?: string;
	memberOrganisation?: Models.MemberOrganisationEntity | Models.IMemberOrganisationEntityAttributes;
	roleId?: string;
	role?: Models.RoleEntity | Models.IRoleEntityAttributes;
	reminderLogss: Array<Models.ReminderLogEntity | Models.IReminderLogEntityAttributes>;
	reviewedFormRequestss: Array<Models.FormReviewRequestEntity | Models.IFormReviewRequestEntityAttributes>;
	loggedEvents: Array<Models.MehubAccountTimelineEventsEntity | Models.IMehubAccountTimelineEventsEntityAttributes>;
	isPrimaryContact?: Models.MemberOrganisationEntity | Models.IMemberOrganisationEntityAttributes;
	assetss: Array<Models.AssetsMehubAccounts | Models.IAssetsMehubAccountsAttributes>;
	documentss: Array<Models.DocumentsMehubAccounts | Models.IDocumentsMehubAccountsAttributes>;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('MehubAccountEntity', 'MeHub Account')
// % protected region % [Customise your entity metadata here] end
export default class MehubAccountEntity extends Model implements IMehubAccountEntityAttributes, TimelineModel  {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsMehubAccountEntity(),
		new MehubAccountMehubAccountEntity(),
		new MehubAdminMehubAccountEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		'email',
		'userName',
		'twoFactorEnabled',
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Email'] off begin
	@Validators.Email()
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'Email',
		displayType: 'displayfield',
		order: 10,
		createFieldType: 'textfield',
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public email: string;
	// % protected region % [Modify props to the crud options here for attribute 'Email'] end

	@observable
	@attribute()
	// % protected region % [Modify props to the crud options here for attribute 'TwoFactorEnabled'] off begin
	@CRUD({
		name: 'TwoFactorEnabled',
		displayType: 'displayfield',
		order: 20,
		createFieldType: 'textfield',
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public twoFactorEnabled: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'TwoFactorEnabled'] end

	// % protected region % [Modify props to the crud options here for attribute 'UserName'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'UserName',
		displayType: 'hidden',
	})
	public userName: string;
	// % protected region % [Modify props to the crud options here for attribute 'UserName'] end

	// % protected region % [Modify props to the crud options here for attribute 'Password'] off begin
	@Validators.Length(12, 128)
	@observable
	@CRUD({
		name: 'Password',
		displayType: 'hidden',
		order: 30,
		createFieldType: 'password',
	})
	public password: string;
	// % protected region % [Modify props to the crud options here for attribute 'Password'] end

	// % protected region % [Modify props to the crud options here for attribute 'Confirm Password'] off begin
	@Validators.Custom('Password Match', (e: string, target: MehubAccountEntity) => {
		return new Promise(resolve => resolve(target.password !== e ? "Password fields do not match" : null))
	})
	@observable
	@CRUD({
		name: 'Confirm Password',
		displayType: 'hidden',
		order: 40,
		createFieldType: 'password',
	})
	public _confirmPassword: string;
	// % protected region % [Modify props to the crud options here for attribute 'Confirm Password'] end

	// % protected region % [Modify props to the crud options here for attribute 'Memnet ID'] off begin
	/**
	 * Memnet external ID assigned to the user 
	 */
	@Validators.Integer()
	@observable
	@attribute()
	@CRUD({
		name: 'Memnet ID',
		displayType: 'textfield',
		order: 50,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public memnetID: number;
	// % protected region % [Modify props to the crud options here for attribute 'Memnet ID'] end

	// % protected region % [Modify props to the crud options here for attribute 'Firstname'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'Firstname',
		displayType: 'textfield',
		order: 60,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public firstname: string;
	// % protected region % [Modify props to the crud options here for attribute 'Firstname'] end

	// % protected region % [Modify props to the crud options here for attribute 'Surname'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'Surname',
		displayType: 'textfield',
		order: 70,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public surname: string;
	// % protected region % [Modify props to the crud options here for attribute 'Surname'] end

	// % protected region % [Modify props to the crud options here for attribute 'Mobile Number'] on begin
	@observable
	@attribute()
	@CRUD({
		name: 'Mobile Number',
		displayType: 'textfield',
		order: 70,
		headerColumn: false,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public mobileNumber: string;
	// % protected region % [Modify props to the crud options here for attribute 'Mobile Number'] end

	// % protected region % [Modify props to the crud options here for attribute 'Street Address 1'] on begin
	@observable
	@attribute()
	@CRUD({
		name: 'Street Address 1',
		displayType: 'textfield',
		order: 80,
		headerColumn: false,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public streetAddress1: string;
	// % protected region % [Modify props to the crud options here for attribute 'Street Address 1'] end

	// % protected region % [Modify props to the crud options here for attribute 'Street Address 2'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'Street Address 2',
		displayType: 'textfield',
		order: 100,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public streetAddress2: string;
	// % protected region % [Modify props to the crud options here for attribute 'Street Address 2'] end

	// % protected region % [Modify props to the crud options here for attribute 'Street Address 3'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'Street Address 3',
		displayType: 'textfield',
		order: 110,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public streetAddress3: string;
	// % protected region % [Modify props to the crud options here for attribute 'Street Address 3'] end

	// % protected region % [Modify props to the crud options here for attribute 'Postcode'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'Postcode',
		displayType: 'textfield',
		order: 120,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public postcode: string;
	// % protected region % [Modify props to the crud options here for attribute 'Postcode'] end

	// % protected region % [Modify props to the crud options here for attribute 'City'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'City',
		displayType: 'textfield',
		order: 130,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public city: string;
	// % protected region % [Modify props to the crud options here for attribute 'City'] end

	// % protected region % [Modify props to the crud options here for attribute 'State'] on begin
	@observable
	@attribute()
	@CRUD({
		name: 'State',
		displayType: 'enum-combobox',
		order: 130,
		searchable: true,
		headerColumn: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.statesOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.statesOptions),
		displayFunction: (attribute: Enums.states) => Enums.statesOptions[attribute],
	})
	public state: Enums.states;
	// % protected region % [Modify props to the crud options here for attribute 'State'] end

	// % protected region % [Modify props to the crud options here for attribute 'Country'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'Country',
		displayType: 'textfield',
		order: 150,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public country: string;
	// % protected region % [Modify props to the crud options here for attribute 'Country'] end

	// % protected region % [Modify props to the crud options here for attribute 'Start Date'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'Start Date',
		displayType: 'datepicker',
		order: 160,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseDate,
	})
	public startDate: Date;
	// % protected region % [Modify props to the crud options here for attribute 'Start Date'] end

	// % protected region % [Modify props to the crud options here for attribute 'End Date'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'End Date',
		displayType: 'datepicker',
		order: 170,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseDate,
	})
	public endDate: Date;
	// % protected region % [Modify props to the crud options here for attribute 'End Date'] end

	// % protected region % [Modify props to the crud options here for attribute 'Is  Duplicate'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'Is  Duplicate',
		displayType: 'checkbox',
		order: 180,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public isDuplicate: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Is  Duplicate'] end

	// % protected region % [Modify props to the crud options here for attribute 'LastSyncDate'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'LastSyncDate',
		displayType: 'datepicker',
		order: 190,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseDate,
	})
	public lastsyncdate: Date;
	// % protected region % [Modify props to the crud options here for attribute 'LastSyncDate'] end

	@observable
	@attribute({isReference: true, manyReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Form Submissions'] on begin
		name: "Form Submissions",
		displayType: 'reference-multicombobox',
		order: 180,
		referenceTypeFunc: () => Models.FormSubmissionsEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'formSubmissionss',
			oppositeEntity: () => Models.FormSubmissionsEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Form Submissions'] end
	})
	public formSubmissionss: Models.FormSubmissionsEntity[] = [];

	@observable
	@attribute({isReference: true, manyReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Licences'] on begin
		name: "Licences",
		displayType: 'reference-multicombobox',
		order: 190,
		referenceTypeFunc: () => Models.LicenceEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'licencess',
			oppositeEntity: () => Models.LicenceEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Licences'] end
	})
	public licencess: Models.LicenceEntity[] = [];

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Member Organisation'] off begin
		name: 'Member Organisation',
		displayType: 'reference-combobox',
		order: 220,
		referenceTypeFunc: () => Models.MemberOrganisationEntity,
		// % protected region % [Modify props to the crud options here for reference 'Member Organisation'] end
	})
	public memberOrganisationId?: string;
	@observable
	@attribute({isReference: true, manyReference: false})
	public memberOrganisation: Models.MemberOrganisationEntity;

	/**
	 * User Roles
	 */
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'role'] on begin
		name: 'Role',
		displayType: 'reference-combobox',
		order: 210,
		referenceTypeFunc: () => Models.RoleEntity,
		// % protected region % [Modify props to the crud options here for reference 'role'] end
	})
	public roleId?: string;
	@observable
	@attribute({isReference: true, manyReference: false})
	public role: Models.RoleEntity;

	@observable
	@attribute({isReference: true, manyReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Reminder Logs'] on begin
		name: "Reminder Logs",
		displayType: 'hidden',
		order: 220,
		referenceTypeFunc: () => Models.ReminderLogEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'reminderLogss',
			oppositeEntity: () => Models.ReminderLogEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Reminder Logs'] end
	})
	public reminderLogss: Models.ReminderLogEntity[] = [];

	/**
	 * Connects Managers with Form Review Requests they have reviewed
	 */
	@observable
	@attribute({isReference: true, manyReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Reviewed Form Requests'] off begin
		name: "Reviewed Form Requests",
		displayType: 'reference-multicombobox',
		order: 250,
		referenceTypeFunc: () => Models.FormReviewRequestEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'reviewedFormRequestss',
			oppositeEntity: () => Models.FormReviewRequestEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Reviewed Form Requests'] end
	})
	public reviewedFormRequestss: Models.FormReviewRequestEntity[] = [];

	@observable
	@attribute({isReference: true, manyReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Logged Event'] off begin
		name: "Logged Events",
		displayType: 'hidden',
		order: 260,
		referenceTypeFunc: () => Models.MehubAccountTimelineEventsEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'loggedEvents',
			oppositeEntity: () => Models.MehubAccountTimelineEventsEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Logged Event'] end
	})
	public loggedEvents: Models.MehubAccountTimelineEventsEntity[] = [];

	@observable
	@attribute({isReference: true, manyReference: false})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Is Primary Contact'] off begin
		name: 'Is Primary Contact',
		displayType: 'reference-combobox',
		order: 270,
		referenceTypeFunc: () => Models.MemberOrganisationEntity,
		optionEqualFunc: (model, option) => model.id === option,
		inputProps: {
			fetchReferenceEntity: true,
		},
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'isPrimaryContacts',
			oppositeEntity: () => Models.MemberOrganisationEntity,
		})
		// % protected region % [Modify props to the crud options here for reference 'Is Primary Contact'] end
	})
	public isPrimaryContact?: Models.MemberOrganisationEntity;

	@observable
	@attribute({isReference: true, manyReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Assets'] off begin
		name: 'Assets',
		displayType: 'reference-multicombobox',
		order: 280,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.AssetsMehubAccounts,
		optionEqualFunc: makeJoinEqualsFunc('assetsId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'mehubAccountEntity',
			oppositeEntityName: 'assetEntity',
			relationName: 'mehubAccounts',
			relationOppositeName: 'assets',
			entity: () => Models.MehubAccountEntity,
			joinEntity: () => Models.AssetsMehubAccounts,
			oppositeEntity: () => Models.AssetEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Assets'] end
	})
	public assetss: Models.AssetsMehubAccounts[] = [];

	@observable
	@attribute({isReference: true, manyReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Documents'] off begin
		name: 'Documents',
		displayType: 'reference-multicombobox',
		order: 290,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.DocumentsMehubAccounts,
		optionEqualFunc: makeJoinEqualsFunc('documentsId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'mehubAccountEntity',
			oppositeEntityName: 'documentEntity',
			relationName: 'mehubAccounts',
			relationOppositeName: 'documents',
			entity: () => Models.MehubAccountEntity,
			joinEntity: () => Models.DocumentsMehubAccounts,
			oppositeEntity: () => Models.DocumentEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Documents'] end
	})
	public documentss: Models.DocumentsMehubAccounts[] = [];

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IMehubAccountEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IMehubAccountEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.email !== undefined) {
				this.email = attributes.email;
			}
			if (attributes.userName !== undefined) {
				this.userName = attributes.userName;
			}
			if (attributes.twoFactorEnabled !== undefined) {
				this.twoFactorEnabled = attributes.twoFactorEnabled;
			}
			if (attributes.memnetID !== undefined) {
				this.memnetID = attributes.memnetID;
			}
			if (attributes.firstname !== undefined) {
				this.firstname = attributes.firstname;
			}
			if (attributes.surname !== undefined) {
				this.surname = attributes.surname;
			}
			if (attributes.mobileNumber !== undefined) {
				this.mobileNumber = attributes.mobileNumber;
			}
			if (attributes.streetAddress1 !== undefined) {
				this.streetAddress1 = attributes.streetAddress1;
			}
			if (attributes.streetAddress2 !== undefined) {
				this.streetAddress2 = attributes.streetAddress2;
			}
			if (attributes.streetAddress3 !== undefined) {
				this.streetAddress3 = attributes.streetAddress3;
			}
			if (attributes.postcode !== undefined) {
				this.postcode = attributes.postcode;
			}
			if (attributes.city !== undefined) {
				this.city = attributes.city;
			}
			if (attributes.state !== undefined) {
				this.state = attributes.state;
			}
			if (attributes.country !== undefined) {
				this.country = attributes.country;
			}
			if (attributes.startDate !== undefined) {
				if (attributes.startDate === null) {
					this.startDate = attributes.startDate;
				} else {
					this.startDate = moment(attributes.startDate).toDate();
				}
			}
			if (attributes.endDate !== undefined) {
				if (attributes.endDate === null) {
					this.endDate = attributes.endDate;
				} else {
					this.endDate = moment(attributes.endDate).toDate();
				}
			}
			if (attributes.isDuplicate !== undefined) {
				this.isDuplicate = attributes.isDuplicate;
			}
			if (attributes.lastsyncdate !== undefined) {
				if (attributes.lastsyncdate === null) {
					this.lastsyncdate = attributes.lastsyncdate;
				} else {
					this.lastsyncdate = moment(attributes.lastsyncdate).toDate();
				}
			}
			if (attributes.formSubmissionss !== undefined && Array.isArray(attributes.formSubmissionss)) {
				for (const model of attributes.formSubmissionss) {
					if (model instanceof Models.FormSubmissionsEntity) {
						this.formSubmissionss.push(model);
					} else {
						this.formSubmissionss.push(new Models.FormSubmissionsEntity(model));
					}
				}
			}
			if (attributes.licencess !== undefined && Array.isArray(attributes.licencess)) {
				for (const model of attributes.licencess) {
					if (model instanceof Models.LicenceEntity) {
						this.licencess.push(model);
					} else {
						this.licencess.push(new Models.LicenceEntity(model));
					}
				}
			}
			if (attributes.memberOrganisationId !== undefined) {
				this.memberOrganisationId = attributes.memberOrganisationId;
			}
			if (attributes.memberOrganisation !== undefined) {
				if (attributes.memberOrganisation === null) {
					this.memberOrganisation = attributes.memberOrganisation;
				} else {
					if (attributes.memberOrganisation instanceof Models.MemberOrganisationEntity) {
						this.memberOrganisation = attributes.memberOrganisation;
						this.memberOrganisationId = attributes.memberOrganisation.id;
					} else {
						this.memberOrganisation = new Models.MemberOrganisationEntity(attributes.memberOrganisation);
						this.memberOrganisationId = this.memberOrganisation.id;
					}
				}
			}
			if (attributes.roleId !== undefined) {
				this.roleId = attributes.roleId;
			}
			if (attributes.role !== undefined) {
				if (attributes.role === null) {
					this.role = attributes.role;
				} else {
					if (attributes.role instanceof Models.RoleEntity) {
						this.role = attributes.role;
						this.roleId = attributes.role.id;
					} else {
						this.role = new Models.RoleEntity(attributes.role);
						this.roleId = this.role.id;
					}
				}
			}
			if (attributes.reminderLogss !== undefined && Array.isArray(attributes.reminderLogss)) {
				for (const model of attributes.reminderLogss) {
					if (model instanceof Models.ReminderLogEntity) {
						this.reminderLogss.push(model);
					} else {
						this.reminderLogss.push(new Models.ReminderLogEntity(model));
					}
				}
			}
			if (attributes.reviewedFormRequestss !== undefined && Array.isArray(attributes.reviewedFormRequestss)) {
				for (const model of attributes.reviewedFormRequestss) {
					if (model instanceof Models.FormReviewRequestEntity) {
						this.reviewedFormRequestss.push(model);
					} else {
						this.reviewedFormRequestss.push(new Models.FormReviewRequestEntity(model));
					}
				}
			}
			if (attributes.loggedEvents !== undefined && Array.isArray(attributes.loggedEvents)) {
				for (const model of attributes.loggedEvents) {
					if (model instanceof Models.MehubAccountTimelineEventsEntity) {
						this.loggedEvents.push(model);
					} else {
						this.loggedEvents.push(new Models.MehubAccountTimelineEventsEntity(model));
					}
				}
			}
			if (attributes.isPrimaryContact !== undefined) {
				if (attributes.isPrimaryContact === null) {
					this.isPrimaryContact = attributes.isPrimaryContact;
				} else {
					if (attributes.isPrimaryContact instanceof Models.MemberOrganisationEntity) {
						this.isPrimaryContact = attributes.isPrimaryContact;
					} else {
						this.isPrimaryContact = new Models.MemberOrganisationEntity(attributes.isPrimaryContact);
					}
				}
			}
			if (attributes.assetss !== undefined && Array.isArray(attributes.assetss)) {
				for (const model of attributes.assetss) {
					if (model instanceof Models.AssetsMehubAccounts) {
						this.assetss.push(model);
					} else {
						this.assetss.push(new Models.AssetsMehubAccounts(model));
					}
				}
			}
			if (attributes.documentss !== undefined && Array.isArray(attributes.documentss)) {
				for (const model of attributes.documentss) {
					if (model instanceof Models.DocumentsMehubAccounts) {
						this.documentss.push(model);
					} else {
						this.documentss.push(new Models.DocumentsMehubAccounts(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] on begin
	public defaultExpands = `
		assetss {
			id
			assets {
				id
				description
			}
		}
		documentss {
			id
			documents {
				id
			}
		}
		formSubmissionss {
			id
		}
		licencess {
			id
			licenceName
		}
		memberOrganisation {
			id
			name
		}
		role {
			id
			name
		}
		reminderLogss {
			id
			reminderType
		}
		reviewedFormRequestss {
			id
		}
		loggedEvents {
			groupId
		}
		isPrimaryContact {
			id
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath = {
			assetss: {},
			documentss: {},
			formSubmissionss: {},
			licencess: {},
			reminderLogss: {},
			reviewedFormRequestss: {},
			loggedEvents: {},
			isPrimaryContact: {},
		};

		if (formMode === 'create') {
			relationPath['password'] = {};

			if (this.password !== this._confirmPassword) {
				throw Error("Password fields do not match");
			}
		}
		return this.save(
			relationPath,
			{
				graphQlInputType: formMode === 'create'
					? `[${this.getModelName()}CreateInput]`
					: `[${this.getModelName()}Input]`,
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'formSubmissionss',
							'licencess',
							'reminderLogss',
							'reviewedFormRequestss',
							'loggedEvents',
							'isPrimaryContact',
							'assetss',
							'documentss',
						]
					},
				],
			}
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] on begin
		let fullName = '';
		if(this.firstname) {
			fullName = this.firstname;
		}
		if(this.surname && this.surname.length) {
			if(fullName.length) {
				fullName += ' ';
			}
			fullName += this.surname;
		}
		if(!fullName.length) {
			fullName = this.email;
		}
		return fullName;
		// % protected region % [Customise the display name for this entity] end
	}

	/**
	 * Gets the timeline event entity type for this form.
	 */
	public getTimelineEventEntity = () => {
		// % protected region % [Modify the getTimelineEventEntity here] off begin
		return Models.MehubAccountTimelineEventsEntity;
		// % protected region % [Modify the getTimelineEventEntity here] end
	}


	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [ createdAttr, modifiedAttr ] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(MehubAccountEntity.prototype, 'created');
CRUD(modifiedAttr)(MehubAccountEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
