import * as React from "react";
import { computed, observable, action, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router';
import axios from "axios";
import * as uuid from 'uuid';
import moment from 'moment';
import BreadcrumbNavigation from "../CustomComponents/BreadcrumbNavigation";

import { SERVER_URL } from "Constants";
import toastAlert from "Util/ToastifyUtils";

import { store } from "Models/Store";
import * as Enums from "Models/Enums";

import SecuredPage from 'Views/Components/Security/SecuredPage';
import Navigation, { Orientation } from 'Views/Components/Navigation/Navigation';
import If from "Views/Components/If/If";
import { Button, Display, Sizes, Colors } from 'Views/Components/Button/Button';
import Modal from "Views/Components/Modal/Modal";
import { getFrontendNavLinks } from 'Views/FrontendNavLinks';

import aroflo_logo from 'images/aroflo_logo.png';

export interface IIntegrationsPageRouteProps extends RouteComponentProps { }

@observer
class IntegrationsPage extends React.Component<IIntegrationsPageRouteProps> {
	@observable
	private loadingApiKey = false;

	@observable
	private modalVisiblity = false;

	@observable
	private warningModalVisibility = false;

	@observable
	private keyIdToRevoke = "";

	@observable
	private generatedKey = "";

	@observable
	private existingApiKeys: {
		id: string;
		createdAt: Date;
	}[] = [];

	@observable
	private apiKeyCopied = false;

	public componentDidMount() {
		this.checkForExistingApiKeys();
	}
	
	@action
	private setModalVisibility(isVisible: boolean) {
		this.modalVisiblity = isVisible;
		if (isVisible === false) {
			this.generatedKey = "";
			this.apiKeyCopied = false;
		}
	}

	@action
	private setRevokeModalVisibility(isVisible: boolean, id: string = "") {
		this.warningModalVisibility = isVisible;

		this.keyIdToRevoke = isVisible
			? id
			: "";
	}

	@action
	private setLoading(loading: boolean) {
		this.loadingApiKey = loading;
	}

	private copyApiKey = async () => {
		await navigator.clipboard.writeText(this.generatedKey);
		runInAction(() => this.apiKeyCopied = true);
	}

	@computed
	private get isManager() {
		switch (store.roleName) {
			case Enums.mehubUserRoleOptions.MANAGER:
			case Enums.mehubUserRoleOptions.SAFETY_MANAGER:
				return true;
			default:
				return false;
		}
	}

	private checkForExistingApiKeys = async () => {
		this.setLoading(true);
		try {
			const result = await axios.get(`${SERVER_URL}/api/account/has-keys`);

			if (Array.isArray(result.data) && result.data.length) {
				runInAction(() => {
					if (Array.isArray(result.data)) {
						this.existingApiKeys = result.data.map(x => ({
							id: x.id,
							createdAt: new Date(x.createdAt),
						}));
					}

				})
			}
		} catch (e) {
			toastAlert('Failed to check for existing API tokens', 'error');
			console.error(e);
		}
		this.setLoading(false);
	}

	private generateApiKey = async () => {
		this.setLoading(true);
		try {
			const result = await axios.post(`${SERVER_URL}/api/account/generate-key`, {
				name: uuid.v4(),
			});

			runInAction(() => {
				this.generatedKey = result.data.key;
				this.existingApiKeys.push({
					id: result.data.id,
					createdAt: new Date(result.data.createdAt),
				})
			})

			this.setModalVisibility(true);
			toastAlert('API Token generated successfully', 'success');
		} catch (e) {
			toastAlert('Failed to generate an API Token', 'error');
			console.error(e);
		}
		this.setLoading(false);
	};

	private revokeApiKey = async (id: string) => {
		this.setLoading(true);
		try {
			await axios.post(`${SERVER_URL}/api/account/revoke-key`, { id });
			
			runInAction(() => {
				this.existingApiKeys = this.existingApiKeys.filter(x => x.id !== id);
			})
			toastAlert('API Token revoked successfully', 'success');
		} catch (e) {
			toastAlert('Failed to revoke a API Token', 'error');
			console.error(e);
		}
		this.setLoading(false);
	};

	private renderSuccessModal() {
		return (
			<Modal
				className='api-key-success-modal'
				label=""
				isOpen={this.modalVisiblity}
				onRequestClose={() => this.setModalVisibility(false)}
			>
				<h4>Your API Token Was Successfully Created</h4>
				<p>
					Make sure to copy your new API token. You won't be able to see this token again.
				</p>
				<div key="actions" className="modal__actions">
					<Button display={Display.Outline}
						colors={Colors.FormSecondary}
						sizes={Sizes.Medium}
						key="cancel"
						onClick={() => this.setModalVisibility(false)}>
						Close
					</Button>
					<Button display={Display.Solid}
						colors={Colors.FormPrimary}
						sizes={Sizes.Medium}
						key="submit"
						onClick={this.copyApiKey}>
						Copy API Key
					</Button>
				</div>
				{this.apiKeyCopied ? (<div className="copy-alert">API Token copied to clipboard</div>) : null}
			</Modal>
		)
	}

	private revokeWarningModal() {
		return (
			<Modal
				className='api-key-warning-modal'
				label=""
				isOpen={this.warningModalVisibility}
				onRequestClose={() => this.setRevokeModalVisibility(false)}
			>
				<h4>Revoke Your API Token?</h4>
				<p>
					Are you sure you want to permanently revoke your API token? Revoking an API token is not reversible.
				</p>
				<div key="actions" className="modal__actions">
					<Button display={Display.Outline}
						colors={Colors.FormSecondary}
						sizes={Sizes.Medium}
						key="cancel"
						onClick={() => this.setRevokeModalVisibility(false)}>
						Close
					</Button>
					<Button display={Display.Solid}
						colors={Colors.Error}
						sizes={Sizes.Medium}
						key="submit"
						onClick={() => {
							this.revokeApiKey(this.keyIdToRevoke);
							this.setRevokeModalVisibility(false);
						}}>
						Revoke
					</Button>
				</div>
				{this.apiKeyCopied ? (<div className="copy-alert">API Token copied to clipboard</div>) : null}
			</Modal>
		)
	}

	private generateKeyInterface = () => (
		<div className="generate-key-container">
			<div>
				<h4>Application</h4>
				<img src={aroflo_logo} alt='' className='aroflow-logo' />
				<Button
					key="generate-btn"
					display={Display.Solid}
					onClick={this.generateApiKey}
					disabled={this.loadingApiKey}
				>
					Create API Token
				</Button>
			</div>
			<div>
				<p>
					Trade business workflows. Sorted. Your AroFlo integration will allow you to create forms
					on the road etc.
				</p>
				{/* <p>
					Learn more about AroFlow <Link onClick={() => alert('bing')} to={ '#' }>here</Link>
				</p> */}
			</div>
		</div>
	);

	private existingKeyInterface = () => (
		<>
			{this.existingApiKeys.map(key => (
				<div className="existing-key-container" key={key.id}>
					<div>
						<h4>Application</h4>
						<img src={aroflo_logo} alt='' className='aroflow-logo' />
					</div>
					<div>
						<h4>Creation Date</h4>
						<p>
							{moment.utc(key?.createdAt).local().format('DD/MM/YYYY HH:mm')}
						</p>
					</div>
					<div>
						<h4>API Access</h4>
						<Button
							display={Display.Solid}
							colors={Colors.Error}
							sizes={Sizes.Medium}
							key="revoke-btn"
							className="revoke-button"
							onClick={() => this.setRevokeModalVisibility(true, key.id)}
							disabled={this.loadingApiKey}
						>
							Revoke API Token
						</Button>
					</div>
				</div>
			))}
			{/* <p>
				<Link onClick={() => alert('bing')} to={ '#' }>Learn more about your AroFlo API Integration {'>'}</Link>
			</p> */}
		</>
	);

	render() {
		const header = (
			<section className="page-header-new">
				<div className="breadcrumb-button">
					<BreadcrumbNavigation />
				</div>
				<div className="page-controls">
					<div className="page-controls__title">
						<h2>Integrations</h2>
					</div>
				</div>
			</section>
		);

		return (
			<SecuredPage groups={['MehubAccount']}>
				<Navigation
					linkGroups={getFrontendNavLinks(this.props)}
					orientation={Orientation.HORIZONTAL}
					match={this.props.match}
					location={this.props.location}
					history={this.props.history}
					staticContext={this.props.staticContext}
					className={'hub-navigation'}
				/>
				<div className="body-content master-template-page">
					{header}
					{this.renderSuccessModal()}
					{this.revokeWarningModal()}
					<If condition={this.isManager}>
						<div className="integrations-content">
							<h4>API Tokens</h4>
							<p>
								Create your own API token in order to connect to third party applications securely.
								Treat this API key as securely as you would any other password.
							</p>
							{
								this.existingApiKeys.length === 0
									? this.generateKeyInterface()
									: this.existingKeyInterface()
							}
						</div>
					</If>
				</div>
			</SecuredPage>
		);
	}
}

export default IntegrationsPage;