/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import { Model, IModelAttributes, attribute, entity } from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from "Util/AttributeUtils";
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchManyToManyFunc,
	makeJoinEqualsFunc,
	makeFetchOneToManyFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import { VisitorsRoleEntity } from 'Models/Security/Acl/VisitorsRoleEntity';
import { MehubAccountRoleEntity } from 'Models/Security/Acl/MehubAccountRoleEntity';
import { MehubAdminRoleEntity } from 'Models/Security/Acl/MehubAdminRoleEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import {SuperAdministratorScheme} from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IRoleEntityAttributes extends IModelAttributes {
	name: string;

	mehubAccountss: Array<Models.MehubAccountEntity | Models.IMehubAccountEntityAttributes>;
	roleSafetyLinkPermissionss: Array<Models.RoleSafetyLinkPermissionEntity | Models.IRoleSafetyLinkPermissionEntityAttributes>;
	searchess: Array<Models.SearchesEntity | Models.ISearchesEntityAttributes>;
	meaDocumentAccesss: Array<Models.RolesMeaDocumentAccess | Models.IRolesMeaDocumentAccessAttributes>;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('RoleEntity', 'Role')
// % protected region % [Customise your entity metadata here] end
export default class RoleEntity extends Model implements IRoleEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsRoleEntity(),
		new MehubAccountRoleEntity(),
		new MehubAdminRoleEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Name'] off begin
	/**
	 * name of the role in the memnet system, for instance Essential - Manager
	 */
	@Validators.Unique()
	@observable
	@attribute()
	@CRUD({
		name: 'Name',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public name: string;
	// % protected region % [Modify props to the crud options here for attribute 'Name'] end

	/**
	 * User Roles
	 */
	@observable
	@attribute({isReference: true, manyReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'MeHub Accounts'] on begin
		name: "MeHub Accounts",
		displayType: 'reference-multicombobox',
		order: 30,
		referenceTypeFunc: () => Models.MehubAccountEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'mehubAccountss',
			oppositeEntity: () => Models.MehubAccountEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'MeHub Accounts'] end
	})
	public mehubAccountss: Models.MehubAccountEntity[] = [];

	@observable
	@attribute({isReference: true, manyReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Role Safety Link Permissions'] on begin
		name: "Role Safety Link Permissions",
		displayType: 'reference-multicombobox',
		order: 20,
		referenceTypeFunc: () => Models.RoleSafetyLinkPermissionEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'roleSafetyLinkPermissionss',
			oppositeEntity: () => Models.RoleSafetyLinkPermissionEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Role Safety Link Permissions'] end
	})
	public roleSafetyLinkPermissionss: Models.RoleSafetyLinkPermissionEntity[] = [];

	@observable
	@attribute({isReference: true, manyReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Searches'] off begin
		name: "Searches",
		displayType: 'reference-multicombobox',
		order: 40,
		referenceTypeFunc: () => Models.SearchesEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'searchess',
			oppositeEntity: () => Models.SearchesEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Searches'] end
	})
	public searchess: Models.SearchesEntity[] = [];

	@observable
	@attribute({isReference: true, manyReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'MEA Document Access'] off begin
		name: 'MEA Document Access',
		displayType: 'reference-multicombobox',
		order: 50,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.RolesMeaDocumentAccess,
		optionEqualFunc: makeJoinEqualsFunc('meaDocumentAccessId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'roleEntity',
			oppositeEntityName: 'documentTypesEntity',
			relationName: 'roles',
			relationOppositeName: 'meaDocumentAccess',
			entity: () => Models.RoleEntity,
			joinEntity: () => Models.RolesMeaDocumentAccess,
			oppositeEntity: () => Models.DocumentTypesEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'MEA Document Access'] end
	})
	public meaDocumentAccesss: Models.RolesMeaDocumentAccess[] = [];

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IRoleEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IRoleEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.name !== undefined) {
				this.name = attributes.name;
			}
			if (attributes.mehubAccountss !== undefined && Array.isArray(attributes.mehubAccountss)) {
				for (const model of attributes.mehubAccountss) {
					if (model instanceof Models.MehubAccountEntity) {
						this.mehubAccountss.push(model);
					} else {
						this.mehubAccountss.push(new Models.MehubAccountEntity(model));
					}
				}
			}
			if (attributes.roleSafetyLinkPermissionss !== undefined && Array.isArray(attributes.roleSafetyLinkPermissionss)) {
				for (const model of attributes.roleSafetyLinkPermissionss) {
					if (model instanceof Models.RoleSafetyLinkPermissionEntity) {
						this.roleSafetyLinkPermissionss.push(model);
					} else {
						this.roleSafetyLinkPermissionss.push(new Models.RoleSafetyLinkPermissionEntity(model));
					}
				}
			}
			if (attributes.searchess !== undefined && Array.isArray(attributes.searchess)) {
				for (const model of attributes.searchess) {
					if (model instanceof Models.SearchesEntity) {
						this.searchess.push(model);
					} else {
						this.searchess.push(new Models.SearchesEntity(model));
					}
				}
			}
			if (attributes.meaDocumentAccesss !== undefined && Array.isArray(attributes.meaDocumentAccesss)) {
				for (const model of attributes.meaDocumentAccesss) {
					if (model instanceof Models.RolesMeaDocumentAccess) {
						this.meaDocumentAccesss.push(model);
					} else {
						this.meaDocumentAccesss.push(new Models.RolesMeaDocumentAccess(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] on begin
	public defaultExpands = `
		meaDocumentAccesss {
			id
			meaDocumentAccess {
				id
			}
		}
		mehubAccountss {
			id
		}
		roleSafetyLinkPermissionss {
			id
		}
		searchess {
			id
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath = {
			meaDocumentAccesss: {},
			mehubAccountss: {},
			roleSafetyLinkPermissionss: {},
			searchess: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'mehubAccountss',
							'roleSafetyLinkPermissionss',
							'searchess',
							'meaDocumentAccesss',
						]
					},
				],
			}
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] on begin
		return this.name;
		// % protected region % [Customise the display name for this entity] end
	}


	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [ createdAttr, modifiedAttr ] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(RoleEntity.prototype, 'created');
CRUD(modifiedAttr)(RoleEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
