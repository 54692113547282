/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import { FormsEntity } from 'Models/Entities';
import SecuredPage from 'Views/Components/Security/SecuredPage';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router';
import { getFrontendNavLinks } from 'Views/FrontendNavLinks';
import Navigation, { Orientation } from 'Views/Components/Navigation/Navigation';
import { FormSubmissionTile } from 'Forms/FormSubmissionTile';

// % protected region % [Add any extra imports here] on begin
import {DynamicFormSubmissionTile} from "../../Forms/DynamicFormSubmissionTile";

export interface ISafetyFormPageRoute {
	id: string;
}
// % protected region % [Add any extra imports here] end

export interface SafetyformPageProps extends RouteComponentProps {
	// % protected region % [Add any extra props here] off begin
	// % protected region % [Add any extra props here] end
}

@observer
// % protected region % [Add any customisations to default class definition here] on begin
class SafetyformPage extends React.Component<RouteComponentProps<ISafetyFormPageRoute>, SafetyformPageProps> {
// % protected region % [Add any customisations to default class definition here] end

	// % protected region % [Add class properties here] off begin
	// % protected region % [Add class properties here] end

	render() {
		// % protected region % [Add logic before rendering contents here] off begin
		// % protected region % [Add logic before rendering contents here] end

		let contents = (
			<SecuredPage groups={['Super Administrators', 'MehubAccount', 'MehubAdmin']}>
				{
				// % protected region % [Alter navigation here] off begin
				}
				<Navigation
					linkGroups={getFrontendNavLinks(this.props)}
					orientation={Orientation.VERTICAL}
					match={this.props.match}
					location={this.props.location}
					history={this.props.history}
					staticContext={this.props.staticContext}
				/>
				{
				// % protected region % [Alter navigation here] end
				}
				<div className="body-content">
					<FormSubmissionTile
						modelType={FormsEntity}
						tileId="d65b54eb-c57e-48d4-8bd3-35fd6ff5ab09"
						// % protected region % [Add props to form component d65b54eb-c57e-48d4-8bd3-35fd6ff5ab09 here] off begin
						// % protected region % [Add props to form component d65b54eb-c57e-48d4-8bd3-35fd6ff5ab09 here] end
					/>
				</div>
			</SecuredPage>
		);

		// % protected region % [Override contents here] on begin
		contents = (
			<SecuredPage groups={['Super Administrators', 'Visitors', 'MehubAccount', 'MehubAdmin']}>
				<Navigation
					linkGroups={getFrontendNavLinks(this.props)}
					orientation={Orientation.HORIZONTAL}
					match={this.props.match}
					location={this.props.location}
					history={this.props.history}
					staticContext={this.props.staticContext}
					className={'hub-navigation'}
				/>
				{/* inline style prevents double vertical scrollbar on long slides */}
				<div className="body-content" style={{overflowY: 'hidden'}}>
					<DynamicFormSubmissionTile
						modelType={FormsEntity}
						formId={this.props.match.params.id}
					/>
				</div>
			</SecuredPage>
		);
		// % protected region % [Override contents here] end

		return contents;
	}
}

// % protected region % [Override export here] off begin
export default SafetyformPage;
// % protected region % [Override export here] end
