/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { Model, IModelAttributes, attribute, entity } from 'Models/Model';
import * as Models from 'Models/Entities';
import { IAcl } from '../Security/IAcl';
import { observable } from 'mobx';
import { VisitorsMehubAccountsReferenceManyToMany } from '../Security/Acl/VisitorsMehubAccountsReferenceManyToMany';
import { MehubAccountMehubAccountsReferenceManyToMany } from '../Security/Acl/MehubAccountMehubAccountsReferenceManyToMany';
import { MehubAdminMehubAccountsReferenceManyToMany } from '../Security/Acl/MehubAdminMehubAccountsReferenceManyToMany';

// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IDocumentsMehubAccountsAttributes extends IModelAttributes {
	documentsId: string;
	mehubAccountsId: string;

	documents: Models.DocumentEntity | Models.IDocumentEntityAttributes;
	mehubAccounts: Models.MehubAccountEntity | Models.IMehubAccountEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

@entity('DocumentsMehubAccounts')
export default class DocumentsMehubAccounts extends Model implements IDocumentsMehubAccountsAttributes {
	public static acls: IAcl[] = [
		new VisitorsMehubAccountsReferenceManyToMany(),
		new MehubAccountMehubAccountsReferenceManyToMany(),
		new MehubAdminMehubAccountsReferenceManyToMany(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	@observable
	@attribute()
	public documentsId: string;

	@observable
	@attribute()
	public mehubAccountsId: string;

	@observable
	@attribute({isReference: true})
	public documents: Models.DocumentEntity;

	@observable
	@attribute({isReference: true})
	public mehubAccounts: Models.MehubAccountEntity;
	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	constructor(attributes?: Partial<IDocumentsMehubAccountsAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		if (attributes) {
			if (attributes.documentsId) {
				this.documentsId = attributes.documentsId;
			}
			if (attributes.mehubAccountsId) {
				this.mehubAccountsId = attributes.mehubAccountsId;
			}

			if (attributes.documents) {
				if (attributes.documents instanceof Models.DocumentEntity) {
					this.documents = attributes.documents;
					this.documentsId = attributes.documents.id;
				} else {
					this.documents = new Models.DocumentEntity(attributes.documents);
					this.documentsId = this.documents.id;
				}
			} else if (attributes.documentsId !== undefined) {
				this.documentsId = attributes.documentsId;
			}

			if (attributes.mehubAccounts) {
				if (attributes.mehubAccounts instanceof Models.MehubAccountEntity) {
					this.mehubAccounts = attributes.mehubAccounts;
					this.mehubAccountsId = attributes.mehubAccounts.id;
				} else {
					this.mehubAccounts = new Models.MehubAccountEntity(attributes.mehubAccounts);
					this.mehubAccountsId = this.mehubAccounts.id;
				}
			} else if (attributes.mehubAccountsId !== undefined) {
				this.mehubAccountsId = attributes.mehubAccountsId;
			}
		}

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}