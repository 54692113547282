
import * as React from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router';
import { store } from 'Models/Store';
import { observable, action } from 'mobx';
import { SafetyLinkEntity } from 'Models/Entities';
import axios from 'axios';
import { SERVER_URL } from 'Constants';
import If from 'Views/Components/If/If';
import GlobalSearch from 'Views/CustomComponents/GlobalSearch';
import BreadcrumbNavigation from "../CustomComponents/BreadcrumbNavigation";


@observer
export default class LibraryTile extends React.Component<RouteComponentProps> {

	@observable
	private myLibrarylinkEntities: SafetyLinkEntity[] = [];

	@observable
	private meaMasterlinkEntities: SafetyLinkEntity[] = [];

	componentDidMount() {
		axios.get(`${SERVER_URL}/api/entity/SafetyLinkEntity/documents_list`)
			.then(({ data }) => this.onSuccess(data))
	}

	@action
	private onSuccess = (data: SafetyLinkEntity[][]) => {
		this.myLibrarylinkEntities = data[0].sort((a,b) => a.order - b.order);
		this.meaMasterlinkEntities = data[1].sort((a,b) => a.order - b.order);
	}

	public render() {
		let createNewDocumentLink = this.myLibrarylinkEntities.find(l => l.name === "New Document")?.create;
		let contents = null;
		contents = (
			<>
				<section className="page-header-new">
					<div className="breadcrumb-button">
						<BreadcrumbNavigation />
					</div>
					<div className="page-controls">
						<div className="page-controls__title">
							<h2>My Library and Documents</h2>
						</div>
						<div className="page-controls__search">
							<div className="input-group input-group-block" aria-label="Search-Field">
								<GlobalSearch precedentType={'LIBRARY'} />
							</div>
						</div>
					</div>
				</section>

				<section className="library-container">
					<div className="my-documents">
						<div className="my-documents__intro">
							<p>Your documents are shown here. You can view and create a document within a specific
							category below or you can create a brand new document record. You can also create a
                                document using the MEA master library.</p>
						</div>
						<div className="my-documents__controls">
							<h4>My Documents</h4>
							<If condition={!!createNewDocumentLink}>
								<button className="btn btn--outline" onClick={() => window.open(createNewDocumentLink)}>Create new document</button>
							</If>
						</div>
						{this.myLibrarylinkEntities.map(link =>
							<div className="my-documents__item" key={link.name}>
								<p>{link.name}</p>
								<div className="form-category__commands">
									{link.view ? <a className="view-button icon-left icon-look" href={link.view} target="_blank" rel="noreferrer">View</a> : null}
								</div>
							</div>
						)}

					</div>

					<If condition={this.meaMasterlinkEntities.length > 0}>
						<div className="mea-library">
							<h4>MEA Master Library</h4>
							<p>These documents are created and maintained by MEA. Use them as part of your workflow or as a starting point to creating your own template.</p>

							{this.meaMasterlinkEntities.map(link =>
								<div className="my-documents__item" key={link.name}>
									<p>{link.name}</p>
									<div className="form-category__commands">
										{link.view ? <a className="view-button icon-left icon-look" href={link.view} target="_blank" rel="noreferrer">View</a> : null}
									</div>
								</div>
							)}
						</div>
					</If>

				</section>
			</>
		);

		return contents;
	}

}

