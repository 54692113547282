import { Condition } from '../Schema/Question';

function CompareMultiComboboxOptions (condition: Condition, conditionalValue: any): boolean {
    
    if (conditionalValue) {
        const value: string[] = conditionalValue;
        switch (condition.condition) {
            case 'equal':
                return value.length != 0 && value.every(x => x == condition.value);
            case 'notEqual':
                return value.length != 0 && value.every(x => x != condition.value);
            case 'contains':
                return value.includes(condition.value);
            default:
                return false;
        }
    }
    return false;
}

export default CompareMultiComboboxOptions;