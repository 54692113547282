import * as React from 'react';
import SecuredPage from 'Views/Components/Security/SecuredPage';
import EntityCRUD from 'Views/Components/CRUD/EntityCRUD';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router';
import { getFrontendNavLinks } from 'Views/FrontendNavLinks';
import Navigation, { Orientation } from 'Views/Components/Navigation/Navigation';
import {LicenceEntity} from "../../Models/Entities";
import NewLicence from "../CustomComponents/NewLicence";
import BreadcrumbNavigation from "../CustomComponents/BreadcrumbNavigation";
import {gql} from "@apollo/client";
import {action, autorun, observable} from "mobx";
import {IBreadCrumbsTag} from "../Components/Breadcrumbs/Breadcrumbs";
import {store} from "../../Models/Store";

export interface UploadLicencePageProps {
    id: string;
}


@observer
class UploadLicencePage extends React.Component<RouteComponentProps<UploadLicencePageProps>> {

    private getLicenceNameQuery = gql`query getLicenseName($id: [String]) {
             licenceEntity(where: {path: "id", comparison: equal, value: $id}) {
                    licenceName
                }
            }
            `;

    @observable
    private lastCrumb: IBreadCrumbsTag | undefined;

    componentDidMount() {
        autorun(() => {
            if (this.props.location.pathname.includes("edit")
                || this.props.location.pathname.includes("view")) {
                const listPathName = this.props.location.pathname.split('/');
                const id = listPathName[listPathName.length - 1];
                const mode = this.props.location.pathname.includes("edit") ? 'Edit' : 'View';
                store.apolloClient.query({
                    query: this.getLicenceNameQuery,
                    fetchPolicy: 'no-cache',
                    variables: {id: id}
                }).then(action(result => {
                    this.lastCrumb = {label: `${mode} ${result.data.licenceEntity.licenceName}`,
                        link: this.props.location.pathname};
                }))
            } else {
                action(() => this.lastCrumb = undefined);
            }
        })
    }
    
    render() {
        let header = (
            <section className="page-header-new">
                <div className="breadcrumb-button">
                    <BreadcrumbNavigation lastCrumb={this.lastCrumb} />
                </div>
                <div className="page-controls">
                    <div className="page-controls__title">
                        <h2>Upload Licences/Competencies</h2>
                    </div>
                </div>
            </section>
        );
        
        let contents = (
            <SecuredPage groups={['Super Administrators', 'MehubAccount', 'MehubAdmin']}>
                <Navigation
                    linkGroups={getFrontendNavLinks(this.props)}
                    orientation={Orientation.HORIZONTAL}
                    match={this.props.match}
                    location={this.props.location}
                    history={this.props.history}
                    staticContext={this.props.staticContext}
                    className={'hub-navigation'}
                />
                <div className="body-content">
                    {header}
                    <EntityCRUD 
                        modelType={LicenceEntity} 
                        {...this.props}
                        collectionComponent={() => undefined}
                        createComponent={() => <NewLicence id={this.props.match.params.id} />}
                    />
                </div>
            </SecuredPage>
        );

        return contents;
    }
}

export default UploadLicencePage;

