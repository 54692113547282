/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import * as Models from "Models/Entities";
import AccordionGroup from 'Views/Components/Accordion/Accordion';
import SecuredPage from "Views/Components/Security/SecuredPage";
import FormVersionCollection from "Forms/Admin/Version/FormVersionCollection";
import { RouteComponentProps } from "react-router";
import { PageWrapper } from "Views/Components/PageWrapper/PageWrapper";
import { SecurityService } from "Services/SecurityService";
// % protected region % [Add any extra imports here] on begin
import Tabs, {ITabConfig} from "../../../Components/Tabs/Tabs";
import {store} from "../../../../Models/Store";
import {formTypesOptions} from "../../../../Models/Enums";
import {Button} from "../../../Components/Button/Button";
// % protected region % [Add any extra imports here] end

// % protected region % [Add any extra types here] on begin
export interface IFormLandingPageRouteProps extends RouteComponentProps {
	tab?: string,
}
// % protected region % [Add any extra types here] end

// % protected region % [Override FormsLandingPage here] on begin
export default class FormsLandingPage extends React.Component<IFormLandingPageRouteProps> {
// % protected region % [Override FormsLandingPage here] end

	// % protected region % [Override getAccordionProps here] on begin
	private getAccordionProps = (showActive: boolean) => {
		const forms = [
			{displayName: formTypesOptions.RISK_ASSESSMENT, model: Models.FormsEntity},
			{displayName: formTypesOptions.INCIDENT, model: Models.FormsEntity},
			{displayName: formTypesOptions.INSPECTIONS, model: Models.FormsEntity},
			{displayName: formTypesOptions.PERMITS, model: Models.FormsEntity},
			{displayName: formTypesOptions.VERIFICATION, model: Models.FormsEntity},
			{displayName: formTypesOptions.PROJECT, model: Models.FormsEntity},
			{displayName: formTypesOptions.QUALITY, model: Models.FormsEntity},
			{displayName: formTypesOptions.WORKPLACE_RELATIONS,  model: Models.FormsEntity},
			{displayName: formTypesOptions.MEETING_AND_TRAINING,  model: Models.FormsEntity},
		]

		return forms
			.filter(f => SecurityService.canRead(f.model))
			.map(f => ({
				name: f.displayName,
				key: f.displayName,
				component: <FormVersionCollection
					formName='FormsEntity'
					formDisplayName={f.displayName}
					showCreateTile={false}
					showActive={showActive}
					formType={Object
						.keys(formTypesOptions)
						.find(key => formTypesOptions[key] === f.displayName) 
						?? "Undefined Form Type"}
				/>
			}));
	};
	// % protected region % [Override getAccordionProps here] end

	// % protected region % [Add any extra methods here] on begin
	private getTabs(): ITabConfig[] {
		return [
			{
				name: 'Active forms',
				key: 'active-forms',
				component: (<AccordionGroup accordions={this.getAccordionProps(true)} />),
			},
			{
				name: 'Inactive forms',
				key: 'inactive-forms',
				component: (<AccordionGroup accordions={this.getAccordionProps(false)} />),
			},
		];
	}

	// Helper method to change the url when a user clicks on any of the tabbed navigations
	private changeTabUrl = (index: number) =>
		store.routerHistory.push(`/admin/forms/${this.getTabs()[index].key}`);

	private renderTabs() {
		let currentTab = this.props.tab;

		let currentTabIndex = this.getTabs()
			.findIndex(({ key }) => key === currentTab);

		// If it is an unknown tab, take the user to the Active forms page
		if (currentTabIndex === -1) {
			currentTabIndex = 0;
		}

		return <Tabs className='project-tab-nav'
					 tabs={this.getTabs()}
					 onTabClicked={this.changeTabUrl}
					 defaultTab={0}
					 currentTab={currentTabIndex}>
		</Tabs>;
	}

	private static createNewFormLink() {
		store.routerHistory.push('/admin/formsentity/create?redirect=/admin/forms/build/formsentity/{id}')
	}
	// % protected region % [Add any extra methods here] end

	// % protected region % [Override render here] on begin
	public render(){
		return (
			<SecuredPage groups={["Super Administrators", "MehubAdmin"]}>
				<PageWrapper {...this.props}>
					<section className={'forms-behaviour forms-behaviour__landing'}>
						<h3>Forms Builder</h3>
							<div className="new-form-button">
								<Button className="btn btn--primary btn--solid" 
										icon={{ icon: 'plus', iconPos: 'icon-left' }}
										onClick={ FormsLandingPage.createNewFormLink }>New Form</Button>
							</div>
						{this.renderTabs()}
					</section>
				</PageWrapper>
			</SecuredPage>
		)
	}
	// % protected region % [Override render here] end
}