import React from 'react';
import { attribute, entity, IModelAttributes, ISaveOptions, jsonReplacerFn, Model } from "../Model";
import { FormVersion } from "../../Forms/FormVersion";
import { Form } from "../../Forms/Schema/Question";
import { action, observable } from "mobx";
import { CRUD } from "../CRUDOptions";
import { IAcl } from "../Security/IAcl";
import { SuperAdministratorScheme } from "../Security/Acl/SuperAdministratorScheme";
import { VisitorsFormsEntity } from "../Security/Acl/VisitorsFormsEntity";
import { MehubAccountFormsEntity } from "../Security/Acl/MehubAccountFormsEntity";
import { MehubAdminFormsEntity } from "../Security/Acl/MehubAdminFormsEntity";
import * as Models from "./index";
import moment from "moment";
import { FormsEntity } from "./index";
import * as Enums from "../Enums";
import { getCreatedModifiedCrudOptions } from "../../Util/EntityUtils";
import * as AttrUtils from "Util/AttributeUtils";

export interface FormsEntityFormVersionAttributes extends IModelAttributes, Omit<FormVersion, 'created' | 'modified' | 'id'> {
}

@entity('FormsEntityFormVersion', 'Forms')
export default class FormsEntityFormVersion extends Model implements FormsEntityFormVersionAttributes {
    public static acls: IAcl[] = [
        new SuperAdministratorScheme(),
        new VisitorsFormsEntity(),
        new MehubAccountFormsEntity(),
        new MehubAdminFormsEntity(),
    ];
    
    @observable
    @attribute()
    @CRUD({
        name: 'Form Version',
        headerColumn: true,
        displayType: 'textfield',
        order: 10,
    })
    version: number;

    @observable
    @attribute()
    @CRUD({
        name: 'FormID',
        headerColumn: false,
        displayType: 'hidden',
    })
    formId: string;

    @observable
    @attribute()
    @CRUD({
        name: 'Active',
        headerColumn: false,
        displayType: 'hidden',
    })
    active: boolean;
    
    @observable
    @CRUD({
        name: 'Form Type',
        headerColumn: true,
        displayType: "enum-combobox",
        order: 20,
        isJoinEntity: true,
        isReadonly: true,
        searchable: true,
        enumResolveFunction: Object.entries(Enums.formTypesOptions).map(([ key, value ]) => ({
                display: value,
                value: key
            })),
        referenceTypeFunc: () => Models.FormsEntity,
        displayFunction: (attribute, that) => {
            const formVersion = that as FormsEntityFormVersion;
            return Enums.formTypesOptions[formVersion.formType];
        },
        sortable: false,
        customAttributeName: "version",
        searchFunction: "equal",
        searchTransform: AttrUtils.standardiseInteger
    })
    formType: Enums.formTypes;

    @observable
    form: FormsEntity;

    @observable
    @attribute()
    @CRUD({
        name: 'Form Data',
        displayType: 'hidden',
        headerColumn: false,
    })
    formData: Form;
    
    @observable
    @CRUD({
        name: 'Form Data',
        displayType: 'form-data',
        headerColumn: false,
        order: 1000,
    })
    submissionData: {[key: string]: any} = {};

    @observable
    public formVersion: { formData: Form };
    
    @observable
    @CRUD({
        name: 'Status',
        displayType: "textfield",
        headerColumn: true,
        displayFunction: (attribute, that) => {
            const formVersion = that as FormsEntityFormVersion;
            const publishedVersion = formVersion.publishVersion;
            if (!publishedVersion) {
                return '';
            }

            if (publishedVersion?.id === formVersion.id) {
                return 'Published version';
            } else if (FormsEntityFormVersion.maxVersion === formVersion.version) {
                return "Draft";
            } else {
                return 'Active version';
            }

        }
    })
    public publishVersion?: FormVersion;
    
    public static maxVersion: number = 0;

	public get isDraft() {
        return FormsEntityFormVersion.maxVersion === this.version;
	}

    constructor(attributes?: Partial<FormsEntityFormVersionAttributes>) {
        super(attributes);
    }

    @action
    public assignAttributes(attributes?: Partial<FormsEntityFormVersionAttributes>) {
        super.assignAttributes(attributes);

        if (attributes) {
            if (attributes.formData !== undefined) {
                let formData = attributes.formData;
                if (typeof formData === 'string') {
                    formData = JSON.parse(formData);
                }
                this.formData = formData;
                
                this.formVersion = { formData: formData };
            }
            if (attributes.active !== undefined) {
                this.active = attributes.active;
            }
            if (attributes.version !== undefined) {
                this.version = attributes.version;
                if(this.version > FormsEntityFormVersion.maxVersion) {
                    FormsEntityFormVersion.maxVersion = this.version;
                }
            }
            if (attributes.form !== undefined) {
                this.form = attributes.form;
                this.formType = this.form.formType;
            }
            if (attributes.formId !== undefined){
                this.formId = attributes.formId;
            }
            if (attributes.form?.publishedVersion !== undefined) {
                this.publishVersion = attributes.form.publishedVersion;
            }
        }
    }

    public async save(relationPath: {} = {}, options: ISaveOptions = {}): Promise<void> {
        options = {
            jsonTransformFn: this.formTransform,
            ...options
        };
        relationPath= {formData: {}}
        return super.save(relationPath, options);
    }

    private formTransform: jsonReplacerFn = (input) => {
        const formData = input['formData'];
        if (formData) {
            input['formData'] = JSON.stringify(formData);
        }
        return input;
    }
    
    public defaultExpands = `
        id
        created
        modified
        formData
        version
        formId
        form {
            id
            name
            formType
            publishedVersionId
            publishedVersion {
                id
                version
            }
        }
        
    `;
    
    public listExpands = `
        id
        created
        modified
        formData
        version
        formId
        form {
            id
            name
            formType
            publishedVersionId
            publishedVersion {
                id
                version
            }
        }
    `;
}

const [ createdAttr ] = getCreatedModifiedCrudOptions();

let customCreatedAttr = {
    ...createdAttr,
    name: 'Date created',
    headerColumn: true,
    order: 50,
    displayFunction: (attr: moment.MomentInput) => moment.utc(attr).local().format('DD/MM/YYYY hh:mm A'),
}
CRUD(customCreatedAttr)(FormsEntityFormVersion.prototype, 'created');