import * as React from "react";
import {Component} from "react";
import {observer} from 'mobx-react';
import {QuestionType, ValidatorType} from 'Forms/Schema/Question';
import {IQuestionTile, QuestionTileOptionsProps} from '../QuestionTile';
import TileOptions from '../Options/TileOptions';
import CompareBoolean from 'Forms/Conditions/CompareBoolean';
import {ValidateBoolean} from 'Forms/Validators/ValidateBoolean';
import {QuestionComponent, QuestionComponentProps} from 'Forms/Questions/QuestionComponent';
import {DropdownProps, Table} from 'semantic-ui-react'
import classNames from "classnames";
import {action, observable, runInAction} from "mobx";
import If from "../../../Views/Components/If/If";
import {Combobox} from "../../../Views/Components/Combobox/Combobox";
import {Model} from "../../../Models/Model";
import {hasRequiredValidator} from "../../Validators/ValidationUtils";

enum Risk {
    Low = 'low',
    Moderate = 'moderate',
    High = 'high',
    Catastrophic = 'catastrophic'
}

enum Consequence {
    Insignificant = 'insignificant',
    Minor = 'minor',
    Moderate = 'moderate',
    Major = 'major',
    Catastrophic = 'catastrophic'
}

enum Likelihood {
    AlmostCertain = 'almost-certain',
    Likely = 'likely',
    Possible = 'possible',
    Unlikely = 'unlikely',
    Rare = 'rare'
}

interface IRiskOption extends DropdownProps {
    display: string,
    value: Consequence | Likelihood,
    riskMultiplier: number,
}

@observer
export class RiskMatrixQuestionTileOptions extends Component<QuestionTileOptionsProps> {

    public render() {
        const { question, schema } = this.props;
        return (
            <TileOptions question={question} schema={schema} hasShowConditions hasValidators />
        );
    }
}

export interface IRiskMatrixQuestionTileProps<T> extends QuestionComponentProps<T> {
    options: {
        values: {
            value: string
        }[]
    };
}

export function getConsequenceModelProperty (questionId: string) : string  {
    return `${questionId}-selectedConsequence`;
}

export function getLikelihoodModelProperty (questionId: string) : string  {
    return `${questionId}-selectedLikelihood`;
}

export function getRiskScoreModelProperty (questionId: string) : string  {
    return `${questionId}-riskScore`;
}

export function getCellIdString (columnId: string, rowId: string) : string {
    return `${columnId}-${rowId}`;
}

@observer
export class RiskMatrixQuestionTile<T extends Model> extends QuestionComponent<T, IRiskMatrixQuestionTileProps<T>> implements IQuestionTile {
    static displayName = 'RiskMatrix';

    static questionType: QuestionType = 'riskMatrix';
    static optionsMenu = RiskMatrixQuestionTileOptions;
    static conditionOptions = [
        { display: 'Equal', value: 'equal' },
        { display: 'Not equal', value: 'notEqual' },
    ];
    static validatorOptions: { display: string, value: ValidatorType }[] = [
        { display: 'Required', value: 'required' },
    ];
    static compareFunction = CompareBoolean;
    static validateFunction = ValidateBoolean;

    @observable
    private selectedCellId: string;
    
    @observable
    private selectedConsequence: Consequence | undefined = undefined;

    @observable
    private selectedLikelihood: Likelihood | undefined = undefined;
    
    private consequenceOptions: IRiskOption[] = [
        {
            display: 'Insignificant - First aid / limited impact / low financial loss',
            value: Consequence.Insignificant,
            riskMultiplier: 1,
        },
        {
            display: 'Minor - Medical treatment / reversible short/medium term local impact / medium financial loss',
            value: Consequence.Minor,
            riskMultiplier: 2,
        },
        {
            display: 'Moderate - Lost time injury / reversible medium term local impact / high financial loss',
            value: Consequence.Moderate,
            riskMultiplier: 3,
        },
        {
            display: 'Major - Permanent disability / medium to long term widespread impact / major financial loss',
            value: Consequence.Major,
            riskMultiplier: 4,
        },
        {
            display: 'Catastrophic - Fatality / permanent disability / long term widespread impact / huge financial loss',
            value: Consequence.Catastrophic,
            riskMultiplier: 5,
        },
    ];

    private likelihoodOptions: IRiskOption[] = [
        {
            display: 'Almost Certain - It is almost certain in most circumstances',
            value: Likelihood.AlmostCertain,
            riskMultiplier: 5,
        },
        {
            display: 'Likely - The risk is likely to occur in most circumstances',
            value: Likelihood.Likely,
            riskMultiplier: 4,
        },
        {
            display: 'Possible - There is uncertainty that the risk could occur',
            value: Likelihood.Possible,
            riskMultiplier: 3,
        },
        {
            display: 'Unlikely - The risk could occur at some time but there is confidence that it will not',
            value: Likelihood.Unlikely,
            riskMultiplier: 2,
        },
        {
            display: 'Rare - The impact / risk may occur only in exceptional circumstances',
            value: Likelihood.Rare,
            riskMultiplier: 1,
        },
    ];

    private legendRows = [
        {
            risk: Risk.Catastrophic,
            description: 'Work unable to proceed, seek other methods (significant)',
        },
        {
            risk: Risk.High,
            description: 'Permission from high-level management for work to proceed (significant)',
        },
        {
            risk: Risk.Moderate,
            description: 'Permission from worker in-charge for work to proceed (insignificant)',
        },
        {
            risk: Risk.Low,
            description: 'Work able to proceed (insignificant)',
        },
    ];

    @action
    private handleCellClick = (column: IRiskOption , row: IRiskOption) => {
        const { model, id } = this.props;

        this.selectedCellId = getCellIdString(column.value, row.value);
        model[getConsequenceModelProperty(id)] = column.value;
        model[getLikelihoodModelProperty(id)] = row.value;
        model[getRiskScoreModelProperty(id)] = this.calculateRisk(column.riskMultiplier, row.riskMultiplier);
    };
    
    @action
    private handleComboboxChange = () => {
        const { model, id } = this.props;
        
        const columnValue = model[getConsequenceModelProperty(id)];
        const rowValue = model[getLikelihoodModelProperty(id)];

        this.selectedCellId = getCellIdString(columnValue, rowValue);

        const column = this.consequenceOptions.find((o)=>o.value === columnValue);
        const row = this.likelihoodOptions.find((o)=>o.value === rowValue);
        
        if (column && row) {
            const columnRiskMultiplier = column.riskMultiplier;
            const rowRiskMultiplier = row.riskMultiplier;

            model[getRiskScoreModelProperty(id)] = this.calculateRisk(columnRiskMultiplier, rowRiskMultiplier);
        }
    };

    private calculateRisk = (consequence: number, likelihood: number) => {
        let riskScore = consequence * likelihood;

        if (riskScore <= 3) {
            return Risk.Low;
        } else if (riskScore >= 4 && riskScore <= 9) {
            return Risk.Moderate;
        } else if (riskScore >= 10 && riskScore <= 15) {
            return Risk.High;
        } else {
            return Risk.Catastrophic;
        }
    }

    private renderRiskTable = () => {
        const { isReadOnly, id, model } = this.props;
        
        const columnHeaders = this.consequenceOptions;
        const rowHeaders = this.likelihoodOptions;
        
        // load previous selection
        let selectedColumn = model[getConsequenceModelProperty(id)];
        let selectedRow = model[getLikelihoodModelProperty(id)];
        if(selectedRow && selectedColumn) {
            runInAction(() => {
                this.selectedCellId = getCellIdString(selectedColumn, selectedRow);
            });
        }

        return (
            <Table celled structured columns={6}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell rowSpan='2' textAlign='center'>Likelihood</Table.HeaderCell>
                        <Table.HeaderCell colSpan='6' textAlign='center'>Consequence</Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                        {columnHeaders.map((column, i) =>
                            <Table.HeaderCell className='sub-heading' key={i}>{column.display}</Table.HeaderCell>
                        )}
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {rowHeaders.map((row, i) => {
                        return (
                            <Table.Row key={i}>
                                <Table.Cell>{row.display}</Table.Cell>
                                {columnHeaders.map((column, j) => {
                                    return (
                                        <Table.Cell
                                            className={classNames('risk-options', this.calculateRisk(column.riskMultiplier, row.riskMultiplier), { 'disabled': isReadOnly })}
                                            key={j}
                                            id={getCellIdString(column.value, row.value)}
                                            onClick={()=>!isReadOnly && this.handleCellClick(column, row)}
                                        >
                                            <If condition={this.selectedCellId === getCellIdString(column.value, row.value)}>
                                                <span className="icon-check-circle icon-only"/>
                                            </If>
                                        </Table.Cell>
                                    );
                                })}
                            </Table.Row>
                        );
                    })}
                </Table.Body>
            </Table>
        )
    }

    private renderLegendTable = () => {
        return (
            <Table celled collapsing>
                <Table.Body>
                    {this.legendRows.map((row, i) => {
                        return (
                            <Table.Row key={i}>
                                <Table.Cell className={row.risk}>
                                    {row.risk.toUpperCase()}
                                </Table.Cell>
                                <Table.Cell>{row.description}</Table.Cell>
                            </Table.Row>
                        );
                    })}
                </Table.Body>
            </Table>
        )
    }

    private renderCalculationBanner = () => {
        const { model, id } = this.props;
        
        const risk: Risk = model[getRiskScoreModelProperty(id)];
        const legendRow = this.legendRows.find((l) => l.risk === risk);
        
        return (
            <If condition={model[getRiskScoreModelProperty(id)]}>
                <p>Risk Calculation</p>
                <div className={classNames('risk-banner', risk)}>
                    {`${legendRow?.risk.toUpperCase()} - ${legendRow?.description}`}
                </div>
            </If>
        )
    }

    public render() {
        const { title, model, id, isReadOnly, validators } = this.props;

        return (
            <div className="risk-matrix-question-wrapper">
                <p>{title}</p>
                <div className="risk-matrix-legend-layout">
                    <div className="risk-matrix-wrapper">
                        {this.renderRiskTable()}
                    </div>
                    <div className="legend-wrapper">
                        <p>Risk Legend</p>
                        {this.renderLegendTable()}
                    </div>
                </div>
                <Combobox
                    model={model}
                    modelProperty={getConsequenceModelProperty(id)}
                    label='Consequence'
                    options={this.consequenceOptions}
                    isDisabled={isReadOnly}
                    onAfterChange={()=>this.handleComboboxChange()}
                    isRequired={hasRequiredValidator(validators)}
                />
                <Combobox
                    model={model}
                    modelProperty={getLikelihoodModelProperty(id)}
                    label='Likelihood'
                    options={this.likelihoodOptions}
                    isDisabled={isReadOnly}
                    onAfterChange={()=>this.handleComboboxChange()}
                    isRequired={hasRequiredValidator(validators)}
                />
                {this.renderCalculationBanner()}
            </div>
        );
    }
}
