import React from "react";
import {Label} from "semantic-ui-react";
import {Button} from "../Components/Button/Button";
import {SERVER_URL} from "../../Constants";

interface PreviewDocButtonProps {
    documentID?: string;
    documentLink?: string;
}

export default class PreviewDocButton extends React.Component<PreviewDocButtonProps> {
    render() {
        const url = this.props.documentID
            ? `${SERVER_URL}/api/files/${this.props.documentID}`
            : this.props.documentLink;
        return url ?
            <Button
                className={"open-preview"}
                onClick={() => window.open(url)}>
                <Label
                    icon={'eye'}
                    content={"Open Document"}
                />
            </Button> 
            : 'No document or link was provided';
    }
}