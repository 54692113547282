/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
// % protected region % [Add any extra imports here] off begin
// % protected region % [Add any extra imports here] end

export type assetStatus =
	// % protected region % [Override assetStatus keys here] off begin
	'IN_SERVICE' |
		'OUT_OF_SERVICE';
	// % protected region % [Override assetStatus keys here] end

export const assetStatusOptions: { [key in assetStatus]: string } = {
	// % protected region % [Override assetStatus display fields here] off begin
	IN_SERVICE: 'In Service',
	OUT_OF_SERVICE: 'Out of Service',
	// % protected region % [Override assetStatus display fields here] end
};

export type assetTypes =
	// % protected region % [Override assetTypes keys here] off begin
	'PLANT' |
		'LADDER' |
		'HARNESS' |
		'INSTRUMENT' |
		'TOOL' |
		'SAFETY_EQUIPMENT' |
		'ELECTRICAL_EQUIPMENT' |
		'OTHER';
	// % protected region % [Override assetTypes keys here] end

export const assetTypesOptions: { [key in assetTypes]: string } = {
	// % protected region % [Override assetTypes display fields here] off begin
	PLANT: 'Plant',
	LADDER: 'Ladder',
	HARNESS: 'Harness',
	INSTRUMENT: 'Instrument',
	TOOL: 'Tool',
	SAFETY_EQUIPMENT: 'Safety Equipment',
	ELECTRICAL_EQUIPMENT: 'Electrical Equipment',
	OTHER: 'Other',
	// % protected region % [Override assetTypes display fields here] end
};

export type category =
	// % protected region % [Override category keys here] off begin
	'FEEDBACK' |
		'HELP';
	// % protected region % [Override category keys here] end

export const categoryOptions: { [key in category]: string } = {
	// % protected region % [Override category display fields here] off begin
	FEEDBACK: 'Feedback',
	HELP: 'Help',
	// % protected region % [Override category display fields here] end
};

export type documentStatus =
	// % protected region % [Override documentStatus keys here] off begin
	'ACTIVE' |
		'INACTIVE' |
		'PENDING';
	// % protected region % [Override documentStatus keys here] end

export const documentStatusOptions: { [key in documentStatus]: string } = {
	// % protected region % [Override documentStatus display fields here] off begin
	ACTIVE: 'Active',
	INACTIVE: 'Inactive',
	PENDING: 'Pending',
	// % protected region % [Override documentStatus display fields here] end
};

export type formTypes =
	// % protected region % [Override formTypes keys here] off begin
	'RISK_ASSESSMENT' |
		'INCIDENT' |
		'INSPECTIONS' |
		'PERMITS' |
		'VERIFICATION' |
		'PROJECT' |
		'QUALITY' |
		'WORKPLACE_RELATIONS' |
		'MEETING_AND_TRAINING';
	// % protected region % [Override formTypes keys here] end

export const formTypesOptions: { [key in formTypes]: string } = {
	// % protected region % [Override formTypes display fields here] on begin
	RISK_ASSESSMENT: 'Risk Assessment',
	INCIDENT: 'Incident',
	INSPECTIONS: 'Inspections',
	PERMITS: 'Permits',
	VERIFICATION: 'Verification',
	PROJECT: 'Project',
	QUALITY: 'Quality',
	WORKPLACE_RELATIONS: 'Workplace Relations',
	MEETING_AND_TRAINING: 'Meetings / Training',
	// % protected region % [Override formTypes display fields here] end
};

export type inspectionFrequency =
	// % protected region % [Override inspectionFrequency keys here] off begin
	'ONE_MONTH' |
		'THREE_MONTHS' |
		'SIX_MONTHS' |
		'NA';
	// % protected region % [Override inspectionFrequency keys here] end

export const inspectionFrequencyOptions: { [key in inspectionFrequency]: string } = {
	// % protected region % [Override inspectionFrequency display fields here] on begin
	ONE_MONTH: '1 month',
	THREE_MONTHS: '3 months',
	SIX_MONTHS: '6 months',
	NA: 'N/A',
	// % protected region % [Override inspectionFrequency display fields here] end
};

export type licenceStatus =
	// % protected region % [Override licenceStatus keys here] off begin
	'ACTIVE' |
		'PENDING' |
		'EXPIRED';
	// % protected region % [Override licenceStatus keys here] end

export const licenceStatusOptions: { [key in licenceStatus]: string } = {
	// % protected region % [Override licenceStatus display fields here] off begin
	ACTIVE: 'Active',
	PENDING: 'Pending',
	EXPIRED: 'Expired',
	// % protected region % [Override licenceStatus display fields here] end
};

export type licenceTypes =
	// % protected region % [Override licenceTypes keys here] off begin
	'ELECTRICAL' |
		'DRIVER' |
		'PERMIT' |
		'COMPETENCY' |
		'INDUCTION' |
		'OTHER';
	// % protected region % [Override licenceTypes keys here] end

export const licenceTypesOptions: { [key in licenceTypes]: string } = {
	// % protected region % [Override licenceTypes display fields here] off begin
	ELECTRICAL: 'Electrical',
	DRIVER: 'Driver',
	PERMIT: 'Permit',
	COMPETENCY: 'Competency',
	INDUCTION: 'Induction',
	OTHER: 'Other',
	// % protected region % [Override licenceTypes display fields here] end
};

export type reminderTypes =
	// % protected region % [Override reminderTypes keys here] off begin
	'LICENCE_DUE' |
		'ASSET_INSPECTION' |
		'ASSET_SERVICE' |
		'ASSET_REGISTRATION' |
		'DOCUMENT_ANNUAL_REVIEW' |
		'LICENCE_PENDING' |
		'DOCUMENT_PENDING';
	// % protected region % [Override reminderTypes keys here] end

export const reminderTypesOptions: { [key in reminderTypes]: string } = {
	// % protected region % [Override reminderTypes display fields here] off begin
	LICENCE_DUE: 'Licence Due',
	ASSET_INSPECTION: 'Asset Inspection',
	ASSET_SERVICE: 'Asset Service',
	ASSET_REGISTRATION: 'Asset Registration',
	DOCUMENT_ANNUAL_REVIEW: 'Document Annual Review',
	LICENCE_PENDING: 'Licence Pending',
	DOCUMENT_PENDING: 'Document Pending',
	// % protected region % [Override reminderTypes display fields here] end
};

export type searchTypes =
	// % protected region % [Override searchTypes keys here] off begin
	'UNSUCCESSFUL';
	// % protected region % [Override searchTypes keys here] end

export const searchTypesOptions: { [key in searchTypes]: string } = {
	// % protected region % [Override searchTypes display fields here] off begin
	UNSUCCESSFUL: 'Unsuccessful',
	// % protected region % [Override searchTypes display fields here] end
};

export type states =
	// % protected region % [Override states keys here] on begin
	'QLD' |
		'NSW' |
		'VIC' |
		'TAS' |
		'SA' |
		'WA' |
		'ACT' |
		'NT' |
		'UNKNOWN';
	// % protected region % [Override states keys here] end

export const statesOptions: { [key in states]: string } = {
	// % protected region % [Override states display fields here] off begin
	QLD: 'QLD',
	NSW: 'NSW',
	VIC: 'VIC',
	TAS: 'TAS',
	SA: 'SA',
	WA: 'WA',
	ACT: 'ACT',
	NT: 'NT',
	UNKNOWN: 'UNKNOWN',
	// % protected region % [Override states display fields here] end
};

export type triggerTypes =
	// % protected region % [Override triggerTypes keys here] off begin
	'REMINDER_ONE' |
		'REMINDER_TWO' |
		'DUE_DATE';
	// % protected region % [Override triggerTypes keys here] end

export const triggerTypesOptions: { [key in triggerTypes]: string } = {
	// % protected region % [Override triggerTypes display fields here] off begin
	REMINDER_ONE: 'Reminder One',
	REMINDER_TWO: 'Reminder Two',
	DUE_DATE: 'Due Date',
	// % protected region % [Override triggerTypes display fields here] end
};

// % protected region % [Add any extra enums here] on begin
// Manually maintained class to allow easy reference to Mehub user roles
export type mehubUserRole =
	'SAFETY_MANAGER' |
	'SAFETY_WORKER' |
	'MANAGER' |
	'WORKER' |
	'PRO_MANAGER' |
	'PRO_WORKER';

export const mehubUserRoleOptions: { [key in mehubUserRole]: string } = {
	SAFETY_MANAGER: 'SafetyManager',
	SAFETY_WORKER: 'SafetyWorker',
	MANAGER: 'Manager',
	WORKER: 'Worker',
	PRO_MANAGER: 'Pro-Manager',
	PRO_WORKER: 'Pro-Worker',
};

export type documentTypes =
	'Business' |
	'Workplace' |
	'Office' |
	'SWMS' |
	'SDS'|
	'Workplace_Relations';

export const documentTypeList: documentTypes[] = [
	"Business",
	"Workplace",
	"Office",
	"SWMS",
	"SDS",
	"Workplace_Relations"
];
// % protected region % [Add any extra enums here] end
