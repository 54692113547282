import * as React from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router';
import {SAFETY_TOOLTIPS, SERVER_URL} from 'Constants';
import { store } from 'Models/Store';
import Query_Button from '../../images/Query_Button.svg';
import GlobalSearch from 'Views/CustomComponents/GlobalSearch';
import {formTypes, formTypesOptions, mehubUserRoleOptions} from "../../Models/Enums";
import axios from "axios";
import {adminIsDraftModal, linkListModal} from "../Components/Modal/ModalUtils";
import {FormsEntity} from "../../Models/Entities";
import {Form} from "../../Forms/Schema/Question";
import {IFormProps} from "../../Forms/FormTile";
import MarketingTile from 'Views/CustomComponents/MarketingTile';
import { Link } from 'react-router-dom';
import BreadcrumbNavigation from "../CustomComponents/BreadcrumbNavigation";
import gql from "graphql-tag";

export interface IFormTileProps<T> extends IFormProps {
	schema: Form;
	model: T;
	onSubmit?: (model: T) => void;
	formTitle?: string;
	onCancel?: (() => void);
}

const WORKER_OR_MANAGER: string[] = [
	mehubUserRoleOptions.WORKER,
	mehubUserRoleOptions.MANAGER
];

const WORKER_OR_MANAGER_TILES: formTypes[] = [
	'RISK_ASSESSMENT',
	'INCIDENT',
	'VERIFICATION',
	'MEETING_AND_TRAINING'
];

export class extendedFormEntity extends FormsEntity {
	hasDraft: boolean = false;
}

@observer
export default class UpdatedSafetyTile extends React.Component<RouteComponentProps> {
	public render() {
		const formTypeKeys = Object.keys(formTypesOptions) as formTypes[];
		
		const isUserManagerOrWorker = WORKER_OR_MANAGER.indexOf(store.roleName ?? '') !== -1;
		
		const filterTiles: (x: formTypes) => boolean = x =>
			(isUserManagerOrWorker && WORKER_OR_MANAGER_TILES.indexOf(x) !== -1)
				|| (!isUserManagerOrWorker && x !== "MEETING_AND_TRAINING");
		
		let marketingTile = (
			isUserManagerOrWorker && <MarketingTile/>
		);
		
		let contents = (
			<section className="primary-safety-tiles updated-safety-tiles">
					<div className="breadcrumb-button">
						<BreadcrumbNavigation />
					</div>

					<div className="page-controls">
						<div className="page-controls__title">
							<h2>Safety</h2>
						</div>
						<div className="page-controls__search">
							<div className="input-group input-group-block" aria-label="Search-Field">
								<GlobalSearch precedentType={'SAFETY'} />
							</div>
						</div>
					</div>

					<div className="primary-safety-tiles__tiles" style={{gridTemplateColumns: `repeat(${this.getNumberOfColumns()}, auto)`}}>
						{marketingTile}
						{formTypeKeys.filter(filterTiles).map((formType) =>
							<div className="tile safety-tile" key={formType} onClick={() => this.openCreateFormSubmissionModal(formType)}>
								<div className="tile__tooltip" onClick={(e) => e.stopPropagation()}>
									<img src={Query_Button} />
									<span className="tooltip__content">
										{SAFETY_TOOLTIPS[formType]}
									</span>
								</div>

								<div className="safety-tile__title">
									<h4>{formTypesOptions[formType]}</h4>
								</div>

								<div className="safety-tile__commands">
									<a className="create-button icon-left icon-file-add"
									   onClick={(e) => {
										   e.stopPropagation();
										   this.openCreateFormSubmissionModal(formType);
									   }}
									>
										Create
									</a>
									<a className="divider" onClick={(e) => e.stopPropagation()}>|</a>
									<a className="view-button icon-left icon-look"
									   onClick={(e) => {
									   	   e.stopPropagation();
										   this.viewFormSubmissions(formType);
									   }}
									>
										View
									</a>
								</div>
							</div>
						)}
					</div>
				</section>
		);

		return contents;
	}

	private viewFormSubmissions = (formType: formTypes) => {
		store.routerHistory.push(`/user-submissions/${encodeURIComponent(formTypesOptions[formType])}`);
	}

	private openCreateFormSubmissionModal = (formType: formTypes) => {
		axios.get(`${SERVER_URL}/api/entity/FormsEntity/form_type/${formType}`)
			.then(async (resp) => {
				const { data: forms } = resp;

				const userDrafts = await this.allDrafts();
				
				const modalBody = forms.length ? 
					(<div className="create-safety-form-modal-body">
						{
							forms.map((form: extendedFormEntity) =>
								<div className="create-safety-form-modal-body__item" key={form.name}>
									<p>
										{form.name}
										{userDrafts.includes(form.id) ? <span className="draft-label"> DRAFT</span> :
											null}
									</p>
									<div className="safety-tile__link-buttons">
										<a className="create-button icon-left icon-file-add" 
										   onClick={() => this.isDraftModal(form)}>Create</a>
									</div>
								</div>
							)
						}
					</div>)
					: <p>No forms are currently available.</p>;
				
				linkListModal(formTypesOptions[formType], modalBody);
			})
	}

	private getNumberOfColumns = () => {
		let numTiles = Object.values(formTypesOptions).length;

		if (numTiles > 4) {
			return 4;
		}

		return numTiles;
	}

	//[MEASD-200/#198] Add a label to forms in the create list that have a draft
	private allDrafts = async (): Promise<string> => {
		const { data } = await store.apolloClient.query({
			query: gql`query formSubmissionsEntitys($userId: [String]) {
				formSubmissionsEntitys (
					where: [
						{path: "isCompleted", comparison: equal, value: "true", negate: true},
						{path: "mehubAccountId", comparison: equal, value: $userId}
					]
				) {
					formVersion{
						formId
					}
				}
			}
			`,
			fetchPolicy: 'network-only',
			variables: { userId: [store.userId] }
		});
		return data["formSubmissionsEntitys"].flatMap((f:any) => f.formVersion.formId);
	}
	
	private isDraftModal = (form: FormsEntity) => {
		axios.get(`${SERVER_URL}/api/entity/FormSubmissionsEntity/hasDraft/${form.id}`).then(({ data }) => {
			if (!data) {
				store.modal.hide();
				store.routerHistory.push(`/safety-form/${form.id}`);
			} else {
				store.modal.hide();
				adminIsDraftModal('A draft of this form already exists', 'You can either continue the existing draft, ' +
					'or discard it and start a new submission. How would you like to proceed', {
					confirmText: 'Continue editing',
					cancelText: 'Discard and start from scratch'
				}).catch(async () => {
					const discardFormId = form.id
					await axios.delete(`${SERVER_URL}/api/entity/FormSubmissionsEntity/deleteDraft/${discardFormId}`);
				}).finally(() => {
					store.routerHistory.push(`/safety-form/${form.id}`);
				});
			}
		});
	}
	
}