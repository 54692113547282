/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router';
import * as Models from 'Models/Entities';
import EntityCRUD from 'Views/Components/CRUD/EntityCRUD';
import { PageWrapper } from 'Views/Components/PageWrapper/PageWrapper';
import SecuredPage from 'Views/Components/Security/SecuredPage';
import { IEntityContextMenuActions } from 'Views/Components/EntityContextMenu/EntityContextMenu';
// % protected region % [Add any extra imports here] on begin
import {MemberOrganisationEntity} from "Models/Entities";
import { observable, runInAction } from 'mobx';
import Modal from '../../../Components/Modal/Modal';
import FormAssignmentModal from "../../../CustomComponents/FormAssignmentModal";
import Spinner from "../../../Components/Spinner/Spinner";
// % protected region % [Add any extra imports here] end

@observer
export default class MemberOrganisationEntityPage extends React.Component<RouteComponentProps> {
	// % protected region % [Add any extra attributes here] on begin
	@observable
	private modalState = {
		open: false,
	};

	@observable
	private selectedMemberIds: string[];

	private allPagesSelected: boolean | undefined;	

	private forms: Models.FormsEntity[] = [];
	
	// % protected region % [Add any extra attributes here] end

	private pageActions: IEntityContextMenuActions<Models.MemberOrganisationEntity> = [
		// % protected region % [Add any extra actions here] off begin
		// % protected region % [Add any extra actions here] end
	];

	public render() {
		let contents = null;

		// % protected region % [Override contents here] on begin
		runInAction(() => {
			Models.FormsEntity.fetchForms()
			.then(data => {
				this.forms = [];
				data.forEach(
					form => {this.forms.push(form); }
				)
			})
		});
		
		contents = (
			<PageWrapper {...this.props}>
				<Modal
					isOpen={this.modalState.open}
					label="User Selection Modal"
					onRequestClose={() => runInAction(() => this.modalState.open = false)}>
					<FormAssignmentModal 
						formsSelected={this.formsSelected} 
						formOptions={this.getModalForms()} 
					 	onConfirm={this.onConfirmModal} 
						onCancel={this.onCancelModal} />
				</Modal>

				{this.assignFormLoading === 'Loading' && <Spinner /> }

				<EntityCRUD
					modelType={Models.MemberOrganisationEntity}
					mutateOptions={(model, options, mode) => {
						if(model instanceof MemberOrganisationEntity) {
							let allowedAttributes = [
								'formss'
							];
							
							options.forEach(option => {
								if(!allowedAttributes.includes(option.attributeName)) {
									option.isReadonly = true;
								}
							});
						}
						return options;
					}}
					saveFn={((entity, formMode) => {
						const relationPath = {
							formss: {},
							assetss: {},
						};
						return entity.save(
							relationPath,
							{
								options: [
									{
										key: 'mergeReferences',
										graphQlType: '[String]',
										value: [
											'assetss',
											'formss',
										]
									},
								],
							}
						);
					})}
					additionalBulkActions={[{
						bulkAction: (mode, ids, event) => {
							this.allPagesSelected = (mode === 'excludedIds');
							this.selectedMemberIds = ids;
							this.onOpenModal();
						},
						label: "Assign Forms",
						showIcon: true,
					}]}
					{...this.props}
				/>
			</PageWrapper>
		);
		// % protected region % [Override contents here] end

		return (
			<SecuredPage groups={["Super Administrators", "MehubAccount","MehubAdmin"]}>
				{contents}
			</SecuredPage>
		);
	}

	// % protected region % [Add any extra functions here] on begin
	private onOpenModal = () => {
		runInAction(() => this.modalState.open = true);
	};

	protected onCancelModal = () => {
		runInAction(() => this.modalState.open = false);
	};

	@observable
	private assignFormLoading: 'Loading' | 'Done' = 'Done';

	private onConfirmModal = async () => {
		runInAction(() => { 
			this.modalState.open = false;
			this.assignFormLoading = 'Loading';
		});
		let memberOrgs: MemberOrganisationEntity[];
		if(this.allPagesSelected){
			memberOrgs = await Models.MemberOrganisationEntity.fetchMemberOrganisation(undefined);
			this.selectedMemberIds = memberOrgs.map(x => x.id);
		} else {
			memberOrgs = await Models.MemberOrganisationEntity.fetchMemberOrganisation(this.selectedMemberIds);	
		}
		
		for(const memberOrg of memberOrgs){
			for (const formId of this.formsSelected.selectedForm){
				const existingMemberForms = memberOrg.formss.map(x => x.formsId);
				if (!existingMemberForms.includes(formId)) {
					const newOrgForm = new Models.FormsMemberOrganisations({
						formsId: formId,
						memberOrganisationsId: memberOrg.id
					});
					runInAction(() => {
						memberOrg.formss.push(newOrgForm)
					});
				}
			}
			await memberOrg.save(
				{
					formss: {},
				},
				{
					options: [
						{
							key: 'mergeReferences',
							graphQlType: '[String]',
							value: [
								'formss',
							]
						},
					],
				})	
		}
		runInAction(() => { 
			this.formsSelected.selectedForm = [];
			this.assignFormLoading = 'Done';
		});
	}

	@observable
	private formsSelected = {
		selectedForm: []
	};


	private getModalForms() {
		const options: {
			display: string;
			value: string;
		}[] = [];

		this.forms.forEach(
			form => {
				options.push({ display: form.name, value: form.id });
			}
		);
		return options;
	}
	// % protected region % [Add any extra functions here] end
}
