/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import { SERVER_URL } from 'Constants';
import { action, observable } from 'mobx';
import { Model, IModelAttributes, attribute, entity } from 'Models/Model';
import * as Models from 'Models/Entities';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from "Util/AttributeUtils";
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchOneToManyFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import { VisitorsStandardEntity } from 'Models/Security/Acl/VisitorsStandardEntity';
import { MehubAccountStandardEntity } from 'Models/Security/Acl/MehubAccountStandardEntity';
import { MehubAdminStandardEntity } from 'Models/Security/Acl/MehubAdminStandardEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import {SuperAdministratorScheme} from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IStandardEntityAttributes extends IModelAttributes {
	name: string;
	header: string;
	description: string;
	link: string;
	iconId: string;
	icon: Blob;

	guidancess: Array<Models.GuidanceEntity | Models.IGuidanceEntityAttributes>;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('StandardEntity', 'Standard')
// % protected region % [Customise your entity metadata here] end
export default class StandardEntity extends Model implements IStandardEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsStandardEntity(),
		new MehubAccountStandardEntity(),
		new MehubAdminStandardEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Name'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'Name',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public name: string;
	// % protected region % [Modify props to the crud options here for attribute 'Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Header'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'Header',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public header: string;
	// % protected region % [Modify props to the crud options here for attribute 'Header'] end

	// % protected region % [Modify props to the crud options here for attribute 'description'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'description',
		displayType: 'textarea',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public description: string;
	// % protected region % [Modify props to the crud options here for attribute 'description'] end

	// % protected region % [Modify props to the crud options here for attribute 'Link'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'Link',
		displayType: 'textfield',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public link: string;
	// % protected region % [Modify props to the crud options here for attribute 'Link'] end

	// % protected region % [Modify props to the crud options here for attribute 'Icon'] on begin
	/**
	 * Icon of the Standard
	 */
	@observable
	@attribute({file: 'icon'})
	@CRUD({
		name: 'Icon',
		displayType: 'file',
		order: 50,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseUuid,
		inputProps: {
			imageOnly: true,
		},
		fileAttribute: 'icon',
		displayFunction: attr => attr ? <img src={`${SERVER_URL}/api/files/${attr}`} style={{maxWidth: '100px'}} /> : 'No File Attached',
	})
	public iconId: string;
	@observable
	public icon: Blob;
	// % protected region % [Modify props to the crud options here for attribute 'Icon'] end

	@observable
	@attribute({isReference: true, manyReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Guidances'] off begin
		name: "Guidances",
		displayType: 'reference-multicombobox',
		order: 60,
		referenceTypeFunc: () => Models.GuidanceEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'guidancess',
			oppositeEntity: () => Models.GuidanceEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Guidances'] end
	})
	public guidancess: Models.GuidanceEntity[] = [];

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IStandardEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IStandardEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.name !== undefined) {
				this.name = attributes.name;
			}
			if (attributes.header !== undefined) {
				this.header = attributes.header;
			}
			if (attributes.description !== undefined) {
				this.description = attributes.description;
			}
			if (attributes.link !== undefined) {
				this.link = attributes.link;
			}
			if (attributes.icon !== undefined) {
				this.icon = attributes.icon;
			}
			if (attributes.iconId !== undefined) {
				this.iconId = attributes.iconId;
			}
			if (attributes.guidancess !== undefined && Array.isArray(attributes.guidancess)) {
				for (const model of attributes.guidancess) {
					if (model instanceof Models.GuidanceEntity) {
						this.guidancess.push(model);
					} else {
						this.guidancess.push(new Models.GuidanceEntity(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		guidancess {
			${Models.GuidanceEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath = {
			guidancess: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'guidancess',
						]
					},
				],
				contentType: 'multipart/form-data',
			}
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}


	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [ createdAttr, modifiedAttr ] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(StandardEntity.prototype, 'created');
CRUD(modifiedAttr)(StandardEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
