import React from "react";
import {PageWrapper} from "../../../Components/PageWrapper/PageWrapper";
import EntityCRUD from "../../../Components/CRUD/EntityCRUD";
import SecuredPage from "../../../Components/Security/SecuredPage";
import {RouteComponentProps} from "react-router";
import FormsEntityFormVersion from "../../../../Models/Entities/FormsEntityFormVersion";
import {Comparators} from "../../../Components/ModelCollection/ModelQuery";
import {action} from "mobx";
import axios from "axios";
import { SERVER_URL } from "Constants";
import { store } from "Models/Store";

export interface IFormVersionPageRoute {
    id: string;
}

export default class FormsVersionPage extends React.Component<RouteComponentProps<IFormVersionPageRoute>> {

    public render() {
        let contents = null;
        
        let comparisonType: Comparators = 'equal';
        
        var isPublishedVersion = (model: FormsEntityFormVersion) => {
            if (model.publishVersion) {
                return model.id === model.publishVersion.id;
            }
            return false;         
        }

        contents = (
            <PageWrapper {...this.props}>
                <EntityCRUD
                    modelType={FormsEntityFormVersion}
                    filterClientside={(model => model.active)}
                    actionsMore={[{
                        onEntityClick: action(async (arg, entity: FormsEntityFormVersion) => {
                            entity.active = false;
                            await entity.save();
                            window.location.reload();
                        }),
                        condition: (model: FormsEntityFormVersion) => {
                            let isPublished = isPublishedVersion(model);
                            return !isPublished;
                        },
                        label: 'Archive'
                    },
                    {
                        onEntityClick: action(async (arg, entity: FormsEntityFormVersion) => {
                            try {
                                let formVersionId = entity.id; 
                                let formId = entity.formId;
                                const result = await axios.post(`${SERVER_URL}/api/entity/FormsEntity/promoteActiveVersion/${formId}`,
                                    "\"" + formVersionId + "\"",
                                    { headers: { "Content-Type": "application/json" } });
                                    store.routerHistory.push(`/admin/forms/build/FormsEntity/${formId}`);

                            } catch (e) {
                                alert('There was an error when promoting the form');
                                return;
                            }
                        }),
                        condition: (model: FormsEntityFormVersion) => {
                            let isPublished = isPublishedVersion(model);
                            return !isPublished && !model.isDraft;
                        },
                        label: 'Promote'
                    },
                    {
                        onEntityClick: action(async (arg, entity: FormsEntityFormVersion) => {}),
                        condition: (model: FormsEntityFormVersion) => {
                            let isPublished = isPublishedVersion(model);
                            return isPublished;
                        },
                        label: 'No actions available'
                    }]}

                    collectionUpdateAction={() => undefined}
                    collectionCreateAction={() => undefined}
                    collectionDeleteAction={() => undefined}
                    
                    filterCollection={[[{
                        path: 'formId',
                        comparison: comparisonType,
                        value: this.props.match.params.id,
                    }], [{
                        path: 'active',
                        comparison: comparisonType,
                        value: "true",
                    }]]}
                    {...this.props}
                />
            </PageWrapper>
        );
        
        return (
            <SecuredPage groups={["Super Administrators", "MehubAdmin"]}>
                {contents}
            </SecuredPage>
        );
    }
}