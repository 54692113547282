/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { ILink } from 'Views/Components/Navigation/Navigation';
import { RouteComponentProps } from 'react-router';
import { store } from 'Models/Store';
// % protected region % [Add any extra imports here] off begin
// % protected region % [Add any extra imports here] end

export function getFrontendNavLinks(pageProps: RouteComponentProps): ILink[][] {
	// % protected region % [Add any logic before displaying page links here] on begin
	const isAdmin = store.hasBackendAccess;
	const isManager = store.roleName === 'SafetyManager' || store.roleName === 'Manager';
	const isWorker = store.roleName === 'SafetyWorker';
	// % protected region % [Add any logic before displaying page links here] end
	return [
		[
			// % protected region % [Customise top nav section here] on begin
			{label: "Safety", path: '/mesafety'},
			{label: "Standards", path: '/standards'},
			{label: "Technical", path: 'https://www.masterelectricians.com.au/member-resources/technical', useATag: true},
			{label: "Employer – HR/Legal", path: '/', icon: 'chevron-down', iconPos: 'icon-right', subLinks: [
				{label: "HR", path: "https://masterelectricians.com.au/member-resources/employment-hr", useATag: true},
				{label: "Legal", path: "https://masterelectricians.com.au/member-resources/legal", useATag: true}
			]},
			{label: "Savings", path: 'https://masterelectricians.com.au/members/resources/member-benefits', useATag: true},
			{label: "Programs", path: '/', icon: 'chevron-down', iconPos: 'icon-right', subLinks: [
				{label: "Master Program", path: "https://masterelectricians.com.au/master-program", useATag: true},
				{label: "Advantage Program", path: "https://masterelectricians.com.au/advantage-program", useATag: true},
				{label: "Business Centre", path: "https://training.mea.asn.au/index/ssologin/service:saml", useATag: true}
			]},
			{label: "My Business", path: '/', icon: 'chevron-down', iconPos: 'icon-right', subLinks: [
					{label: "My Documents", path: `/my-documents`, shouldDisplay: () => (isAdmin || isManager || isWorker)},
					{label: "My Assets", path: `/assets-0`, shouldDisplay: () => (isWorker || isManager || isAdmin)},
					{label: "My Users", path: `/users`, shouldDisplay: () => (isWorker || isManager || isAdmin)},
					{label: "Safety Templates", path: `/safety-template`, shouldDisplay: () => (isManager || isAdmin)},
					{label: "MEA Documents", path: `/mea-documents`, shouldDisplay: () => (isAdmin || isManager || isWorker)},
					{label: "Toolbox/Safety Meeting", path: 'https://training.mea.asn.au/index/ssologin/service:saml', useATag: true},
					{label: "My Integrations", path: `/integrations`, shouldDisplay: () => isManager},
				]},
			{label: "Activity", path: '/activity'}
			// % protected region % [Customise top nav section here] end
		],
		[
			// % protected region % [Customise middle nav section here] on begin
			{label: "Profile", path: 'https://meamembers.memnet.com.au/MyMembership.aspx', icon: "profile", useATag: true},
			{label: "Events", path: 'https://www.masterelectricians.com.au/events', icon: "webinars", useATag: true},
			{label: "Newsletters", path: 'https://www.masterelectricians.com.au/members/resources/email-newsletters', icon: "newsletter", useATag: true},
			{label: "Contact Us", path: '/contactus', icon: "helpdesk"},
			{label: "Logout", path: '/logout', icon: "logout", iconPos: "icon-left", shouldDisplay: () => store.loggedIn, onClick: () => store.clearLoggedInUser()}
			// % protected region % [Customise middle nav section here] end
		],
		[
			// % protected region % [Customise bottom nav section here] on begin
			{label: null, path: '/login', shouldDisplay: () => !store.loggedIn},
			{label: null, path: '/logout', shouldDisplay: () => store.loggedIn, onClick: () => store.clearLoggedInUser()}
			// % protected region % [Customise bottom nav section here] end
		],
	];
}
