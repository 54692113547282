/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import { Model, IModelAttributes, attribute, entity } from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from "Util/AttributeUtils";
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchOneToManyFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import { VisitorsSafetyLinkSectionEntity } from 'Models/Security/Acl/VisitorsSafetyLinkSectionEntity';
import { MehubAccountSafetyLinkSectionEntity } from 'Models/Security/Acl/MehubAccountSafetyLinkSectionEntity';
import { MehubAdminSafetyLinkSectionEntity } from 'Models/Security/Acl/MehubAdminSafetyLinkSectionEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import {SuperAdministratorScheme} from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface ISafetyLinkSectionEntityAttributes extends IModelAttributes {
	name: string;
	tooltip: string;
	order: number;

	safetyLinkss: Array<Models.SafetyLinkEntity | Models.ISafetyLinkEntityAttributes>;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('SafetyLinkSectionEntity', 'Safety Link section')
// % protected region % [Customise your entity metadata here] end
export default class SafetyLinkSectionEntity extends Model implements ISafetyLinkSectionEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsSafetyLinkSectionEntity(),
		new MehubAccountSafetyLinkSectionEntity(),
		new MehubAdminSafetyLinkSectionEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Name'] off begin
	/**
	 * Name of topic
	 */
	@Validators.Unique()
	@observable
	@attribute()
	@CRUD({
		name: 'Name',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public name: string;
	// % protected region % [Modify props to the crud options here for attribute 'Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Tooltip'] off begin
	/**
	 * used for displaying against a topic
	 */
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'Tooltip',
		displayType: 'textarea',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public tooltip: string;
	// % protected region % [Modify props to the crud options here for attribute 'Tooltip'] end

	// % protected region % [Modify props to the crud options here for attribute 'Order'] off begin
	/**
	 * Link Section Order
	 */
	@Validators.Integer()
	@observable
	@attribute()
	@CRUD({
		name: 'Order',
		displayType: 'textfield',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public order: number;
	// % protected region % [Modify props to the crud options here for attribute 'Order'] end

	/**
	 * Would recommend never changing this to m-m as it could lead to poor navigation
	 */
	@observable
	@attribute({isReference: true, manyReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Safety Links'] off begin
		name: "Safety Links",
		displayType: 'reference-multicombobox',
		order: 40,
		referenceTypeFunc: () => Models.SafetyLinkEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'safetyLinkss',
			oppositeEntity: () => Models.SafetyLinkEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Safety Links'] end
	})
	public safetyLinkss: Models.SafetyLinkEntity[] = [];

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<ISafetyLinkSectionEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<ISafetyLinkSectionEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.name !== undefined) {
				this.name = attributes.name;
			}
			if (attributes.tooltip !== undefined) {
				this.tooltip = attributes.tooltip;
			}
			if (attributes.order !== undefined) {
				this.order = attributes.order;
			}
			if (attributes.safetyLinkss !== undefined && Array.isArray(attributes.safetyLinkss)) {
				for (const model of attributes.safetyLinkss) {
					if (model instanceof Models.SafetyLinkEntity) {
						this.safetyLinkss.push(model);
					} else {
						this.safetyLinkss.push(new Models.SafetyLinkEntity(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		safetyLinkss {
			${Models.SafetyLinkEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath = {
			safetyLinkss: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'safetyLinkss',
						]
					},
				],
			}
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}


	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [ createdAttr, modifiedAttr ] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(SafetyLinkSectionEntity.prototype, 'created');
CRUD(modifiedAttr)(SafetyLinkSectionEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
