import * as React from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router';
import * as Models from 'Models/Entities';
import EntityCRUD from 'Views/Components/CRUD/EntityCRUD';
import { PageWrapper } from 'Views/Components/PageWrapper/PageWrapper';
import SecuredPage from 'Views/Components/Security/SecuredPage';
import { IEntityContextMenuActions } from 'Views/Components/EntityContextMenu/EntityContextMenu';
import {Model} from "../../../../Models/Model";

@observer
export default class DocumentEntityPage extends React.Component<RouteComponentProps> {

    private pageActions: IEntityContextMenuActions<Models.DocumentEntity> = [];

    public render() {
        let contents = null;
        contents = (
            <PageWrapper {...this.props}>
                <EntityCRUD
                    modelType={Models.DocumentEntity}
                    actionsMore={this.pageActions}
                    {...this.props}
                    hasConditon={[[{
                        path: "documentTypess" as keyof Model,
                        conditions: [[{
                            path: "documentTypes.meaDocument",
                            comparison: "equal",
                            value: "true",
                        }]]
                    }
                    ]]}
                />
            </PageWrapper>
        );

        return (
            <SecuredPage groups={["Super Administrators", "MehubAccount", "MehubAdmin"]}>
                {contents}
            </SecuredPage>
        );
    }
}
