/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import { AssetEntity } from 'Models/Entities';
import SecuredPage from 'Views/Components/Security/SecuredPage';
import EntityCRUD from 'Views/Components/CRUD/EntityCRUD';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router';
import { getFrontendNavLinks } from 'Views/FrontendNavLinks';
import Navigation, { Orientation } from 'Views/Components/Navigation/Navigation';

// % protected region % [Add any extra imports here] on begin
import NewAsset from "../CustomComponents/NewAsset";
import { store } from "../../Models/Store";
import { MehubAccountEntity } from 'Models/Entities';
import Tabs, { ITabConfig } from "../Components/Tabs/Tabs";
import * as Enums from '../../Models/Enums';
import { observable, action, computed, autorun } from 'mobx';
import If from 'Views/Components/If/If';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import MarketingTile from "Views/CustomComponents/MarketingTile";
import BreadcrumbNavigation from "../CustomComponents/BreadcrumbNavigation";
import { gql } from "@apollo/client";
import { IBreadCrumbsTag } from "../Components/Breadcrumbs/Breadcrumbs";
// % protected region % [Add any extra imports here] end

export interface AssetsPageProps extends RouteComponentProps {
	// % protected region % [Add any extra props here] off begin
	// % protected region % [Add any extra props here] end
}

@observer
// % protected region % [Add any customisations to default class definition here] on begin
class AssetsPage extends React.Component<RouteComponentProps<{ pageNo?: string }>> {
	// % protected region % [Add any customisations to default class definition here] end

	// % protected region % [Add class properties here] on begin
	@observable
	private organisationId: string;

	private getAssetNameQuery = gql`query getAssetName($id: [String]) {
			  assetEntity(where: {path: "id", comparison: equal, value: $id}) {
			   description
			  }
			}`;

	@observable
	private lastCrumb: IBreadCrumbsTag | undefined;

	async componentDidMount() {
		const mehubAccount: MehubAccountEntity[] = await MehubAccountEntity
			.fetch({ args: [[{ path: 'id', comparison: 'equal', value: store.userId }]] });
		if (mehubAccount.length !== 0) {
			this.organisationId = mehubAccount[0].memberOrganisationId ?? '';
		}

		autorun(() => {
			if (this.props.location.pathname.includes("edit")
				|| this.props.location.pathname.includes("view")) {
				const listPathName = this.props.location.pathname.split('/');
				const id = listPathName[listPathName.length - 1];
				const mode = this.props.location.pathname.includes("edit") ? 'Edit' : 'View';
				store.apolloClient.query({
					query: this.getAssetNameQuery,
					fetchPolicy: 'no-cache',
					variables: { id: id }
				}).then(action(result => {
					this.lastCrumb = {
						label: `${mode} ${result.data.assetEntity.description}`,
						link: this.props.location.pathname
					};
				}))
			} else {
				action(() => this.lastCrumb = undefined);
			}
		})
	}

	@observable
	private tabIndex: number = 0;

	@action
	private changeTab = (index: number) => {
		this.tabIndex = index;
	}

	@computed
	private get selectedStatus() {
		let defaultStatus: Enums.assetStatus = 'IN_SERVICE';
		let tabName = this.getTabs()[this.tabIndex].name;
		for (const key in Enums.assetStatusOptions) {
			if (Enums.assetStatusOptions[key] === tabName) {
				return key;
			}
		}
		return defaultStatus;
	}

	private getTabs(): ITabConfig[] {
		return [
			{
				name: Enums.assetStatusOptions.IN_SERVICE,
				key: 'in-service',
				component: (null),
			},
			{
				name: Enums.assetStatusOptions.OUT_OF_SERVICE,
				key: 'out-of-service',
				component: (null),
			},
		];
	}

	private renderTabs(isRenderTabs: boolean) {
		if (isRenderTabs) {
			return <Tabs className='service-status-tab-nav'
				tabs={this.getTabs()}
				onTabClicked={this.changeTab}
				defaultTab={0}
				currentTab={this.tabIndex}>
			</Tabs>;
		}
		return null;
	}

	private pageToShow = Number(this.props.match.params.pageNo);

	private navToPage = () => {
		store.routerHistory.push(`/assets-${this.navToPage}`);
	}

	// % protected region % [Add class properties here] end

	render() {
		// % protected region % [Add logic before rendering contents here] off begin
		// % protected region % [Add logic before rendering contents here] end

		let contents = (
			<SecuredPage groups={['Super Administrators', 'MehubAccount', 'MehubAdmin']}>
				{
					// % protected region % [Alter navigation here] on begin
				}
				<Navigation
					linkGroups={getFrontendNavLinks(this.props)}
					orientation={Orientation.HORIZONTAL}
					match={this.props.match}
					location={this.props.location}
					history={this.props.history}
					staticContext={this.props.staticContext}
					className={'hub-navigation'}
				/>
				{
					// % protected region % [Alter navigation here] end
				}
				<div className="body-content">
					<EntityCRUD
						{...this.props}
						modelType={AssetEntity}
						URLExtension="f8810f40-90fe-4afb-adac-34a91051473a"
					// % protected region % [Add props to crud component f8810f40-90fe-4afb-adac-34a91051473a here] off begin
					// % protected region % [Add props to crud component f8810f40-90fe-4afb-adac-34a91051473a here] end
					/>
				</div>
			</SecuredPage>
		);

		// % protected region % [Override contents here] on begin
		let displayMarketingTile = false;

		switch (store.roleName) {
			case Enums.mehubUserRoleOptions.WORKER:
			case Enums.mehubUserRoleOptions.MANAGER:
				displayMarketingTile = true;
				break;
		}

		let header = (
			<section className="page-header-new">
				<div className="breadcrumb-button">
					<BreadcrumbNavigation lastCrumb={this.lastCrumb} />
				</div>
				<div className="page-controls">
					<div className="page-controls__title">
						<h2>Assets</h2>
					</div>
				</div>
			</section>
		);

		// Prevent In Service and Out of Service tabs being display on edit/view/create
		const isRenderTabs = !(new RegExp("edit|view|create", "i")).test(this.props.location.pathname);

		contents = (
			<SecuredPage groups={['Super Administrators', 'MehubAccount', 'MehubAdmin']}>
				<Navigation
					linkGroups={getFrontendNavLinks(this.props)}
					orientation={Orientation.HORIZONTAL}
					match={this.props.match}
					location={this.props.location}
					history={this.props.history}
					staticContext={this.props.staticContext}
					className={'hub-navigation'}
				/>
				<div className="body-content asset-page">
					{header}
					<If condition={displayMarketingTile}>
						<section className="primary-safety-tiles">
							<div className="primary-safety-tiles__tiles">
								<MarketingTile />
							</div>
						</section>
					</If>
					<If condition={!displayMarketingTile}>
						{this.renderTabs(isRenderTabs)}
						<EntityCRUD
							{...this.props}
							modelType={AssetEntity}
							mutateOptions={(model, options, mode) => {
								if (model instanceof AssetEntity) {
									let outOfService: Enums.assetStatus = 'OUT_OF_SERVICE';
									if (mode === EntityFormMode.EDIT && model.status === outOfService) {
										options.forEach(option => {
											if (option.attributeName !== 'status') {
												option.isReadonly = true;
											}
										});
									}
								}
								return options;
							}}
							initialFilterApplied = {isRenderTabs}
							filterCollection={[[{ path: "status", comparison: 'equal', value: this.selectedStatus }]]}
							URLExtension="f8810f40-90fe-4afb-adac-34a91051473a"
							createComponent={() => <NewAsset organisationId={this.organisationId} />}
							pageToShow={this.pageToShow}
						/>
					</If>
				</div>
			</SecuredPage>
		);
		// % protected region % [Override contents here] end

		return contents;
	}
}

// % protected region % [Override export here] off begin
export default AssetsPage;
// % protected region % [Override export here] end
