import * as React from 'react';
import { AssetEntity, MehubAccountEntity } from 'Models/Entities';
import SecuredPage from 'Views/Components/Security/SecuredPage';
import EntityCRUD from 'Views/Components/CRUD/EntityCRUD';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router';
import { getFrontendNavLinks } from 'Views/FrontendNavLinks';
import Navigation, { Orientation } from 'Views/Components/Navigation/Navigation';
import NewAsset from "../CustomComponents/NewAsset";
import { lowerCaseFirst } from "../../Util/StringUtils";
import { getFormListQuery } from "../../Forms/Admin/Version/FormVersionCollection";
import { getFetchSingleQuery } from "../../Util/EntityUtils";
import Collection from "../Components/Collection/Collection";
import { Button, Colors, Display, Sizes } from "../Components/Button/Button";
import { IAssetEntityAttributes } from "../../Models/Entities/AssetEntity";
import { action, observable, runInAction } from "mobx";
import { store } from "../../Models/Store";
import axios from "axios";
import { SERVER_URL } from "../../Constants";
import alert from "../../Util/ToastifyUtils";
import If from "../Components/If/If";


export interface ActiveAssetsPageProps {
    id: string;
}

@observer
class ActiveAssetsPage extends React.Component<RouteComponentProps<ActiveAssetsPageProps>> {

    @observable
    private selectedItems: string[];

    @action
    private onItemSelectionChange = (models: string[]) => {
        this.selectedItems = models;
    };
    private cancelAttachDocuments = () => {
        store.routerHistory.push(`/safety-form/${this.props.match.params.id}`);
    }
    private attachAssetsToForm = async () => {
        try {
            await axios.post(`${SERVER_URL}/api/entity/AssetEntity/attachAssets`, {
                formId: this.props.match.params.id,
                assets: this.selectedItems
            });

            alert('Successfully attached assets', 'success');

            // Finish attaching will assign the assets to the form and take the user back to form
            store.routerHistory.push(`/safety-form/${this.props.match.params.id}`);
        } catch (e) {
            alert('Failed to attach assets to the form', 'error');
            console.error(e);
        }
    }

    render() {
        const contents = (
            <SecuredPage groups={['Super Administrators', 'MehubAccount', 'MehubAdmin']}>
                <Navigation
                    linkGroups={getFrontendNavLinks(this.props)}
                    orientation={Orientation.HORIZONTAL}
                    match={this.props.match}
                    location={this.props.location}
                    history={this.props.history}
                    staticContext={this.props.staticContext}
                    className={'hub-navigation'}
                />
                <div className="body-content">
                    <div className="active-assets-header web">
                        <h3>Attach assets</h3>
                        <Button display={Display.Solid}
                            colors={Colors.FormPrimary}
                            sizes={Sizes.Medium}
                            onClick={this.attachAssetsToForm}>
                            Finish attaching
                        </Button>
                    </div>
                    <EntityCRUD
                        {...this.props}
                        modelType={AssetEntity}
                        collectionCreateAction={() => undefined}
                        collectionUpdateAction={() => undefined}
                        filterCollection={[[{ path: "status", comparison: 'equal', value: "IN_SERVICE" }]]}
                        entityCollectionProps={{
                            itemSelectionChanged: this.onItemSelectionChange,
                        }}
                        disableBulkExport
                    />
                    <div className='btn-group btn attach-docs-buttons mobile'>
                        <Button className={'attach-doc-button attach-cancel-button'}
                            display={Display.Outline}
                            colors={Colors.FormPrimary}
                            sizes={Sizes.Medium}
                            onClick={this.cancelAttachDocuments}>
                            Cancel
                        </Button>
                        <Button className={'attach-doc-button attach-done-button'}
                            display={Display.Solid}
                            colors={Colors.FormPrimary}
                            sizes={Sizes.Medium}
                            onClick={this.attachAssetsToForm}>
                            Done
                        </Button>
                    </div>
                </div>
            </SecuredPage>
        );

        return contents;
    }
}

export default ActiveAssetsPage;

