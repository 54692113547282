import * as React from "react";
import { Component } from "react";
import { observer } from 'mobx-react';
import { QuestionType, ValidatorType } from 'Forms/Schema/Question';
import { IQuestionTile, QuestionTileOptionsProps } from '../QuestionTile';
import TileOptions from '../Options/TileOptions';
import { hasRequiredValidator } from '../../Validators/ValidationUtils';
import { ValidateMultiCombobox } from 'Forms/Validators/ValidateMultiCombobox';
import { QuestionComponent, QuestionComponentProps } from 'Forms/Questions/QuestionComponent';
import { Combobox } from "../../../Views/Components/Combobox/Combobox";
import CompareMultiComboboxOptions from "../../Conditions/CompareMultiComboboxOptions";
import ComboboxQuestionOptions from '../Options/ComboboxQuestionOptions';

@observer
export class MultiComboboxQuestionTileOptions extends Component<QuestionTileOptionsProps> {

	public render() {
		const { question, schema } = this.props;
		return (<>
			<TileOptions
				question={question}
				schema={schema}
				hasShowConditions
				hasValidators
				hasTooltip
			/>
			<ComboboxQuestionOptions
				question={question}
				schema={schema}
			/>
		</>)
	}
}

export interface IMultiComboboxQuestionTileProps<T> extends QuestionComponentProps<T> {
	options: {
		values: {
			value: string
		}[]
	};
}

@observer
export class MultiComboboxQuestionTile<T> extends QuestionComponent<T, IMultiComboboxQuestionTileProps<T>> implements IQuestionTile {
	static displayName = 'MultiCombobox';

	static questionType: QuestionType = 'multicombobox';
	static optionsMenu = MultiComboboxQuestionTileOptions;
	static conditionOptions = [
		{ display: 'Equal', value: 'equal' },
		{ display: 'Not equal', value: 'notEqual' },
		{ display: 'Contains', value: 'contains' },
	];
	static validatorOptions: { display: string, value: ValidatorType }[] = [
		{ display: 'Required', value: 'required' },
	];
	static compareFunction = CompareMultiComboboxOptions;
	static validateFunction = ValidateMultiCombobox;

	public render() {
		const { title, model, id, isReadOnly, validators, toolTip, className, options } = this.props;

		const comboOptions = options.values ?
			options.values.map((value) => ({
				'display': value.value,
				'value': value.value
			})) :
			[{
				display: undefined,
				value: undefined
			}];

		return (
			<div className="multicombobox-wrapper">
				<Combobox
					className={className}
					model={model}
					modelProperty={id}
					options={comboOptions}
					isRequired={hasRequiredValidator(validators)}
					isDisabled={isReadOnly}
					tooltip={toolTip}
					label={title}
					labelVisible
					inputProps={{
						multiple: true,
					}}
				/>
			</div>
		);
	}
}

