import * as React from "react";
import { TemplateEntity, DocumentTypesEntity } from 'Models/Entities';
import SecuredPage from 'Views/Components/Security/SecuredPage';
import EntityCRUD from 'Views/Components/CRUD/EntityCRUD';
import { action, observable, autorun } from 'mobx';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router';
import { getFrontendNavLinks } from 'Views/FrontendNavLinks';
import Navigation, { Orientation } from 'Views/Components/Navigation/Navigation';
import { store } from "Models/Store";
import Tabs, { ITabConfig } from "Views/Components/Tabs/Tabs";
import { documentTypeList } from "Models/Enums";
import { Model } from "Models/Model";
import { SERVER_URL } from "Constants";
import * as Enums from "Models/Enums";
import If from "Views/Components/If/If";
import MarketingTile from "Views/CustomComponents/MarketingTile";
import { gql } from "@apollo/client";
import alertToast from "Util/ToastifyUtils";
import { ScrollTabs, ITab } from 'Views/CustomComponents/ScrollTabs';
import BreadcrumbNavigation from "../CustomComponents/BreadcrumbNavigation";
import {IBreadCrumbsTag} from "../Components/Breadcrumbs/Breadcrumbs";
import qs from "qs";
import {HasCondition} from "../Components/ModelCollection/ModelQuery";

export interface IMasterTemplateLandingPageRouteProps extends RouteComponentProps {
    tab?: string,
}

@observer
class MasterTemplateLandingPage extends React.Component<IMasterTemplateLandingPageRouteProps> {
    @observable
    private tabs: ITab[] = [];

    @action
	async fetchDocumentTypes() {
		const fetchDocumentTypes = gql`query getDocumentTypes {
                documentTypesEntitys(
                    where: {path: "meaDocument", comparison: equal, value: "true", negate: true},
                    orderBy: {path: "order"},
                    has: [[{
                        path: "templatess",
                    }]]
                )
				{
					name
					description
					order
					roless {
						roles {
							name
						}
					}
                    templatess {
						id
					}
				}
			}`;
            
        store.apolloClient.query({
            query: fetchDocumentTypes,
            fetchPolicy: 'no-cache',
        }).then(action(result => {
            this.tabs = [];
            result.data.documentTypesEntitys.forEach((documentType : DocumentTypesEntity) => {
                this.tabs.push({
                    name: documentType.name,
                    key: encodeURIComponent(documentType.name),
                    tooltip: documentType.description,
                });
            });
            const existingSelectedTab = this.tabs.find(t => t.key === this.props.tab);
            if (this.tabs.length && !existingSelectedTab) {
                // [MEASD-144] Trigger tab url change when first loading up the page
                store.routerHistory.replace(`/safety-template/${this.tabs[0].key}`);
            }
        }));
	}
    private getSafetyTemplateQuery = gql`query getSafetyTemplateName($id: [String]) {
            templateEntity(where: {path: "id", comparison: equal, value: $id}) {
               templateName
              }
            }`;

    @observable
    private lastCrumb: IBreadCrumbsTag | undefined;
    
    componentDidMount() {
        autorun(() => {
            this.fetchDocumentTypes();
		});

        autorun(() => {
            if (this.props.location.pathname.includes("edit")
                || this.props.location.pathname.includes("view")) {
                const listPathName = this.props.location.pathname.split('/');
                const id = listPathName[listPathName.length - 1];
                const mode = this.props.location.pathname.includes("edit") ? 'Edit' : 'View';
                store.apolloClient.query({
                    query: this.getSafetyTemplateQuery,
                    fetchPolicy: 'no-cache',
                    variables: {id: id}
                }).then(action(result => {
                    this.lastCrumb = {label: `${mode} ${result.data.templateEntity.templateName}`,
                        link: this.props.location.pathname};
                }))
            } else {
                action(() => this.lastCrumb = undefined);
            }
        })
    }

    // Helper method to change the url when a user clicks on any of the tabbed navigations
    private changeTabUrl(tab: ITab){
        store.routerHistory.push(`/safety-template/${tab.key}`); 
    }
        
    
    private downloadTemplate = async (mode: 'includedIds' | 'excludedIds', ids: string[], event: React.MouseEvent<Element, MouseEvent>) => {
        try {
            let selectedItems;
            if (mode === 'includedIds') {
                const templateEntities = (await store.apolloClient
                    .query({
                        query: gql`
                            query getTemplates($ids: [String]) {
                                templateEntitys(where: {path: "id", comparison: in, value: $ids}) {
                                    id
                                    templateUploadId
                                }
                            }`,
                        variables: {
                            ids,
                        },
                        fetchPolicy: 'network-only',
                    })).data['templateEntitys'].map((d: any) => new TemplateEntity(d)) as TemplateEntity[];

                selectedItems = templateEntities.map(element => element.templateUploadId);
            }
            else{
                let hasFilterCondition : HasCondition<Model>[][] = 
                    [[{
                    path: "documentTypess" as keyof Model, 
                        conditions: [[{
                        path: "documentTypes.name", 
                            comparison: "equal", 
                            value: this.props.tab ?? this.tabs[0].name
                    }]]
                }]];
                
                const templateEntities = (await store.apolloClient
                .query({
                    query: gql`
                        query getTemplates($hasFilterCondition: [[HasConditionType]]) {
                            templateEntitys(has: $hasFilterCondition) {
                                id
                                templateUploadId
                            }
                        }`,
                    variables: {
                        hasFilterCondition,
                    },
                    fetchPolicy: 'network-only',
                })).data['templateEntitys'].map((d: any) => new TemplateEntity(d)) as TemplateEntity[];

                selectedItems = templateEntities.map(element => element.templateUploadId);
            }

            let newList: string[] = [];
            await selectedItems.forEach(param => {
                if (param != null)
                    newList.push(param)
            });
            if (newList.length > 0){
                const url = `${SERVER_URL}/api/files/bulkDocuments?${qs.stringify({ documentId : newList }, {arrayFormat: 'repeat'})}`;
                window.open(url);
            } else{
                alertToast("No downloadable template pdf/s found. Only templates with a file attached can be downloaded.", "warning");
            }
        } catch (err) {
            console.error(err);
            alertToast('Unable to download template pdf/s', 'error');
        }
    }
    
    private tabContent = (documentType: string) => {
        return (
            <EntityCRUD
                {...this.props}
                modelType={TemplateEntity}
                URLExtension="f17d2da4-b6c8-424c-b39b-46c5df579221"
                collectionCreateAction={() => undefined}
                collectionUpdateAction={() => undefined}
                hasConditon={[[{path: "documentTypess" as keyof Model, conditions: [[{path: "documentTypes.name", comparison: "equal", value: documentType}]]}]]}
                additionalBulkActions={[{
                    bulkAction: this.downloadTemplate,
                    label: "Download",
                    showIcon: true,
                    icon: "download",
                    iconPos: 'icon-left',
                }]}
            />
        );
    }
    
    render() {
        let displayMarketingTile = false;

        switch (store.roleName) {
            case Enums.mehubUserRoleOptions.WORKER:
            case Enums.mehubUserRoleOptions.MANAGER:
                displayMarketingTile = true;
                break;
        }
        
        let header = (
            <section className="page-header-new">
                <div className="breadcrumb-button">
                    <BreadcrumbNavigation lastCrumb={this.lastCrumb} />
                </div>
                <div className="page-controls">
                    <div className="page-controls__title">
                        <h2>Safety Template Library</h2>
                    </div>
                </div>
                <ScrollTabs
                    defaultTabKey={this.tabs.length ? this.tabs.find(x => x.key === this.props.tab)?.key: undefined}
                    tabs={this.tabs}
                    onTabClick={action((clickedTab: ITab) => { 
                        this.changeTabUrl(clickedTab);
                    })}
                />
            </section>
        );
        
        return (
            <SecuredPage groups={['Super Administrators', 'MehubAccount', 'MehubAdmin']}>
                <Navigation
                    linkGroups={getFrontendNavLinks(this.props)}
                    orientation={Orientation.HORIZONTAL}
                    match={this.props.match}
                    location={this.props.location}
                    history={this.props.history}
                    staticContext={this.props.staticContext}
                    className={'hub-navigation'}
                />
                <div className="body-content master-template-page" key={this.props.tab}>
                    {header}
                    <If condition={displayMarketingTile}>
                        <section className="primary-safety-tiles">
                            <div className="primary-safety-tiles__tiles">
                                <MarketingTile />
                            </div>
                        </section>
                    </If>
                    <If condition={!displayMarketingTile}>
                        {this.tabs[0]?.name ? this.tabContent(this.props.tab ?? this.tabs[0].name) : <></>}
                    </If>
                </div>
            </SecuredPage>
        );
    }
}

export default MasterTemplateLandingPage;