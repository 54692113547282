import * as React from 'react';
import {ChangeEventHandler} from 'react';
import * as uuid from 'uuid';
import classNames from 'classnames';
import InputWrapper from '../Inputs/InputWrapper';
import InputsHelper from '../Helpers/InputsHelper';
import {DisplayType} from '../Models/Enums';
import {action, computed, observable} from 'mobx';
import {observer} from 'mobx-react';
import {Button, Colors, Display, Sizes} from 'Views/Components/Button/Button';
import {FileUploadPreview} from 'Views/Components/FileUpload/UploadPreview';
import If from 'Views/Components/If/If';
import {Model} from '../../../Models/Model';
import {IIconProps} from "../Helpers/Common";

export interface MultiFileUploadProps<T> {

    /**
     * The model to load the result data into.
     */
    model: T;
    /**
     * The property to load the file into. The datatype of this field is the Javascript File type.
     */
    modelProperty: string;

    fileModel: {new(): Model};

    fileModelProperty: string;

    /**
     * Should a file preview be shown or a function to override the preview. If this is not set then the preview will
     * not be displayed
     */
    preview?: boolean | ((id: string, file?: File, onDelete?: () => void) => React.ReactNode);
    /**
     * The content types to accept. This takes the format of the accept tag in a HTML input.
     *
     * See https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#accept
     */
    contentType?: string;
    /**
     * Allow only images? This will restrict the content type to images only (this can be overwritten by the contentType
     * prop). This will also enable an image preview window to see the image to be uploaded before submitting.
     */
    imageUpload?: boolean;
    /**
     * Id for the component.
     */
    id?: string;
    /**
     * The name of the input.
     */
    name?: string;
    /**
     * The class name of the component.
     */
    className?: string;
    /**
     * The display mode for the input.
     */
    displayType?: DisplayType;
    /**
     * The label for the input.
     */
    label?: string;
    /**
     * Should the label be visible. If this is set the false the label will still exist in the aria-label attribute.
     */
    labelVisible?: boolean;
    /**
     * Is this field required.
     */
    isRequired?: boolean;
    /**
     * Is this field disabled.
     */
    isDisabled?: boolean;
    /**
     * Is this a readonly field. This will disable the field and remove all inputs.
     */
    isReadOnly?: boolean;
    /**
     * Is this a static input.
     */
    staticInput?: boolean;
    /**
     * The a tooltip for this input.
     */
    tooltip?: string;
    /**
     * A description for this input.
     */
    subDescription?: string;
    /**
     * Props to directly pass to the HTML input element.
     */
    inputProps?: React.InputHTMLAttributes<Element>;
    /**
     * Errors to display for this component.
     */
    errors?: string | string[];
    /**
     * An override for the onchange function.
     * @param file The file the user selected
     */
    onChange?: (file: File) => boolean;
    /**
     * Callback after onChange has been completed. This is not called if onChange was overwritten.
     * @param file The file the user selected
     */
    onAfterChange?: (file: File) => void;
    /**
     * Override for when the delete file button is pressed.
     */
    onDelete?: (id: string) => void;
    /**
     * Callback after a file has been cleared. This is not called if onDelete was overwritten.
     */
    onAfterDelete?: () => void;
    /**
     * Should the drop area for files be disabled. If this component is in read only mode then the area will also be
     * disabled.
     */
    disableDropArea?: boolean;
    /**
     * Override to be used for the choose file button text.
     */
    buttonText?: string;
    /**
     * Set the max file upload size
     */
    maxFileUploadSize?: number;
    /**
     * Display of the button
     */
    display?: Display;
    /**
     * Icon
     */
    icon?: IIconProps;
    /**
     * Size of the button
     */
    sizes?: Sizes;
    /**
     * Color
     */
    colors?: Colors;
}

type FileModel = {id: string, file: File | undefined};

/**
 * This component provides an interface to load a file from the users device.
 */
@observer
class MultiFileUpload<T> extends React.Component<MultiFileUploadProps<T>> {
    protected uuid = uuid.v4();
    protected inputRef: HTMLInputElement | null = null;

    @observable
    public isBeingHovered = false;

    @observable
    protected internalErrors: string[] = [];

    @computed
    get fileModels(): FileModel[] {
        if(this.props.model) {
            return this.props.model[this.props.modelProperty]
            .map((model: Model) => (({id: model._clientId, file: model[this.props.fileModelProperty]})));
        }
        return [];
    }

    @computed
    public get files() {
        return this.fileModels.map(x => x.file)
    }

    @computed
    public get disableDelete() {
        return this.props.isRequired || this.props.isDisabled || this.props.isReadOnly;
    }

    @computed
    protected get acceptType() {
        const { contentType, imageUpload } = this.props;
        return contentType ?? (imageUpload ? 'image/*' : undefined);
    }

    @computed
    protected get errors() {
        const errorsProp = this.props.errors;
        if (typeof errorsProp === 'string') {
            return [...this.internalErrors, errorsProp];
        } else if (Array.isArray(errorsProp)) {
            return [...this.internalErrors, ...errorsProp];
        }
        return this.internalErrors;
    }

    @action
    public setFile = (file: File) => {
        if (this.props.onChange) {
            return this.props.onChange(file);
        }

        this.internalErrors = [];
        if (!this.validateContentType(file)) {
            const message =  `Content type ${file.type} is not valid for ${this.acceptType}`;
            this.internalErrors.push(message);
            console.warn(message);
            return false;
        }

        const maxFileUploadSize = this.props.maxFileUploadSize ?? 100; // 100MB default

        if (file.size > maxFileUploadSize * 1000000) { // times 1000000 to convert to MB
            const message = `The provided file is too large, the maximum file size is ${maxFileUploadSize}MB.`;
            this.internalErrors.push(message);
            console.warn(message);
            return false;
        }

        let models: Model[] = this.props.model[this.props.modelProperty];
        let newModel = new (this.props.fileModel)();
        newModel[this.props.fileModelProperty] = file;
        models.push(newModel);

        this.props.onAfterChange?.(file);

        return true;
    };

    @action
    public clearFile = (id: string) => {
        this.internalErrors = [];
        if (this.props.onDelete) {
            return this.props.onDelete(id);
        }
        if (this.inputRef) {
            this.inputRef.value = '';
        }

        let models: Model[] = this.props.model[this.props.modelProperty];
        const modelToDelete = this.props.model[this.props.modelProperty].find((x: Model) => x._clientId === id);
        const idx = this.props.model[this.props.modelProperty].indexOf(modelToDelete);
        models.splice(idx, 1);

        this.props.onAfterDelete?.()
    };

    public validateContentType = (file: File) => {
        const types = this.acceptType?.split(',').map(x => x.trim());

        // If this is null then there is no validation
        if (!types) {
            return true;
        }

        // Iterate over each allowed type and validate it against the file
        for (const type of types) {
            // Check file content types
            if (file.type === type) {
                return true;
            }

            // File extension match
            if (type.startsWith('.') && file.name.endsWith(type)) {
                return true;
            }

            // Check special content types
            if (type === 'audio/*' || type === 'video/*' || type === 'image/*') {
                const specialType = type.replace('/*', '');
                if (file.type.startsWith(specialType)) {
                    return true;
                }
            }
        }

        return false;
    };

    protected onChange: ChangeEventHandler<HTMLInputElement> = event => {
        const { files } = event.target;
        if (files) {
            for (let i = 0; i < files.length; i++) {
                this.setFile(files[i]);
            }
        }
    };

    protected onDragOver = (event: React.DragEvent) => event.preventDefault();

    @action
    protected onDragEnter = () => this.isBeingHovered = true;

    @action
    protected onDragLeave = () => this.isBeingHovered = false;

    @action
    protected onDrop = (event: React.DragEvent) => {
        event.preventDefault();
        this.isBeingHovered = false;
        const file = event.dataTransfer.files[0];
        if (file) {
            this.setFile(file);
        }
    };

    protected onClick = () => {
        this.inputRef?.focus();
        this.inputRef?.click();
    };

    protected preview = () => {
        const { preview } = this.props;
        if (typeof preview === 'function') {
            const view = this.fileModels.map(f => (
                <React.Fragment key={f.id}>
                    {preview?.(f.id, f.file, this.disableDelete ? undefined : () => this.clearFile(f.id))}
                </React.Fragment>
            ));
            return <>{view}</>;
        }

        if (this.files.length > 0 && !!preview) {
            return this.fileModels.filter((f): f is { file: File, id: string } => f !== undefined && f.file !== undefined).map((file, index) => {
                return <FileUploadPreview
                    key={file.file?.name}
                    fileName={file.file?.name}
                    imagePreview={false}
                    fileBlob={file.file}
                    onDelete={this.disableDelete ? undefined : () => this.clearFile(file.id)}
                />
            });
        }
        return null;
    };

    public render() {
        const {
            name,
            className,
            displayType,
            label,
            isRequired,
            isDisabled,
            isReadOnly,
            staticInput,
            tooltip,
            subDescription,
            disableDropArea,
            buttonText,
            display,
            icon,
            sizes,
            colors,
        } = this.props;

        const wrapperId = this.uuid.toString();
        const fieldId = `${wrapperId}-field`;

        const labelVisible = (this.props.labelVisible === undefined) ? true : this.props.labelVisible;
        const ariaLabel = !labelVisible ? label : undefined;
        const ariaDescribedby = InputsHelper.getAriaDescribedBy(wrapperId, tooltip, subDescription);

        return (
            <div
                className={classNames(
                    'upload',
                    'upload__file',
                    isReadOnly ? 'readonly' : undefined,
                    className)}
                id={this.props.id}>
                <InputWrapper
                    id={wrapperId}
                    inputId={fieldId}
                    className="file-input"
                    displayType={displayType}
                    isRequired={isRequired}
                    staticInput={staticInput}
                    tooltip={tooltip}
                    subDescription={subDescription}
                    label={label}
                    labelVisible={labelVisible}
                    errors={this.errors}>
                    <input
                        ref={instance => this.inputRef = instance}
                        style={{display: 'none'}}
                        aria-hidden="true"
                        type="file"
                        name={name}
                        accept={this.acceptType}
                        multiple={false}
                        onChange={this.onChange}
                        disabled={isDisabled}
                        readOnly={staticInput}
                        aria-label={ariaLabel}
                        aria-describedby={ariaDescribedby}
                        {...this.props.inputProps}/>
                    <If condition={isReadOnly !== true}>
                        <Button
                            icon={icon ?? {iconPos: 'icon-left', 'icon': 'upload'}}
                            display={display ?? Display.Solid}
                            sizes={sizes}
                            colors={colors}
                            disabled={isDisabled}
                            onClick={this.onClick}>
                            {buttonText ?? 'Choose File'}
                        </Button>
                        <If condition={disableDropArea !== true}>
                            <div
                                className={classNames(
                                    'upload__drag-area',
                                    this.isBeingHovered ? 'active' : undefined,
                                    isDisabled ? 'disabled' : undefined)}
                                onDragOver={this.onDragOver}
                                onDragEnter={this.onDragEnter}
                                onDragLeave={this.onDragLeave}
                                onDrop={this.onDrop}>
                            </div>
                        </If>
                    </If>
                </InputWrapper>
                <div className="file-preview">
                    {this.preview()}
                </div>
            </div>
        );
    }
}

export default MultiFileUpload;