/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { QuestionType, ValidatorType } from 'Forms/Schema/Question';
import { Combobox } from 'Views/Components/Combobox/Combobox';
import { IQuestionTile, QuestionTileOptionsProps } from '../QuestionTile';
import TileOptions from 'Forms/Questions/Options/TileOptions';
import { hasRequiredValidator } from '../../Validators/ValidationUtils';
import CompareCombobox from 'Forms/Conditions/CompareCombobox';
import { ValidateBoolean } from 'Forms/Validators/ValidateBoolean';
import { QuestionComponent, QuestionComponentProps } from 'Forms/Questions/QuestionComponent';
import ComboboxQuestionOptions from '../Options/ComboboxQuestionOptions';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

@observer
export class ComboboxQuestionTileOptions extends Component<QuestionTileOptionsProps> {
	// % protected region % [Add extra options class properties here] off begin
	// % protected region % [Add extra options class properties here] end

	public render() {
		// % protected region % [Customize options render here] off begin
		const { question, schema } = this.props;
		return (
			<>
				<TileOptions 
					question={question} 
					schema={schema} 
					hasShowConditions 
					hasValidators 
					hasTooltip
				/>
				<ComboboxQuestionOptions
					question={question}
					schema={schema}
				/>
			</>
		);
		// % protected region % [Customize options render here] end
	}
}

export interface IComboboxQuestionTileProps<T> extends QuestionComponentProps<T> {
	options: {
		values: {
			value: string
		}[]
	};
	// % protected region % [Add extra props here] off begin
	// % protected region % [Add extra props here] end
}

@observer
export class ComboboxQuestionTile<T> extends QuestionComponent<T, IComboboxQuestionTileProps<T>> implements IQuestionTile {
	// % protected region % [Customize static form vars here] off begin
	static displayName = 'Combobox';

	static questionType: QuestionType = 'combobox';
	static optionsMenu = ComboboxQuestionTileOptions;
	static conditionOptions = [
		{ display: 'Equal', value: 'equal' },
		{ display: 'Not equal', value: 'notEqual' },
	];
	static validatorOptions: { display: string, value: ValidatorType }[] = [
		{ display: 'Required', value: 'required' },
	];
	static compareFunction = CompareCombobox;
	static validateFunction = ValidateBoolean;
	// % protected region % [Customize static form vars here] end

	// % protected region % [Add extra class properties here] off begin
	// % protected region % [Add extra class properties here] end
	public render() {
		// % protected region % [Customize render here] off begin
		const { title, model, id, isReadOnly, validators, toolTip, className, options, ...props } = this.props;
		const comboOptions = options.values ? 
			options.values.map((value) => ({
				'display': value.value,
				'value': value.value
			})) : 
			[{
				display: undefined,
				value: undefined
			}];
		return (
			<div className="combobox-wrapper">
				<Combobox
					className={className}
					model={model}
					modelProperty={id}
					options={comboOptions}
					isRequired={hasRequiredValidator(validators)}
					isDisabled={isReadOnly}
					tooltip={toolTip}
					label={title}
					labelVisible
					{...props}
				/>
			</div>
		);
		// % protected region % [Customize render here] end
	}
}

// % protected region % [Add extra methods here] off begin
// % protected region % [Add extra methods here] end