import * as React from 'react';
import { DocumentEntity } from 'Models/Entities';
import EntityCRUD from 'Views/Components/CRUD/EntityCRUD';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router';
import { observable } from "mobx";
import { IConditionalFetchArgs, Model } from 'Models/Model';
import {DocumentTypesEntity} from 'Models/Entities';
import If from "../Components/If/If";
import {viewActionOptions} from "../Components/CRUD/EntityCollection";
import { ITab } from 'Views/CustomComponents/ScrollTabs';

export interface MeaDocumentProps extends RouteComponentProps {
    createAction?: (options: viewActionOptions<Model>) => React.ReactNode;
    removeEditAction?: boolean;
    removeDeleteAction?: boolean;
    testMode?: boolean;
    currentTab?: ITab,
}

@observer
class MeaDocument extends React.Component<MeaDocumentProps> {

    @observable
    selectedReferences: DocumentTypesEntity[] = [];

    searchDocuments = async (search: string) => {
        const args: IConditionalFetchArgs<DocumentTypesEntity> = {
            args: [[{path: 'name', comparison: 'like', value: `%${search}%`}], 
                [{path: 'meaDocument', comparison: 'equal', value: 'true'}]]
        };
        const documents = await DocumentTypesEntity.fetch<DocumentTypesEntity>(
            args, undefined, undefined, true);

        return documents.map(x => ({
            display: x.getDisplayName(),
            value: x,
        }));
    };

    render() {
        return (
            <If condition={this.props.currentTab !== undefined}>
                <EntityCRUD
                    {...this.props}
                    modelType={DocumentEntity}
                    URLExtension="93c41b42-125a-41d1-8536-8bccf3eb0b55"
                    collectionCreateAction={this.props.createAction}
                    removeDeleteAction={this.props.removeDeleteAction}
                    removeEditAction={this.props.removeEditAction}
                    hasConditon={[[{
                        path: "documentTypess" as keyof Model,
                        conditions: [ [{
                            path: "documentTypes.name",
                            comparison: "equal",
                            value: this.props.currentTab?.name,
                        }],[{
                            path: "documentTypes.meaDocument",
                            comparison: "equal",
                            value: "true",
                        }]]
                    }
                    ]]}
                />
            </If>
        );
    }
}

export default MeaDocument;
