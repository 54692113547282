import * as React from "react";
import {Component} from "react";
import {observer} from 'mobx-react';
import {QuestionType, ValidatorType} from 'Forms/Schema/Question';
import {IQuestionTile, QuestionTileOptionsProps} from '../QuestionTile';
import TileOptions from '../Options/TileOptions';
import CompareBoolean from 'Forms/Conditions/CompareBoolean';
import {ValidateMultiCombobox} from 'Forms/Validators/ValidateMultiCombobox';
import {QuestionComponent, QuestionComponentProps} from 'Forms/Questions/QuestionComponent';
import { MehubAccountEntity } from "Models/Entities";
import { store } from "Models/Store";
import {action, computed, observable, runInAction} from "mobx";
import {hasRequiredValidator} from "../../Validators/ValidationUtils";
import { MultiCombobox } from "Views/Components/Combobox/MultiCombobox";
import _ from "lodash";
import { QuestionIdSubstring } from "../QuestionUtils";
import { IWhereCondition } from "Views/Components/ModelCollection/ModelQuery";
import moment from "moment";

@observer
export class SelectUserProfileTileOptions extends Component<QuestionTileOptionsProps> {

	public render() {
		const { question, schema } = this.props;
		return (
			<TileOptions question={question} schema={schema} hasValidators hasShowConditions hasTooltip />
		);
	}
}

export interface ISelectUserProfileTileProps<T> extends QuestionComponentProps<T> {
	options: {
		values: {
			display: string;
			value: string;
		}[]
	};
}

interface IOpt {
	display: string | undefined;
	value: string | undefined;
	isFixed: boolean;
}

@observer
export class SelectUserProfileTile<T> extends QuestionComponent<T, ISelectUserProfileTileProps<T>> implements IQuestionTile {
	static displayName = 'User Select';

	static questionType: QuestionType = 'selectUserProfile';
	static optionsMenu = SelectUserProfileTileOptions;
	static conditionOptions = [
		{ display: 'Equal', value: 'equal' },
		{ display: 'Not equal', value: 'notEqual' },
	];
	static validatorOptions: { display: string, value: ValidatorType }[] = [
		{ display: 'Required', value: 'required' },
	];
	static compareFunction = CompareBoolean;
	static validateFunction = ValidateMultiCombobox;
	
	@observable
	private users: MehubAccountEntity[] = [];

	@observable 
	private currentUser?: MehubAccountEntity;

	private isCompleteSlide: boolean = false;

	componentDidMount() {
		const { model, id } = this.props;

		this.isCompleteSlide = id.includes(QuestionIdSubstring.NOTIFICATION_SLIDE_USERSELECT);
		
		// Fetch users
		if(process.env.JEST_WORKER_ID !== undefined) {
			// Test is using mock data. Don't fetch.
			return;
		}
					
		MehubAccountEntity.fetch<MehubAccountEntity>({ args: [[{path: 'email', comparison: "equal", value: store.email}]] }).then(data => {
			if(data && data.length) {
				runInAction(() => { this.currentUser = data[0] });
				if(data[0].memberOrganisation) { // prevent crash when no member org is set
					let organisationId = data[0].memberOrganisation.id;
					// Filter out 
					if(this.props.isReadOnly){
						return MehubAccountEntity.fetch<MehubAccountEntity>(
							{
								args: [
									[{ path: 'memberOrganisation.id', comparison: "equal", value: organisationId }],
								]
							}
						);
					} else {
						return MehubAccountEntity.fetch<MehubAccountEntity>(
							{
								args: [
									[{ path: 'memberOrganisation.id', comparison: "equal", value: organisationId }],
									[{ path: 'Lastsyncdate', comparison: 'greaterThanOrEqual', value: moment().startOf('day')}],
								]
							}
						);
					}						
				} else {
					console.warn("User has no member org");
				}
			}
			return [];
		})
		.then(this.setData);
		
	}

	@action
	private setData = (data: MehubAccountEntity[]) => {
		const { model, id } = this.props;
		this.users = data;
		
		// on notification slide autofill with primary contact 
		if(this.isCompleteSlide && model[id] === undefined && this.primaryContactIndex > -1) {
			model[id] = [this.users[this.primaryContactIndex].email];
		}
	};

	@computed
	private get primaryContactIndex(): number {
		
		return this.users.findIndex(user => user.id === this.currentUser?.memberOrganisation?.primaryContactId);
	}

	public render() {
		const { title, model, id, isReadOnly, validators, toolTip, className, options, ...props } = this.props;

		// Default value set to undefined so there is no empty option 
		let optionsArr: IOpt[];

		if(process.env.JEST_WORKER_ID !== undefined && options.values) {
			// The code in this block is used only for JEST test
			optionsArr = options.values.map((el) => ({
				'display': el.display,
				'value': el.value,
				'isFixed': false,
			}));
		} else {
			optionsArr = this.users.map((user) => {
				// Since we are not sure which name information will be provided, 
				// allow for each combination of possibilities
				let displayName = '';
				if(user.firstname && user.firstname.length) {
					displayName = user.firstname;
				}
				if(user.surname && user.surname.length) {
					if(displayName.length) {
						displayName += ' '; 
					}
					displayName += user.surname;
				}
				if(!displayName.length) {
					// if no name has been provided, default to email
					displayName = user.email;
				}
				return {display: displayName, value: displayName, isFixed: false}
			}) ?? [];
			
			if(optionsArr.length) {
				// As per the comment in MEA-384, the customer doesn't see name collusions as being an issue
				// However, we do a quick check here to identify whether the issue exists
				let uniqueDisplayNames = new Set(optionsArr.map(opt => opt.display));
				if(uniqueDisplayNames.size < optionsArr.length) {
					console.warn(`${optionsArr.length - uniqueDisplayNames.size} name collusions exist`);
				}
			}

			// on notification slide make the primary contact autofill uneditable 
			if(this.isCompleteSlide && optionsArr.length && this.primaryContactIndex > -1) {
				
				optionsArr[this.primaryContactIndex].isFixed = true;
			}
		}

		return (
			<MultiCombobox
				model={model}
				modelProperty={id}
				label={title}
				tooltip={toolTip}
				className={className}
				options={optionsArr}
				isRequired={hasRequiredValidator(validators)}
				isDisabled={isReadOnly}
				inputProps={{
					multiple: true,
					header: 'Type to search by name'
				}}
			/>
		);
	}
}
