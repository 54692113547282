import { Validator } from '../Schema/Question';

export function ValidateMultiCombobox<T>(validator: Validator, model: T): string {
	const value = model[validator.path];
	switch (validator.validator) {
		case 'required':
			if (!(value) || (Array.isArray(value) && !value.length)) {
				return 'This field is required\n';
			}
			return '';
		default:
			return 'A validator on this field is not implemented\n';
	}
}