import {Button, Display} from "../Components/Button/Button";
import * as React from "react";
import {MultiCombobox} from "../Components/Combobox/MultiCombobox";
import {ComboboxOption} from "../Components/Combobox/Combobox";

interface FormAssignmentModalProps {
    formsSelected: { selectedForm: string[] };
    formOptions: ComboboxOption<string>[];

    onConfirm?: () => void;
    onCancel?: () => void;
}

export default function FormAssignmentModal(props: FormAssignmentModalProps) {

    const renderModalCombobox = () => {
        return (
            <MultiCombobox model={props.formsSelected}
                           modelProperty="selectedForm"
                           label="Select forms"
                           labelVisible = {false}
                           options={props.formOptions}
                           searchable={false}
            />
        )
    }
    
    
   return ( 
       <>
           <h4>Select Forms to Assign</h4>
           <p>What type of user would you like to create?</p>
           {renderModalCombobox()}
           <div key="actions" className="modal__actions">
               <Button key="confirm" onClick={props.onConfirm} display={Display.Solid}>Confirm</Button>
               <Button key="cancel" onClick={props.onCancel} display={Display.Outline}>Cancel</Button>
           </div>
       </>
   )
}