/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import { DocumentEntity } from 'Models/Entities';
import SecuredPage from 'Views/Components/Security/SecuredPage';
import EntityCRUD from 'Views/Components/CRUD/EntityCRUD';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router';
import { getFrontendNavLinks } from 'Views/FrontendNavLinks';
import Navigation, { Orientation } from 'Views/Components/Navigation/Navigation';

// % protected region % [Add any extra imports here] on begin
import {store} from "Models/Store";
import { action, autorun, observable } from "mobx";
import MeaDocument from "Views/CustomComponents/MeaDocument";
import { ScrollTabs, ITab } from 'Views/CustomComponents/ScrollTabs';
import {gql} from "@apollo/client";
import {DocumentTypesEntity} from 'Models/Entities';
import BreadcrumbNavigation from "../CustomComponents/BreadcrumbNavigation";
import {IBreadCrumbsTag} from "../Components/Breadcrumbs/Breadcrumbs";
// % protected region % [Add any extra imports here] end

export interface MeaDocumentsPageProps extends RouteComponentProps {
	// % protected region % [Add any extra props here] off begin
	// % protected region % [Add any extra props here] end
}

@observer
// % protected region % [Add any customisations to default class definition here] off begin
class MeaDocumentsPage extends React.Component<MeaDocumentsPageProps> {
// % protected region % [Add any customisations to default class definition here] end

	// % protected region % [Add class properties here] on begin
	@observable
	private tabs: ITab[] = [];

	@observable
	private currentTab: ITab | undefined = undefined;
	
	@observable
	private lastCrumb: IBreadCrumbsTag | undefined;

	componentDidMount() {
		
		autorun(() => {
			if (this.props.location.pathname) {
				this.fetchDocumentTypes();
			}
		});

		autorun(() => {
			if (this.props.location.pathname.includes("edit")
				|| this.props.location.pathname.includes("view")) {
				const listPathName = this.props.location.pathname.split('/');
				const id = listPathName[listPathName.length - 1];
				const mode = this.props.location.pathname.includes("edit") ? 'Edit' : 'View';
				store.apolloClient.query({
					query: this.getDocumentNameQuery,
					fetchPolicy: 'no-cache',
					variables: {id: id}
				}).then(action(result => {
					this.lastCrumb = {label: `${mode} ${result.data.documentEntity.documentName}`,
						link: this.props.location.pathname};
				}))
			} else {
				action(() => this.lastCrumb = undefined);
			}
		});
    }

	@action
	async fetchDocumentTypes() {
		const fetchDocumentTypes = gql`query getDocumentTypes {
			documentTypesEntitys(
				where: {path: "meaDocument", comparison: equal, value: "true" },
				orderBy: {path: "order"}
				has: [[{
					path: "documentss",
				}]]
			) 
				{
					name
					description
					order
					roless {
						roles {
							name
						}
					}
					documentss {
						id
						documents {
							id
							documentName
						}
					}
				}
			}`;
            
        store.apolloClient.query({
            query: fetchDocumentTypes,
            fetchPolicy: 'no-cache',
        }).then(action(result => {
			this.tabs = [];
            result.data.documentTypesEntitys.forEach((documentType : DocumentTypesEntity) => {
				this.tabs.push({
					name: documentType.name,
					key: encodeURIComponent(documentType.name),
					tooltip: documentType.description,
				});
            });

			// set current tab to expected default tab
			this.currentTab = this.tabs.length
				? this.tabs[0]
				: undefined;
        }));
	}

	private getDocumentNameQuery = gql`query getDocumentName($id: [String]) {
		documentEntity(where: {path: "id", comparison: equal, value: $id}) {
		  documentName
		}
	  }`;

	// % protected region % [Add class properties here] end

	render() {
		// % protected region % [Add logic before rendering contents here] off begin
		// % protected region % [Add logic before rendering contents here] end

		let contents = (
			<SecuredPage groups={['Super Administrators']}>
				{
				// % protected region % [Alter navigation here] off begin
				}
				<Navigation
					linkGroups={getFrontendNavLinks(this.props)}
					orientation={Orientation.VERTICAL}
					match={this.props.match}
					location={this.props.location}
					history={this.props.history}
					staticContext={this.props.staticContext}
				/>
				{
				// % protected region % [Alter navigation here] end
				}
				<div className="body-content">
					<EntityCRUD
						{...this.props}
						modelType={DocumentEntity}
						URLExtension="93c41b42-125a-41d1-8536-8bccf3eb0b55"
						// % protected region % [Add props to crud component 93c41b42-125a-41d1-8536-8bccf3eb0b55 here] off begin
						// % protected region % [Add props to crud component 93c41b42-125a-41d1-8536-8bccf3eb0b55 here] end
					/>
				</div>
			</SecuredPage>
		);

		// % protected region % [Override contents here] on begin
		contents = (
			<SecuredPage groups={['Super Administrators', 'Visitors', 'MehubAccount', 'MehubAdmin']}>
				<Navigation
					linkGroups={getFrontendNavLinks(this.props)}
					orientation={Orientation.HORIZONTAL}
					match={this.props.match}
					location={this.props.location}
					history={this.props.history}
					staticContext={this.props.staticContext}
					className={'hub-navigation'}
				/>
				<div className="body-content master-template-page">
					<section className="page-header-new">
						<div className="breadcrumb-button">
							<BreadcrumbNavigation lastCrumb={this.lastCrumb} />
						</div>
						<div className="page-controls">
							<div className="page-controls__title">
								<h2>MEA Documents</h2>
							</div>
						</div>
						<ScrollTabs
							defaultTabKey={this.tabs.length ? this.tabs[0].key : undefined}
							tabs={this.tabs}
							onTabClick={action((clickedTab: ITab) => { this.currentTab = clickedTab })}
						/>
					</section>
					<MeaDocument
						{...this.props} 
						removeEditAction={true}
						removeDeleteAction={true}
						createAction={() => undefined}
						currentTab={this.currentTab}
					/>
				</div>
			</SecuredPage>
		);
		// % protected region % [Override contents here] end

		return contents;
	}
}

// % protected region % [Override export here] off begin
export default MeaDocumentsPage;
// % protected region % [Override export here] end
