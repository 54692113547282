import * as React from 'react';
import { IAttributeProps } from 'Views/Components/CRUD/Attributes/IAttributeProps';
import { observer } from 'mobx-react';
import { DocumentEntity } from 'Models/Entities';
import { ButtonGroup } from 'Views/Components/Button/ButtonGroup';
import { Button, Colors, Display } from 'Views/Components/Button/Button';
import { runInAction } from 'mobx';
import { store } from 'Models/Store';

export interface PendingApprovalProps extends IAttributeProps<DocumentEntity> {

}

@observer
export default class PendingApproval extends React.Component<PendingApprovalProps> {

	constructor(props: PendingApprovalProps) {
		super(props);
	}


	private onApproveDocument = (event: React.MouseEvent<Element, MouseEvent>) => {
		const { model } = this.props;
        runInAction(() => {
            model.status = 'ACTIVE';
        });
        model.save().then(() => {
            store.routerHistory.goBack();
        });
	}

	private onRemoveDocument = (event: React.MouseEvent<Element, MouseEvent>) => {
		const { model } = this.props;
        model.delete().then(() => {
            store.routerHistory.goBack();
        });
	}

	public render() {
		const { model } = this.props;	

		const docPendingText = ['This document is pending approval an cannot be attached to documents until it is approved.', <br />,
		 'If a document is unapproved, it will automatically be removed from the list.'];

		const pendingDocumentsContent = (
            <div className="pending-documents-content">
				<div className="pending-documents">
					<p><strong>Document Pending</strong></p>
					<p>{docPendingText}</p>
					<ButtonGroup>
						<Button colors={Colors.Success} display={Display.Outline} onClick={this.onApproveDocument}>Approve Document</Button>
						<Button colors={Colors.Error} display={Display.Outline} onClick={this.onRemoveDocument}>Unapprove and Remove</Button>
					</ButtonGroup>
				</div>
            </div>
		)

		return model.status === "PENDING" ? pendingDocumentsContent : '';
	}
}