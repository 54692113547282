/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import SecuredPage from 'Views/Components/Security/SecuredPage';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router';
import { getFrontendNavLinks } from 'Views/FrontendNavLinks';
import Navigation, { Orientation } from 'Views/Components/Navigation/Navigation';

// % protected region % [Add any extra imports here] on begin
import ReactGA from 'react-ga';
import UpdatedSafetyTile from "../Tiles/UpdatedSafetyTile";
import OriginalSafetyTile from "../Tiles/OriginalSafetyTile";
import { store } from "../../Models/Store";
import { mehubUserRoleOptions } from "../../Models/Enums";
// % protected region % [Add any extra imports here] end

export interface MesafetyPageProps extends RouteComponentProps {
	// % protected region % [Add any extra props here] off begin
	// % protected region % [Add any extra props here] end
}

@observer
// % protected region % [Add any customisations to default class definition here] off begin
class MesafetyPage extends React.Component<MesafetyPageProps> {
// % protected region % [Add any customisations to default class definition here] end

	// % protected region % [Add class properties here] on begin
	public componentDidMount() {
		ReactGA.pageview(window.location.pathname);
	}
	
	public getUpdatedSafetyTile() {
		const {
			history, location, match, staticContext,
		} = this.props;

		return (<UpdatedSafetyTile
			history={history}
			location={location}
			match={match}
			staticContext={staticContext}
		/>);
	}

	public getOriginalSafetyTile() {
		const {
			history, location, match, staticContext,
		} = this.props;

		return (<OriginalSafetyTile
			history={history}
			location={location}
			match={match}
			staticContext={staticContext}
		/>);
	}

	public renderSafetyTile() {
		const originalMehubUserRoles = [
			mehubUserRoleOptions.PRO_MANAGER,
			mehubUserRoleOptions.PRO_WORKER
		];

		if (originalMehubUserRoles.includes(store.roleName as string)) {
			return this.getOriginalSafetyTile();
		}
		
		return this.getUpdatedSafetyTile();
	}
	
	// % protected region % [Add class properties here] end

	render() {
		// % protected region % [Add logic before rendering contents here] off begin
		// % protected region % [Add logic before rendering contents here] end

		let contents = (
			<SecuredPage groups={['Super Administrators', 'MehubAccount', 'MehubAdmin']}>
				{
				// % protected region % [Alter navigation here] off begin
				}
				<Navigation
					linkGroups={getFrontendNavLinks(this.props)}
					orientation={Orientation.VERTICAL}
					match={this.props.match}
					location={this.props.location}
					history={this.props.history}
					staticContext={this.props.staticContext}
				/>
				{
				// % protected region % [Alter navigation here] end
				}
				<div className="body-content">
				</div>
			</SecuredPage>
		);

		// % protected region % [Override contents here] on begin
		const {
			history, location, match, staticContext,
		} = this.props;

		const navigationLinks = getFrontendNavLinks(this.props);

		contents = (
			<SecuredPage groups={['Super Administrators', 'MehubAdmin', 'MehubAccount']}>
				<Navigation
					linkGroups={navigationLinks}
					orientation={Orientation.HORIZONTAL}
					match={match}
					location={location}
					history={history}
					staticContext={staticContext}
					className={'hub-navigation'}
				/>

				<div className="body-content">
					{this.renderSafetyTile()}
				</div>
			</SecuredPage>
		);
		// % protected region % [Override contents here] end

		return contents;
	}
}

// % protected region % [Override export here] off begin
export default MesafetyPage;
// % protected region % [Override export here] end
