import * as React from 'react';
import { observer } from 'mobx-react';
import { SERVER_URL } from '../../../Constants';
import { RouteComponentProps } from "react-router";
import queryString from "querystring";
import { store } from 'Models/Store';
import { SAML_CONFIG } from '../../../Constants';

@observer
export default class Logout_MEA extends React.Component<RouteComponentProps> {
	public componentDidMount() {
        const isAdmin = store?.userGroups?.some(u => u.name.indexOf('MehubAdmin') === 0);

        if(isAdmin) {
            const location = queryString.parse(this.props.location.search.substring(1))['redirect'] || undefined;
            let qry = `?redirect=${location ? `${location}` : '/'}`;
            window.location.href = `${SERVER_URL}/api/authorization/logout${qry}`;
        } else {
            window.location.href = SAML_CONFIG.samlSloUrl;
        }
	}

	public render() {
		return null;
	}
}