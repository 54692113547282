/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import moment from 'moment';
import { action, observable } from 'mobx';
import { Model, IModelAttributes, attribute, entity } from 'Models/Model';
import * as Models from 'Models/Entities';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from "Util/AttributeUtils";
import { IAcl } from 'Models/Security/IAcl';
import {
	makeEnumFetchFunction,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import { VisitorsReminderLogEntity } from 'Models/Security/Acl/VisitorsReminderLogEntity';
import { MehubAccountReminderLogEntity } from 'Models/Security/Acl/MehubAccountReminderLogEntity';
import { MehubAdminReminderLogEntity } from 'Models/Security/Acl/MehubAdminReminderLogEntity';
import * as Enums from '../Enums';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import {SuperAdministratorScheme} from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IReminderLogEntityAttributes extends IModelAttributes {
	reminderType: Enums.reminderTypes;
	triggerType: Enums.triggerTypes;
	triggerDate: Date;
	entityID: string;
	description: string;
	lastTriggerDate: Date;

	mehubAccountId?: string;
	mehubAccount?: Models.MehubAccountEntity | Models.IMehubAccountEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] on begin
	owner: IReminderLogOwner;
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] on begin
interface IReminderLogOwner {
	id: string;
	email: string;
	firstName: string | null; 
	surname: string | null;
}

@entity('ReminderLogEntity', 'Reminder Log')
// % protected region % [Customise your entity metadata here] end
export default class ReminderLogEntity extends Model implements IReminderLogEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsReminderLogEntity(),
		new MehubAccountReminderLogEntity(),
		new MehubAdminReminderLogEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Reminder Type'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'Reminder Type',
		displayType: 'enum-combobox',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.reminderTypesOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.reminderTypesOptions),
		displayFunction: (attribute: Enums.reminderTypes) => Enums.reminderTypesOptions[attribute],
	})
	public reminderType: Enums.reminderTypes;
	// % protected region % [Modify props to the crud options here for attribute 'Reminder Type'] end

	// % protected region % [Modify props to the crud options here for attribute 'Trigger Type'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'Trigger Type',
		displayType: 'enum-combobox',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.triggerTypesOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.triggerTypesOptions),
		displayFunction: (attribute: Enums.triggerTypes) => Enums.triggerTypesOptions[attribute],
	})
	public triggerType: Enums.triggerTypes;
	// % protected region % [Modify props to the crud options here for attribute 'Trigger Type'] end

	// % protected region % [Modify props to the crud options here for attribute 'Trigger Date'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'Trigger Date',
		displayType: 'datepicker',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseDate,
	})
	public triggerDate: Date;
	// % protected region % [Modify props to the crud options here for attribute 'Trigger Date'] end

	// % protected region % [Modify props to the crud options here for attribute 'Entity ID'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'Entity ID',
		displayType: 'textfield',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public entityID: string;
	// % protected region % [Modify props to the crud options here for attribute 'Entity ID'] end

	// % protected region % [Modify props to the crud options here for attribute 'Description'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'Description',
		displayType: 'textfield',
		order: 50,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public description: string;
	// % protected region % [Modify props to the crud options here for attribute 'Description'] end

	// % protected region % [Modify props to the crud options here for attribute 'Last Trigger Date'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'Last Trigger Date',
		displayType: 'datepicker',
		order: 60,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseDate,
	})
	public lastTriggerDate: Date;
	// % protected region % [Modify props to the crud options here for attribute 'Last Trigger Date'] end

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'MeHub Account'] off begin
		name: 'MeHub Account',
		displayType: 'reference-combobox',
		order: 70,
		referenceTypeFunc: () => Models.MehubAccountEntity,
		// % protected region % [Modify props to the crud options here for reference 'MeHub Account'] end
	})
	public mehubAccountId?: string;
	@observable
	@attribute({isReference: true, manyReference: false})
	public mehubAccount: Models.MehubAccountEntity;

	// % protected region % [Add any custom attributes to the model here] on begin
	@observable
	@CRUD({
		name: "User",
		displayType: 'hidden',
	})
	public owner: IReminderLogOwner;

	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IReminderLogEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IReminderLogEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.reminderType !== undefined) {
				this.reminderType = attributes.reminderType;
			}
			if (attributes.triggerType !== undefined) {
				this.triggerType = attributes.triggerType;
			}
			if (attributes.triggerDate !== undefined) {
				if (attributes.triggerDate === null) {
					this.triggerDate = attributes.triggerDate;
				} else {
					this.triggerDate = moment(attributes.triggerDate).toDate();
				}
			}
			if (attributes.entityID !== undefined) {
				this.entityID = attributes.entityID;
			}
			if (attributes.description !== undefined) {
				this.description = attributes.description;
			}
			if (attributes.lastTriggerDate !== undefined) {
				if (attributes.lastTriggerDate === null) {
					this.lastTriggerDate = attributes.lastTriggerDate;
				} else {
					this.lastTriggerDate = moment(attributes.lastTriggerDate).toDate();
				}
			}
			if (attributes.mehubAccountId !== undefined) {
				this.mehubAccountId = attributes.mehubAccountId;
			}
			if (attributes.mehubAccount !== undefined) {
				if (attributes.mehubAccount === null) {
					this.mehubAccount = attributes.mehubAccount;
				} else {
					if (attributes.mehubAccount instanceof Models.MehubAccountEntity) {
						this.mehubAccount = attributes.mehubAccount;
						this.mehubAccountId = attributes.mehubAccount.id;
					} else {
						this.mehubAccount = new Models.MehubAccountEntity(attributes.mehubAccount);
						this.mehubAccountId = this.mehubAccount.id;
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] on begin
			if(attributes.owner !== undefined) {
				this.owner = attributes.owner;
			}
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] on begin
	public defaultExpands = `
		mehubAccount {
			${Models.MehubAccountEntity.getAttributes().join('\n')}
		}
		owner {
			id
			email
			firstName
			surname
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath = {
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
						]
					},
				],
			}
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}


	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [ createdAttr, modifiedAttr ] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(ReminderLogEntity.prototype, 'created');
CRUD(modifiedAttr)(ReminderLogEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
