/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import { Model, IModelAttributes, attribute, entity } from 'Models/Model';
import * as Models from 'Models/Entities';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from "Util/AttributeUtils";
import { IAcl } from 'Models/Security/IAcl';
import {
	makeEnumFetchFunction,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import { VisitorsSearchesEntity } from 'Models/Security/Acl/VisitorsSearchesEntity';
import { MehubAccountSearchesEntity } from 'Models/Security/Acl/MehubAccountSearchesEntity';
import { MehubAdminSearchesEntity } from 'Models/Security/Acl/MehubAdminSearchesEntity';
import * as Enums from '../Enums';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import {SuperAdministratorScheme} from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface ISearchesEntityAttributes extends IModelAttributes {
	searchType: Enums.searchTypes;
	searchTerm: string;

	roleId?: string;
	role?: Models.RoleEntity | Models.IRoleEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('SearchesEntity', 'Searches')
// % protected region % [Customise your entity metadata here] end
export default class SearchesEntity extends Model implements ISearchesEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsSearchesEntity(),
		new MehubAccountSearchesEntity(),
		new MehubAdminSearchesEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Search Type'] off begin
	/**
	 * The type of the search term
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Search Type',
		displayType: 'enum-combobox',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.searchTypesOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.searchTypesOptions),
		displayFunction: (attribute: Enums.searchTypes) => Enums.searchTypesOptions[attribute],
	})
	public searchType: Enums.searchTypes;
	// % protected region % [Modify props to the crud options here for attribute 'Search Type'] end

	// % protected region % [Modify props to the crud options here for attribute 'Search Term'] off begin
	/**
	 * term the user used to search
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Search Term',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public searchTerm: string;
	// % protected region % [Modify props to the crud options here for attribute 'Search Term'] end

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'role'] on begin
		name: 'role',
		displayType: 'reference-combobox',
		order: 30,
		headerColumn: true,
		referenceTypeFunc: () => Models.RoleEntity,
		displayFunction: (attr, that) => (that as Models.SearchesEntity).role?.getDisplayName(),
		// % protected region % [Modify props to the crud options here for reference 'role'] end
	})
	public roleId?: string;
	@observable
	@attribute({isReference: true, manyReference: false})
	public role: Models.RoleEntity;

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<ISearchesEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<ISearchesEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.searchType !== undefined) {
				this.searchType = attributes.searchType;
			}
			if (attributes.searchTerm !== undefined) {
				this.searchTerm = attributes.searchTerm;
			}
			if (attributes.roleId !== undefined) {
				this.roleId = attributes.roleId;
			}
			if (attributes.role !== undefined) {
				if (attributes.role === null) {
					this.role = attributes.role;
				} else {
					if (attributes.role instanceof Models.RoleEntity) {
						this.role = attributes.role;
						this.roleId = attributes.role.id;
					} else {
						this.role = new Models.RoleEntity(attributes.role);
						this.roleId = this.role.id;
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		role {
			${Models.RoleEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath = {
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
						]
					},
				],
			}
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}


	// % protected region % [Add any further custom model features here] on begin
	public listExpands = `
		role {
			${Models.RoleEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [ createdAttr, modifiedAttr ] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(SearchesEntity.prototype, 'created');
CRUD(modifiedAttr)(SearchesEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
