import React from 'react';
import { observer } from 'mobx-react';
import * as Models from '../../Models/Entities';
import { runInAction, observable, action } from 'mobx';
import { store } from 'Models/Store';
import If from 'Views/Components/If/If';
import momentZ from 'moment-timezone';

@observer
export default class NewsTicker extends React.Component {
	@observable
	private news?: Models.NewsEntity;

	public componentDidMount() {
		Models.NewsEntity
				.fetch<Models.NewsEntity>(
					{},
					`
					notice
					`)
				.then((data: Models.NewsEntity[]) => {
					runInAction(() => {
						if (localStorage.getItem('newsContent') !== data[0].notice) {
							localStorage.setItem('displayNewsTicker', 'true');
							localStorage.setItem('newsContent', data[0].notice);
							store.displayNewsTicker = true;
						}
						this.news = data[0];
					})
				})
				.catch((e: any) => {
					runInAction(() => {
						this.news = undefined;
					});
				});
	}

	public render() {
		let notice = this.news?.notice.replace(/(<a href=")?((https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)))(">(.*)<\/a>)?/gi, function () {
			return '<a target="_blank" href="' + arguments[2] + '">' + (arguments[7] || arguments[2]) + '</a>'
		});

		return (
			<If condition={!!this.news?.notice && store.displayNewsTicker}>
				<section className="news-ticker">
					<div className="page-controls">
						<div className="updated-date page-controls__title">
							<h4>
								{(this.news?.modified && this.news?.showtime)? momentZ(this.news?.modified).tz(momentZ.tz.guess()).format('dddd, Do MMMM YYYY') : ""}
							</h4>
						</div>
						<div className="news-content page-controls__title">
							<h4 dangerouslySetInnerHTML={{__html: notice || ""}} />
						</div>
						<div className="page-controls__hide-button">
							<button className="btn btn--text" onClick={this.onHideNewsTicker}>Hide</button>
						</div>
					</div>

				</section>


			</If>

		);
	}

	@action
	private onHideNewsTicker = () => {
		store.displayNewsTicker = false;
		localStorage.setItem('displayNewsTicker', 'false');
	}
}