import * as React from 'react';
import Axios from 'axios';
import { IAttributeProps } from 'Views/Components/CRUD/Attributes/IAttributeProps';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import { SERVER_URL } from 'Constants';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import { FilePreviewProps, FileUploadPreview, PreviewProps, UploadPreview } from 'Views/Components/FileUpload/UploadPreview';
import { AssetAttachmentsEntity, AssetEntity, LicenceAttachmentsEntity, LicenceEntity } from 'Models/Entities';
import MultiFileUpload from './MultiFileUpload';
import Spinner from '../Spinner/Spinner';

export interface LicenceAttachmentViewEditProps extends IAttributeProps<LicenceEntity> {
	fileAttribute: string;
	imageOnly?: boolean;
}

interface FileMetadata {
	id: string;
	created: string;
	modified: string;
	fileName: string;
	contentType: string;
	length: number;
}

@observer
export default class LicenceAttachmentViewEdit extends React.Component<LicenceAttachmentViewEditProps> {

	@observable
	loadingState: 'loading' | 'error' | 'data' = 'loading';

	@observable
	protected fileMetadata: Record<string, FileMetadata> = {};

	@observable
	errors: any = undefined;

	@action
	protected onAfterDelete = () => {
		this.props.model[this.props.options.attributeName] = undefined;
	};

	constructor(props: LicenceAttachmentViewEditProps) {
		super(props);
	}

	public componentWillMount() {
		// For view or edit mode load the initial file from the server
		// [MEASD-116] for the create mode as there is no data to load, manually change loading state to data so spinner stops rendering
		switch (this.props.formMode) {
			case EntityFormMode.VIEW:
			case EntityFormMode.EDIT:
				const attachments = this.props.model.licenceAttachmentss;
				const requests = attachments
                    .filter(x => x.attachmentsId !== undefined)
					.map(attachment => Axios.get(`${SERVER_URL}/api/files/metadata/${attachment.attachmentsId}`));
				Promise.all(requests)
					.then(result => result.map(x => x.data))
					.then(this.setData)
					.catch(action(e => {
						this.errors = e;
						this.loadingState = 'error';
					}));
				break;
			case EntityFormMode.CREATE: 
				this.loadingState = 'data';
		}
	}

	@action
	private setData = (data: FileMetadata[]) => {
		for (const datum of data) {
			this.fileMetadata[datum.id] = datum;
		}
		this.loadingState = 'data'
	};

	@action
	deleteAttachment = (attachment: LicenceAttachmentsEntity) => {
		const idx = this.props.model.licenceAttachmentss.indexOf(attachment);
		this.props.model.licenceAttachmentss.splice(idx, 1);
	}

	public render() {
		const {
			fileAttribute,
			isReadonly,
			imageOnly,
			model,
			isRequired,
			onAfterChange,
			className,
			errors,
			options,
			formMode
		} = this.props;

		if (this.loadingState === 'loading') {
			return <Spinner />;
		}

		if (this.loadingState === 'error') {
			return JSON.stringify(this.errors);
		}		

		return <div>
			<MultiFileUpload
				model={model}
				modelProperty="licenceAttachmentss"
				fileModel={LicenceAttachmentsEntity}
				fileModelProperty="attachments"
				isReadOnly={isReadonly}
				disableDropArea
				label={options.displayName}
				maxFileUploadSize={20}
				preview={(id, file, onDelete) => {
					const attachment = model.licenceAttachmentss.find(x => x._clientId === id);
					if (attachment?.attachments) {
						let fileName = 'Unnamed File';
						if (attachment.attachments instanceof File) {
							fileName = attachment.attachments.name;
						}

						const props: FilePreviewProps = {
							fileBlob: attachment.attachments,
							fileName: fileName,
							download: true,
						};
	
						if (formMode !== EntityFormMode.VIEW && typeof onDelete === 'function') {
							props.onDelete = onDelete;
						}
						return (
							<div>
								<FileUploadPreview key={attachment.id} {...props} />
							</div>
						);
					}
	
					if (attachment?.attachmentsId) {
						const props: PreviewProps = {
							download: true,
							fileUrl:`${SERVER_URL}/api/files/${attachment.attachmentsId}?download=true`,
							imagePreview: false,
							fileName: this.fileMetadata[attachment.attachmentsId].fileName,
						}

						if (formMode !== EntityFormMode.VIEW && typeof onDelete === 'function') {
							props.onDelete = onDelete;
						}
						return (
							<UploadPreview key={attachment.id} {...props}/>
						);
					}
	
					return <></>;
				}}
			/>
		</div>
	} 
}