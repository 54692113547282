import * as React from "react";
import cn from 'classnames';
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { Button } from 'Views/Components/Button/Button';
import { Tooltip } from 'Views/Components/Tooltip/Tooltip';


export interface ITab {
	key: string;
	name: string;
	tooltip?: string;
}

interface IScrollTabsProps {
	defaultTabKey?: string,
	tabs: ITab[],
	onTabClick: (tab: ITab) => any
}

const LeftArrow = () => {
	const {
		getPrevItem,
		isFirstItemVisible,
		scrollToItem,
		visibleItemsWithoutSeparators,
		initComplete,
		getItemByIndex,
	} = React.useContext(VisibilityContext);

	const [hidden, setHidden] = React.useState(
		!initComplete || (initComplete && isFirstItemVisible)
	);
	React.useEffect(() => {
		if (visibleItemsWithoutSeparators.length) {
			setHidden(isFirstItemVisible);
		}
	}, [isFirstItemVisible, visibleItemsWithoutSeparators]);

	const clickHandler = () => {
		const prevItem = getPrevItem();
		if (prevItem) {
			const prevIndex = Number.parseInt(prevItem?.index) + visibleItemsWithoutSeparators.length - 2; // scroll 2 unless to end
			const itemTwoPast = getItemByIndex(prevIndex);

			scrollToItem(
				itemTwoPast?.entry.target,
				"smooth",
				"end"
			  );
		}
	};

	return (
		<Button
			onClick={clickHandler}
			className={cn('tab-nav-btn', 'left', { hidden, visible: !hidden })}
		/>
	)
}

const RightArrow = () => {
	const {
		getNextItem,
		isLastItemVisible,
		scrollToItem,
		visibleItemsWithoutSeparators,
		getItemByIndex,
		isLastItem,
	} = React.useContext(VisibilityContext);

	const [hidden, setHidden] = React.useState(
		!visibleItemsWithoutSeparators.length && isLastItemVisible
	);

	React.useEffect(() => {
		if (visibleItemsWithoutSeparators.length) {
			setHidden(isLastItemVisible);
		}
	}, [isLastItemVisible, visibleItemsWithoutSeparators]);

	const clickHandler = () => {
		const nextItem = getNextItem();
		if (nextItem) {
			const isLast = isLastItem(nextItem.key);
			const nextIndex = Number.parseInt(nextItem?.index) + (isLast ? 0 : 1); // scroll 2 unless to end
			const itemTwoOver = getItemByIndex(nextIndex);

			scrollToItem(
				itemTwoOver?.entry.target,
				"smooth",
				"end"
			  );
		}
	};

	return (
		<Button
			onClick={clickHandler}
			className={cn('tab-nav-btn', 'right', { hidden, visible: !hidden })}
		/>
	)
}

interface ITabProps {
	itemId: string;
	name: string,
	tooltip?: string,
	active: boolean,
	onClick: (itemId: string, event: React.MouseEvent) => void
}

const Tab = ({ itemId, name, tooltip, active = false, onClick }: ITabProps) => (
	<div
		role="button"
		tabIndex={0}
		className={cn('scrollable-tab', { active })}
		onClick={(e) => onClick(itemId, e)}
	>
		<h4>{name}{tooltip ? <Tooltip id={itemId} content={tooltip}></Tooltip> : null}</h4>
	</div>
)

export const ScrollTabs = (props: IScrollTabsProps) => {
	const [currentTab, setCurrentTab] = React.useState(props.defaultTabKey || "");

	React.useEffect(() => {
		if (props.defaultTabKey && currentTab === "") setCurrentTab(props.defaultTabKey);
	}, [props.defaultTabKey, props.tabs]);

	return (
		<div className="scroll-tabs">
			<ScrollMenu
				LeftArrow={LeftArrow}
				RightArrow={RightArrow}
				options={{
					ratio: 0.9,
					rootMargin: "5px",
					threshold: [0.01, 0.05, 0.5, 0.75, 0.95, 1]
				}}
			>
				{props.tabs.map(t => (
					<Tab
						key={t.key}
						itemId={t.key}
						name={t.name}
						tooltip={t.tooltip}
						active={t.key === currentTab}
						onClick={(itemId) => {
							setCurrentTab(itemId);
							props.onTabClick(props.tabs.find(t => t.key === itemId)!);
						}}
					/>
				))}
			</ScrollMenu>
		</div>
	);
}

export default ScrollTabs;