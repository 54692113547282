/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import { Model, IModelAttributes, attribute, entity } from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from "Util/AttributeUtils";
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchManyToManyFunc,
	makeJoinEqualsFunc,
	makeFetchOneToManyFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import { VisitorsMemberOrganisationEntity } from 'Models/Security/Acl/VisitorsMemberOrganisationEntity';
import { MehubAccountMemberOrganisationEntity } from 'Models/Security/Acl/MehubAccountMemberOrganisationEntity';
import { MehubAdminMemberOrganisationEntity } from 'Models/Security/Acl/MehubAdminMemberOrganisationEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import {SuperAdministratorScheme} from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] on begin
import {store} from "../Store";
import gql from "graphql-tag";
// % protected region % [Add any further imports here] end

export interface IMemberOrganisationEntityAttributes extends IModelAttributes {
	name: string;
	memberCode: string;
	isActive: boolean;

	apikeyss: Array<Models.ApikeyEntity | Models.IApikeyEntityAttributes>;
	assetss: Array<Models.AssetEntity | Models.IAssetEntityAttributes>;
	documentss: Array<Models.DocumentEntity | Models.IDocumentEntityAttributes>;
	mehubAccountss: Array<Models.MehubAccountEntity | Models.IMehubAccountEntityAttributes>;
	primaryContactId?: string;
	primaryContact?: Models.MehubAccountEntity | Models.IMehubAccountEntityAttributes;
	formss: Array<Models.FormsMemberOrganisations | Models.IFormsMemberOrganisationsAttributes>;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('MemberOrganisationEntity', 'Member Organisation')
// % protected region % [Customise your entity metadata here] end
export default class MemberOrganisationEntity extends Model implements IMemberOrganisationEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsMemberOrganisationEntity(),
		new MehubAccountMemberOrganisationEntity(),
		new MehubAdminMemberOrganisationEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Name'] off begin
	/**
	 * The name of the orginisation
	 */
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'Name',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public name: string;
	// % protected region % [Modify props to the crud options here for attribute 'Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Member Code'] off begin
	/**
	 * Identifies the organisation in the memnet system
	 */
	@Validators.Unique()
	@observable
	@attribute()
	@CRUD({
		name: 'Member Code',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public memberCode: string;
	// % protected region % [Modify props to the crud options here for attribute 'Member Code'] end

	// % protected region % [Modify props to the crud options here for attribute 'Is Active'] off begin
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'Is Active',
		displayType: 'checkbox',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public isActive: boolean = false;
	// % protected region % [Modify props to the crud options here for attribute 'Is Active'] end

	/**
	 * The Api keys belonging to an organisation
	 */
	@observable
	@attribute({isReference: true, manyReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'ApiKeys'] off begin
		name: "ApiKeys",
		displayType: 'reference-multicombobox',
		order: 40,
		referenceTypeFunc: () => Models.ApikeyEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'apikeyss',
			oppositeEntity: () => Models.ApikeyEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'ApiKeys'] end
	})
	public apikeyss: Models.ApikeyEntity[] = [];

	@observable
	@attribute({isReference: true, manyReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Assets'] on begin
		name: "Assets",
		displayType: 'reference-multicombobox',
		order: 40,
		referenceTypeFunc: () => Models.AssetEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'assetss',
			oppositeEntity: () => Models.AssetEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Assets'] end
	})
	public assetss: Models.AssetEntity[] = [];

	@observable
	@attribute({isReference: true, manyReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Documents'] on begin
		name: "Documents",
		displayType: 'reference-multicombobox',
		order: 50,
		referenceTypeFunc: () => Models.DocumentEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'documentss',
			oppositeEntity: () => Models.DocumentEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Documents'] end
	})
	public documentss: Models.DocumentEntity[] = [];

	@observable
	@attribute({isReference: true, manyReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'MeHub Accounts'] on begin
		name: "MeHub Accounts",
		displayType: 'reference-multicombobox',
		order: 50,
		referenceTypeFunc: () => Models.MehubAccountEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'mehubAccountss',
			oppositeEntity: () => Models.MehubAccountEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'MeHub Accounts'] end
	})
	public mehubAccountss: Models.MehubAccountEntity[] = [];

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Primary Contact'] off begin
		name: 'Primary Contact',
		displayType: 'reference-combobox',
		order: 80,
		referenceTypeFunc: () => Models.MehubAccountEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'primaryContacts',
			oppositeEntity: () => Models.MehubAccountEntity,
		})
		// % protected region % [Modify props to the crud options here for reference 'Primary Contact'] end
	})
	public primaryContactId?: string;
	@observable
	@attribute({isReference: true, manyReference: false})
	public primaryContact: Models.MehubAccountEntity;

	/**
	 * One form can be assigned to many organisations
	 */
	@observable
	@attribute({isReference: true, manyReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Forms'] on begin
		name: 'Related Forms',
		displayType: 'reference-multicombobox',
		order: 60,
		isJoinEntity: true,
		headerColumn: true,
		displayFunction: (attribute) => {
			return attribute.length;
		},
		sortable: false,
		referenceTypeFunc: () => Models.FormsMemberOrganisations,
		optionEqualFunc: makeJoinEqualsFunc('formsId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'memberOrganisationEntity',
			oppositeEntityName: 'formsEntity',
			relationName: 'memberOrganisations',
			relationOppositeName: 'forms',
			entity: () => Models.MemberOrganisationEntity,
			joinEntity: () => Models.FormsMemberOrganisations,
			oppositeEntity: () => Models.FormsEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Forms'] end
	})
	public formss: Models.FormsMemberOrganisations[] = [];

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IMemberOrganisationEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IMemberOrganisationEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.name !== undefined) {
				this.name = attributes.name;
			}
			if (attributes.memberCode !== undefined) {
				this.memberCode = attributes.memberCode;
			}
			if (attributes.isActive !== undefined) {
				this.isActive = attributes.isActive;
			}
			if (attributes.apikeyss !== undefined && Array.isArray(attributes.apikeyss)) {
				for (const model of attributes.apikeyss) {
					if (model instanceof Models.ApikeyEntity) {
						this.apikeyss.push(model);
					} else {
						this.apikeyss.push(new Models.ApikeyEntity(model));
					}
				}
			}
			if (attributes.assetss !== undefined && Array.isArray(attributes.assetss)) {
				for (const model of attributes.assetss) {
					if (model instanceof Models.AssetEntity) {
						this.assetss.push(model);
					} else {
						this.assetss.push(new Models.AssetEntity(model));
					}
				}
			}
			if (attributes.documentss !== undefined && Array.isArray(attributes.documentss)) {
				for (const model of attributes.documentss) {
					if (model instanceof Models.DocumentEntity) {
						this.documentss.push(model);
					} else {
						this.documentss.push(new Models.DocumentEntity(model));
					}
				}
			}
			if (attributes.mehubAccountss !== undefined && Array.isArray(attributes.mehubAccountss)) {
				for (const model of attributes.mehubAccountss) {
					if (model instanceof Models.MehubAccountEntity) {
						this.mehubAccountss.push(model);
					} else {
						this.mehubAccountss.push(new Models.MehubAccountEntity(model));
					}
				}
			}
			if (attributes.primaryContactId !== undefined) {
				this.primaryContactId = attributes.primaryContactId;
			}
			if (attributes.primaryContact !== undefined) {
				if (attributes.primaryContact === null) {
					this.primaryContact = attributes.primaryContact;
				} else {
					if (attributes.primaryContact instanceof Models.MehubAccountEntity) {
						this.primaryContact = attributes.primaryContact;
						this.primaryContactId = attributes.primaryContact.id;
					} else {
						this.primaryContact = new Models.MehubAccountEntity(attributes.primaryContact);
						this.primaryContactId = this.primaryContact.id;
					}
				}
			}
			if (attributes.formss !== undefined && Array.isArray(attributes.formss)) {
				for (const model of attributes.formss) {
					if (model instanceof Models.FormsMemberOrganisations) {
						this.formss.push(model);
					} else {
						this.formss.push(new Models.FormsMemberOrganisations(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] on begin
	public defaultExpands = `
		formss {
			id
				forms{
					id
					name
				}
		}
		apikeyss {
			id
			name
		}
		assetss {
			id
			description
		}
		documentss {
			id
			documentName
		}
		mehubAccountss {
			id
			firstname
			surname
			email
		}
		primaryContact {
			id
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath = {
			formss: {},
			apikeyss: {},
			assetss: {},
			documentss: {},
			mehubAccountss: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'assetss',
							'documentss',
							'mehubAccountss',
							'primaryContact',
							'formss',
						]
					},
				],
			}
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] on begin
		return this.name;
		// % protected region % [Customise the display name for this entity] end
	}


	// % protected region % [Add any further custom model features here] on begin
	public listExpands = `
		formss {
			${Models.FormsMemberOrganisations.getAttributes().join('\n')}
		}
	`;

	public static async fetchMemberOrganisation(ids: string[] | undefined): Promise<MemberOrganisationEntity[]> {
		const fetchQuery = ids ? gql`query fetchMember ($id: [String]) {
			  memberOrganisationEntitys(where: {path: "id", comparison: in, value: $id}) {
			    id
			    name
				formss {
				  formsId
				}
			  }
			}` : gql`query {
				memberOrganisationEntitys {
					id
					name
					formss {
					  formsId
					}
			    }
			}`;
		const { data } = await store.apolloClient.query({
			query: fetchQuery,
			variables: ids ? {"id": ids} : {},
			fetchPolicy: 'network-only',
		});
		return data["memberOrganisationEntitys"].map((r: any) => new this(r));
	}
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [ createdAttr, modifiedAttr ] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(MemberOrganisationEntity.prototype, 'created');
CRUD(modifiedAttr)(MemberOrganisationEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
