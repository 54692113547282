import * as React from 'react';
import {observer} from 'mobx-react';
import {RouteComponentProps} from 'react-router';
import {store} from 'Models/Store';
import {action, observable} from "mobx";
import * as Enums from '../../Models/Enums';
import axios from 'axios';
import {FeedbackAndHelpEntity} from "../../Models/Entities";
import alert from "../../Util/ToastifyUtils";
import {alertModal} from "../Components/Modal/ModalUtils";
import classNames from "classnames";
import BreadcrumbNavigation from "../CustomComponents/BreadcrumbNavigation";

interface IContactUsState {
    contactType?: Enums.category;
    information: string;
}

const defaultContactUsState: IContactUsState = {
    contactType: undefined,
    information: ''
};

@observer
export default class ContactUsTile extends React.Component<RouteComponentProps> {

    @observable
    private contactUsState: IContactUsState = defaultContactUsState;

    @action
    updateInformation = (value: string) => {
        this.contactUsState.information = value;
    }

    @action
    clearInformation = () => {
        this.contactUsState.information = '';
    }

    @action
    private setContactType = (newContactType: IContactUsState['contactType']) => {
		this.contactUsState.contactType = newContactType;
	}


    public render() {
        const {contactType} = this.contactUsState;
        
        const isHelp = contactType === 'HELP'; 
        const isFeedback = contactType === 'FEEDBACK'; 

        return (
            <section className="bisected">
                <form className="contact-form-area" onSubmit={this.handleSubmit}>
                    <div className="contact-form-area__header">
                        <div className="breadcrumb-button dark">
                            <BreadcrumbNavigation />
                        </div>
                        <div className="page-controls">
                            <h2>Help and Feedback</h2>
                        </div>
                    </div>

                    <div className="contact-form-area__body">
                        <div className="contact-form-area__text">
                            <p>If you can’t find what you are looking for or you’d like to 
                                provide feedback, help is only a click away.</p>
                        </div>

                        <div className="btn-toolbar flex-wrap">
                            <button className={classNames('btn btn--outline', {'active': contactType === 'HELP'})}
                                    type="button" onClick={() => this.setContactType('HELP')}>I need help</button>
                            <button className={classNames('btn btn--outline', {'active': contactType === 'FEEDBACK'})}
                                    type="button" onClick={() => this.setContactType('FEEDBACK')}>Feedback on ME Hub</button>
                        </div>

                        {isHelp ? this.renderHelpForm() : isFeedback ? this.renderFeedbackForm() : null}
                    </div>
                </form>

                <div className={classNames('img-container', isHelp ? 'img-help' : isFeedback ? 'img-feedback' : 'img-help')}>
                    <div className='img-area' />
                </div>
            </section>
        );
    }

    private renderHelpForm() {
        const {information} = this.contactUsState;

        return (
            <div className="contact-form-area__msg">
                <div className="contact-form-area__text">
                    <p>Please complete the field below and hit submit, we will get back to you ASAP or
                        call us on <a href="tel:1300 889 198">1300 889 198</a>.</p>
                </div>
                <label htmlFor="information">I need help with...</label>
                <textarea required id="information" name="information" value={information}
                          onChange={(e) => this.updateInformation(e.target.value)}
                          placeholder="Start typing something here" />
                <button type="submit" className="btn btn--solid btn--primary">Submit</button>
            </div>
        );
    }

    private renderFeedbackForm() {
        const { information } = this.contactUsState;

        return (
            <div className="contact-form-area__msg">
                <div className="contact-form-area__text">
                    <p>We want to keep improving this site. Please let us know:</p>
                    <ul>
                        <li>Was it easy to find what you were looking for?</li>
                        <li>What’s missing / what can we improve?</li>
                    </ul>
                </div>
                <label htmlFor="information">I suggest that...</label>
                <textarea required id="information" name="information" value={information}
                          onChange={(e) => this.updateInformation(e.target.value)}
                          placeholder="Start typing something here" />
                <button type="submit" className="btn btn--solid btn--primary">Submit</button>
            </div>
        );
    }

    private handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const {contactType, information} = this.contactUsState;

        axios.post('/api/entity/FeedbackAndHelpEntity', new FeedbackAndHelpEntity({
            category: contactType,
            email: store.email || '',
            information: information
        }))
            .then(res => {
                const message = contactType === "HELP" ?
                    "Your message has been sent. We’ll be in touch asap!" :
                    "Thanks for providing your thoughts. All feedback is appreciated.";

				alertModal('Submission successful', message).then(() => (this.clearInformation()));
            })
            .catch(err => {
                alert(err.message, 'error', {autoClose: false});
            });
    }
}