import * as React from 'react';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import If from "../If/If";
import {FocusEvent} from 'react'


export interface IStepIndicatorNodeProps {
	stepName: string;
	stepNumber: number;
	numberOfSteps: number;
	isCurrentStep: boolean;
	isStepCompleted: boolean;
	stepChange: Function;
}

export interface IStepIndicatorProps {
	className?: string;
	stepNames: string[],
	currentStep: number,
	stepChange: Function,
}

// @observer 
// export function stepProgress {
// 	return (
// 		<div className={`toggleSlides ${this.toggleSlideView ? "icon-chevron-up" : "icon-chevron-down"} ${numberOfSteps <= 1 ? "hidden" : ""}`} onClick={this.handleSlideCollapse}>
// 			{stepNumber+"/"+numberOfSteps}
// 		</div>
// 	)
// }


@observer
export class StepIndicator extends React.Component<IStepIndicatorProps, any> {

	@observable
	private toggleSlideView: boolean = false;

	private getStepNode = (node: IStepIndicatorNodeProps) => {
		const {
			stepName,
			stepNumber,
			numberOfSteps,
			isCurrentStep,
			isStepCompleted,
			stepChange,
		} = node;

		return (
			<React.Fragment key={`${stepNumber}-fragment`}>
				<li className={classNames('step', { 'active': isCurrentStep, 'inactive': !isCurrentStep && !this.toggleSlideView, 'completed': isStepCompleted })}
					key={`${stepNumber}-node`} onMouseDown={()=>stepChange(stepNumber)} onTouchStart={()=>stepChange(stepNumber)} onFocus={() => this.handleSlideCollapse(true)} onBlur={() => this.handleSlideCollapse(false)} tabIndex={1}>
					<div className={classNames('node')} />
					<p>{stepName}</p>
					<If condition={isCurrentStep}>
						<div className={`toggleSlides ${this.toggleSlideView ? "icon-chevron-up" : "icon-chevron-down"} ${numberOfSteps <= 1 ? "hidden" : ""}`} onTouchStart={() => this.handleSlideCollapse(!this.toggleSlideView)}>
							{stepNumber+"/"+numberOfSteps}
						</div>	
					</If>
				</li>
				<If condition={stepNumber < numberOfSteps}>
					<li key={`${stepNumber}-divider`}>
						<div className={classNames('divider', { 'active': isCurrentStep, 'hidden': !this.toggleSlideView, 'completed': isStepCompleted })}/>
					</li>
				</If>
			</React.Fragment>
		);
	}

	render() {
		const { className, stepNames, currentStep, stepChange } = this.props;
		var selectedStepName = [];
		let numberOfSteps = stepNames.length;

		if(this.toggleSlideView == false) {
			selectedStepName = [stepNames[currentStep-1]];
		}
		else {
			selectedStepName = stepNames;
		}
		return (
			<ul className={classNames('step-indicator', className)}>
				{
					selectedStepName.map((stepName, index) => {
						var isCurrentStep = (index + 1) === currentStep;
						var isStepCompleted = currentStep > (index + 1);
						var stepNum = (index + 1);
						
						if(selectedStepName.length <= 1) {
							isCurrentStep = true;
							stepNum = currentStep;
						}
						return this.getStepNode({
							stepName,
							stepNumber: stepNum,
							numberOfSteps,
							isCurrentStep,
							isStepCompleted,
							stepChange,
						});
					})
				}
			</ul>
		);		
	}

	@action
	private handleSlideCollapse = (toggleState: boolean) => {
		this.toggleSlideView = toggleState;
	};

}
