import axios from "axios";
import {SERVER_URL} from "../Constants";
import { store } from 'Models/Store'
import { gql } from "@apollo/client";
import { MehubAccountEntity } from "Models/Entities";
import alertToast from "./ToastifyUtils";

export const exportPDF = (mode: 'includedIds' | 'excludedIds', ids: string[]) => {
    var timezoneId = encodeURIComponent(Intl.DateTimeFormat().resolvedOptions().timeZone);
                        
    ids.forEach(id => 
        axios.get(`${SERVER_URL}/api/entity/FormSubmissionsEntity/pdf/${id}/${timezoneId}`, { responseType: "blob" })
            .then(({ data }) => {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Form Submission.pdf');
                link.click();
            })
    );
};

/** For use as additionalBulkAction in EntityCRUD */
export const exportUserPDF = (mode: 'includedIds' | 'excludedIds', ids: string[]) => {
    if (mode === 'includedIds') {
        exportUserPdfs(ids);
    }
};

/**
 * Exports 0 or more user pdfs
 * @param ids MeHubAccountEntity ids
 */
export const exportUserPdfs = async (ids: string[]) => {
    try {
        const mehubAccounts = (await store.apolloClient
            .query({
                query: gql`
                    query getMeHubAccounts($ids: [ID]) {
                        mehubAccountEntitys(ids: $ids) {
                            id
                            created
                            modified
                            firstname
                            surname
                            email
                        }
                    }
                `,
                variables: { ids },
                fetchPolicy: 'network-only',
            }))
            .data['mehubAccountEntitys']
            .map((data: any) => new MehubAccountEntity(data)) as MehubAccountEntity[];
            
        mehubAccounts.forEach(account => {
            axios.get(`${SERVER_URL}/api/entity/MehubAccountEntity/pdf/${account.id}`, {responseType: "blob"})
            .then(({ data }) => {
                const fileName = `${account.getDisplayName()}.pdf`
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                link.click();
            });
        });
    } catch (err) {
        console.error(err);
        alertToast('Unable to retrieve pdf for user', 'error');
    }
}
