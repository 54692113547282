import React from "react";
import { AssetAttachmentsEntity, AssetEntity } from "../../Models/Entities";
import { Button, Colors, Display, Sizes } from "../Components/Button/Button";
import { EntityFormMode } from "../Components/Helpers/Common";
import { getAttributeComponent } from "../Components/CRUD/Attributes/AttributeFactory";
import { isRequired } from "../../Util/EntityUtils";
import { observer } from "mobx-react";
import { observable } from "mobx";
import alert from "../../Util/ToastifyUtils";
import { store } from "../../Models/Store";
import { SERVER_URL } from "../../Constants";
import MultiFileUpload from "../Components/FileUpload/MultiFileUpload";

export interface NewAssetProps {
    asset?: AssetEntity;
    organisationId: string;
}

@observer
export default class NewAsset extends React.Component<NewAssetProps> {
    
    @observable
    private assetEntity = this.props.asset ?? new AssetEntity({
        inspectionFrequency: "THREE_MONTHS",
        memberOrganisationId: this.props.organisationId,
        status: "IN_SERVICE",
    });

    private renderBasicAssetDetails() {
        const fields = [
            'assetType',
            'assetID',
            'brand',
            'assetModel',
            'serialNumber',
            'description',
        ];
        return this.createFormFields(fields);
    }
    
    private renderAssetStatusDetails() {
        const fields = [
            'status',
            'registration',
            'serviceDueDate',
            'registrationRenewalDate',
        ];
        return this.createFormFields(fields);
    }
    
    private renderAssetAssignee() {
        const fields = [
            'mehubAccountss',
        ];
        return this.createFormFields(fields);
    }
    
    private renderAssetInspectionDetails() {
        const fields = [
            'inspectionFrequency',
            'lastInspection',
            'nextInspection',
        ];
        return this.createFormFields(fields);
    }
    
    private renderAssetNotificationDetails() {
        const fields = [
            'reminderNotification',
            'secondReminder',
        ];
        return this.createFormFields(fields);
    }
    
    private renderAssetComments() {
        const fields = [
         'comments',
        ];
        return this.createFormFields(fields);
    }
    
    private renderLinkAssets() {
        const fields = [
            'parentAssetId',
            'assetss',
        ]
        return this.createFormFields(fields);
    }
    
    private createFormFields(fields: string[]) {
        const model = this.assetEntity;

        return this.assetEntity.getAttributeCRUDOptions()
            .filter(x => fields.indexOf(x.attributeName) > -1)
            .sort((a, b) =>
                fields.indexOf(a.attributeName) - fields.indexOf(b.attributeName))
            .map(attributeOptions => getAttributeComponent(attributeOptions,
                model,
                model.getErrorsForAttribute(attributeOptions.attributeName),
                EntityFormMode.CREATE,
                isRequired(model, attributeOptions.attributeName),
                undefined,
                model.validate.bind(model)));
    }
    
    private async saveAsset() {
        await this.assetEntity.validate();
        
        if (this.assetEntity.hasValidationError) {
            return;
        }
        
        try {
            const relationPath = {
                mehubAccountss: {},
                assetAttachmentss: {},
                assetss: {},
            };
            await this.assetEntity.save(
                relationPath,
                {
                    options: [
                        {
                            key: 'mergeReferences',
                            graphQlType: '[String]',
                            value: [
                                'assetAttachmentss',
                                'mehubAccounts',
                                'assetss',
                            ]
                        },
                    ],
                    contentType: 'multipart/form-data',
                }
            );
            alert('Successfully saved asset', 'success');
            
            store.routerHistory.push(`${SERVER_URL}/assets`);
        } catch (e) {
            alert('Error saving asset', 'error');
        }
    }
    
    render() {
        let contents = (
            <div className="new-asset-section">
                <h3>{!!this.props.asset ? 'Edit' : 'Create New'} Asset</h3>
                <div className="asset-basic-details">
                    {this.renderBasicAssetDetails()}
                </div>
                <h3>Status</h3>
                <div className="asset-status-details">
                    {this.renderAssetStatusDetails()}
                </div>
                <h3>Assignee</h3>
                <div className="asset-assignee-details">
                    {this.renderAssetAssignee()}
                </div>
                <h3>Inspections</h3>
                <div className="asset-inspection-details">
                    {this.renderAssetInspectionDetails()}
                </div>
                <h3>Comments</h3>
                <div className="asset-comments-box">
                    {this.renderAssetComments()}
                </div>
                <h3>Attachments</h3>
                <div className="asset-attachment">
                    <MultiFileUpload model={this.assetEntity}
                                     modelProperty='assetAttachmentss'
                                     fileModel={AssetAttachmentsEntity}
                                     fileModelProperty='attachments'
                                     preview={true}
                                     disableDropArea={true}
                                     maxFileUploadSize={20}
                    />
                </div>
                <h3>Link asset</h3>
                <div className="asset-link-asset">
                    {this.renderLinkAssets()}
                </div>
                <div className="asset-button-group">
                    <Button className="cancel-new-asset"
                            onClick={() => store.routerHistory.push(`${SERVER_URL}/assets`)}
                            display={Display.Outline}
                            colors={Colors.FormTertiary}
                            sizes={Sizes.Medium}>
                        Cancel
                    </Button>
                    <Button className="save-new-asset"
                            onClick={() => this.saveAsset()}
                            display={Display.Solid}
                            colors={Colors.FormPrimary}
                            sizes={Sizes.Medium}>
                        Save
                    </Button>
                </div>
            </div>
        );
        return contents;
    }

}