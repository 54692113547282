/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { Model, IModelAttributes, attribute, entity } from 'Models/Model';
import * as Models from 'Models/Entities';
import { IAcl } from '../Security/IAcl';
import { observable } from 'mobx';
import { VisitorsMemberOrganisationsReferenceManyToMany } from '../Security/Acl/VisitorsMemberOrganisationsReferenceManyToMany';
import { MehubAccountMemberOrganisationsReferenceManyToMany } from '../Security/Acl/MehubAccountMemberOrganisationsReferenceManyToMany';
import { MehubAdminMemberOrganisationsReferenceManyToMany } from '../Security/Acl/MehubAdminMemberOrganisationsReferenceManyToMany';

// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IFormsMemberOrganisationsAttributes extends IModelAttributes {
	formsId: string;
	memberOrganisationsId: string;

	forms: Models.FormsEntity | Models.IFormsEntityAttributes;
	memberOrganisations: Models.MemberOrganisationEntity | Models.IMemberOrganisationEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

@entity('FormsMemberOrganisations')
export default class FormsMemberOrganisations extends Model implements IFormsMemberOrganisationsAttributes {
	public static acls: IAcl[] = [
		new VisitorsMemberOrganisationsReferenceManyToMany(),
		new MehubAccountMemberOrganisationsReferenceManyToMany(),
		new MehubAdminMemberOrganisationsReferenceManyToMany(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	@observable
	@attribute()
	public formsId: string;

	@observable
	@attribute()
	public memberOrganisationsId: string;

	@observable
	@attribute({isReference: true})
	public forms: Models.FormsEntity;

	@observable
	@attribute({isReference: true})
	public memberOrganisations: Models.MemberOrganisationEntity;
	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	constructor(attributes?: Partial<IFormsMemberOrganisationsAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		if (attributes) {
			if (attributes.formsId) {
				this.formsId = attributes.formsId;
			}
			if (attributes.memberOrganisationsId) {
				this.memberOrganisationsId = attributes.memberOrganisationsId;
			}

			if (attributes.forms) {
				if (attributes.forms instanceof Models.FormsEntity) {
					this.forms = attributes.forms;
					this.formsId = attributes.forms.id;
				} else {
					this.forms = new Models.FormsEntity(attributes.forms);
					this.formsId = this.forms.id;
				}
			} else if (attributes.formsId !== undefined) {
				this.formsId = attributes.formsId;
			}

			if (attributes.memberOrganisations) {
				if (attributes.memberOrganisations instanceof Models.MemberOrganisationEntity) {
					this.memberOrganisations = attributes.memberOrganisations;
					this.memberOrganisationsId = attributes.memberOrganisations.id;
				} else {
					this.memberOrganisations = new Models.MemberOrganisationEntity(attributes.memberOrganisations);
					this.memberOrganisationsId = this.memberOrganisations.id;
				}
			} else if (attributes.memberOrganisationsId !== undefined) {
				this.memberOrganisationsId = attributes.memberOrganisationsId;
			}
		}

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}