/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { store } from 'Models/Store';
import { IFetchArgs, Model } from 'Models/Model';
import { FormVersion } from './FormVersion';
import { FormEntityData } from 'Forms/FormEntityData';
import { lowerCaseFirst } from 'Util/StringUtils';
import { Form } from 'Forms/Schema/Question';

type formEntity = Model & FormEntityData;

const formExpand = `
	formVersions {
		id
		created
		modified
		version
		formData
	}
`;

function tryParseResults(result: string | Form) {
	if (typeof result === 'string') {
		return JSON.parse(result);
	}
	return result;
}

export async function fetchFormVersions(
	model: formEntity,
	includeSubmissions?: boolean,
	conditions?: IFetchArgs<{}>):
	Promise<FormVersion[]> {
	const query = model.getFetchWithExpands(formExpand, "model", lowerCaseFirst(model.getModelName()));
	const result = await store.apolloClient
		.query({
			query: query,
			variables: {ids: [model.id]},
			fetchPolicy: 'network-only',
		});
	return result.data.model.formVersions.map((x: any) => {return {...x, formData: tryParseResults(x.formData)}});
}

export async function fetchPublishedVersion(
	model: formEntity,
	includeSubmissions?: boolean):
	Promise<FormVersion | undefined> {
	const result = await fetchFormVersions(model, includeSubmissions, {id: model.publishedVersionId});
	return result[0];
}