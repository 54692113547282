import * as React from 'react';
import { observer } from 'mobx-react';
import { IModelType, Model } from 'Models/Model';
import {action, observable, runInAction} from 'mobx';
import {FormEntityData, SubmissionEntityData} from 'Forms/FormEntityData';
import { AccordionSection } from 'Views/Components/Accordion/Accordion';
import { store } from 'Models/Store';
import { FormEntityTile } from 'Forms/FormEntityTile';
import Spinner from 'Views/Components/Spinner/Spinner';
import { Button } from 'Views/Components/Button/Button';

import alert from "../Util/ToastifyUtils";
import {FormsEntity, FormSubmissionsEntity} from "../Models/Entities";
import axios, {AxiosResponse} from "axios";
import {SERVER_URL} from "../Constants";

export interface DynamicFormSubmissionTileProps {
	modelType: IModelType;
	formId: string;
	beforeContent?: (title: string, nextFn: () => void) => React.ReactNode;
	afterContent?: (savedAsDraft?: boolean) => React.ReactNode;
}

@observer
export class DynamicFormSubmissionTile extends React.Component<DynamicFormSubmissionTileProps> {
	static defaultProps: Partial<DynamicFormSubmissionTileProps> = {
		beforeContent: (title, nextFn) => {
			return (
				<>
					<h3>
						{title}
					</h3>
					<Button onClick={nextFn}>Open Form</Button>
				</>
			)
		},
		afterContent: (savedAsDraft?) => {
			store.routerHistory.push(`/`);
			
			// [MEASD-200/#198] Change alert based on if a draft is being saved or not
			if(savedAsDraft){
				alert('The draft has been submitted successfully.', 'warning');
			}
			else {
				alert('The form has been submitted successfully.', 'success');
			}
			
			return (<></>);
		},
	};

	@observable
	private requestState: 'pending' | 'error' | 'success' = 'pending';

	@observable
	private formState: 'before' | 'during' | 'after' = 'during';

	@observable
	private entity?: Model & FormEntityData;

	@observable
	private submissionEntity?: FormSubmissionsEntity;
	
	@observable
	private error?: React.ReactNode;
	
	private savedAsDraft?: boolean = false;

	@action
	private updateFormSchema = (form?: any) => {
		if (form) {
			this.entity = form;
		}
		this.requestState = 'success';
	}

	@action
	private updateError = (error: any) => {
		console.error(error);
		this.error = (
			<div>
				There was an error fetching this form;
				<AccordionSection name="Detailed Errors" component={JSON.stringify(error)} key="form-errors"/>
			</div>
		);
		this.requestState = 'error';
	}
	@action
	private setFormState = (state: 'before' | 'during' | 'after') => {
		this.formState = state;
	}
	
	private fetchLatestForm = () => {
		FormsEntity.fetch<FormsEntity>({
			ids: [this.props.formId],
			args: [[{path: 'isActive', comparison: "equal", value: 'true'}]]
		})
			.then((forms) => {
				if (forms) {
					return this.updateFormSchema(new this.props.modelType(forms[0]));
				}
				return this.updateFormSchema();
			})
			.catch(e => {
				this.updateError(e);
			});
		return;
	} 

	public componentDidMount(): void {
		axios.get(`${SERVER_URL}/api/entity/FormSubmissionsEntity/fetchDraft/${this.props.formId}`)
			.then(action(({ data }) => {
				if (!data) {
					return this.fetchLatestForm();
				}
				const submission = new FormSubmissionsEntity(data);
				this.submissionEntity = submission;
				
				this.updateFormSchema(new this.props.modelType(submission.formVersion.form));
			})).catch(this.updateError);
	}

	private renderSuccess = () => {
		if (this.entity) {
			switch (this.formState) {
				case 'before': return this.props.beforeContent
					? this.props.beforeContent(this.entity.name, () => this.setFormState('during'))
					: undefined;
				case 'during': 
					return <FormEntityTile 
								submissionEntity={this.submissionEntity} 
								model={this.entity} 
								onAfterSubmit={(entity, savedAsDraft) => {
										this.savedAsDraft = savedAsDraft;
										this.setFormState('after'); }}
							/>;
				case 'after': return this.props.afterContent ? this.props.afterContent(this.savedAsDraft) : undefined;
			}
		}
		return (
			<div>
				There is no entity associated with this form tile
			</div>
		);
	}

	public render() {
		let content: React.ReactNode = null;
		switch (this.requestState) {
			case 'pending': 
				content = <Spinner />; 
				break;
			case 'error': 
				content = this.error;
				break;
			case 'success': 
				content = this.renderSuccess();
				break;
		}
		return content;
	}
}