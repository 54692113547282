/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import { Model, IModelAttributes, attribute, entity } from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from "Util/AttributeUtils";
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchManyToManyFunc,
	makeJoinEqualsFunc,
	makeFetchOneToManyFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import { VisitorsFormSubmissionsEntity } from 'Models/Security/Acl/VisitorsFormSubmissionsEntity';
import { MehubAccountFormSubmissionsEntity } from 'Models/Security/Acl/MehubAccountFormSubmissionsEntity';
import { MehubAdminFormSubmissionsEntity } from 'Models/Security/Acl/MehubAdminFormSubmissionsEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import { FormVersion } from 'Forms/FormVersion';
import { FormEntityData, SubmissionEntityData } from 'Forms/FormEntityData';
import { jsonReplacerFn } from 'Models/Model';
import {SuperAdministratorScheme} from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] on begin
import moment from "moment";
import * as Enums from "../Enums";
import {FormSubmissionsAssets} from "Models/Entities";
import {ICRUDOptions} from '../CRUDOptions';
// % protected region % [Add any further imports here] end

export interface IFormSubmissionsEntityAttributes extends IModelAttributes, SubmissionEntityData {
	isCompleted: boolean;
	jobNumber: string;
	jobAddress: string;

	additionalAttachmentss: Array<Models.AdditionalAttachmentEntity | Models.IAdditionalAttachmentEntityAttributes>;
	mehubAccountId?: string;
	mehubAccount?: Models.MehubAccountEntity | Models.IMehubAccountEntityAttributes;
	formReviewRequestId?: string;
	formReviewRequest?: Models.FormReviewRequestEntity | Models.IFormReviewRequestEntityAttributes;
	assetss: Array<Models.FormSubmissionsAssets | Models.IFormSubmissionsAssetsAttributes>;
	documentss: Array<Models.FormSubmissionsDocuments | Models.IFormSubmissionsDocumentsAttributes>;
	// % protected region % [Add any custom attributes to the interface here] on begin
	owner: ISubmissionOwner;
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] on begin
interface ISubmissionOwner {
	email: string;
	firstName: string | null; 
	surname: string | null;
	toString: () => string;
}
@entity('FormSubmissionsEntity', 'Form Submissions')
// % protected region % [Customise your entity metadata here] end
export default class FormSubmissionsEntity extends Model implements IFormSubmissionsEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsFormSubmissionsEntity(),
		new MehubAccountFormSubmissionsEntity(),
		new MehubAdminFormSubmissionsEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Is Completed'] on begin
	@observable
	@attribute()
	@CRUD({
		name: 'Is Completed',
		displayType: 'hidden',
		order: 10,
		headerColumn: false,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public isCompleted: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Is Completed'] end

	// % protected region % [Modify props to the crud options here for attribute 'Job Number'] on begin
	@observable
	@attribute()
	@CRUD({
		name: 'Job Number',
		displayType: 'hidden',
		order: 20,
		headerColumn: false,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public jobNumber: string;
	// % protected region % [Modify props to the crud options here for attribute 'Job Number'] end

	// % protected region % [Modify props to the crud options here for attribute 'Job Address'] on begin
	@observable
	@attribute()
	@CRUD({
		name: 'Job Address',
		displayType: 'hidden',
		order: 30,
		headerColumn: false,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public jobAddress: string;
	// % protected region % [Modify props to the crud options here for attribute 'Job Address'] end

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for attribute 'Submission Data'] on begin
		name: 'Submission Data',
		displayType: 'form-data',
		order: 1000,
		headerColumn: false,
		searchable: false,
		isReadonly: true,
		// % protected region % [Modify props to the crud options here for attribute 'Submission Data'] end
	})
	public submissionData: {[key: string]: any} = {};

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for attribute 'Form Version'] on begin
		name: 'Form Version',
		displayType: 'reference-combobox',
		order: 20,
		isJoinEntity: true,
		headerColumn: false,
		readFieldType: 'hidden',
		updateFieldType: 'hidden',
		referenceTypeFunc: () => Models.FormsEntity,
		displayFunction: (attr, that) => (that as FormSubmissionsEntity).formVersion.version.toString(),
		onAfterChange: (model) => {
			const formEntity = model['formVersionId'] as FormEntityData;
			model['formVersionId'] = formEntity.publishedVersionId;
			model['formVersion'] = formEntity.publishedVersion;
		},
		// % protected region % [Modify props to the crud options here for attribute 'Form Version'] end
	})
	formVersionId: string;

	@observable
	@attribute({isReference: true, manyReference: false})
	formVersion: FormVersion;

	@observable
	@attribute({isReference: true, manyReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Additional Attachments'] on begin
		name: "Additional Attachments",
		displayType: 'hidden',
		order: 40,
		referenceTypeFunc: () => Models.AdditionalAttachmentEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'additionalAttachmentss',
			oppositeEntity: () => Models.AdditionalAttachmentEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Additional Attachments'] end
	})
	public additionalAttachmentss: Models.AdditionalAttachmentEntity[] = [];

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'MeHub Account'] on begin
		name: 'MeHub Account',
		displayType: 'hidden',
		order: 50,
		referenceTypeFunc: () => Models.MehubAccountEntity,
		// % protected region % [Modify props to the crud options here for reference 'MeHub Account'] end
	})
	public mehubAccountId?: string;
	@observable
	@attribute({isReference: true, manyReference: false})
	public mehubAccount: Models.MehubAccountEntity;

	@observable
	@attribute()
	public formReviewRequestId?: string;
	@observable
	@attribute({isReference: true, manyReference: false})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Form Review Request'] on begin
		name: 'Form Review Request',
		displayType: 'hidden',
		order: 60,
		inputProps: {
			displayFunction: (model?: Models.FormReviewRequestEntity) => model ? model.getDisplayName() : null,
		},
		// % protected region % [Modify props to the crud options here for reference 'Form Review Request'] end
	})
	public formReviewRequest: Models.FormReviewRequestEntity;

	@observable
	@attribute({isReference: true, manyReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Assets'] on begin
		name: 'Assets',
		displayType: 'hidden',
		order: 40,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.FormSubmissionsAssets,
		optionEqualFunc: makeJoinEqualsFunc('assetsId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'formSubmissionsEntity',
			oppositeEntityName: 'assetEntity',
			relationName: 'formSubmissions',
			relationOppositeName: 'assets',
			entity: () => Models.FormSubmissionsEntity,
			joinEntity: () => Models.FormSubmissionsAssets,
			oppositeEntity: () => Models.AssetEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Assets'] end
	})
	public assetss: Models.FormSubmissionsAssets[] = [];

	@observable
	@attribute({isReference: true, manyReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Documents'] on begin
		name: 'Documents',
		displayType: 'hidden',
		order: 50,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.FormSubmissionsDocuments,
		optionEqualFunc: makeJoinEqualsFunc('documentsId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'formSubmissionsEntity',
			oppositeEntityName: 'documentEntity',
			relationName: 'formSubmissions',
			relationOppositeName: 'documents',
			entity: () => Models.FormSubmissionsEntity,
			joinEntity: () => Models.FormSubmissionsDocuments,
			oppositeEntity: () => Models.DocumentEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Documents'] end
	})
	public documentss: Models.FormSubmissionsDocuments[] = [];

	// % protected region % [Add any custom attributes to the model here] on begin
	@observable
	@CRUD({
		name: 'Form Name',
		displayType: 'hidden',
		order: 30,
		isJoinEntity: true,
		headerColumn: true,
		isReadonly: true,
		searchable: true,
		referenceTypeFunc: () => Models.FormsEntity,
		displayFunction: (attr, that) => (that as FormSubmissionsEntity).formVersion.form?.name,
		sortable: false,
		customAttributeName: "formVersion.form.name",
		searchFunction: "like",
		searchTransform: AttrUtils.standardiseString
	})
	formName: string;

	@observable
	@CRUD({
		name: 'Form type',
		displayType: 'hidden',
		order: 40,
		headerColumn: true,
		isReadonly: true,
		searchable: false,
		displayFunction: (attribute: Enums.formTypes) => Enums.formTypesOptions[attribute],
		sortable: false,
	})
	formType: string;

	@observable
	@CRUD({
		name: "User",
		displayType: 'hidden',
		headerColumn: true,
		order: 5,
		displayFunction: (attr: ISubmissionOwner, that) => {
			let fullName = '';
			if(attr.firstName) {
				fullName = attr.firstName;
			}
			if(attr.surname && attr.surname.length) {
				if(fullName.length) {
					fullName += ' ';
				}
				fullName += attr.surname;
			}
			if(!fullName.length) {
				fullName = attr.email;
			}
			return fullName;
		},
		readFieldType: 'displayfield',
	})
	public owner: ISubmissionOwner;

	// [MEASD-152] Job Number and Job Address duplicated to reorder columns
	// The original columns still exist for the sake of simple searching
	@observable
	@CRUD({
		name: 'Job Number',
		displayType: 'hidden',
		order: 60,
		headerColumn: true,
		searchable: false,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
		displayFunction: (attr, that) => (that as FormSubmissionsEntity).jobNumber,
	})
	public newJobNumber: string;

	@observable
	@CRUD({
		name: 'Job Address',
		displayType: 'hidden',
		order: 70,
		headerColumn: true,
		searchable: false,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
		displayFunction: (attr, that) => (that as FormSubmissionsEntity).jobAddress,
	})
	public newJobAddress: string;
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IFormSubmissionsEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IFormSubmissionsEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.isCompleted !== undefined) {
				this.isCompleted = attributes.isCompleted;
			}
			if (attributes.jobNumber !== undefined) {
				this.jobNumber = attributes.jobNumber;
			}
			if (attributes.jobAddress !== undefined) {
				this.jobAddress = attributes.jobAddress;
			}
			if (attributes.submissionData !== undefined) {
				if (typeof attributes.submissionData === 'string') {
					this.submissionData = JSON.parse(attributes.submissionData) as {[key: string]: any};
				} else {
					this.submissionData = attributes.submissionData;
				}
			}
			if (attributes.formVersion !== undefined) {
				this.formVersion = attributes.formVersion;
				if (typeof attributes.formVersion.formData === 'string') {
					this.formVersion.formData = JSON.parse(attributes.formVersion.formData);
				}
			}
			if (attributes.formVersionId !== undefined) {
				this.formVersionId = attributes.formVersionId;
			}
			if (attributes.additionalAttachmentss !== undefined && Array.isArray(attributes.additionalAttachmentss)) {
				for (const model of attributes.additionalAttachmentss) {
					if (model instanceof Models.AdditionalAttachmentEntity) {
						this.additionalAttachmentss.push(model);
					} else {
						this.additionalAttachmentss.push(new Models.AdditionalAttachmentEntity(model));
					}
				}
			}
			if (attributes.mehubAccountId !== undefined) {
				this.mehubAccountId = attributes.mehubAccountId;
			}
			if (attributes.mehubAccount !== undefined) {
				if (attributes.mehubAccount === null) {
					this.mehubAccount = attributes.mehubAccount;
				} else {
					if (attributes.mehubAccount instanceof Models.MehubAccountEntity) {
						this.mehubAccount = attributes.mehubAccount;
						this.mehubAccountId = attributes.mehubAccount.id;
					} else {
						this.mehubAccount = new Models.MehubAccountEntity(attributes.mehubAccount);
						this.mehubAccountId = this.mehubAccount.id;
					}
				}
			}
			if (attributes.formReviewRequestId !== undefined) {
				this.formReviewRequestId = attributes.formReviewRequestId;
			}
			if (attributes.formReviewRequest !== undefined) {
				if (attributes.formReviewRequest === null) {
					this.formReviewRequest = attributes.formReviewRequest;
				} else {
					if (attributes.formReviewRequest instanceof Models.FormReviewRequestEntity) {
						this.formReviewRequest = attributes.formReviewRequest;
						this.formReviewRequestId = attributes.formReviewRequest.id;
					} else {
						this.formReviewRequest = new Models.FormReviewRequestEntity(attributes.formReviewRequest);
						this.formReviewRequestId = this.formReviewRequest.id;
					}
				}
			}
			if (attributes.assetss !== undefined && Array.isArray(attributes.assetss)) {
				for (const model of attributes.assetss) {
					if (model instanceof Models.FormSubmissionsAssets) {
						this.assetss.push(model);
					} else {
						this.assetss.push(new Models.FormSubmissionsAssets(model));
					}
				}
			}
			if (attributes.documentss !== undefined && Array.isArray(attributes.documentss)) {
				for (const model of attributes.documentss) {
					if (model instanceof Models.FormSubmissionsDocuments) {
						this.documentss.push(model);
					} else {
						this.documentss.push(new Models.FormSubmissionsDocuments(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] on begin
			if (attributes.formVersion?.form?.name !== undefined) {
				this.formName = attributes.formVersion?.form?.name;
			}
			if (attributes.formVersion?.form?.formType !== undefined) {
				this.formType = attributes.formVersion?.form?.formType;
			}
			if (attributes.assetss !== undefined) {
				this.assetss = attributes.assetss.map(x => new FormSubmissionsAssets(x));
			}
			if(attributes.owner !== undefined) {
				this.owner = attributes.owner;
				if(attributes.isCompleted == true){
					this.owner.toString = () => {
						let fullName = '';
						if(this.owner.firstName) {
							fullName = this.owner.firstName;
						}
						if(this.owner.surname && this.owner.surname.length) {
							if(fullName.length) {
								fullName += ' ';
							}
							fullName += this.owner.surname;
						}
						if(!fullName.length) {
							fullName = this.owner.email;
						}
						return fullName;
					}
				}
			}
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] on begin
	public defaultExpands = `
		created
		owner {
			email
			firstName
			surname
		}
		formVersion {
			id
			created
			modified
			formData
			version
			form {
				id
				name
				formType
			}
		}
		assetss {
			id
			assets {
				id
				description
			}
		}
		documentss {
			id
			documents {
				id
				documentName
				documentUploadId
			}
		}
		additionalAttachmentss {
			id
			attachmentId
			uploadedFile {
				id
				fileName
			}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	// % protected region % [Customize List Expands here] on begin
	public listExpands = `
		created
		owner {
			email
			firstName
			surname
		}
		formVersion {
			id
			created
			modified
			formData
			version
			form {
				id
				name
				formType
			}
		}
		assetss {
			id
			assets {
				id
				description
			}
		}
		documentss {
			id
			documents {
				id
				documentName
			}
		}
		additionalAttachmentss {
			id
			uploadedFile {
				id
				fileName
			}
		}
	`;
	// % protected region % [Customize List Expands here] end

	/**
	 * The JSON transform function used to transform the submission data before
	 * sending it to the server.
	 */
	private submissionTransform: jsonReplacerFn = (input) => {
		// % protected region % [Modify submission transform here] off begin
		const submissionData = input['submissionData'];
		if (submissionData) {
			input['submissionData'] = JSON.stringify(submissionData);
		}
		return input;
		// % protected region % [Modify submission transform here] end
	}

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath = {
			submissionData: {},
			assetss: {},
			documentss: {},
			additionalAttachmentss: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'additionalAttachmentss',
							'assetss',
							'documentss',
						]
					},
				],
				jsonTransformFn: this.submissionTransform,
			}
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}


	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] on begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [ createdAttr, modifiedAttr ] = getCreatedModifiedCrudOptions();

let customCreatedAttr: ICRUDOptions = {
	...createdAttr,
	name: 'Date created',
	headerColumn: true,
	order: 50,
	displayType: 'hidden',
	displayFunction: (attr: moment.MomentInput) => moment.utc(attr).local().format('DD/MM/YYYY hh:mm A'),
};
CRUD(customCreatedAttr)(FormSubmissionsEntity.prototype, 'created');
// CRUD(modifiedAttr)(FormSubmissionsEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
