import * as React from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router';
import { observable, action } from 'mobx';
import axios from 'axios';
import { SERVER_URL } from 'Constants';
import { SafetyLinksAndSection } from 'Models/Entities/SafetyLinkEntity';
import If from 'Views/Components/If/If';
import { store } from 'Models/Store';
import Query_Button from '../../images/Query_Button.svg';
import { linkListModal } from 'Views/Components/Modal/ModalUtils';
import GlobalSearch from 'Views/CustomComponents/GlobalSearch';
import Spinner from 'Views/Components/Spinner/Spinner';
import MarketingTile from 'Views/CustomComponents/MarketingTile';
import BreadcrumbNavigation from "../CustomComponents/BreadcrumbNavigation";

@observer
export default class OriginalSafetyTile extends React.Component<RouteComponentProps> {

	@observable
	private requestState: 'pending' | 'success' = 'pending';

	@observable
	private safetyLinksAndSection: SafetyLinksAndSection[] = [];

	componentDidMount() {
		axios.get(`${SERVER_URL}/api/entity/SafetyLinkEntity/safety_list`)
			.then(({ data }) => this.onSuccess(data))
	}

	@action onSuccess = (data: SafetyLinksAndSection[]) => {
		this.safetyLinksAndSection = this.sortSafetyOrder(data);
		this.requestState = 'success'; 
	}

	private sortSafetyOrder = (data: SafetyLinksAndSection[]) => {
		let sortedData = data.sort((a,b) => a.order - b.order);
		sortedData.forEach(s => {
			if (s.safetyLinkEntities.length > 1) {
				s.safetyLinkEntities = s.safetyLinkEntities.sort((x,y) => x.order - y.order);
			}
		});

		return sortedData;
	}

	public render() {
		let displayLibraryTile = true;
		let displayMarketingTile = false;
		let displayErrorTile = false;

		let marketingTile = (
			displayMarketingTile ? <MarketingTile/> : null
		);

		let myLibraryTile = (
			displayLibraryTile ?
				<div className="tile safety-tile view-library" onClick={() => this.onLibraryClick()}>
					<div className="tile__tooltip" onClick={(e) => e.stopPropagation()}>
						<img src={Query_Button} />
						<span className="tooltip__content">
							Your Documents
					</span>
					</div>

					<div className="safety-tile__title">
						<h4>My Library</h4>
					</div>
					<div className="safety-tile__commands">
						<a className="view-button icon-left icon-look">View My Library</a>
					</div>
				</div>
				: null
		);

		let errorTile = (
			displayErrorTile ?
				<div className="tile error-tile" >
					<p>There is a system error, we are working to resolve this, please log back in later today.</p>
					 <p>If you need immediate support please email <a href="mailto:info@mea.asn.au">info@mea.asn.au</a> or call <a href="tel:1300 889 198">1300 889 198</a>.</p>
				</div> 
				: null
		);
		
		let getNumberOfColumns = () => {
			let numTiles = 0;

			if (displayMarketingTile) {
				numTiles++;
			}

			if (displayLibraryTile) {
				numTiles++;
			}

			if (displayErrorTile) {
				numTiles++;
			}

			numTiles += this.safetyLinksAndSection.length;
			
			if (numTiles > 4) {
				return 4;
			}
			
			return numTiles;
		}

		let contents = (
			<section className="primary-safety-tiles original-safety-tiles">

					<div className="breadcrumb-button">
						<BreadcrumbNavigation />
					</div>

					<div className="page-controls">
						<div className="page-controls__title">
							<h2>Safety</h2>
						</div>
						<div className="page-controls__search">
							<div className="input-group input-group-block" aria-label="Search-Field">
								<GlobalSearch precedentType={'SAFETY'} />
							</div>
						</div>
					</div>

					<div className="primary-safety-tiles__tiles" style={{gridTemplateColumns: `repeat(${getNumberOfColumns()}, auto)`}}>
						{marketingTile}
						{myLibraryTile}
						{errorTile}

						{this.safetyLinksAndSection.map(safetySection =>
							<div className="tile safety-tile" key={safetySection.id} onClick={() => this.standardTileClick(safetySection)}>
								<div className="tile__tooltip" onClick={(e) => e.stopPropagation()}>
									<img src={Query_Button} />
									<span className="tooltip__content">
										{safetySection.tooltip}
									</span>
								</div>

								<div className="safety-tile__title">
									<h4>{safetySection.name}</h4>
								</div>

								<If condition={safetySection.safetyLinkEntities.length === 1}>
									<div className="safety-tile__commands">
										{safetySection.safetyLinkEntities[0].create ? 
											<a className="create-button icon-left icon-file-add" 
											   href={safetySection.safetyLinkEntities[0].create} 
											   target="_blank"
											   onClick={(e) => e.stopPropagation()} rel="noreferrer"
											>Create</a>
											: null
										}
										{safetySection.safetyLinkEntities[0].create && safetySection.safetyLinkEntities[0].view ? 
											<a onClick={(e) => e.stopPropagation()}> | </a>
											: null
										}
										{safetySection.safetyLinkEntities[0].view ? 
											<a className="view-button icon-left icon-look" 
											   href={safetySection.safetyLinkEntities[0].view} 
											   target="_blank"
											   onClick={(e) => e.stopPropagation()} rel="noreferrer"
											>View</a>
											: null
										}
									</div>
								</If>

								<If condition={safetySection.safetyLinkEntities.length > 1}>
									<div className="safety-tile__commands">
										<a className="more-button">Show More</a>
									</div>
								</If>

							</div>
						)}
					</div>
				</section>
		);

		switch (this.requestState) {
			case 'pending': return <Spinner />;
			case 'success': return contents; 
		}
	}

	private onShowMoreLinks = (safetyLinksAndSection: SafetyLinksAndSection) => {
		let modalBody = (
			<>
				{
					safetyLinksAndSection.safetyLinkEntities.map(link =>
						<div className="create-safety-form-modal-body__item" key={link.name}>
							<p>{link.name}</p>
							<div className="safety-tile__link-buttons">
								{link.create ? <a className="create-button icon-left icon-file-add" href={link.create} target="_blank" rel="noreferrer">Create</a> : null}
								{link.view ? <a className="view-button icon-left icon-look" href={link.view} target="_blank" rel="noreferrer">View</a> : null}
							</div>
						</div>
					)
				}
			</>
		);

		linkListModal(
			`${safetyLinksAndSection.name}`,
			modalBody
		);
	}

	private standardTileClick = (safetySection: SafetyLinksAndSection) => {
		if (safetySection.safetyLinkEntities.length === 1) {
			window.open(safetySection.safetyLinkEntities[0].view, '_blank');
		} else if (safetySection.safetyLinkEntities.length > 1) {
			this.onShowMoreLinks(safetySection);
		}
	}

	private onLibraryClick = () => {
		const eventName = 'Safetypage_Click_Library';
		store.routerHistory.push('/documentlibrary'); 
	}
}