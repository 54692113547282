import React from "react";
import {Icon, Menu} from "semantic-ui-react";
import {Link} from "react-router-dom";
import {ILink} from "../Components/Navigation/Navigation";
import {action, observable} from "mobx";

interface SubMenuProps {
    link: ILink;
}

export default class SubMenu extends React.Component<SubMenuProps> {
    
    @observable
    private showDropdown = false;
    
    render() {
        return ( 
        <>
            <Menu.Item
                as={Link}
                className={'items-sidebar'}
                key={this.props.link.path}
                onClick={action(() => this.showDropdown = !this.showDropdown)}>
                { this.props.link.label }
                <span
                    style={{ float: "right" }}
                >
                    {this.showDropdown ? <Icon name={"angle down"} /> : <Icon name={"angle right"} />}
                </span>
            </Menu.Item>
            {this.showDropdown && this.props.link.subLinks ?
                this.props.link.subLinks
                    .filter(subLink => subLink.shouldDisplay ? subLink.shouldDisplay() : true)
                    .map((subLink, i) => {
                    return <Menu.Item
                        as={subLink.useATag ? 'a' : Link}
                        href={subLink.path}
                        to={subLink.path}
                        key={subLink.path}
                        className={'items-sidebar submenu-full'}
                    >
                        {subLink.label}
                    </Menu.Item>
                }) : <></>
            }
        </>
    )}
};
