/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import Navigation, { Orientation, ILink } from 'Views/Components/Navigation/Navigation';
import { RouteComponentProps } from 'react-router';
import * as Models from 'Models/Entities';
import { Model } from 'Models/Model';
import { IIconProps } from "Views/Components/Helpers/Common";
import { SecurityService } from "Services/SecurityService";
import { getModelDisplayName } from 'Util/EntityUtils';
import { store } from 'Models/Store';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

interface AdminLink extends IIconProps {
	path: string;
	label: string;
	entity: {new (args: any): Model};
	isMember?: boolean;
	// % protected region % [Add extra AdminLink fields here] off begin
	// % protected region % [Add extra AdminLink fields here] end
}

const getPageLinks = (): AdminLink[] => [
	{
		// % protected region % [Override navigation link for AdditionalAttachmentEntity here] off begin
		path: '/admin/additionalattachmententity',
		label: getModelDisplayName(Models.AdditionalAttachmentEntity),
		entity: Models.AdditionalAttachmentEntity,
		isMember: false
		// % protected region % [Override navigation link for AdditionalAttachmentEntity here] end
	},
	{
		// % protected region % [Override navigation link for ApikeyEntity here] off begin
		path: '/admin/apikeyentity',
		label: getModelDisplayName(Models.ApikeyEntity),
		entity: Models.ApikeyEntity,
		isMember: false
		// % protected region % [Override navigation link for ApikeyEntity here] end
	},
	{
		// % protected region % [Override navigation link for AssetEntity here] off begin
		path: '/admin/assetentity',
		label: getModelDisplayName(Models.AssetEntity),
		entity: Models.AssetEntity,
		isMember: false
		// % protected region % [Override navigation link for AssetEntity here] end
	},
	{
		// % protected region % [Override navigation link for AssetAttachmentsEntity here] off begin
		path: '/admin/assetattachmentsentity',
		label: getModelDisplayName(Models.AssetAttachmentsEntity),
		entity: Models.AssetAttachmentsEntity,
		isMember: false
		// % protected region % [Override navigation link for AssetAttachmentsEntity here] end
	},
	{
		// % protected region % [Override navigation link for DocumentEntity here] off begin
		path: '/admin/documententity',
		label: getModelDisplayName(Models.DocumentEntity),
		entity: Models.DocumentEntity,
		isMember: false
		// % protected region % [Override navigation link for DocumentEntity here] end
	},
	{
		// % protected region % [Override navigation link for DocumentTypesEntity here] on begin
		path: '/admin/meadocumententity', 
		label: 'MEA Documents',
		entity: Models.DocumentEntity,
		isMember: false,
	},
	{
		path: '/admin/documenttypesentity',
		label: getModelDisplayName(Models.DocumentTypesEntity),
		entity: Models.DocumentTypesEntity,
		isMember: false
		// % protected region % [Override navigation link for DocumentTypesEntity here] end
	},
	{
		// % protected region % [Override navigation link for FeedbackAndHelpEntity here] off begin
		path: '/admin/feedbackandhelpentity',
		label: getModelDisplayName(Models.FeedbackAndHelpEntity),
		entity: Models.FeedbackAndHelpEntity,
		isMember: false
		// % protected region % [Override navigation link for FeedbackAndHelpEntity here] end
	},
	{
		// % protected region % [Override navigation link for FormReviewRequestEntity here] off begin
		path: '/admin/formreviewrequestentity',
		label: getModelDisplayName(Models.FormReviewRequestEntity),
		entity: Models.FormReviewRequestEntity,
		isMember: false
		// % protected region % [Override navigation link for FormReviewRequestEntity here] end
	},
	{
		// % protected region % [Override navigation link for FormSubmissionsEntity here] off begin
		path: '/admin/formsubmissionsentity',
		label: getModelDisplayName(Models.FormSubmissionsEntity),
		entity: Models.FormSubmissionsEntity,
		isMember: false
		// % protected region % [Override navigation link for FormSubmissionsEntity here] end
	},
	{
		// % protected region % [Override navigation link for FormsEntity here] off begin
		path: '/admin/formsentity',
		label: getModelDisplayName(Models.FormsEntity),
		entity: Models.FormsEntity,
		isMember: false
		// % protected region % [Override navigation link for FormsEntity here] end
	},
	{
		// % protected region % [Override navigation link for GuidanceEntity here] off begin
		path: '/admin/guidanceentity',
		label: getModelDisplayName(Models.GuidanceEntity),
		entity: Models.GuidanceEntity,
		isMember: false
		// % protected region % [Override navigation link for GuidanceEntity here] end
	},
	{
		// % protected region % [Override navigation link for HomePageLinkEntity here] off begin
		path: '/admin/homepagelinkentity',
		label: getModelDisplayName(Models.HomePageLinkEntity),
		entity: Models.HomePageLinkEntity,
		isMember: false
		// % protected region % [Override navigation link for HomePageLinkEntity here] end
	},
	{
		// % protected region % [Override navigation link for LicenceEntity here] off begin
		path: '/admin/licenceentity',
		label: getModelDisplayName(Models.LicenceEntity),
		entity: Models.LicenceEntity,
		isMember: false
		// % protected region % [Override navigation link for LicenceEntity here] end
	},
	{
		// % protected region % [Override navigation link for LicenceAttachmentsEntity here] off begin
		path: '/admin/licenceattachmentsentity',
		label: getModelDisplayName(Models.LicenceAttachmentsEntity),
		entity: Models.LicenceAttachmentsEntity,
		isMember: false
		// % protected region % [Override navigation link for LicenceAttachmentsEntity here] end
	},
	{
		// % protected region % [Override navigation link for MehubAccountEntity here] off begin
		path: '/admin/mehubaccountentity',
		label: getModelDisplayName(Models.MehubAccountEntity),
		entity: Models.MehubAccountEntity,
		isMember: true
		// % protected region % [Override navigation link for MehubAccountEntity here] end
	},
	{
		// % protected region % [Override navigation link for MehubAdminEntity here] off begin
		path: '/admin/mehubadminentity',
		label: getModelDisplayName(Models.MehubAdminEntity),
		entity: Models.MehubAdminEntity,
		isMember: true
		// % protected region % [Override navigation link for MehubAdminEntity here] end
	},
	{
		// % protected region % [Override navigation link for MemberOrganisationEntity here] off begin
		path: '/admin/memberorganisationentity',
		label: getModelDisplayName(Models.MemberOrganisationEntity),
		entity: Models.MemberOrganisationEntity,
		isMember: false
		// % protected region % [Override navigation link for MemberOrganisationEntity here] end
	},
	{
		// % protected region % [Override navigation link for NewsEntity here] off begin
		path: '/admin/newsentity',
		label: getModelDisplayName(Models.NewsEntity),
		entity: Models.NewsEntity,
		isMember: false
		// % protected region % [Override navigation link for NewsEntity here] end
	},
	{
		// % protected region % [Override navigation link for ReminderLogEntity here] off begin
		path: '/admin/reminderlogentity',
		label: getModelDisplayName(Models.ReminderLogEntity),
		entity: Models.ReminderLogEntity,
		isMember: false
		// % protected region % [Override navigation link for ReminderLogEntity here] end
	},
	{
		// % protected region % [Override navigation link for RoleEntity here] off begin
		path: '/admin/roleentity',
		label: getModelDisplayName(Models.RoleEntity),
		entity: Models.RoleEntity,
		isMember: false
		// % protected region % [Override navigation link for RoleEntity here] end
	},
	{
		// % protected region % [Override navigation link for RoleSafetyLinkPermissionEntity here] off begin
		path: '/admin/rolesafetylinkpermissionentity',
		label: getModelDisplayName(Models.RoleSafetyLinkPermissionEntity),
		entity: Models.RoleSafetyLinkPermissionEntity,
		isMember: false
		// % protected region % [Override navigation link for RoleSafetyLinkPermissionEntity here] end
	},
	{
		// % protected region % [Override navigation link for SafetyLinkEntity here] off begin
		path: '/admin/safetylinkentity',
		label: getModelDisplayName(Models.SafetyLinkEntity),
		entity: Models.SafetyLinkEntity,
		isMember: false
		// % protected region % [Override navigation link for SafetyLinkEntity here] end
	},
	{
		// % protected region % [Override navigation link for SafetyLinkSectionEntity here] off begin
		path: '/admin/safetylinksectionentity',
		label: getModelDisplayName(Models.SafetyLinkSectionEntity),
		entity: Models.SafetyLinkSectionEntity,
		isMember: false
		// % protected region % [Override navigation link for SafetyLinkSectionEntity here] end
	},
	{
		// % protected region % [Override navigation link for SearchesEntity here] off begin
		path: '/admin/searchesentity',
		label: getModelDisplayName(Models.SearchesEntity),
		entity: Models.SearchesEntity,
		isMember: false
		// % protected region % [Override navigation link for SearchesEntity here] end
	},
	{
		// % protected region % [Override navigation link for StandardEntity here] off begin
		path: '/admin/standardentity',
		label: getModelDisplayName(Models.StandardEntity),
		entity: Models.StandardEntity,
		isMember: false
		// % protected region % [Override navigation link for StandardEntity here] end
	},
	{
		// % protected region % [Override navigation link for TemplateEntity here] off begin
		path: '/admin/templateentity',
		label: getModelDisplayName(Models.TemplateEntity),
		entity: Models.TemplateEntity,
		isMember: false
		// % protected region % [Override navigation link for TemplateEntity here] end
	},
	{
		// % protected region % [Override navigation link for FormsEntityFormTileEntity here] off begin
		path: '/admin/formsentityformtileentity',
		label: getModelDisplayName(Models.FormsEntityFormTileEntity),
		entity: Models.FormsEntityFormTileEntity,
		isMember: false
		// % protected region % [Override navigation link for FormsEntityFormTileEntity here] end
	},
	{
		// % protected region % [Override navigation link for MehubAccountTimelineEventsEntity here] off begin
		path: '/admin/mehubaccounttimelineeventsentity',
		label: getModelDisplayName(Models.MehubAccountTimelineEventsEntity),
		entity: Models.MehubAccountTimelineEventsEntity,
		isMember: false
		// % protected region % [Override navigation link for MehubAccountTimelineEventsEntity here] end
	},
	// % protected region % [Add any extra page links here] off begin
	// % protected region % [Add any extra page links here] end
];

export default class PageLinks extends React.Component<RouteComponentProps> {
	private filter = (link: AdminLink) => {
		return SecurityService.canRead(link.entity);
	}

	public render() {
		return <Navigation
			className='nav__admin'
			orientation={Orientation.VERTICAL}
			linkGroups={this.getAdminNavLinks()}
			{...this.props} />;
	}

	private getAdminNavLinks = () : ILink[][] => {
		// % protected region % [Add custom logic before all here] off begin
		// % protected region % [Add custom logic before all here] end

		const links = getPageLinks();
		let userLinks = links.filter(link => link.isMember).filter(this.filter);
		let entityLinks = links.filter(link => ! link.isMember).filter(this.filter);

		let linkGroups: ILink[][] = [];

		// % protected region % [Add any custom logic here before groups are made] on begin
		let excludedEntityLinks = [
			getModelDisplayName(Models.MemberOrganisationEntity),
			getModelDisplayName(Models.FormsEntity),
			getModelDisplayName(Models.FormSubmissionsEntity),
		];

		entityLinks = entityLinks.filter(link => !excludedEntityLinks.includes(link.label));
		// % protected region % [Add any custom logic here before groups are made] end

		const homeLinkGroup: ILink[] = [
			{ path: '/admin', label: 'Home', icon: 'home', iconPos: 'icon-left' },
			// % protected region % [Updated your home link group here] off begin
			// % protected region % [Updated your home link group here] end
		];
		linkGroups.push(homeLinkGroup);

		const entityLinkGroup: ILink[] = [];
		if (userLinks.length > 0) {
			entityLinkGroup.push(
				{
					path: '/admin/users',
					label: 'Users',
					icon: 'person-group',
					iconPos: 'icon-left',
					subLinks: [
						{path: "/admin/user", label: "All Users"},
						...userLinks.map(link => ({path: link.path, label: link.label}))
					]
				}
			);
		}
		if (entityLinks.length > 0) {
			entityLinkGroup.push(
				{
					path: '/admin/entities',
					label: 'Entities',
					icon: 'list',
					iconPos: 'icon-left',
					subLinks: entityLinks.map(link => {
						return {
							path: link.path,
							label: link.label,
						}
					})
				}
			);
		}
		linkGroups.push(entityLinkGroup);

		// % protected region % [Add any new link groups here before other and bottom] off begin
		// % protected region % [Add any new link groups here before other and bottom] end

		const otherlinkGroup: ILink[] = [];
		// % protected region % [Update the link group for the forms extension here] on begin
		const formsGroups: string[] = ['MehubAccount', 'MehubAdmin', 'Super Administrators'];
		if (store.userGroups.some(ug => formsGroups.some(fg => fg === ug.name))) {
			otherlinkGroup.push({ path: '/admin/forms', label: 'Forms Builder', icon: 'maintance', iconPos: 'icon-left', isDisabled: false });
			otherlinkGroup.push({ path: '/admin/formsubmissionsentity', label: 'Form Submissions', icon: 'forms', iconPos: 'icon-left', isDisabled: false });
			otherlinkGroup.push({ path: '/admin/templateentity', label: 'Templates', icon: 'file', iconPos: 'icon-left', isDisabled: false });
		}
		// % protected region % [Update the link group for the forms extension here] end
		// % protected region % [Update the link group for the timelines extension here] off begin
		otherlinkGroup.push(
			{
				path: '/admin/timelines',
				label: 'Timelines',
				icon: 'timeline',
				iconPos: 'icon-left',
				isDisabled: false
			}
		);
		// % protected region % [Update the link group for the timelines extension here] end

		// % protected region % [Add any additional links to otherLinkGroup here] on begin
		otherlinkGroup.push({ path: '/admin/memberorganisationentity', label: 'Organisations', icon: 'community', iconPos: 'icon-left', isDisabled: false });
		// % protected region % [Add any additional links to otherLinkGroup here] end

		if (otherlinkGroup.length > 0) {
			linkGroups.push(otherlinkGroup);
		}

		const bottomlinkGroup: ILink[] = [];
		bottomlinkGroup.push(
			// % protected region % [Modify the utility link group here] on begin
			{
				path: '/admin/',
				label: 'Utilities',
				icon: 'settings',
				iconPos: 'icon-left',
				subLinks: [
					{
						path: '/admin/styleguide',
						label: 'Style Guide',
						useATag: false
					},
					{
						path: '/admin/graphiql',
						label: 'GraphiQL',
						useATag: true,
					},
					{
						path: '/api/hangfire',
						label: 'Hangfire',
						useATag: true,
					},
					{
						path: '/api/swagger',
						label: 'OpenAPI',
						useATag: true,
					},
					{
						path: 'https://gitlab.codebots.dev',
						label: 'Git',
						useATag: true,
					},
				],
			}
			// % protected region % [Modify the utility link group here] end
		);

		// % protected region % [Update the logout link here] on begin
		bottomlinkGroup.push(
			{
				path: '/logout',
				label: 'Logout',
				icon: 'logout',
				iconPos: 'icon-left'
			}
		);
		// % protected region % [Update the logout link here] end

		// % protected region % [Add any additional links to bottomlinkGroup here] off begin
		// % protected region % [Add any additional links to bottomlinkGroup here] end

		linkGroups.push(bottomlinkGroup);

		// % protected region % [Modify your link groups here before returning] off begin
		// % protected region % [Modify your link groups here before returning] end

		return linkGroups;
	}

	// % protected region % [Add custom methods here] off begin
	// % protected region % [Add custom methods here] end
}