/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router';
import * as Models from 'Models/Entities';
import EntityCRUD from 'Views/Components/CRUD/EntityCRUD';
import { PageWrapper } from 'Views/Components/PageWrapper/PageWrapper';
import SecuredPage from 'Views/Components/Security/SecuredPage';
import { IEntityContextMenuActions } from 'Views/Components/EntityContextMenu/EntityContextMenu';
// % protected region % [Add any extra imports here] on begin
import { action, computed, observable } from 'mobx';
import Tabs, { ITabConfig } from 'Views/Components/Tabs/Tabs';
import { Model } from 'Models/Model';
import { DocumentTypesEntity } from 'Models/Entities';
import { autorun } from 'mobx';
import { store } from "Models/Store";
import { gql } from "@apollo/client";
import { ITab } from 'Views/CustomComponents/ScrollTabs';
// % protected region % [Add any extra imports here] end

@observer
export default class TemplateEntityPage extends React.Component<RouteComponentProps> {
	// % protected region % [Add any extra attributes here] on begin
	@observable
    private tabs: ITab[] = [];

	@observable
    private currentTab: ITab | undefined = undefined;

	componentDidMount() {
		autorun(() => {
            if (this.props.location.pathname) {
                this.fetchDocumentTypes();
			}
		});
	}

	@action
	async fetchDocumentTypes() {
		const fetchDocumentTypes = gql`query getDocumentTypes {
                documentTypesEntitys(
                    where: {path: "meaDocument", comparison: equal, value: "true", negate: true },
                    orderBy: {path: "order"},
                    has: [[{
                        path: "templatess",
                    }]]
                )
				{
					name
					description
					order
					roless {
						roles {
							name
						}
					}
                    templatess {
						id
					}
				}
			}`;
            
        store.apolloClient.query({
            query: fetchDocumentTypes,
            fetchPolicy: 'no-cache',
        }).then(action(result => {
            this.tabs = [];
            result.data.documentTypesEntitys.forEach((documentType : DocumentTypesEntity) => {
				this.tabs.push({
					name: documentType.name,
					key: encodeURIComponent(documentType.name),
					tooltip: documentType.description,
				});
            });

            if (this.tabs.length) {
                // set current tab to expected default tab
                this.currentTab = this.tabs[0];
            }
        }));
	}
	// % protected region % [Add any extra attributes here] end

	private pageActions: IEntityContextMenuActions<Models.TemplateEntity> = [
		// % protected region % [Add any extra actions here] off begin
		// % protected region % [Add any extra actions here] end
	];

	public render() {
		let contents = null;

		// % protected region % [Override contents here] on begin
		const isRenderTabs = !(new RegExp("edit|view|create", "i")).test(this.props.location.pathname);
		contents = (
			<PageWrapper {...this.props}>
				{this.renderTabs(isRenderTabs)}
				<EntityCRUD
					modelType={Models.TemplateEntity}
					{...this.props}
					hasConditon={[[{path: "documentTypess" as keyof Model, conditions: [[{path: "documentTypes.name", comparison: "equal", value: this.documentType}]]}]]}
				/>
			</PageWrapper>
		);
		// % protected region % [Override contents here] end

		return (
			<SecuredPage groups={["Super Administrators", "MehubAccount","MehubAdmin"]}>
				{contents}
			</SecuredPage>
		);
	}

	// % protected region % [Add any extra functions here] on begin
	@observable 
	private tabIndex: number = 0;

	@computed
	private get documentType() {
		return this.getTabs()[this.tabIndex]?.name;
	}

	@action
	private changeTab = (index: number) => {
		this.tabIndex = index;
	}

	private getTabs(): ITabConfig[] {
		console.log(JSON.stringify(this.tabs, undefined, 2));
		return this.tabs.map(documentType => {
			return { name: documentType.name, key: documentType.key, component: (null) }
		});
	}

	private renderTabs(isRenderTabs: boolean) {
		if(isRenderTabs && this.tabs.length) {
			return <>
				<Tabs className='admin-template-tab-nav'
							tabs={this.getTabs()}
							onTabClicked={this.changeTab}
							defaultTab={0}
							currentTab={this.tabIndex}>
				</Tabs>
			</>;
		}
		return null;
	}
	// % protected region % [Add any extra functions here] end
}
