/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import React, { Component, ReactNode } from 'react';
import { observer } from 'mobx-react';
import {Form, Question} from '../../Schema/Question';
import { Combobox } from 'Views/Components/Combobox/Combobox';
import { TextArea } from 'Views/Components/TextArea/TextArea';
import TileOptions from './TileOptions';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface FormTileOptionsProps {
	schema: Form;
	question: Question;
	// % protected region % [Add to props here] off begin
	// % protected region % [Add to props here] end
}

@observer
export default class FormStatementOptions extends Component<FormTileOptionsProps> {


	private renderStylingOptions(): ReactNode {
		const { question } = this.props;
		// % protected region % [Override renderStylingOptions here] off begin
		return (
			<>
				<h4>Styling</h4>
				<Combobox
					model={question}
					label="Style"
					modelProperty="className"
					options={TileOptions.getStylingOptions(question)}
				/>
			</>
		);
		// % protected region % [Override renderStylingOptions here] end
	}


	public render(): ReactNode {
		return (
			<>
				<h4>Statement Text</h4>
				<TextArea
					model={this.props.question}
					modelProperty="content"
				/>
				<hr className='sidebar-separator'></hr>
				{this.renderStylingOptions()}
				<hr className='sidebar-separator'></hr>
			</>
		)
	}
}
