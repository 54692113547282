/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable, runInAction } from 'mobx';
import { Model, IModelAttributes, attribute, entity } from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from "Util/AttributeUtils";
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchManyToManyFunc,
	makeJoinEqualsFunc,
	makeFetchOneToManyFunc,
	makeEnumFetchFunction,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import { VisitorsFormsEntity } from 'Models/Security/Acl/VisitorsFormsEntity';
import { MehubAccountFormsEntity } from 'Models/Security/Acl/MehubAccountFormsEntity';
import { MehubAdminFormsEntity } from 'Models/Security/Acl/MehubAdminFormsEntity';
import * as Enums from '../Enums';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import { FormEntityData, FormEntityDataAttributes, getAllVersionsFn, getPublishedVersionFn } from 'Forms/FormEntityData';
import { FormVersion } from 'Forms/FormVersion';
import { fetchFormVersions, fetchPublishedVersion } from 'Forms/Forms';
import {SuperAdministratorScheme} from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] on begin
import {
	DocumentTypesEntity,
	FormsDocumentTypes,
	FormsMemberOrganisations,
	MemberOrganisationEntity
} from "Models/Entities";
import gql from 'graphql-tag';
import { store } from 'Models/Store';
// % protected region % [Add any further imports here] end

export interface IFormsEntityAttributes extends IModelAttributes, FormEntityDataAttributes {
	name: string;
	isDraft: boolean;
	formType: Enums.formTypes;
	isActive: boolean;
	isDefault: boolean;

	formPages: Array<Models.FormsEntityFormTileEntity | Models.IFormsEntityFormTileEntityAttributes>;
	documentTypess: Array<Models.FormsDocumentTypes | Models.IFormsDocumentTypesAttributes>;
	memberOrganisationss: Array<Models.FormsMemberOrganisations | Models.IFormsMemberOrganisationsAttributes>;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('FormsEntity', 'Forms')
// % protected region % [Customise your entity metadata here] end
export default class FormsEntity extends Model implements IFormsEntityAttributes, FormEntityData  {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsFormsEntity(),
		new MehubAccountFormsEntity(),
		new MehubAdminFormsEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Name'] off begin
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'Name',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public name: string;
	// % protected region % [Modify props to the crud options here for attribute 'Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Is Draft'] on begin
	/**
	 * A form can be saved as a draft when its being created
	 */
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'Is Draft',
		displayType: 'hidden',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public isDraft: boolean = false;
	// % protected region % [Modify props to the crud options here for attribute 'Is Draft'] end

	// % protected region % [Modify props to the crud options here for attribute 'Form type'] on begin
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'Form type',
		displayType: 'enum-combobox',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.formTypesOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.formTypesOptions),
		displayFunction: (attribute: Enums.formTypes) => Enums.formTypesOptions[attribute],
		onAfterChange: (model) => {
			// Local function to add document types to the model used by the switch statement below
			const addDocumentTypeToModel = (documentTypes: DocumentTypesEntity[], documentTypeNames: string[]):void => {
				// For each documentType name that needs to be added, add respective documentTypeEntity to the model
				documentTypeNames.forEach((documentTypeName) => {
					let documentTypeEntity = documentTypes.find((documentType)=> documentType.name === documentTypeName);
					if (documentTypeEntity) {
						/*
						 * FormsDocumentTypes entity is needed due to the many to many relationship between Forms and 
						 * DocumentTypes entities, therefore DocumentTypesEntity needs to be wrapped by FormsDocumentTypes
						 */ 
						let formsDocumentTypes = new FormsDocumentTypes({
							documentTypes: 	documentTypeEntity
						});
						model["documentTypess"].push(formsDocumentTypes);
					}
				})
			}
			
			// Clear out the document type selection
			action(model["documentTypess"].clear());

			// Fetch all document type entities and add to the model depending on the formType selected
			DocumentTypesEntity.fetch<DocumentTypesEntity>().then(action((documentTypes) => {
				switch (model['formType']) {
					case 'RISK_ASSESSMENT':
						addDocumentTypeToModel(documentTypes, ["SWMS"]);
						break;
					case 'INCIDENT':
						addDocumentTypeToModel(documentTypes, [
							"Business",
							"Field",
							"Office",
							"Workshop / Warehouse",
							"SWMS",
							"SDS",
							"Workplace Relations"
						]);
						break;
					case 'INSPECTIONS':
						addDocumentTypeToModel(documentTypes, ["Field", "Workshop / Warehouse"]);
						break;
					case 'PERMITS':
						addDocumentTypeToModel(documentTypes, ["Field"]);
						break;
					case 'VERIFICATION':
						addDocumentTypeToModel(documentTypes, ["Field"]);
						break;
					case 'PROJECT':
						addDocumentTypeToModel(documentTypes, ["SWMS"]);
						break;
					case 'QUALITY':
						addDocumentTypeToModel(documentTypes, ["Office"]);
						break;
					case 'WORKPLACE_RELATIONS':
						addDocumentTypeToModel(documentTypes, ["Workplace Relations"]);
						break;
				}
			}));
		}
	})
	public formType: Enums.formTypes;
	// % protected region % [Modify props to the crud options here for attribute 'Form type'] end

	// % protected region % [Modify props to the crud options here for attribute 'Is Active'] on begin
	@observable
	@attribute()
	@CRUD({
		name: 'Is Active',
		displayType: 'hidden',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public isActive: boolean = false;
	// % protected region % [Modify props to the crud options here for attribute 'Is Active'] end

	// % protected region % [Modify props to the crud options here for attribute 'Is Default'] off begin
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'Is Default',
		displayType: 'checkbox',
		order: 50,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public isDefault: boolean = false;
	// % protected region % [Modify props to the crud options here for attribute 'Is Default'] end

	@observable
	@attribute({isReference: true, manyReference: false})
	public formVersions: FormVersion[] = [];

	@observable
	@attribute()
	public publishedVersionId?: string;

	@observable
	@attribute({isReference: true, manyReference: false})
	public publishedVersion?: FormVersion;

	@observable
	@attribute({isReference: true, manyReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Form Page'] on begin
		name: "Form Pages",
		displayType: 'hidden',
		order: 50,
		referenceTypeFunc: () => Models.FormsEntityFormTileEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'formPages',
			oppositeEntity: () => Models.FormsEntityFormTileEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Form Page'] end
	})
	public formPages: Models.FormsEntityFormTileEntity[] = [];

	@Validators.Length(1)
	@Validators.Required()
	@observable
	@attribute({isReference: true, manyReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Document Types'] on begin
		name: 'Associated Document Types',
		displayType: 'reference-multicombobox',
		order: 60,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.FormsDocumentTypes,
		optionEqualFunc: makeJoinEqualsFunc('documentTypesId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'formsEntity',
			oppositeEntityName: 'documentTypesEntity',
			relationName: 'forms',
			relationOppositeName: 'documentTypes',
			entity: () => Models.FormsEntity,
			joinEntity: () => Models.FormsDocumentTypes,
			oppositeEntity: () => Models.DocumentTypesEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Document Types'] end
	})
	public documentTypess: Models.FormsDocumentTypes[] = [];

	/**
	 * One form can be assigned to many organisations
	 */
	@Validators.Length(1)
	@Validators.Required()
	@observable
	@attribute({isReference: true, manyReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Member Organisations'] on begin
		name: 'Member Organisations',
		displayType: 'reference-multicombobox-with-selectall',
		order: 70,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.FormsMemberOrganisations,
		optionEqualFunc: makeJoinEqualsFunc('memberOrganisationsId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'formsEntity',
			oppositeEntityName: 'memberOrganisationEntity',
			relationName: 'forms',
			relationOppositeName: 'memberOrganisations',
			entity: () => Models.FormsEntity,
			joinEntity: () => Models.FormsMemberOrganisations,
			oppositeEntity: () => Models.MemberOrganisationEntity,
		}),
		onSelectAll: async (model) => {
			const { data } = await store.apolloClient.query({
				query: gql`query memberOrganisationEntity($args: [[WhereExpressionGraph]], $skip: Int, $take: Int, $orderBy: [OrderByGraph], $ids: [ID], $has: [[HasConditionType]]) {
					memberOrganisationEntitys: memberOrganisationEntitysConditional(
					  conditions: $args
					  skip: $skip
					  take: $take
					  orderBy: $orderBy
					  ids: $ids
					  has: $has
					) {
					  id
					  created
					  modified
					  name
					  memberCode
					  isActive
					  primaryContactId
					  __typename
					}
					countMemberOrganisationEntitys: countMemberOrganisationEntitysConditional(
					  conditions: $args
					) {
					  number
					  __typename
					}
				}
				`,
				fetchPolicy: 'network-only',
			});
			
			runInAction(() => {
				model["memberOrganisationss"].clear();
				data["memberOrganisationEntitys"].forEach((memberOrganisation: MemberOrganisationEntity) => {
					/*
					 * FormsMemberOrganisations entity is needed due to the many to many relationship between Form and 
					 * MemberOrganisation entities, therefore memberOrganisation needs to be wrapped by FormsMemberOrganisations
					 */
					let formMemberOrganisation = new FormsMemberOrganisations({
						memberOrganisations: memberOrganisation
					});

					model["memberOrganisationss"].push(formMemberOrganisation);
				})
			});
		},
		onUnselectAll: (model) => {
			model["memberOrganisationss"].clear();
		}
		// % protected region % [Modify props to the crud options here for reference 'Member Organisations'] end
	})
	public memberOrganisationss: Models.FormsMemberOrganisations[] = [];

	// % protected region % [Add any custom attributes to the model here] on begin
	@observable
	@CRUD({
		name:'Version',
		displayType: 'textfield',
		headerColumn: false,
		createFieldType: "hidden",
		isReadonly: true,
	})
	public latestFormVersion: number;
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IFormsEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IFormsEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.isDraft !== undefined) {
				this.isDraft = attributes.isDraft;
			}
			if (attributes.formType !== undefined) {
				this.formType = attributes.formType;
			}
			if (attributes.isActive !== undefined) {
				this.isActive = attributes.isActive;
			}
			if (attributes.isDefault !== undefined) {
				this.isDefault = attributes.isDefault;
			}
			if (attributes.publishedVersionId !== undefined) {
				this.publishedVersionId = attributes.publishedVersionId;
			}
			if (attributes.publishedVersion !== undefined) {
				if (attributes.publishedVersion === null) {
					this.publishedVersion = attributes.publishedVersion;
				} else {
					this.publishedVersion = attributes.publishedVersion;
					this.publishedVersionId = attributes.publishedVersion.id;
					if (typeof attributes.publishedVersion.formData === 'string') {
						this.publishedVersion.formData = JSON.parse(attributes.publishedVersion.formData);
					}
				}
			}
			if (attributes.formVersions !== undefined) {
				this.formVersions.push(...attributes.formVersions);
			}
			if (attributes.name !== undefined) {
				this.name = attributes.name;
			}
			if (attributes.formPages !== undefined && Array.isArray(attributes.formPages)) {
				for (const model of attributes.formPages) {
					if (model instanceof Models.FormsEntityFormTileEntity) {
						this.formPages.push(model);
					} else {
						this.formPages.push(new Models.FormsEntityFormTileEntity(model));
					}
				}
			}
			if (attributes.documentTypess !== undefined && Array.isArray(attributes.documentTypess)) {
				for (const model of attributes.documentTypess) {
					if (model instanceof Models.FormsDocumentTypes) {
						this.documentTypess.push(model);
					} else {
						this.documentTypess.push(new Models.FormsDocumentTypes(model));
					}
				}
			}
			if (attributes.memberOrganisationss !== undefined && Array.isArray(attributes.memberOrganisationss)) {
				for (const model of attributes.memberOrganisationss) {
					if (model instanceof Models.FormsMemberOrganisations) {
						this.memberOrganisationss.push(model);
					} else {
						this.memberOrganisationss.push(new Models.FormsMemberOrganisations(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] on begin
			this.formVersions.forEach(version => {
				// When saving formVersion as a draft, it appears that the formData is not correctly being parsed
				if (version && typeof version.formData === 'string') {
					version.formData = JSON.parse(version.formData);
				}
			});
			
			this.latestFormVersion = attributes.publishedVersion?.version || 0;
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] on begin
	public defaultExpands = `
		publishedVersion {
			id
			version
			created
			modified
			formData
		}
		documentTypess {
			${Models.FormsDocumentTypes.getAttributes().join('\n')}
			documentTypes {
				${Models.DocumentTypesEntity.getAttributes().join('\n')}
			}
		}
		memberOrganisationss {
			${Models.FormsMemberOrganisations.getAttributes().join('\n')}
			memberOrganisations {
				${Models.MemberOrganisationEntity.getAttributes().join('\n')}
			}
		}
		formPages {
			${Models.FormsEntityFormTileEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath = {
			documentTypess: {},
			memberOrganisationss: {},
			formPages: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'documentTypess',
							'memberOrganisationss',
						]
					},
				],
			}
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.name;
		// % protected region % [Customise the display name for this entity] end
	}

	/**
	 * Gets all the versions for this form.
	 */
	public getAllVersions: getAllVersionsFn = (includeSubmissions?, conditions?) => {
		// % protected region % [Modify the getAllVersionsFn here] off begin
		return fetchFormVersions(this, includeSubmissions, conditions)
			.then(d => {
				runInAction(() => this.formVersions = d);
				return d.map(x => x.formData)
			});
		// % protected region % [Modify the getAllVersionsFn here] end
	};

	/**
	 * Gets the published version for this form.
	 */
	public getPublishedVersion: getPublishedVersionFn = includeSubmissions => {
		// % protected region % [Modify the getPublishedVersionFn here] off begin
		return fetchPublishedVersion(this, includeSubmissions)
			.then(d => {
				runInAction(() => this.publishedVersion = d);
				return d ? d.formData : undefined;
			});
		// % protected region % [Modify the getPublishedVersionFn here] end
	};

	/**
	 * Gets the submission entity type for this form.
	 */
	public getSubmissionEntity = () => {
		// % protected region % [Modify the getSubmissionEntity here] off begin
		return Models.FormSubmissionsEntity;
		// % protected region % [Modify the getSubmissionEntity here] end
	}


	// % protected region % [Add any further custom model features here] on begin
	public static async fetchForms(): Promise<FormsEntity[]> {
		const { data } = await store.apolloClient.query({
			query: gql`query {
				formsEntitys {
					id
					name
				}
			}`,
			fetchPolicy: 'network-only',
		});
		return data["formsEntitys"].map((r: any) => new this(r));
	}

	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [ createdAttr, modifiedAttr ] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(FormsEntity.prototype, 'created');
CRUD(modifiedAttr)(FormsEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
