import React from "react";
import {Breadcrumbs} from "../Components/Breadcrumbs/Breadcrumbs";
import {Route, RouteComponentProps, Switch} from "react-router";
import {IBreadCrumbsTag} from "../Components/Breadcrumbs/Breadcrumbs";

interface RouteLink {
    path: string;
    name: string;
    notSafetyLink?: boolean;
}

const routes: RouteLink[] = [
    { path: "/mesafety", name: "Safety", notSafetyLink: true },
    { path: "/user-submissions/:type", name: "View Submissions" },
    { path: "/user-submissions/:type/:id/view/:id", name: "View" },
    { path: "/user-submissions/:type/:id/edit/:id", name: "Edit" },
    { path: "/my-documents", name: "My Documents" },
    { path: "/my-documents/create", name: "Create Document" },
    { path: "/my-documents/view/:id", name: "View Document" },
    { path: "/my-documents/edit/:id", name: "Edit Document" },
    { path: "/mea-documents", name: "MEA Documents" },
    { path: "/mea-documents/:id/view/:id", name: "View MEA Document" },
    // { path: "/assets", name: "My Assets" },
    { path: "/assets-:pageNo?", name: "My Assets" },
    { path: "/assets-:pageNo?/:id/create", name: "Create Asset" },
    { path: "/assets-:pageNo?/:id/view/:id", name: "View Asset" },
    { path: "/assets-:pageNo?/:id/edit/:id", name: "Edit Asset" },
    { path: "/users", name: "My Users" },
    { path: "/users/create", name: "Create User" },
    { path: "/users/view/:id", name: "View User" },
    { path: "/users/edit/:id", name: "Edit User" },
    { path: "/users/:id/licences/create", name: "Upload License" },
    { path: "/licences/view/:id", name: "View License" },
    { path: "/licences/edit/:id", name: "Edit License" },
    { path: "/safety-template/:type", name: "Safety Template Library" },
    { path: "/safety-template/:type/:id/view/:id", name: "View" },
    { path: "/integrations", name: "My Integrations" },
    { path: "/standards", name: "Standards", notSafetyLink: true },
    { path: "/contactus", name: "Help and Feedback", notSafetyLink: true }
];

interface BreadcrumbNavigationProps {
    lastCrumb?: IBreadCrumbsTag;
}

export function filterRoutes (props: RouteComponentProps, notSafetyLink?: boolean) : IBreadCrumbsTag[] {
    let crumbs: IBreadCrumbsTag[] = [{link: '/', label: 'Home'}];
    if (!notSafetyLink) {
        crumbs.push({link: '/mesafety', label: 'Safety'});
    }
    let addType = false;
    routes
        // Get all routes that contain the current one.
        .filter(({path}) => props.match.path.includes(path))
        .map(({path, name}) => {
            if (props.match.params['type'] && !addType
                && (props.match.path.includes('/user-submissions')
                    || props.match.path.includes('/safety-template'))) {
                crumbs.push({
                    link: path.replace(':type', props.match.params['type']),
                    label: name,
                }, {
                    link: path.replace(':type', props.match.params['type']),
                    label: props.match.params['type'],
                });
                addType = true;
            } else if (!path.includes("edit")
                && !path.includes("view")) {
                crumbs.push({
                    link: path,
                    label: name,
                })
            }
        });
    return crumbs;
}

export default class BreadcrumbNavigation extends React.Component<BreadcrumbNavigationProps> {
    
    
    render() {
        return (
            <Switch>
                {routes.map((route: RouteLink, key) => (
                    <Route
                        exact
                        path={route.path}
                        key={key}
                        render={props => {
                            let crumbs = filterRoutes(props, route.notSafetyLink);
                            if (this.props.lastCrumb 
                                && (route.path.includes("view") || route.path.includes("edit"))) {
                                crumbs.push(this.props.lastCrumb);
                            }
                            return (
                                <Breadcrumbs 
                                    key={route.path} 
                                    tags={crumbs} 
                                />
                            );
                        }}
                    />
                ))}
            </Switch>
        );
    }
}
