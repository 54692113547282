/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import _ from 'lodash';
import { action, computed, observable } from 'mobx';
import { observer } from 'mobx-react';

import { jsonReplacerFn, Model } from 'Models/Model';
import { FormTile, IFormProps } from './FormTile';
import { FormEntityData, SubmissionEntityData } from 'Forms/FormEntityData';
// % protected region % [Add any further imports here] on begin
import { runInAction } from 'mobx';
import {FormSubmissionsEntity, MehubAccountEntity} from "../Models/Entities";
import { QuestionIdSubstring } from './Questions/QuestionUtils';
import axios from 'axios';
import { SERVER_URL } from 'Constants';
import {store} from "../Models/Store";
// % protected region % [Add any further imports here] end

export interface IFormEntityTileProps<T extends FormEntityData> extends IFormProps {
	model: T;
	onAfterSubmit?: (entity: Model & SubmissionEntityData, savedAsDraft: boolean) => void;
	routePath?: string;
	// % protected region % [Add extra form entity tile props here] on begin
	submissionEntity?: FormSubmissionsEntity;
	// % protected region % [Add extra form entity tile props here] end
}

/**
 * Managed form that handles submit getting published forms and submit logic automatically
 */
@observer
export class FormEntityTile<T extends FormEntityData> extends React.Component<IFormEntityTileProps<T>> {
	@observable
	private submissionEntity: Model & SubmissionEntityData = new (this.props.model.getSubmissionEntity())();

	// % protected region % [Add extra class fields here] on begin
	componentDidMount() {
			runInAction(() => {
				if (this.props.submissionEntity) {
					this.submissionEntity = this.props.submissionEntity
				}
			});
	}
	
	private onCancel = async () => {
		if (this.submissionEntity.id) {
			await this.submissionEntity.delete();
		}
	}
	
	@action
	private completeForm = () => {
		let submissionEntity = this.submissionEntity as FormSubmissionsEntity;
		
		submissionEntity.isCompleted = true;
		
		this.onSubmit();
	};
	// % protected region % [Add extra class fields here] end

	private submissionTransform: jsonReplacerFn = (input) => {
		// % protected region % [Override submissionTransform here] off begin
		const submissionData = input['submissionData'];
		if (submissionData) {
			input['submissionData'] = JSON.stringify(submissionData);
		}
		return input;
		// % protected region % [Override submissionTransform here] end
	}

	@action
	// % protected region % [Modify the onSubmit behaviour of forms here] on begin
	private onSubmit = async () => {
		if (this.formVersion) {
			this.submissionEntity.formVersionId = this.formVersion.id;
			const questions = _
				.flatMap(this.formVersion.formData.slides, s => s.contents)
				.filter(q => q.property !== undefined);
			const excludedAttributes = ['id', 'created', 'modified', 'submissionData', 'formVersionId'];
			const submissionAttributes = _.without(this.submissionEntity.attributes, ...excludedAttributes);
			for (const question of questions) {
				const data = this.submissionEntity.submissionData[question.id];
				const property = question.property;
				if (data !== undefined && data !== null && property && submissionAttributes.indexOf(property) > -1) {
					this.submissionEntity.assignAttributes({[property]: data});
				}
			}
		}

		let usersSelected: MehubAccountEntity[];
		let submissionEntity = this.submissionEntity as FormSubmissionsEntity;
		
		// find notification slide if it exists
		const notificationSlide = Object.entries(submissionEntity.submissionData)
			.filter(q => q[0].includes(QuestionIdSubstring.NOTIFICATION_SLIDE_USERSELECT));
		
		// find selected users from notification slide
		let notificationUserSelections: any;
		if(notificationSlide && notificationSlide.length > 0){
			notificationUserSelections = notificationSlide[0][1];
		}

		// find user entities for all notification slide users
		if(notificationUserSelections && notificationUserSelections.length > 0){
			await MehubAccountEntity.fetch<MehubAccountEntity>({
				args: [[{
					path: 'email',
					comparison: "equal",
					value: store.email
				}]]
			}).then(async data => {
				if (data && data.length) {
					if (data[0].memberOrganisation) { // prevent crash when no member org is set
						let organisationId = data[0].memberOrganisation.id;
						const foundUsers = await MehubAccountEntity.fetch<MehubAccountEntity>(
							{
								args:
									[[
										{path: 'memberOrganisation.id', comparison: "equal", value: organisationId}
									]]
							});
						return foundUsers.filter(u => notificationUserSelections.includes(u.firstname + ' ' + u.surname));
					} else {
						console.warn("User has no member org");
					}
				}
				return [];
			}).then((data) => usersSelected = data);
		}
		
		return submissionEntity.save({submissionData: {}, additionalAttachmentss: {}}, {
			jsonTransformFn: this.submissionTransform, contentType: "multipart/form-data"
		})
			.then(() => {
				if (submissionEntity.isCompleted) {
					// trigger email to all users on notification slide
					if(usersSelected && usersSelected.length > 0){
						usersSelected.forEach((user: MehubAccountEntity) => {
							// make API call
							axios.post(
								`${SERVER_URL}/api/account/send-submission-notification-email`,
								{
									userName: user.userName,
									submissionId: submissionEntity.id,
								}
								).then(({data}) => {
										// do anything that needs to be done after
									}
								)
						})
					}
					
				}

				if (this.props.onAfterSubmit) {
					return this.props.onAfterSubmit(this.submissionEntity, submissionEntity.isCompleted ? !submissionEntity.isCompleted : true);
				}
			});
	}
	
	// % protected region % [Modify the onSubmit behaviour of forms here] end

	@computed
	public get formVersion() {
		// % protected region % [Override formVersion here] on begin
		return this.props.submissionEntity ? this.props.submissionEntity.formVersion 
			: this.props.model.publishedVersion;
		// % protected region % [Override formVersion here] end
	}

	public render() {
		// % protected region % [Override render here] on begin
		if (this.formVersion) {
			return <FormTile
				{...this.props}
				formId={this.props.model['id']}
				model={this.submissionEntity.submissionData}
				schema={this.formVersion.formData}
				onCancel={this.onCancel}
				onSubmit={this.completeForm}
				onSaveDraft={this.onSubmit}
				formTitle={this.props.model.name}
				submissionEntity={this.submissionEntity as FormSubmissionsEntity}
			/>;
		}
		return <p>There is no published version for this form.</p>;
		// % protected region % [Override render here] end
	}
}