/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { Model, IModelAttributes, attribute, entity } from 'Models/Model';
import * as Models from 'Models/Entities';
import { IAcl } from '../Security/IAcl';
import { observable } from 'mobx';
import { VisitorsDocumentsReferenceManyToMany } from '../Security/Acl/VisitorsDocumentsReferenceManyToMany';
import { MehubAccountDocumentsReferenceManyToMany } from '../Security/Acl/MehubAccountDocumentsReferenceManyToMany';
import { MehubAdminDocumentsReferenceManyToMany } from '../Security/Acl/MehubAdminDocumentsReferenceManyToMany';

// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IFormSubmissionsDocumentsAttributes extends IModelAttributes {
	formSubmissionsId: string;
	documentsId: string;

	formSubmissions: Models.FormSubmissionsEntity | Models.IFormSubmissionsEntityAttributes;
	documents: Models.DocumentEntity | Models.IDocumentEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

@entity('FormSubmissionsDocuments')
export default class FormSubmissionsDocuments extends Model implements IFormSubmissionsDocumentsAttributes {
	public static acls: IAcl[] = [
		new VisitorsDocumentsReferenceManyToMany(),
		new MehubAccountDocumentsReferenceManyToMany(),
		new MehubAdminDocumentsReferenceManyToMany(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	@observable
	@attribute()
	public formSubmissionsId: string;

	@observable
	@attribute()
	public documentsId: string;

	@observable
	@attribute({isReference: true})
	public formSubmissions: Models.FormSubmissionsEntity;

	@observable
	@attribute({isReference: true})
	public documents: Models.DocumentEntity;
	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	constructor(attributes?: Partial<IFormSubmissionsDocumentsAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		if (attributes) {
			if (attributes.formSubmissionsId) {
				this.formSubmissionsId = attributes.formSubmissionsId;
			}
			if (attributes.documentsId) {
				this.documentsId = attributes.documentsId;
			}

			if (attributes.formSubmissions) {
				if (attributes.formSubmissions instanceof Models.FormSubmissionsEntity) {
					this.formSubmissions = attributes.formSubmissions;
					this.formSubmissionsId = attributes.formSubmissions.id;
				} else {
					this.formSubmissions = new Models.FormSubmissionsEntity(attributes.formSubmissions);
					this.formSubmissionsId = this.formSubmissions.id;
				}
			} else if (attributes.formSubmissionsId !== undefined) {
				this.formSubmissionsId = attributes.formSubmissionsId;
			}

			if (attributes.documents) {
				if (attributes.documents instanceof Models.DocumentEntity) {
					this.documents = attributes.documents;
					this.documentsId = attributes.documents.id;
				} else {
					this.documents = new Models.DocumentEntity(attributes.documents);
					this.documentsId = this.documents.id;
				}
			} else if (attributes.documentsId !== undefined) {
				this.documentsId = attributes.documentsId;
			}
		}

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}