/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import { MehubAccountEntity } from 'Models/Entities';
import SecuredPage from 'Views/Components/Security/SecuredPage';
import EntityCRUD from 'Views/Components/CRUD/EntityCRUD';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router';
import { getFrontendNavLinks } from 'Views/FrontendNavLinks';
import Navigation, { Orientation } from 'Views/Components/Navigation/Navigation';

// % protected region % [Add any extra imports here] on begin
import {store} from "../../Models/Store";
import Tabs, {ITabConfig} from "../Components/Tabs/Tabs";
import {action, autorun, observable} from "mobx";
import {AdditionalBulkActions} from "../Components/CRUD/EntityCollection";
import {Model} from "../../Models/Model";
import {SecurityService} from "../../Services/SecurityService";
import * as Models from "../../Models/Entities";
import {exportUserPDF, exportUserPdfs} from "../../Util/PdfExportUtils";
import * as Enums from "../../Models/Enums";
import If from "../Components/If/If";
import UserViewPage from "./UserViewPage";
import {Button, Colors, Display, Sizes} from "../Components/Button/Button";
import {EntityFormMode} from "../Components/Helpers/Common";
import MarketingTile from 'Views/CustomComponents/MarketingTile';
import BreadcrumbNavigation from "../CustomComponents/BreadcrumbNavigation";
import {gql} from "@apollo/client";
import {IBreadCrumbsTag} from "../Components/Breadcrumbs/Breadcrumbs";
import moment from 'moment';
// % protected region % [Add any extra imports here] end

export interface UsersPageProps extends RouteComponentProps {
	// % protected region % [Add any extra props here] off begin
	// % protected region % [Add any extra props here] end
}

@observer
// % protected region % [Add any customisations to default class definition here] off begin
class UsersPage extends React.Component<UsersPageProps> {
// % protected region % [Add any customisations to default class definition here] end

	// % protected region % [Add class properties here] on begin
	@observable
	private tabIndex: number = 0;
	
	@observable
	private mehubAccount?: MehubAccountEntity;

	private getUserNameQuery = gql`query getUserName($id: [String]) {
		  mehubAccountEntity(where: {path: "id", comparison: equal, value: $id}) {
		   userName
			firstname
		   surname
		  }
		}`;

	@observable
	private lastCrumb: IBreadCrumbsTag | undefined;

	componentDidMount() {
		autorun(() => {
			if (this.props.location.pathname.includes("edit")
				|| this.props.location.pathname.includes("view")) {
				const listPathName = this.props.location.pathname.split('/');
				const id = listPathName[listPathName.length - 1];
				const mode = this.props.location.pathname.includes("edit") ? 'Edit' : 'View';
				store.apolloClient.query({
					query: this.getUserNameQuery,
					fetchPolicy: 'no-cache',
					variables: {id: id}
				}).then(action(result => {
					const user = result.data.mehubAccountEntity;
					let userName = '';
					if (user.firstname) {
						userName += user.firstname;
					}
					if (user.surname)  {
						userName += ` ${user.surname}`;
					}
					if (!userName) {
						userName = user.userName;
					}
					this.lastCrumb = {label: `${mode} ${userName}`,
						link: this.props.location.pathname};
				}))
			} else if (!this.props.location.pathname.includes("licences/create")) {
				action(() => this.lastCrumb = undefined);
			}
		})
	}

	@action
	private changeTab = (index: number) => {
		this.tabIndex = index;
	}

	private getTabs(): ITabConfig[] {
		let tabs = [];

		tabs.push({
			name: 'Active users',
			key: 'true',
			component: null
		});

		tabs.push({
			name: 'Inactive users',
			key: 'false',
			component: null
		});

		return tabs;
	}

	private renderTabs(isRenderTabs: boolean) {
		if (isRenderTabs) {
			return <Tabs className='project-tab-nav-users'
						 tabs={this.getTabs()}
						 onTabClicked={this.changeTab}
						 defaultTab={0}
						 currentTab={this.tabIndex}>
			</Tabs>;
		}
		return null;
	}

	// % protected region % [Add class properties here] end

	render() {
		// % protected region % [Add logic before rendering contents here] off begin
		// % protected region % [Add logic before rendering contents here] end

		let contents = (
			<SecuredPage groups={['Super Administrators']}>
				{
				// % protected region % [Alter navigation here] off begin
				}
				<Navigation
					linkGroups={getFrontendNavLinks(this.props)}
					orientation={Orientation.VERTICAL}
					match={this.props.match}
					location={this.props.location}
					history={this.props.history}
					staticContext={this.props.staticContext}
				/>
				{
				// % protected region % [Alter navigation here] end
				}
				<div className="body-content">
					<EntityCRUD
						{...this.props}
						modelType={MehubAccountEntity}
						URLExtension="a4d68d37-5dd2-431a-989e-dee2c06a7648"
						// % protected region % [Add props to crud component a4d68d37-5dd2-431a-989e-dee2c06a7648 here] off begin
						// % protected region % [Add props to crud component a4d68d37-5dd2-431a-989e-dee2c06a7648 here] end
					/>
				</div>
			</SecuredPage>
		);

		// % protected region % [Override contents here] on begin
		const isRenderTabs = !(new RegExp("edit|view|create", "i")).test(this.props.location.pathname);

		const additionalBulkActions: Array<AdditionalBulkActions<Model>> = [];
		if (SecurityService.canRead(Models.MehubAccountEntity) && store.roleName !== Enums.mehubUserRoleOptions.SAFETY_WORKER) {
			additionalBulkActions.push({
				bulkAction: exportUserPDF,
				label: "PDF Export",
				showIcon: true,
				icon: "download",
				iconPos: 'icon-left',
			});
		}

		let displayMarketingTile = false;

		switch (store.roleName) {
			case Enums.mehubUserRoleOptions.MANAGER:
				displayMarketingTile = true;
				break;
		}
		
		let header = (
			<section className="page-header-new">
				<div className="breadcrumb-button">
					<BreadcrumbNavigation lastCrumb={this.lastCrumb} />
				</div>
				<div className="page-controls">
					<div className="page-controls__title">
						<h2>User management</h2>
					</div>
				</div>
			</section>
		);

		let viewPageHeader = (
			<section className="page-header-new">
				<div className="breadcrumb-button">
					<BreadcrumbNavigation lastCrumb={this.lastCrumb} />
				</div>
				<div className="page-controls profile-view-page-header">
					<div className="page-controls__title">
						<div className="user-details">
							<h2>{this.mehubAccount?.getDisplayName() ?? ''}</h2>
							<p>{this.mehubAccount?.memnetID.toString() ?? ''}</p>
						</div>
						<div className="user-view-button-group">
							<Button display={Display.Solid}
									colors={Colors.FormPrimary}
									sizes={Sizes.Medium}
									key="cancel"
									onClick={() => this.mehubAccount ? exportUserPdfs([this.mehubAccount.id]) : undefined}>
								Export as PDF
							</Button>
							<Button display={Display.Solid}
									colors={Colors.FormPrimary}
									sizes={Sizes.Medium}
									key="cancel"
									onClick={() => store.routerHistory
										.push(`/users/${this.mehubAccount?.id ?? ''}/licences/create`)}>
								Upload licenses
							</Button>
						</div>
					</div>
				</div>
			</section>
		);
		contents = (
			<SecuredPage groups={['Super Administrators', 'MehubAccount', 'MehubAdmin']}>
				<Navigation
					linkGroups={getFrontendNavLinks(this.props)}
					orientation={Orientation.HORIZONTAL}
					match={this.props.match}
					location={this.props.location}
					history={this.props.history}
					staticContext={this.props.staticContext}
					className={'hub-navigation'}
				/>
				<div className="body-content frontend-users-page">
					<If condition={displayMarketingTile}>
						{header}
						<section className="primary-safety-tiles">
							<div className="primary-safety-tiles__tiles">
								<MarketingTile/>
							</div>
						</section>
					</If>
					<If condition={!displayMarketingTile}>
						{this.props.location.pathname.includes('/view/') || this.props.location.pathname.includes('/edit/') ? viewPageHeader : header}
						{this.renderTabs(isRenderTabs)}
						<EntityCRUD
							{...this.props}
							modelType={MehubAccountEntity}
							filterCollection={[[{
								path: "Lastsyncdate",
								comparison: this.getTabs()[this.tabIndex].key === 'true'?'greaterThanOrEqual':'lessThan',
								value: moment().startOf('day')
							}]]}
							initialFilterApplied = {isRenderTabs}
							disableBulkExport
							additionalBulkActions={additionalBulkActions}
							collectionViewAction={() => ({
								label: 'View',
								action: () => {},
								customButton: (model) => (store.roleName === 'SafetyWorker' && model.id !== store.userId) ? undefined :
									(
										<Button
											onClick={() => store.routerHistory.push(`/users/view/${model.id}`)}
											icon={{ icon: 'look', iconPos: 'icon-top' }}>
											View
										</Button>
									),
							})}
							viewComponent={(routeProps) => <UserViewPage 
									{...this.props}
									id={routeProps.match.params['id']}
									formMode={EntityFormMode.VIEW}
									setUser={action(user => this.mehubAccount = user)}
							/>}
							collectionUpdateAction={() => ({
								label: 'Edit',
								action: () => {},
								customButton: (model) => (store.roleName === 'SafetyWorker' && model.id !== store.userId) ? undefined : 
									(
										<Button
											onClick={() => store.routerHistory.push(`/users/edit/${model.id}`)}
											icon={{ icon: 'edit', iconPos: 'icon-top' }}>
											Edit
										</Button>
									),
							})}
							editComponent={(routeProps) => <UserViewPage
									{...this.props}
									id={routeProps.match.params['id']}
									formMode={EntityFormMode.EDIT}
									setUser={action(user => this.mehubAccount = user)}
								/>}
							hideSelectAll
						/>
					</If>
				</div>
			</SecuredPage>
		);
		// % protected region % [Override contents here] end

		return contents;
	}
}

// % protected region % [Override export here] off begin
export default UsersPage;
// % protected region % [Override export here] end
