/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import { FormSubmissionsEntity } from 'Models/Entities';
import SecuredPage from 'Views/Components/Security/SecuredPage';
import EntityCRUD from 'Views/Components/CRUD/EntityCRUD';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router';
import { getFrontendNavLinks } from 'Views/FrontendNavLinks';
import Navigation, { Orientation } from 'Views/Components/Navigation/Navigation';

// % protected region % [Add any extra imports here] on begin
import {AdditionalBulkActions} from "../Components/CRUD/EntityCollection";
import {Model} from "../../Models/Model";
import {SecurityService} from "../../Services/SecurityService";
import * as Models from "../../Models/Entities";
import {exportPDF} from "../../Util/PdfExportUtils";
import {store} from "../../Models/Store";
import {Comparators} from "../Components/ModelCollection/ModelQuery";
import {formTypesOptions, mehubUserRoleOptions} from "../../Models/Enums";
import {displayType} from "../../Models/CRUDOptions";
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import alertToast from "../../Util/ToastifyUtils";
import BreadcrumbNavigation from "../CustomComponents/BreadcrumbNavigation";
import {gql} from "@apollo/client";
import {action, observable, autorun} from "mobx";
import {IBreadCrumbsTag} from "../Components/Breadcrumbs/Breadcrumbs";

export interface IUserSubmissionsPageRoute {
	formType: string;
}
// % protected region % [Add any extra imports here] end

export interface UserSubmissionsPageProps extends RouteComponentProps {
	// % protected region % [Add any extra props here] off begin
	// % protected region % [Add any extra props here] end
}

@observer
// % protected region % [Add any customisations to default class definition here] on begin
class UserSubmissionsPage extends React.Component<RouteComponentProps<IUserSubmissionsPageRoute>, UserSubmissionsPageProps> {
// % protected region % [Add any customisations to default class definition here] end

	// % protected region % [Add class properties here] on begin
	private getFormNameQuery = gql`query getFormName($id: [String]) {
      formSubmissionsEntity(where: {path: "id", comparison: equal, value: $id}) {
        formVersion {
          form {
            name
          }
        }
      }
    }`;
	
	@observable
	private lastCrumb: IBreadCrumbsTag | undefined;
	
	componentDidMount() {
		autorun(() => {
			if (this.props.location.pathname.includes("edit")
				|| this.props.location.pathname.includes("view")) {
				const listPathName = this.props.location.pathname.split('/');
				const id = listPathName[listPathName.length - 1];
				const mode = this.props.location.pathname.includes("edit") ? 'Edit' : 'View';
				store.apolloClient.query({
					query: this.getFormNameQuery,
					fetchPolicy: 'no-cache',
					variables: {id: id}
				}).then(action(result => {
					this.lastCrumb = {label: `${mode} ${result.data.formSubmissionsEntity.formVersion.form.name}`,
						link: this.props.location.pathname};
				}))
			} else {
				action(() => this.lastCrumb = undefined);
			}
		})
	}

	// % protected region % [Add class properties here] end

	render() {
		// % protected region % [Add logic before rendering contents here] on begin
		const additionalBulkActions: Array<AdditionalBulkActions<Model>> = [];

		if (SecurityService.canRead(Models.FormSubmissionsEntity)) {
			additionalBulkActions.push({
				bulkAction: exportPDF,
				label: "PDF Export",
				showIcon: true,
				icon: "download",
				iconPos: 'icon-left',
			});
		}
		
		// Need to transform human readable formType back to formType enum to use in filter
		let formType = undefined;
		Object.entries(formTypesOptions).forEach(([formTypeKey, formTypeValue])=>{
			if (formTypeValue === decodeURIComponent(this.props.match.params.formType)) {
				formType = formTypeKey;
			}
		})

		// % protected region % [Add logic before rendering contents here] end

		let contents = (
			<SecuredPage groups={['Super Administrators', 'MehubAccount', 'MehubAdmin']}>
				{
				// % protected region % [Alter navigation here] on begin
				}
				<Navigation
					linkGroups={getFrontendNavLinks(this.props)}
					orientation={Orientation.HORIZONTAL}
					match={this.props.match}
					location={this.props.location}
					history={this.props.history}
					staticContext={this.props.staticContext}
					className={'hub-navigation'}
				/>
				{
				// % protected region % [Alter navigation here] end
				}
				<div className="body-content">
					<EntityCRUD
						{...this.props}
						modelType={FormSubmissionsEntity}
						URLExtension="931fca3e-8864-4b41-ad21-f822021b6edf"
						// % protected region % [Add props to crud component 931fca3e-8864-4b41-ad21-f822021b6edf here] on begin
						additionalFilters={[
							{
								path: 'formVersion.form.formType',
								comparison: "equal" as Comparators | 'range',
								value1: formType ? [formType] : [] as string[],
								value2: undefined,
								active:  true,
								displayName: 'Form Type',
								displayType: "enum-combobox" as displayType,
								enumResolveFunction: Object.entries(formTypesOptions).map(([ key, value ]) => ({
									display: value,
									value: key
								})),
							}
						]}
						entityCollectionProps={{
							initialFilterApplied: !!formType,
						}}
						collectionCreateAction={() => undefined}
						additionalBulkActions={additionalBulkActions}
						// % protected region % [Add props to crud component 931fca3e-8864-4b41-ad21-f822021b6edf here] end
					/>
				</div>
			</SecuredPage>
		);

		// % protected region % [Override contents here] on begin
		let header = (
            <section className="page-header-new">
                <div className="breadcrumb-button">
                    <BreadcrumbNavigation lastCrumb={this.lastCrumb} />
                </div>
                <div className="page-controls">
                    <div className="page-controls__title">
                        <h2>View Submissions</h2>
                    </div>
                </div>
            </section>
        );
		
		contents = (
			<SecuredPage groups={['Super Administrators', 'Visitors', 'MehubAccount', 'MehubAdmin']}>
				<Navigation
					linkGroups={getFrontendNavLinks(this.props)}
					orientation={Orientation.HORIZONTAL}
					match={this.props.match}
					location={this.props.location}
					history={this.props.history}
					staticContext={this.props.staticContext}
					className={'hub-navigation'}
				/>
				<div className="body-content user-submissions">
					{header}
					<EntityCRUD
						{...this.props}
						modelType={FormSubmissionsEntity}
						URLExtension="931fca3e-8864-4b41-ad21-f822021b6edf"
						additionalFilters={[
							{
								path: 'formVersion.form.formType',
								comparison: "equal" as Comparators | 'range',
								value1: formType ? [formType] : [] as string[],
								value2: undefined,
								active:  true,
								displayName: 'Form Type',
								displayType: "enum-combobox" as displayType,
								enumResolveFunction: Object.entries(formTypesOptions).map(([ key, value ]) => ({
									display: value,
									value: key
								})),
							}
						]}
						entityCollectionProps={{
							initialFilterApplied: !!formType,
						}}
						removeViewAction={store.roleName === mehubUserRoleOptions.SAFETY_MANAGER || store.roleName === mehubUserRoleOptions.MANAGER}
						mutateOptions={(model, options, mode) => {
							if(model instanceof FormSubmissionsEntity) {
								if(mode === EntityFormMode.VIEW) {
									options.forEach(option => {
										if(option.attributeName === 'submissionData') {
											option.className = "submissionDataView";
										}
									});
								}
							}
							return options;
						}}
						collectionCreateAction={() => undefined}
						additionalBulkActions={additionalBulkActions}
						filterCollection={[[{path: 'isCompleted', comparison: "equal", value: 'true'}]]}
						saveFn={async (entity: FormSubmissionsEntity, formMode: EntityFormMode) => {
							const slides = entity.formVersion.formData.slides;
							if (slides && slides.length >= 2 && !slides[slides.length - 1].contents.map(q => q.id).includes("ReviewSlideOption")) {
								alertToast("This form does not contain Review section", "error");
								throw new Error('');
							}
							if (entity.submissionData && !entity.submissionData["ReviewSlideOption"]) {
								alertToast("Review option is not selected", "error");
								throw new Error('');
							}
							if (entity.submissionData 
								&& entity.submissionData["ReviewSlideOption"] === 'CI Improvement Opportunity' 
								&& !entity.submissionData["ReviewSlideComments"]) 
							{
								alertToast("Comments are required", "error");
								throw new Error('');
							}
							await entity.saveFromCrud(formMode);
						}}
					/>
				</div>
			</SecuredPage>
		);
		// % protected region % [Override contents here] end

		return contents;
	}
}

// % protected region % [Override export here] off begin
export default UserSubmissionsPage;
// % protected region % [Override export here] end
