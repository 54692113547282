/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { QuestionType, ValidatorType } from 'Forms/Schema/Question';
import { Checkbox } from 'Views/Components/Checkbox/Checkbox';
import { IQuestionTile, QuestionTileOptionsProps } from '../QuestionTile';
import TileOptions from '../Options/TileOptions';
import { hasRequiredValidator } from '../../Validators/ValidationUtils';
import CompareBoolean from 'Forms/Conditions/CompareBoolean';
import { ValidateBoolean } from 'Forms/Validators/ValidateBoolean';
import { QuestionComponent, QuestionComponentProps } from 'Forms/Questions/QuestionComponent';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

@observer
export class CheckboxQuestionTileOptions extends Component<QuestionTileOptionsProps> {
	// % protected region % [Add extra options class properties here] off begin
	// % protected region % [Add extra options class properties here] end

	public render() {
		// % protected region % [Customize options render here] off begin
		const { question, schema } = this.props;
		return (
			<TileOptions 
				question={question} 
				schema={schema} 
				hasShowConditions 
				hasValidators 
				hasTooltip 
			/>
		);
		// % protected region % [Customize options render here] end
	}
}

export interface ICheckboxQuestionTileProps<T> extends QuestionComponentProps<T> {
	// % protected region % [Add extra props here] off begin
	// % protected region % [Add extra props here] end
}

@observer
export class CheckboxQuestionTile<T> extends QuestionComponent<T, ICheckboxQuestionTileProps<T>> implements IQuestionTile {
	// % protected region % [Customize static form vars here] off begin
	static displayName = 'Checkbox';

	static questionType: QuestionType = 'checkbox';
	static optionsMenu = CheckboxQuestionTileOptions;
	static conditionOptions = [
		{ display: 'Equal', value: 'equal' },
		{ display: 'Not equal', value: 'notEqual' },
	];
	static validatorOptions: { display: string, value: ValidatorType }[] = [
		{ display: 'Required', value: 'required' },
	];
	static stylingOptions = undefined;
	static compareFunction = CompareBoolean;
	static validateFunction = ValidateBoolean;
	// % protected region % [Customize static form vars here] end

	// % protected region % [Add extra class properties here] off begin
	// % protected region % [Add extra class properties here] end

	public render() {
		// % protected region % [Customize render here] off begin
		const { title, model, id, isReadOnly, validators, toolTip, className, checkValidation } = this.props;

		return (
			<div className="checkbox-wrapper">
				<Checkbox
					name={id}
					model={model}
					modelProperty={id}
					label={title}
					labelVisible
					isReadOnly={isReadOnly}
					tooltip={toolTip}
					isRequired={hasRequiredValidator(validators)}
					onAfterChecked={checkValidation}
					className={className}
				/>
			</div>
		);
		// % protected region % [Customize render here] end
	}
}

// % protected region % [Add extra methods here] off begin
// % protected region % [Add extra methods here] end