/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import React, { Component, ReactNode } from 'react';
import { action, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import * as uuid from 'uuid';
import { TextField } from 'Views/Components/TextBox/TextBox';
import { Button, Colors, Display } from 'Views/Components/Button/Button';
import {Form, Question} from '../../Schema/Question';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface ComboboxQuestionOptionsProps {
	schema: Form;
	question: Question;
	// % protected region % [Add to props here] off begin
	// % protected region % [Add to props here] end
}

@observer
export default class ComboboxQuestionOptions extends Component<ComboboxQuestionOptionsProps> {
	@action
	public addComboboxQuestionOption = (): void => {
		// % protected region % [Override addComboboxQuestionOption here] off begin
		const { question } = this.props;
		if (question.options) {
			question.options.values.push({
				value: '',
				id: uuid.v4(),
			});
		}
		// % protected region % [Override addComboboxQuestionOption here] end
	};

	public removeComboboxQuestionOptions = (index: number) => {
		// % protected region % [Override removeComboboxQuestionOptions here] off begin
		return (): void => {
			const { question } = this.props;

			runInAction(() => {
				if (question.options && question.options.values) {
					question.options.values.splice(index, 1);
				}
			});
		};
		// % protected region % [Override removeComboboxQuestionOptions here] end
	};

	private renderComboboxQuestionOptions = (): ReactNode => {
		const { question } = this.props;

		const ComboboxQuestionOptions = question.options && question.options.values
			? question.options.values.map((model: { id: string; value: string }, i: number) => {
				return (
					<React.Fragment key={model.id}>
						<TextField
							model={model}
							modelProperty="value"
							label={`Option ${i + 1}`}
							sideButton={
								<Button
									colors={Colors.Primary}
									display={Display.Solid}
									onClick={this.removeComboboxQuestionOptions(i)}
								>
									Remove
								</Button>
							}
						/>
					</React.Fragment>
				);
			})
			: <></>;
		return (
			<>
				<h4>Combobox Options</h4>
				{ComboboxQuestionOptions}
			</>
		);
	};

	public render(): ReactNode {
		return (
			<>
				{this.renderComboboxQuestionOptions()}
				<Button
					colors={Colors.Primary}
					display={Display.Solid}
					onClick={this.addComboboxQuestionOption}
				>
					Add combobox option
				</Button>
				<hr className='sidebar-separator'></hr>
			</>
		)
	}
}