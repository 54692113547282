/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import moment from 'moment';
import { action, observable } from 'mobx';
import { Model, IModelAttributes, attribute, entity } from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from "Util/AttributeUtils";
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchManyToManyFunc,
	makeJoinEqualsFunc,
	makeFetchOneToManyFunc,
	makeEnumFetchFunction,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import { VisitorsAssetEntity } from 'Models/Security/Acl/VisitorsAssetEntity';
import { MehubAccountAssetEntity } from 'Models/Security/Acl/MehubAccountAssetEntity';
import { MehubAdminAssetEntity } from 'Models/Security/Acl/MehubAdminAssetEntity';
import * as Enums from '../Enums';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import {SuperAdministratorScheme} from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] on begin
import { runInAction } from 'mobx';
import _ from 'lodash';
import { store } from '../Store';
import { gql } from '@apollo/client';
import { IWhereCondition } from 'Views/Components/ModelCollection/ModelQuery';
import { ErrorResponse } from '@apollo/client/link/error';
import { getTheNetworkError } from 'Util/GraphQLUtils';
// % protected region % [Add any further imports here] end

export interface IAssetEntityAttributes extends IModelAttributes {
	assetType: Enums.assetTypes;
	assetID: string;
	brand: string;
	assetModel: string;
	serialNumber: string;
	description: string;
	status: Enums.assetStatus;
	registration: string;
	registrationRenewalDate: Date;
	serviceDueDate: Date;
	inspectionFrequency: Enums.inspectionFrequency;
	lastInspection: Date;
	nextInspection: Date;
	comments: string;
	reminderNotification: Date;
	secondReminder: Date;

	assetAttachmentss: Array<Models.AssetAttachmentsEntity | Models.IAssetAttachmentsEntityAttributes>;
	memberOrganisationId?: string;
	memberOrganisation?: Models.MemberOrganisationEntity | Models.IMemberOrganisationEntityAttributes;
	parentAssetId?: string;
	parentAsset?: Models.AssetEntity | Models.IAssetEntityAttributes;
	assetss: Array<Models.AssetEntity | Models.IAssetEntityAttributes>;
	formSubmissionss: Array<Models.FormSubmissionsAssets | Models.IFormSubmissionsAssetsAttributes>;
	mehubAccountss: Array<Models.AssetsMehubAccounts | Models.IAssetsMehubAccountsAttributes>;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('AssetEntity', 'Asset')
// % protected region % [Customise your entity metadata here] end
export default class AssetEntity extends Model implements IAssetEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsAssetEntity(),
		new MehubAccountAssetEntity(),
		new MehubAdminAssetEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Asset Type'] off begin
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'Asset Type',
		displayType: 'enum-combobox',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.assetTypesOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.assetTypesOptions),
		displayFunction: (attribute: Enums.assetTypes) => Enums.assetTypesOptions[attribute],
	})
	public assetType: Enums.assetTypes;
	// % protected region % [Modify props to the crud options here for attribute 'Asset Type'] end

	// % protected region % [Modify props to the crud options here for attribute 'Asset ID'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'Asset ID',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public assetID: string;
	// % protected region % [Modify props to the crud options here for attribute 'Asset ID'] end

	// % protected region % [Modify props to the crud options here for attribute 'Brand'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'Brand',
		displayType: 'textfield',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public brand: string;
	// % protected region % [Modify props to the crud options here for attribute 'Brand'] end

	// % protected region % [Modify props to the crud options here for attribute 'Asset Model'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'Asset Model',
		displayType: 'textfield',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public assetModel: string;
	// % protected region % [Modify props to the crud options here for attribute 'Asset Model'] end

	// % protected region % [Modify props to the crud options here for attribute 'Serial Number'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'Serial Number',
		displayType: 'textfield',
		order: 50,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public serialNumber: string;
	// % protected region % [Modify props to the crud options here for attribute 'Serial Number'] end

	// % protected region % [Modify props to the crud options here for attribute 'Description'] on begin
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'Asset Name/Description',
		displayType: 'textfield',
		order: 60,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public description: string;
	// % protected region % [Modify props to the crud options here for attribute 'Description'] end

	// % protected region % [Modify props to the crud options here for attribute 'Status'] on begin
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'Status',
		displayType: 'enum-combobox',
		order: 70,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.assetStatusOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.assetStatusOptions),
		displayFunction: (attribute: Enums.assetStatus) => Enums.assetStatusOptions[attribute],
	})
	public status: Enums.assetStatus;
	// % protected region % [Modify props to the crud options here for attribute 'Status'] end

	// % protected region % [Modify props to the crud options here for attribute 'Registration'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'Registration',
		displayType: 'textfield',
		order: 80,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public registration: string;
	// % protected region % [Modify props to the crud options here for attribute 'Registration'] end

	// % protected region % [Modify props to the crud options here for attribute 'Registration Renewal Date'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'Registration Renewal Date',
		displayType: 'datepicker',
		order: 90,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseDate,
	})
	public registrationRenewalDate: Date;
	// % protected region % [Modify props to the crud options here for attribute 'Registration Renewal Date'] end

	// % protected region % [Modify props to the crud options here for attribute 'Service Due Date'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'Service Due Date',
		displayType: 'datepicker',
		order: 100,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseDate,
	})
	public serviceDueDate: Date;
	// % protected region % [Modify props to the crud options here for attribute 'Service Due Date'] end

	// % protected region % [Modify props to the crud options here for attribute 'Inspection Frequency'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'Inspection Frequency',
		displayType: 'enum-combobox',
		order: 110,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.inspectionFrequencyOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.inspectionFrequencyOptions),
		displayFunction: (attribute: Enums.inspectionFrequency) => Enums.inspectionFrequencyOptions[attribute],
	})
	public inspectionFrequency: Enums.inspectionFrequency;
	// % protected region % [Modify props to the crud options here for attribute 'Inspection Frequency'] end

	// % protected region % [Modify props to the crud options here for attribute 'Last Inspection'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'Last Inspection',
		displayType: 'datepicker',
		order: 120,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseDate,
	})
	public lastInspection: Date;
	// % protected region % [Modify props to the crud options here for attribute 'Last Inspection'] end

	// % protected region % [Modify props to the crud options here for attribute 'Next Inspection'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'Next Inspection',
		displayType: 'datepicker',
		order: 130,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseDate,
	})
	public nextInspection: Date;
	// % protected region % [Modify props to the crud options here for attribute 'Next Inspection'] end

	// % protected region % [Modify props to the crud options here for attribute 'Comments'] on begin
	@observable
	@attribute()
	@CRUD({
		name: 'Comments',
		displayType: 'textarea',
		order: 130,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public comments: string;
	// % protected region % [Modify props to the crud options here for attribute 'Comments'] end

	// % protected region % [Modify props to the crud options here for attribute 'Reminder Notification'] on begin
	@observable
	@attribute()
	@CRUD({
		name: 'Reminder Notification',
		displayType: 'hidden',
		order: 140,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseDate,
	})
	public reminderNotification: Date;
	// % protected region % [Modify props to the crud options here for attribute 'Reminder Notification'] end

	// % protected region % [Modify props to the crud options here for attribute 'Second Reminder'] on begin
	@observable
	@attribute()
	@CRUD({
		name: 'Second Reminder',
		displayType: 'hidden',
		order: 150,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseDate,
	})
	public secondReminder: Date;
	// % protected region % [Modify props to the crud options here for attribute 'Second Reminder'] end

	@observable
	@attribute({isReference: true, manyReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Asset Attachments'] on begin
		name: "Asset Attachments",
		displayType: 'fileList',
		order: 160,
		fileAttribute: 'attachments',
		// % protected region % [Modify props to the crud options here for reference 'Asset Attachments'] end
	})
	public assetAttachmentss: Models.AssetAttachmentsEntity[] = [];

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Member Organisation'] on begin
		name: 'Member Organisation',
		displayType: 'hidden',
		order: 170,
		referenceTypeFunc: () => Models.MemberOrganisationEntity,
		// % protected region % [Modify props to the crud options here for reference 'Member Organisation'] end
	})
	public memberOrganisationId?: string;
	@observable
	@attribute({isReference: true, manyReference: false})
	public memberOrganisation: Models.MemberOrganisationEntity;

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Parent Asset'] on begin
		name: 'Parent Asset',
		displayType: 'reference-combobox',
		order: 180,
		referenceTypeFunc: () => Models.AssetEntity,
		// % protected region % [Modify props to the crud options here for reference 'Parent Asset'] end
	})
	public parentAssetId?: string;
	@observable
	@attribute({isReference: true, manyReference: false})
	public parentAsset: Models.AssetEntity;

	@observable
	@attribute({isReference: true, manyReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Assets'] on begin
		name: "Child Assets",
		displayType: 'reference-multicombobox',
		order: 190,
		referenceTypeFunc: () => Models.AssetEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'assetss',
			oppositeEntity: () => Models.AssetEntity,
			args: [
				[{ path: 'status', comparison: 'equal', value: 'IN_SERVICE' }], 
				[{ path: 'parentAssetId', comparison: "equal", value: null}]
			]
		}),
		// % protected region % [Modify props to the crud options here for reference 'Assets'] end
	})
	public assetss: Models.AssetEntity[] = [];

	@observable
	@attribute({isReference: true, manyReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Form Submissions'] on begin
		name: 'Form Submissions',
		displayType: 'hidden',
		order: 200,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.FormSubmissionsAssets,
		optionEqualFunc: makeJoinEqualsFunc('formSubmissionsId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'assetEntity',
			oppositeEntityName: 'formSubmissionsEntity',
			relationName: 'assets',
			relationOppositeName: 'formSubmissions',
			entity: () => Models.AssetEntity,
			joinEntity: () => Models.FormSubmissionsAssets,
			oppositeEntity: () => Models.FormSubmissionsEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Form Submissions'] end
	})
	public formSubmissionss: Models.FormSubmissionsAssets[] = [];

	@observable
	@attribute({isReference: true, manyReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'MeHub Accounts'] on begin
		name: 'Assignee',
		displayType: 'reference-multicombobox',
		order: 105,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.AssetsMehubAccounts,
		optionEqualFunc: makeJoinEqualsFunc('mehubAccountsId'),
		referenceResolveFunction: (query, options) => makeFetchManyToManyFunc({
			entityName: 'assetEntity',
			oppositeEntityName: 'mehubAccountEntity',
			relationName: 'assets',
			relationOppositeName: 'mehubAccounts',
			entity: () => Models.AssetEntity,
			joinEntity: () => Models.AssetsMehubAccounts,
			oppositeEntity: () => Models.MehubAccountEntity,
			args: (query, existingArgs) => {
				let account = options.model as Models.MehubAccountEntity;
				// If user belongs to org, return users only from that org. Otherwise return all users.
				return _.isString(account.memberOrganisationId) ?
					[
						...existingArgs,
						[{path: 'memberOrganisationId', comparison: 'equal', value: account.memberOrganisationId}],
					] : [...existingArgs];
			},
		})(query, options),
		// % protected region % [Modify props to the crud options here for reference 'MeHub Accounts'] end
	})
	public mehubAccountss: Models.AssetsMehubAccounts[] = [];

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IAssetEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] on begin
		this.delete = this.delete.bind(this);
		this.deleteWhere = this.deleteWhere.bind(this);
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IAssetEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.assetType !== undefined) {
				this.assetType = attributes.assetType;
			}
			if (attributes.assetID !== undefined) {
				this.assetID = attributes.assetID;
			}
			if (attributes.brand !== undefined) {
				this.brand = attributes.brand;
			}
			if (attributes.assetModel !== undefined) {
				this.assetModel = attributes.assetModel;
			}
			if (attributes.serialNumber !== undefined) {
				this.serialNumber = attributes.serialNumber;
			}
			if (attributes.description !== undefined) {
				this.description = attributes.description;
			}
			if (attributes.status !== undefined) {
				this.status = attributes.status;
			}
			if (attributes.registration !== undefined) {
				this.registration = attributes.registration;
			}
			if (attributes.registrationRenewalDate !== undefined) {
				if (attributes.registrationRenewalDate === null) {
					this.registrationRenewalDate = attributes.registrationRenewalDate;
				} else {
					this.registrationRenewalDate = moment(attributes.registrationRenewalDate).toDate();
				}
			}
			if (attributes.serviceDueDate !== undefined) {
				if (attributes.serviceDueDate === null) {
					this.serviceDueDate = attributes.serviceDueDate;
				} else {
					this.serviceDueDate = moment(attributes.serviceDueDate).toDate();
				}
			}
			if (attributes.inspectionFrequency !== undefined) {
				this.inspectionFrequency = attributes.inspectionFrequency;
			}
			if (attributes.lastInspection !== undefined) {
				if (attributes.lastInspection === null) {
					this.lastInspection = attributes.lastInspection;
				} else {
					this.lastInspection = moment(attributes.lastInspection).toDate();
				}
			}
			if (attributes.nextInspection !== undefined) {
				if (attributes.nextInspection === null) {
					this.nextInspection = attributes.nextInspection;
				} else {
					this.nextInspection = moment(attributes.nextInspection).toDate();
				}
			}
			if (attributes.comments !== undefined) {
				this.comments = attributes.comments;
			}
			if (attributes.reminderNotification !== undefined) {
				if (attributes.reminderNotification === null) {
					this.reminderNotification = attributes.reminderNotification;
				} else {
					this.reminderNotification = moment(attributes.reminderNotification).toDate();
				}
			}
			if (attributes.secondReminder !== undefined) {
				if (attributes.secondReminder === null) {
					this.secondReminder = attributes.secondReminder;
				} else {
					this.secondReminder = moment(attributes.secondReminder).toDate();
				}
			}
			if (attributes.assetAttachmentss !== undefined && Array.isArray(attributes.assetAttachmentss)) {
				for (const model of attributes.assetAttachmentss) {
					if (model instanceof Models.AssetAttachmentsEntity) {
						this.assetAttachmentss.push(model);
					} else {
						this.assetAttachmentss.push(new Models.AssetAttachmentsEntity(model));
					}
				}
			}
			if (attributes.memberOrganisationId !== undefined) {
				this.memberOrganisationId = attributes.memberOrganisationId;
			}
			if (attributes.memberOrganisation !== undefined) {
				if (attributes.memberOrganisation === null) {
					this.memberOrganisation = attributes.memberOrganisation;
				} else {
					if (attributes.memberOrganisation instanceof Models.MemberOrganisationEntity) {
						this.memberOrganisation = attributes.memberOrganisation;
						this.memberOrganisationId = attributes.memberOrganisation.id;
					} else {
						this.memberOrganisation = new Models.MemberOrganisationEntity(attributes.memberOrganisation);
						this.memberOrganisationId = this.memberOrganisation.id;
					}
				}
			}
			if (attributes.assetss !== undefined && Array.isArray(attributes.assetss)) {
				for (const model of attributes.assetss) {
					if (model instanceof Models.AssetEntity) {
						this.assetss.push(model);
					} else {
						this.assetss.push(new Models.AssetEntity(model));
					}
				}
			}
			if (attributes.parentAssetId !== undefined) {
				this.parentAssetId = attributes.parentAssetId;
			}
			if (attributes.parentAsset !== undefined) {
				if (attributes.parentAsset === null) {
					this.parentAsset = attributes.parentAsset;
				} else {
					if (attributes.parentAsset instanceof Models.AssetEntity) {
						this.parentAsset = attributes.parentAsset;
						this.parentAssetId = attributes.parentAsset.id;
					} else {
						this.parentAsset = new Models.AssetEntity(attributes.parentAsset);
						this.parentAssetId = this.parentAsset.id;
					}
				}
			}
			if (attributes.formSubmissionss !== undefined && Array.isArray(attributes.formSubmissionss)) {
				for (const model of attributes.formSubmissionss) {
					if (model instanceof Models.FormSubmissionsAssets) {
						this.formSubmissionss.push(model);
					} else {
						this.formSubmissionss.push(new Models.FormSubmissionsAssets(model));
					}
				}
			}
			if (attributes.mehubAccountss !== undefined && Array.isArray(attributes.mehubAccountss)) {
				for (const model of attributes.mehubAccountss) {
					if (model instanceof Models.AssetsMehubAccounts) {
						this.mehubAccountss.push(model);
					} else {
						this.mehubAccountss.push(new Models.AssetsMehubAccounts(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		mehubAccountss {
			${Models.AssetsMehubAccounts.getAttributes().join('\n')}
			mehubAccounts {
				${Models.MehubAccountEntity.getAttributes().join('\n')}
			}
		}
		formSubmissionss {
			${Models.FormSubmissionsAssets.getAttributes().join('\n')}
			formSubmissions {
				${Models.FormSubmissionsEntity.getAttributes().join('\n')}
			}
		}
		assetAttachmentss {
			${Models.AssetAttachmentsEntity.getAttributes().join('\n')}
			${Models.AssetAttachmentsEntity.getFiles().map(f => f.name).join('\n')}
		}
		memberOrganisation {
			${Models.MemberOrganisationEntity.getAttributes().join('\n')}
		}
		assetss {
			${Models.AssetEntity.getAttributes().join('\n')}
		}
		parentAsset {
			${Models.AssetEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] on begin
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath = {
			mehubAccountss: {},
			formss: {},
			assetAttachmentss: {},
			assetss: {},
		};

		if(formMode === EntityFormMode.EDIT) {
			let outOfService: Enums.assetStatus = 'OUT_OF_SERVICE';
			if(formMode === EntityFormMode.EDIT && this.status === outOfService) {
				runInAction(() => {
					this.assetss = [];
					this.parentAssetId = undefined;
					this.mehubAccountss = [];
				});
			}
		}

		return this.save(
			relationPath,
			{
				contentType: 'multipart/form-data',
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'assetAttachmentss',
							'assetss',
							'formss',
							'mehubAccountss',
						]
					},
				],
			}
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] on begin
		return this.description;
		// % protected region % [Customise the display name for this entity] end
	}


	// % protected region % [Add any further custom model features here] on begin
	/**
	 * [#310] Custom deletes the model from the server
	 */
	public async delete() {
		const functionName = `delete${this.getModelName()}`;

		return store.apolloClient
			.mutate({
				mutation: gql`
					mutation delete($assetEntityIds:[ID]) {
						${functionName} (assetEntityIds: $assetEntityIds) {
							id
						}
					}`,
				variables: {assetEntityIds: [this.id]},
				update: (cache, results) => {

				},
			})
			.then(action((response) => {
				const data = response.data[functionName][0];
				Object.assign(this, data);

				// Delete related reminders
				const whereCondition = {
					"path": "EntityID",
					"comparison": "equal",
					"value": this.id,
				};

				store.apolloClient
					.mutate({
						mutation: gql`
							mutation delete($whereConditional:WhereExpressionGraph) {
								deleteReminderLogEntitysConditional(conditions: [$whereConditional]) {
									value
								}
							}`,
						variables: {whereConditional: whereCondition}
					})
					.catch((response: ErrorResponse) => {
						throw getTheNetworkError(response);
				});
			}))
			.catch((response: ErrorResponse) => {
				throw getTheNetworkError(response);
		});
	}

	/**
	 * [#310] Custom eletes the models from the server by conditions
	 */
	public async deleteWhere(conditions?: Array<IWhereCondition<Model>> | Array<Array<IWhereCondition<Model>>>, ids?: string[]) {
		return new Promise<object>((resolve, reject) => {
			const functionName = `delete${this.getModelName()}sConditional`;
			
			return store.apolloClient
				.mutate({
					mutation: gql`
						mutation deleteModelsConditional($args: [[WhereExpressionGraph]], $ids:[ID]) {
							${functionName}(conditions: $args, ids: $ids){
								value
							}
						}`,
					variables: {
						"args": conditions,
						"ids": ids || null
					},
					update: (cache, results) => {

					},
				})
				.then((response) => {
				// Delete related reminders
				// Create a condition for all entity IDS
				const whereIds = {
					"path": "EntityID",
					"comparison": "in",
					"value": ids || "this-is-a-placeholder"
				};

				// Create a condition for the passed in condition
				// Assuming there is only one condition and it has the values `path=id`, `comparison=notIn`
				let whereConditions = {
					"path": "EntityID",
					"comparison": "in",
					"value": "this-is-a-placeholder"
				};

				if(conditions != null 
					&& (conditions?.length ?? 0) > 0)
				{
					whereConditions = {
						"path": "EntityID",
						"comparison": "notIn",
						"value": conditions[0][0].value || "this-is-a-placeholder"
					};
				}
				

				// Send request, reject the promise on error
				store.apolloClient
					.mutate({
						mutation: gql`
							mutation delete($whereIds:WhereExpressionGraph, $whereConditions:WhereExpressionGraph) {
								deleteReminderLogEntitysConditional(conditions: [[$whereIds, $whereConditions]]) {
									value
								}
							}`,
						variables: {
							whereIds: whereIds,
							whereConditions: whereConditions,
						}
					})
					.catch((response: ErrorResponse) => {
						const errorMessage = getTheNetworkError(response);
						reject(errorMessage);
				});

				// Resolve the response
				const result = response.data[functionName];
				resolve(result);
				})
				.catch((response: ErrorResponse) => {
					const errorMessage = getTheNetworkError(response);
					reject(errorMessage);
				});
		})
	}
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [ createdAttr, modifiedAttr ] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(AssetEntity.prototype, 'created');
CRUD(modifiedAttr)(AssetEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
