/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from "react";
import {computed} from "mobx";
import {Link} from "react-router-dom";
import {noSpaces} from "Util/StringUtils";
// % protected region % [Add any extra imports here] on begin
import {Button} from "../../../Views/Components/Button/Button";
import {contextMenu} from "react-contexify";
import {ContextMenu} from "../../../Views/Components/ContextMenu/ContextMenu";
import {store} from "../../../Models/Store";
import classnames from "classnames";
import {FormsEntity, FormsMemberOrganisations} from "../../../Models/Entities";
import {observer} from "mobx-react";
import {action} from "mobx";
import classNames from "classnames";
import {adminIsDraftModal} from "../../../Views/Components/Modal/ModalUtils";
import axios from "axios";
import {SERVER_URL} from "../../../Constants";
// % protected region % [Add any extra imports here] end

// % protected region % [Override IFormVersionTileProps here] on begin
export interface IFormVersionTileProps {
	formVersionName: string;
	formEntityName: string;
	id: string;
	currentVersion?: number | undefined;
	publishedVersion?: number | undefined;
	formsEntity: FormsEntity;
}
// % protected region % [Override IFormVersionTileProps here] end

// % protected region % [Add any extra types here] off begin
// % protected region % [Add any extra types here] end

// % protected region % [Override FormVersionTile here] on begin
@observer
export class FormVersionTile extends React.Component<IFormVersionTileProps> {
// % protected region % [Override FormVersionTile here] end

	// % protected region % [Override formItemVersions here] on begin
	private formItemVersions = () => {
		if(!this.props.publishedVersion && !this.props.currentVersion) {
			return null;
		}

		return (
			<div className='form-item__versions'>
				{this.currentVersionComponent}
				{this.publishedVersionComponent}
			</div>
		);
	};
	// % protected region % [Override formItemVersions here] end

	// % protected region % [Override publishedVersionComponent here] off begin
	@computed
	private get publishedVersionComponent() {
		return this.props.publishedVersion
			? <p className="version__active"> Version {this.props.publishedVersion} </p>
			: null;
	};
	// % protected region % [Override publishedVersionComponent here] end

	// % protected region % [Override currentVersionComponent here] on begin
	@computed
	private get currentVersionComponent() {
		return this.props.currentVersion
			? <p className="version__inactive"> Version {this.props.currentVersion} </p>
			: null;
	};

	@action
	private makeFormInactiveActive = async () => {
		this.props.formsEntity.isActive = !this.props.formsEntity.isActive;
		if (!this.props.formsEntity.isActive) {
			const formsMemberOrganisationsEntity = new FormsMemberOrganisations();
			await formsMemberOrganisationsEntity.deleteWhere(undefined,
				this.props.formsEntity.memberOrganisationss.map(f => f.id));
		}
		await this.props.formsEntity.save();
	}

	// % protected region % [Override currentVersionComponent here] end

	// % protected region % [Override linkAddress here] off begin
	private linkAddress = `/admin/forms/build/${noSpaces(this.props.formEntityName)}/${this.props.id}`;
	// % protected region % [Override linkAddress here] end

	// % protected region % [Add any extra functions here] on begin
	private isDraft = () => {
		const versions = this.props.formsEntity.formVersions
			.sort((a, b) => a.version - b.version);
		const publishedVersionId = this.props.formsEntity.publishedVersionId;
		
		return versions.length === 0 || publishedVersionId !== versions[versions.length - 1].id;
	}

	private editFormTile = async () => {
		if (this.props.formsEntity.isActive) {
			if (this.isDraft()) {
				try {
					await adminIsDraftModal('A draft of this form already exists', 'You can either continue the existing draft, ' +
						'or discard it and start a new submission. How would you like to proceed', {
						confirmText: 'Continue editing',
						cancelText: 'Discard and start from scratch'
					});
				} catch (e) {
					// Discard and start from scratch
					const discardFormId = this.props.id;
					try {
						const result = await axios.post(`${SERVER_URL}/api/entity/FormsEntity/discardDraft/${discardFormId}`);

					} catch (e) {
						// This is if the server returns anything that's not 'Ok()'
						alert('There was an error when discarding the form draft');
						return;
					}
				}
			}

			store.routerHistory.push(this.linkAddress);
		}
	}
	
	// % protected region % [Add any extra functions here] end

	// % protected region % [Override render here] on begin
	public render(){
		return (
			<div className={classNames("form-item", { active: this.props.formsEntity.isActive })}>
				<Link onClick={this.editFormTile} to={ '#' }>
					<div className='form-item__heading' >
						<h3>{this.props.formVersionName}</h3>
						{/* <p className='form-responses'>Responses</p> */}
					</div>
				</Link>
				<div className='form-item__footer'>
					{/*{this.formItemVersions()}*/}
					<div className="draft-status-section">
						<div className={classnames("draft-status", this.isDraft() ? 'draft' : 'published')} />
						<span className="draft-status-text">{this.isDraft() ? 'Draft' : 'Published'}</span>
					</div>
					<Button className="icon-more-horizontal icon-only icon-right" onClick={
						(event) => {
							contextMenu.show({
								event: event,
								id: `forms-question-more-${this.props.formsEntity.id}`
							})	
						}}/>
					<ContextMenu
						menuId={`forms-question-more-${this.props.formsEntity.id}`}
						actions={[
							{
								label: 'Edit form',
								onClick:(e) => {
									store.routerHistory.push(`/admin/${this.props.formEntityName.toLowerCase()}/edit/${this.props.id}`)
								}
							},
							{
								label: 'View versions',
								onClick:(e) => {
									store.routerHistory.push(`/admin/forms/versions/${this.props.id}`)
								},
							},
							{
								label: this.props.formsEntity.isActive ? 'Make inactive' : 'Make active',
								onClick:(e) => {
									this.makeFormInactiveActive();
								}
							},
						]} />
				</div>
			</div>
		)
	}
	// % protected region % [Override render here] end
}