import * as React from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router';
import standards_icon from "../../images/Standards Icon.svg";
import { store } from 'Models/Store';
import { observable, runInAction } from 'mobx';
import { StandardEntity } from 'Models/Entities';
import { SERVER_URL } from 'Constants';
import If from 'Views/Components/If/If';
import { gql } from '@apollo/client';
import GlobalSearch from 'Views/CustomComponents/GlobalSearch';
import BreadcrumbNavigation from "../CustomComponents/BreadcrumbNavigation";

export function getStandardsListQuery() {
	return gql`query fetch ($args: [[WhereExpressionGraph]]) {
	standards: standardEntitysConditional (conditions: $args) {
			name
			header
			link
			guidancess {
				name
				header
				link
			}
			iconId
		}
	}`;
}

@observer
export default class StandardsTile extends React.Component<RouteComponentProps> {

	@observable
	private standardsEntities: StandardEntity[] = [];

	componentDidMount() {
		this.fetchStandards();
	}

	private fetchStandards = () => {
		store.apolloClient.query({ query: getStandardsListQuery(), fetchPolicy: 'network-only' }).then(
			({ loading, errors, data }) => {
				if (loading) {
					return null;
				}
				if (errors) {
					console.error(errors);
					return 'Something went wrong while connecting to the server. The error is ' + JSON.stringify(errors);
				}
				if (data !== undefined) {
					runInAction(() => {
						this.standardsEntities = data ? data['standards'] : [];
						this.sortLinkOrder();
					});
				}
				return null;
			}
		);
	}

	private sortLinkOrder = () => {
		this.standardsEntities = this.standardsEntities.sort((a, b) => a.name.localeCompare(b.name));
		this.standardsEntities.forEach(s => {
			if (s.guidancess.length > 1) {
				s.guidancess = s.guidancess.sort((x, y) => x.name.localeCompare(y.name));
			}
		});
	}

	private getStandardIcon = (iconId: string) => {
		if (iconId) {
			return `${SERVER_URL}/api/files/${iconId}`;
		}

		return standards_icon;
	}

	public render() {
		let contents = null;
		contents = (
			<>
				<section className="page-header-new">
					<div className="breadcrumb-button">
						<BreadcrumbNavigation />
					</div>
					<div className="page-controls">
						<div className="page-controls__title">
							<h2>Standards and Guidance Notes</h2>
						</div>
						<div className="page-controls__search">
							<div className="input-group input-group-block" aria-label="Search-Field">
								<GlobalSearch precedentType={'STANDARD'} />
							</div>
						</div>
					</div>
				</section>

				<section className="library-container">
					<div className="file-open-content">
						<h4 className='file-open-header'>Accessing standards on a desktop (Windows or Mac)</h4>
						<ul>
							<li>Standards can either be viewed online or can be downloaded to your desktop and viewed in Adobe Acrobat Reader.</li>
							<li>If you are downloading the standard to your desktop, you will be required to uncheck ‘Enable global object security policy’ in Adobe Acrobat Reader to allow viewing of the document and have an active internet connection.</li>
							<li>If you require instructions on how to uncheck the <i>global object security policy</i>, please <a href={"/Instructions_-_Download_Standards_from_the_MEA_Website.pdf"} target="_blank" rel="noreferrer">click here</a>.</li>
						</ul>
						<h4 className='file-open-header'>Accessing the standards on a portable device or mobile (IOS or Android)</h4>
						<ul>
							<li>Standards can <b>only be viewed online within the hub.</b> It is not possible to download and view the standards on a portable device or mobile.</li>
						</ul>
						<h4 className='file-open-header'>If you are still not able to view the standards, please click the link below for further information.</h4>
						<ul>
							<li>
								<a className='file-open-header' href="https://store.standards.org.au/product-support" target="_blank" rel="noreferrer">
									https://store.standards.org.au/product-support
								</a>
							</li>
						</ul>
					</div>
				</section>

				<section className="library-container">
					<div className="standards">

						{this.standardsEntities.map(standard =>
							<div className="standards__item" key={standard.id}>
								<div className="standards__img">
									<img src={this.getStandardIcon(standard.iconId)} />
								</div>

								<div className="standards__description" onClick={() => window.open(standard.link)}>
									<h4>{standard.name}</h4>
									<h5>{standard.header}</h5>
									<a className="view-button icon-left icon-look"
										href={standard.link}
										target="_blank"
										onClick={(e) => e.stopPropagation()} rel="noreferrer"
									>View</a>
									<If condition={standard.guidancess.length > 0}>
										<div className="standards__guidance">
											<h6>Guidance Notes</h6>
											{standard.guidancess.map(guidance =>
												<div onClick={(e) => { window.open(guidance.link); e.stopPropagation() }}>
													<div className="guidance-note-header" key={guidance.id}>
														<h4>{guidance.name}</h4>
														<h5>{guidance.header}</h5>
													</div>
													<a className="view-button icon-left icon-look"
														href={guidance.link}
														target="_blank"
														onClick={(e) => e.stopPropagation()} rel="noreferrer"
													>View</a>
												</div>
											)}
										</div>
									</If>
								</div>

							</div>
						)}

					</div>
				</section>
			</>
		);

		return contents;
	}

}

