import React from "react";
import {Button, Colors, Display, Sizes} from "../Components/Button/Button";
import {EntityFormMode} from "../Components/Helpers/Common";
import {getAttributeComponent} from "../Components/CRUD/Attributes/AttributeFactory";
import {isRequired} from "../../Util/EntityUtils";
import {observer} from "mobx-react";
import {action, observable} from "mobx";
import {store} from "../../Models/Store";
import {LicenceEntity, MehubAccountEntity} from "../../Models/Entities";
import {SERVER_URL} from "../../Constants";
import alert from "../../Util/ToastifyUtils";
import If from "../Components/If/If";
import EntityCRUD from 'Views/Components/CRUD/EntityCRUD';
import EntityCollection from "../Components/CRUD/EntityCollection";
import {RouteComponentProps} from "react-router";
import { mehubUserRoleOptions } from "Models/Enums";
import UploadLicencePage from "./UploadLicencePage";


export interface UserViewProps extends RouteComponentProps {
    id: string;
    formMode: EntityFormMode;
    setUsername?: (name: string) => void;
    setMemnetId?: (memnetId: string) => void;
    setUserId?: (userId: string) => void;
    setUser?: (user?: MehubAccountEntity) => void;
}

@observer
export default class UserViewPage extends React.Component<UserViewProps> {

    @observable
    private mehubAccountEntity: MehubAccountEntity;
    
    componentDidMount() {
        if (this.props.setUser) {
            this.props.setUser();
        }
        
        MehubAccountEntity.fetch<MehubAccountEntity>({ ids: [this.props.id] }).then(action((mehubAccountEntity) => {
            this.mehubAccountEntity = mehubAccountEntity[0];

            if (this.props.setUser) {
                this.props.setUser(this.mehubAccountEntity);
            }
            
        }));
    }

    private renderBasicUserDetails() {
        const fields = [
            'mobileNumber',
            'email',
            'streetAddress1',
            'streetAddress2',
            'city',
            'state',
            'startDate',
            'endDate',
        ];
        return this.getUserFields(fields);
    }
    
    private getUserFields(fields: string[]) {
        const model = this.mehubAccountEntity;

        return this.mehubAccountEntity?.getAttributeCRUDOptions()
            .filter(x => fields.indexOf(x.attributeName) > -1)
            .sort((a,b) => fields.indexOf(a.attributeName) - fields.indexOf(b.attributeName))
            .map(attributeOptions => {
                if (this.props.formMode == EntityFormMode.EDIT 
                    && attributeOptions.attributeName !== 'startDate' 
                    && attributeOptions.attributeName !== 'endDate') {
                    attributeOptions.isReadonly = true;
                }
                
                return getAttributeComponent(attributeOptions,
                    model,
                    model.getErrorsForAttribute(attributeOptions.attributeName),
                    this.props.formMode,
                    isRequired(model, attributeOptions.attributeName),
                    undefined,
                    model.validate.bind(model));
            });
    }

    private saveUser = async () => {
        await this.mehubAccountEntity.validate();

        if (this.mehubAccountEntity.hasValidationError) {
            return;
        }

        try {
            await this.mehubAccountEntity.save();
            alert('Successfully saved user', 'success');

            store.routerHistory.push(`${SERVER_URL}/users`);
        } catch (e) {
            alert('Error saving user', 'error');
        }
    }

    render() {
        const isApproveRemovePermission = (store.roleName === mehubUserRoleOptions.SAFETY_MANAGER) || store.hasBackendAccess;

        let contents = (
            <div className="user-view-page">
                <div className="user-basic-details">
                    {this.renderBasicUserDetails()}
                    <div className="user-button-group">
                        <If condition={this.props.formMode == EntityFormMode.EDIT}>
                            <p>Note: To update other details, please proceed to MemNet.</p>
                        </If>
                        <Button className="cancel-user-view"
                                onClick={() => store.routerHistory.push(`${SERVER_URL}/users`)}
                                display={Display.Outline}
                                colors={Colors.FormPrimary}
                                sizes={Sizes.Medium}>
                            Cancel
                        </Button>
                        <If condition={this.props.formMode == EntityFormMode.EDIT}>
                            <Button className="save-user"
                                    onClick={this.saveUser}
                                    display={Display.Solid}
                                    colors={Colors.FormPrimary}
                                    sizes={Sizes.Medium}>
                                Save
                            </Button>
                        </If>
                    </div>
                </div>
                <h3>Licences and Competencies</h3>
                <div className="licences-competencies">
                    <EntityCollection
                        {...this.props}
                        modelType={LicenceEntity}
                        createAction={() => undefined}
                        deleteAction={() => undefined}
                        conditions={[[{
                            path: 'mehubAccountId',
                            comparison: 'equal',
                            value: this.props.id
                        }]]}
                        actionsMore={[{
                            onEntityClick: action(async (arg, entity: LicenceEntity) => {
                                let currentDate = new Date();
                                entity.authorizedDate = currentDate;
                                if(entity.renewalDate !== null) {
                                    entity.status = entity.renewalDate.getTime() > currentDate.getTime() ? "ACTIVE" : "EXPIRED";
                                } else {
                                    entity.status = "ACTIVE";
                                }
                                await entity.save();
                            }),
                            condition: (entity: LicenceEntity) => (entity.status === "PENDING" && isApproveRemovePermission),
                            label: 'Approve'
                        },
                        {
                            onEntityClick: action(async (arg, entity: LicenceEntity) => {
                                await entity.delete();
                                window.location.reload();
                            }),
                            condition: (entity: LicenceEntity) => isApproveRemovePermission,
                            label: 'Remove'
                        },
                        {
                            onEntityClick: action(async (arg, entity: LicenceEntity) => {}),
                            condition: (entity: LicenceEntity) => !isApproveRemovePermission,
                            label: 'No actions available'
                        }
                    ]}
                        viewAction={() => ({
                            label: 'View',
                            icon: 'look',
                            iconPos: 'icon-top',
                            showIcon: true,
                            action: (model) => store.routerHistory.push(`/licences/view/${model.id}`)
                        })}
                        updateAction={() => ({
                           label: 'Edit',
                           icon: 'edit',
                           iconPos: 'icon-top',
                           showIcon: true,
                           action: (model) => store.routerHistory.push(`/licences/edit/${model.id}`)
                        })}
                        collectionProps={{
                            onSearchTriggered: undefined,
                            menuFilterConfig: undefined,
                        }}
                    />
                </div>
            </div>
        );
        return contents;
    }

}