import React from "react";

export default function MarketingTile () {
	return (
		<div className="tile safety-tile access-full-suite" onClick={() => { window.open("https://www.masterelectricians.com.au/safety", '_blank') }}>					
			<div className="safety-tile__marketing-title">
				<h4>Access the full suite of safety resources</h4>
			</div>
			<div className="safety-tile__commands">
				<a className="view-button icon-left icon-look" target="_blank" rel="noreferrer">View</a>
			</div>
		</div>
	)
}