/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { Model, IModelAttributes, attribute, entity } from 'Models/Model';
import * as Models from 'Models/Entities';
import { IAcl } from '../Security/IAcl';
import { observable } from 'mobx';
import { VisitorsDocumentsReferenceManyToMany } from '../Security/Acl/VisitorsDocumentsReferenceManyToMany';
import { MehubAccountDocumentsReferenceManyToMany } from '../Security/Acl/MehubAccountDocumentsReferenceManyToMany';
import { MehubAdminDocumentsReferenceManyToMany } from '../Security/Acl/MehubAdminDocumentsReferenceManyToMany';

// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IDocumentTypesDocumentsAttributes extends IModelAttributes {
	documentTypesId: string;
	documentsId: string;

	documentTypes: Models.DocumentTypesEntity | Models.IDocumentTypesEntityAttributes;
	documents: Models.DocumentEntity | Models.IDocumentEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

@entity('DocumentTypesDocuments')
export default class DocumentTypesDocuments extends Model implements IDocumentTypesDocumentsAttributes {
	public static acls: IAcl[] = [
		new VisitorsDocumentsReferenceManyToMany(),
		new MehubAccountDocumentsReferenceManyToMany(),
		new MehubAdminDocumentsReferenceManyToMany(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	@observable
	@attribute()
	public documentTypesId: string;

	@observable
	@attribute()
	public documentsId: string;

	@observable
	@attribute({isReference: true})
	public documentTypes: Models.DocumentTypesEntity;

	@observable
	@attribute({isReference: true})
	public documents: Models.DocumentEntity;
	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	constructor(attributes?: Partial<IDocumentTypesDocumentsAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		if (attributes) {
			if (attributes.documentTypesId) {
				this.documentTypesId = attributes.documentTypesId;
			}
			if (attributes.documentsId) {
				this.documentsId = attributes.documentsId;
			}

			if (attributes.documentTypes) {
				if (attributes.documentTypes instanceof Models.DocumentTypesEntity) {
					this.documentTypes = attributes.documentTypes;
					this.documentTypesId = attributes.documentTypes.id;
				} else {
					this.documentTypes = new Models.DocumentTypesEntity(attributes.documentTypes);
					this.documentTypesId = this.documentTypes.id;
				}
			} else if (attributes.documentTypesId !== undefined) {
				this.documentTypesId = attributes.documentTypesId;
			}

			if (attributes.documents) {
				if (attributes.documents instanceof Models.DocumentEntity) {
					this.documents = attributes.documents;
					this.documentsId = attributes.documents.id;
				} else {
					this.documents = new Models.DocumentEntity(attributes.documents);
					this.documentsId = this.documents.id;
				}
			} else if (attributes.documentsId !== undefined) {
				this.documentsId = attributes.documentsId;
			}
		}

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}