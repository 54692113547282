/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import { TemplateEntity } from 'Models/Entities';
import SecuredPage from 'Views/Components/Security/SecuredPage';
import EntityCRUD from 'Views/Components/CRUD/EntityCRUD';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router';
import { getFrontendNavLinks } from 'Views/FrontendNavLinks';
import Navigation, { Orientation } from 'Views/Components/Navigation/Navigation';

// % protected region % [Add any extra imports here] on begin
import MasterTemplateLandingPage from "./MasterTemplateLandingPage";
import {Route, Switch} from 'react-router';

interface IMasterTemplateTabsRouteProps {
	tab?: string,
}
// % protected region % [Add any extra imports here] end

export interface MasterTemplatePageProps extends RouteComponentProps {
	// % protected region % [Add any extra props here] off begin
	// % protected region % [Add any extra props here] end
}

@observer
// % protected region % [Add any customisations to default class definition here] off begin
class MasterTemplatePage extends React.Component<MasterTemplatePageProps> {
// % protected region % [Add any customisations to default class definition here] end

	// % protected region % [Add class properties here] off begin
	// % protected region % [Add class properties here] end

	render() {
		// % protected region % [Add logic before rendering contents here] on begin
		const { match } = this.props;
		
		const masterTemplateLandingPage = (pageProps: RouteComponentProps<IMasterTemplateTabsRouteProps>) => {
			const { tab } = pageProps.match.params;

			return (
				<MasterTemplateLandingPage tab={tab} {...pageProps} />
			);
		};
		// % protected region % [Add logic before rendering contents here] end

		let contents = (
			<SecuredPage groups={['Super Administrators']}>
				{
				// % protected region % [Alter navigation here] off begin
				}
				<Navigation
					linkGroups={getFrontendNavLinks(this.props)}
					orientation={Orientation.VERTICAL}
					match={this.props.match}
					location={this.props.location}
					history={this.props.history}
					staticContext={this.props.staticContext}
				/>
				{
				// % protected region % [Alter navigation here] end
				}
				<div className="body-content">
					<EntityCRUD
						{...this.props}
						modelType={TemplateEntity}
						URLExtension="f17d2da4-b6c8-424c-b39b-46c5df579221"
						// % protected region % [Add props to crud component f17d2da4-b6c8-424c-b39b-46c5df579221 here] off begin
						// % protected region % [Add props to crud component f17d2da4-b6c8-424c-b39b-46c5df579221 here] end
					/>
				</div>
			</SecuredPage>
		);

		// % protected region % [Override contents here] on begin
		contents = (
			<Switch>
				<Route path={`${match.url}/:tab?`} render={masterTemplateLandingPage} />
			</Switch>
		);
		// % protected region % [Override contents here] end

		return contents;
	}
}

// % protected region % [Override export here] off begin
export default MasterTemplatePage;
// % protected region % [Override export here] end
