/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import { DocumentEntity } from 'Models/Entities';
import SecuredPage from 'Views/Components/Security/SecuredPage';
import EntityCRUD from 'Views/Components/CRUD/EntityCRUD';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router';
import { getFrontendNavLinks } from 'Views/FrontendNavLinks';
import Navigation, { Orientation } from 'Views/Components/Navigation/Navigation';

// % protected region % [Add any extra imports here] on begin
import {action, autorun, computed, observable, runInAction} from "mobx";
import classNames from "classnames";
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import {gql} from "@apollo/client";

import {store} from "Models/Store";
import { MehubAccountEntity } from 'Models/Entities';
import { IConditionalFetchArgs, Model } from 'Models/Model';
import {DocumentTypesEntity} from 'Models/Entities';
import * as Enums from 'Models/Enums';

import alertToast from "Util/ToastifyUtils";

import {ITabConfig} from "Views/Components/Tabs/Tabs";
import {EntityFormMode} from "Views/Components/Helpers/Common";
import {Button, Colors, Display, Sizes} from "Views/Components/Button/Button";
import If from "Views/Components/If/If";
import {alertModal} from "Views/Components/Modal/ModalUtils";
import MarketingTile from 'Views/CustomComponents/MarketingTile';
import { ScrollTabs, ITab} from 'Views/CustomComponents/ScrollTabs';
import BreadcrumbNavigation from "Views/CustomComponents/BreadcrumbNavigation";
import {IBreadCrumbsTag} from "Views/Components/Breadcrumbs/Breadcrumbs";
import qs from 'qs';
import {forEach} from "lodash";
import { SERVER_URL } from 'Constants';
// % protected region % [Add any extra imports here] end

export interface MyDocumentsPageProps extends RouteComponentProps {
	// % protected region % [Add any extra props here] off begin
	// % protected region % [Add any extra props here] end
}

@observer
// % protected region % [Add any customisations to default class definition here] off begin
class MyDocumentsPage extends React.Component<MyDocumentsPageProps> {
// % protected region % [Add any customisations to default class definition here] end

	// % protected region % [Add class properties here] on begin
	@observable
    private tabs: ITab[] = [];

	@observable
    private currentTab: ITab | undefined = undefined;

	@observable
	private organisationId: string;
	
	private getDocumentNameQuery = gql`query getDocumentName($id: [String]) {
		  documentEntity(where: {path: "id", comparison: equal, value: $id}) {
			documentName
		  }
		}`;

	@observable
	private lastCrumb: IBreadCrumbsTag | undefined;
	
	async componentDidMount() {
		const mehubAccount: MehubAccountEntity[] = await MehubAccountEntity
			.fetch({ args: [[{ path: 'id', comparison: 'equal', value: store.userId }]] });

		if (mehubAccount.length !== 0) {
			this.organisationId = mehubAccount[0].memberOrganisationId ?? '';
		}
		
		autorun(() => {
			if (this.props.location.pathname) {
				this.getDocumentTypeTabs();
			}
		});

		autorun(() => {
			if (this.props.location.pathname.includes("edit")
				|| this.props.location.pathname.includes("view")) {
				const listPathName = this.props.location.pathname.split('/');
				const id = listPathName[listPathName.length - 1];
				const mode = this.props.location.pathname.includes("edit") ? 'Edit' : 'View';
				store.apolloClient.query({
					query: this.getDocumentNameQuery,
					fetchPolicy: 'no-cache',
					variables: {id: id}
				}).then(action(result => { 
					this.lastCrumb = {label: `${mode} ${result.data.documentEntity.documentName}`,
						link: this.props.location.pathname};
				}))
			} else {
				action(() => this.lastCrumb = undefined);
			}
		})
	}

	@action
	getDocumentTypeTabs() {
		const fetchDocumentTypes = gql`query getDocumentTypes {
			documentTypesEntitys(
					where: {path: "meaDocument", comparison: equal, value: "true", negate: true},
					orderBy: {path: "order"},
					has: [[{
						path: "documentss",
					}]]
					) 
				{
					name
					description
					order
					roless {
						roles {
							name
						}
					}
					documentss {
						id
						documents {
							id
						}
					}
				}
			}`;
            
        store.apolloClient.query({
            query: fetchDocumentTypes,
            fetchPolicy: 'no-cache',
        }).then(action(result => {
			this.tabs = [];
            result.data.documentTypesEntitys.forEach((documentType : DocumentTypesEntity) => {
				this.tabs.push({
					name: documentType.name,
					key: encodeURIComponent(documentType.name),
					tooltip: documentType.description,
				});
            });
			
			// set current tab to expected default tab
			this.currentTab = this.tabs.length
				? this.tabs[0]
				: undefined;
        }));
	}

	@observable
	selectedReferences: DocumentTypesEntity[] = [];

	@observable
	private tabIndex: number = store.roleName === "SafetyWorker" ? 0 : 1; // Default Active

	@action
	private changeTab = (index: number) => {
		this.tabIndex = index;
	}

	@computed
	private get statusTabs(): ITabConfig[] {
		const isSafetyWorker = store.roleName === "SafetyWorker";
		
		// conditionally build filter tab array
		const tabs = [
			...(isSafetyWorker
				? []
				: [{
					name: 'Pending',
					key: 'PENDING',
					component: null
				}]),
			{
				name: 'Active',
				key: 'ACTIVE',
				component: null
			},
			...(isSafetyWorker
				? []
				: [{
					name: 'Inactive',
					key: 'INACTIVE',
					component: null
				}]),
		];
		
		return tabs;
	}
	
	@computed
	private get isShowEditButton(): boolean {
		let noEditStatus: Enums.documentStatus = 'INACTIVE';
		if(this.statusTabs.length > this.tabIndex) {
			return (store.roleName !== 'SafetyWorker') && this.statusTabs[this.tabIndex].key !== noEditStatus;
		} else {
			console.error("Invalid tab index")
		}
		return false;
	}
	
	searchDocuments = async (search: string) => {
		const args: IConditionalFetchArgs<DocumentTypesEntity> = {
			args: [[{path: 'name', comparison: 'like', value: `%${search}%`}],
				[{path: 'meaDocument', comparison: 'equal', value: 'false'},
					{path: 'meaDocument', comparison: 'equal', value: null}]]
		};
		const documents = await DocumentTypesEntity.fetch<DocumentTypesEntity>(
			args, undefined, undefined, true);

		return documents.map(x => ({
			display: x.getDisplayName(),
			value: x,
		}));
	};

	private downloadDocument = async (mode: 'includedIds' | 'excludedIds', ids: string[], event: React.MouseEvent<Element, MouseEvent>) =>
	{
		const models = (await store.apolloClient
			.query({
				query: gql`
                            query getDocuments($ids: [String]) {
                                documentEntitys(where: {path: "id", comparison: in, value: $ids}) {
                                    id
                                    documentUploadId
                                }
                            }`,
				variables: {
					ids,
				},
				fetchPolicy: 'network-only',
			})).data['documentEntitys'].map((d: any) => new DocumentEntity(d)) as DocumentEntity[];

		const selectedItems = models.map(element => element.documentUploadId);

		let newList: string[] = [];
		await selectedItems.forEach(param => {
			if (param != null)
				newList.push(param)
		});
		if (newList.length > 0){
			const url = `${SERVER_URL}/api/files/bulkDocuments?${qs.stringify({ documentId : newList }, {arrayFormat: 'repeat'})}`;
			window.open(url);
		} else{
			alertToast("No downloadable documents found. Only documents with a file attached can be downloaded.", "warning");
		}
	}
	// % protected region % [Add class properties here] end

	render() {
		// % protected region % [Add logic before rendering contents here] off begin
		// % protected region % [Add logic before rendering contents here] end

		let contents = (
			<SecuredPage groups={['Super Administrators']}>
				{
				// % protected region % [Alter navigation here] off begin
				}
				<Navigation
					linkGroups={getFrontendNavLinks(this.props)}
					orientation={Orientation.VERTICAL}
					match={this.props.match}
					location={this.props.location}
					history={this.props.history}
					staticContext={this.props.staticContext}
				/>
				{
				// % protected region % [Alter navigation here] end
				}
				<div className="body-content">
					<EntityCRUD
						{...this.props}
						modelType={DocumentEntity}
						URLExtension="cac03359-edab-4685-bb0d-d6954eb14a35"
						// % protected region % [Add props to crud component cac03359-edab-4685-bb0d-d6954eb14a35 here] off begin
						// % protected region % [Add props to crud component cac03359-edab-4685-bb0d-d6954eb14a35 here] end
					/>
				</div>
			</SecuredPage>
		);

		// % protected region % [Override contents here] on begin
		let displayMarketingTile = false;

		switch (store.roleName) {
			case Enums.mehubUserRoleOptions.MANAGER:
				displayMarketingTile = true;
				break;
		}

		contents = <SecuredPage groups={['Super Administrators', 'MehubAccount', 'MehubAdmin']}>
			<Navigation
				linkGroups={getFrontendNavLinks(this.props)}
				orientation={Orientation.HORIZONTAL}
				match={this.props.match}
				location={this.props.location}
				history={this.props.history}
				staticContext={this.props.staticContext}
				className={'hub-navigation'}
			/>
			<div className="body-content asset-page">
				<section className="page-header-new">
					<div className="breadcrumb-button">
						<BreadcrumbNavigation lastCrumb={this.lastCrumb} />
					</div>
					<div className="page-controls">
						<div className="page-controls__title">
							<h2>Controlled Documents</h2>
						</div>
					</div>
					<ScrollTabs 
						defaultTabKey={this.tabs.length ? this.tabs[0].key : undefined}
						tabs={this.tabs}
						onTabClick={action((clickedTab: ITab) => { this.currentTab = clickedTab })}
					/>
				</section>
				<If condition={displayMarketingTile}>
					<section className="primary-safety-tiles">
						<div className="primary-safety-tiles__tiles">
							<MarketingTile/>
						</div>
					</section>
				</If>
				<If condition={!displayMarketingTile}>
					<EntityCRUD
						{...this.props}
						collectionUpdateAction={this.isShowEditButton ? undefined : () => undefined}
						modelType={DocumentEntity}
						collectionCreateAction={() =>
							<div className="create-download-document-buttons">
								<If condition={store.roleName !== 'SafetyWorker'}>
									<Button className="master-template-library-button"
											display={this.statusTabs[this.tabIndex].key === 'ACTIVE' ? Display.Solid : Display.NoShow}
											colors={this.statusTabs[this.tabIndex].key === 'ACTIVE' ? Colors.FormPrimary : Colors.NoShow}
											sizes={this.statusTabs[this.tabIndex].key === 'ACTIVE' ? Sizes.Medium : Sizes.NoShow}
											onClick={() => {store.routerHistory.push(`/safety-template`)}}>
										New from safety templates
									</Button>
								</If>
								<Button
									key="create"
									className={classNames(Display.Outline, 'create-document')}
									colors={Colors.FormSecondary}
									icon={{icon: 'create', iconPos: 'icon-right'}}
									buttonProps={
										{ 
											onClick: () => { 
												this.props.history.push(`${this.props.match.url}/create`); 
											} 
										}
									}>
									Create Document
								</Button>
							</div>
							}
						disableBulkExport
						additionalBulkActions={[{
							bulkAction: this.downloadDocument,
							label: "Download",
							showIcon: true,
							icon: "download",
							iconPos: 'icon-left',
						}]}
						hasConditon={[[{
							path: "documentTypess" as keyof Model, 
							conditions: [[{
									path: "documentTypes.name", 
									comparison: "equal", 
									value: this.currentTab?.name
								}], [{
									path: "documentTypes.meaDocument",
									comparison: "equal",
									value: "false",
									}, {
									path: "documentTypes.meaDocument",
									comparison: "equal",
									value: null,
								}]]
							}
						]]}
						filterCollection={[[{
							path: 'status',
							comparison: 'equal',
							value: this.statusTabs[this.tabIndex].key
						}
						]]}
						additionalActions={[
							<div className="status-toggle-buttons">
								{this.statusTabs.map((t, index) => (
									<Button
										key={t.key}
										className={classNames(Display.Outline, 'status-filter', { active: this.tabIndex === index})}
										colors={Colors.FormSecondary}
										sizes={Sizes.Large}
										onClick={() => this.changeTab(index)}
									>
										{t.name}
									</Button>
								))}
							</div>
						]}
						additionalFilters={[
							{
								path: 'documentTypess',
								displayType: 'reference-multicombobox',
								comparison: 'equal',
								value1: '',
								value2: '',
								active: false,
								displayName: 'Document Type',
								references: this.selectedReferences,
								referencePath: 'documentTypes',
								referenceResolveFunction: AwesomeDebouncePromise(this.searchDocuments, 300),
							}
						]}
						entityCollectionProps={{
							initialFilterApplied: true,
						}}
						mutateOptions={(model, options, mode) => {
							if(model instanceof DocumentEntity) {
								options.forEach(option => {
									if(option.attributeName === 'annualReview') {
										runInAction(()=>{
											model[option.attributeName] = model[option.attributeName] ?? true;
										});	
									}
								});
							}
							return options;
						}}
						saveFn={async (entity: DocumentEntity, formMode: EntityFormMode) => {
							if (formMode === 'create') {
								if (store.roleName == 'SafetyManager') {
									entity.status = 'ACTIVE';
								} else {
									entity.status = 'PENDING';
								}
								if (this.organisationId) {
									entity.memberOrganisationId = this.organisationId;
								}
							}
							
							if (formMode === 'create' || formMode === 'edit') {
								if (entity.documentLink != null && entity.documentUpload != null) {
									alertToast('You can either upload a document or attach a URL against ' +
										'your document', 'error');
									throw new Error(''); // [MEASD-111] This is a means to control toast messages
								}
							}
							await entity.saveFromCrud(formMode);
							
							if (store.roleName === "SafetyWorker") {
								alertModal("Document approval pending",
									"You have successfully published this document, " +
									"however it requires approval from a manager before it can be attached to a form. " +
									"It will appear on the Activity page when your document is approved", 
									{cancelText: "Continue"});
							}
						}}
						actionsMore={[{
							onEntityClick: action(async (arg, entity: DocumentEntity) => {
								entity.status = 'ACTIVE';
								await entity.save();
							}),
							condition: (entity: DocumentEntity) => entity.status === "PENDING",
							label: 'Approve'
						},
						{
							onEntityClick: action(async (arg, entity: DocumentEntity) => {
								await entity.delete();
								window.location.reload(); // TODO: use better way than page reload
							}),
							condition: (entity: DocumentEntity) => entity.status === "PENDING",
							label: 'Remove'
						},
						{
							onEntityClick: action(async (arg, entity: DocumentEntity) => {
								entity.status = 'ACTIVE';
								await entity.save();
							}),
							condition: (entity: DocumentEntity) => entity.status === "INACTIVE",
							label: 'Make Active'
						},
						{
							onEntityClick: action(async (arg, entity: DocumentEntity) => {
								entity.status = 'INACTIVE';
								await entity.save();
							}),
							condition: (entity: DocumentEntity) => entity.status === "ACTIVE" 
								&& (store.roleName === 'SafetyManager' || store.hasBackendAccess),
							label: 'Make Inactive'
						},
						{
							onEntityClick: action(async (arg, entity: DocumentEntity) => {
							}),
							condition: (entity: DocumentEntity) => store.roleName !== 'SafetyManager' 
								&& !store.hasBackendAccess,
							label: 'No actions available'
						}
						]}
					/>
				</If>
			</div>
		</SecuredPage>

		// % protected region % [Override contents here] end

		return contents;
	}
}

// % protected region % [Override export here] off begin
export default MyDocumentsPage;
// % protected region % [Override export here] end
