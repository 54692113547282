import * as React from 'react';
import SecuredPage from 'Views/Components/Security/SecuredPage';
import EntityCRUD from 'Views/Components/CRUD/EntityCRUD';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router';
import { getFrontendNavLinks } from 'Views/FrontendNavLinks';
import Navigation, { Orientation } from 'Views/Components/Navigation/Navigation';
import {Button, Colors, Display, Sizes} from "../Components/Button/Button";
import {AssetAttachmentsEntity, LicenceEntity} from "../../Models/Entities";
import {Observable} from "@apollo/client";
import {action, observable} from "mobx";
import {getAttributeComponent} from "../Components/CRUD/Attributes/AttributeFactory";
import {EntityFormMode} from "../Components/Helpers/Common";
import {isRequired} from "../../Util/EntityUtils";
import alert from "../../Util/ToastifyUtils";
import {store} from "../../Models/Store";
import {SERVER_URL} from "../../Constants";
import { mehubUserRoleOptions, licenceStatus } from 'Models/Enums';
import {adminIsDraftModal} from "../Components/Modal/ModalUtils";
import Modal from "../Components/Modal/Modal";
import {alertModal} from "../Components/Modal/ModalUtils";
import MultiFileUpload from 'Views/Components/FileUpload/MultiFileUpload';

export interface NewLicenceProps {
    id: string;
}


@observer
class NewLicence extends React.Component<NewLicenceProps> {

    @observable
    private licenceEntity: LicenceEntity = new LicenceEntity({
        mehubAccountId: this.props.id
    });

    private renderBasicLicenceDetails() {
        const fields = [
            'licenceName',
            'licenceIssuer',
            'licenceType',
            'licenceNumber',
            'renewalDate',
        ];
        return this.createLicenceFields(fields)
    }
    
    private renderLicenceDesc() {
        const fields = [
            'description',
        ];
        return this.createLicenceFields(fields)
    }

    private createLicenceFields(fields: string[]) {
        const model = this.licenceEntity;

        return this.licenceEntity.getAttributeCRUDOptions()
            .filter(x => fields.indexOf(x.attributeName) > -1)
            .sort((a, b) =>
                fields.indexOf(a.attributeName) - fields.indexOf(b.attributeName))
            .map(attributeOptions => getAttributeComponent(attributeOptions,
                model,
                model.getErrorsForAttribute(attributeOptions.attributeName),
                EntityFormMode.CREATE,
                isRequired(model, attributeOptions.attributeName),
                undefined,
                model.validate.bind(model)));
    }

    private saveLicence = async () => {
        let status: licenceStatus = "PENDING";
        let isSkipPending = mehubUserRoleOptions.SAFETY_MANAGER === store.roleName || store.hasBackendAccess;
        if(isSkipPending) {
            let currentDate = new Date();
            if(this.licenceEntity.renewalDate !== undefined) {
                status = this.licenceEntity.renewalDate.getTime() > currentDate.getTime() ? "ACTIVE" : "EXPIRED";
            } else {
                status = "ACTIVE";
            } 
            
            this.licenceEntity.authorizedDate = currentDate;
        }
        this.licenceEntity.status = status;

        await this.licenceEntity.validate();

        if (this.licenceEntity.hasValidationError) {
            return;
        }
        //grabbed the implementation done similar to NewAsset from MEASD-258
        try {
            const relationPath = {
                mehubAccounts: {},
                licenceAttachmentss: {},
            };
            await this.licenceEntity.save(
                relationPath,
                {
                    options: [
                        {
                            key: 'mergeReferences',
                            graphQlType: '[String]',
                            value: [
                                'mehubAccounts',
                                'licenceAtachmentss',   
                            ]
                        },
                    ],
                    contentType: 'multipart/form-data',
                }
            );
            
            if (store.roleName == mehubUserRoleOptions.SAFETY_WORKER) {
                alertModal("Licence Approval Pending",
                    "You have successfully uploaded your licence, and it can be viewed on your User page. However, it requires approval from " +
                    "a manager before it's Active. Until this time, it will show a status of Pending.", {cancelText: "Continue"});
            } else {
                alert('Successfully uploaded licence', 'success');
            }
            
            store.routerHistory.push(`${SERVER_URL}/users`);
        } catch (e) {
            alert('Error saving user', 'error');
        }
    }
    
    render() {
        let contents = (
                    <div className="licence-entity">
                        <div className="licence-details-page">
                            <MultiFileUpload 
                                model={this.licenceEntity}
                                modelProperty="licenceAttachmentss"
                                fileModel={AssetAttachmentsEntity}
                                fileModelProperty="attachments"
                                preview={true}
                                disableDropArea={true}
                            />
                            <div className="licence-fields">
                                {this.renderBasicLicenceDetails()}
                            </div>
                            <div className="licence-upload-desc">
                                {this.renderLicenceDesc()}
                            </div>
                            <div className="licence-button-group">
                                <Button className="cancel-licence"
                                        onClick={() => store.routerHistory.goBack()}
                                        display={Display.Outline}
                                        colors={Colors.FormPrimary}
                                        sizes={Sizes.Medium}>
                                    Cancel
                                </Button>
                                <Button className="save-licence"
                                        onClick={this.saveLicence}
                                        display={Display.Solid}
                                        colors={Colors.FormPrimary}
                                        sizes={Sizes.Medium}>
                                    Save
                                </Button>
                            </div>
                        </div>
                    </div>
        );

        return contents;
    }
}

export default NewLicence;

